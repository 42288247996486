import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

import SocialMedia from "../SocialMedia/SocialMedia";

const Footer = (props) => {
  return (
    <div
      className="Footer"
      style={props.background ? { background: props.background } : {}}
    >
      <div className="FooterContent">
        <SocialMedia />
        <div className="FooterTexts">
          <Link to="/terms">Terms of Services</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
        <div
          style={{
            height: "2px",
            background: "#ffe53d",
            width: "100%",
            marginTop: "1.5rem",
            marginBottom: "1rem",
          }}
        ></div>
        <p>COPYRIGHT APOLLO ENTERTAINMENT INC. 2024</p>
      </div>
    </div>
  );
};

export default Footer;
