import React from "react";
import GameIntro from "./GameIntro/GameIntro";
import Gameplay from "./Gameplay/Gameplay";
import PlayAndEarn from "./PlayAndEarn/PlayAndEarn";
import ActiveEarning from "./ActiveEarning/ActiveEarning";
import "./NewLegions.css";

const NewLegions = (props) => {
  return (
    <div className="NewLegions">
      <GameIntro width={props.width} />
      <Gameplay width={props.width} />
      <PlayAndEarn width={props.width} />
      <ActiveEarning width={props.width} />
    </div>
  );
};

export default NewLegions;
