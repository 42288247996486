import React from "react";
import "./FormularyBTC.css";

import StickyNav from "../StickyNav/StickyNav";
import Formulary from "./Formulary/Formulary";

const FormularyBTC = (props) => {
  return (
    <div className="JS-Scroll">
      <div
        className="HomeSection"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/BKG.png)`,
        }}
      >
        {/* <StickyNav
          onLoreClick={props.onLoreClick}
          onScrollClick={props.onScrollClick}
          changeRoute={props.changeRoute}
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          useSmooth={true}
        /> */}
        <Formulary width={props.width} />
      </div>
    </div>
  );
};

export default FormularyBTC;
