import React from 'react';
import "./SocialItem.css";

const SocialItem = (props) => {
    return (
        <li id={props.id} className="SocialItem" style={props.customStyle}>
            <a href={props.link} target="_blank">
                <img src={props.src} style={props.imgStyle}></img>
            </a>
        </li>
    );
};

export default SocialItem;