import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { purchaseDiscounted } from "actions/whitelistActions";

import { Square } from "components/ApemoArmyTest/styles";

import "./Phase0.css";

const Phase0 = (props) => {
  const isWalletConnected = useSelector(
    (state) => state.block.isWalletConnected
  );

  const isMinting = useSelector((state) => state.whitelist.isMinting);
  const isFetchingData = useSelector((state) => state.block.isFetchingData);

  // // Is in Ape whitelist (50% Discount)
  // const isInApeWhitelist = useSelector(
  //   (state) =>
  //     state.whitelist.whitelistStatuses["apeList"] &&
  //     state.whitelist.whitelistStatuses["apeList"].isInWhitelist
  // );

  // // Is in Ape whitelist (20% Discount)
  // const isInCrewmenWhitelist = useSelector(
  //   (state) =>
  //     state.whitelist.whitelistStatuses["crewmenList"] &&
  //     state.whitelist.whitelistStatuses["crewmenList"].isInWhitelist
  // );

  // // Is in Trevor whitelist (20% Discount)
  // const isInTrevorWhitelist = useSelector(
  //   (state) =>
  //     state.whitelist.whitelistStatuses["trevorList"] &&
  //     state.whitelist.whitelistStatuses["trevorList"].isInWhitelist
  // );

  // // Is in Hackatao whitelist (20% Discount)
  // const isInHackataoWhitelist = useSelector(
  //   (state) =>
  //     state.whitelist.whitelistStatuses["hackataoList"] &&
  //     state.whitelist.whitelistStatuses["hackataoList"].isInWhitelist
  // );

  // // Is in Public Allow whitelist
  // const isInPublicAllowWhitelist = useSelector(
  //   (state) =>
  //     state.whitelist.whitelistStatuses["publicAllowList"] &&
  //     state.whitelist.whitelistStatuses["publicAllowList"].isInWhitelist
  // );

  // // Is in Free Claim whitelist
  // const isInFreeClaimWhitelist = useSelector(
  //   (state) =>
  //     state.whitelist.whitelistStatuses["freeClaimList"] &&
  //     state.whitelist.whitelistStatuses["freeClaimList"].isInWhitelist
  // );

  // Ape allotment (max limit to purchase)
  const apeAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["apeList"] !== undefined
      ? state.whitelist.whitelistStatuses["apeList"].allotment
      : 0
  );

  // Crewmen allotment (max limit to purchase)
  const crewmenAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["crewmenList"] !== undefined
      ? state.whitelist.whitelistStatuses["crewmenList"].allotment
      : 0
  );

  // Trevor allotment (max limit to purchase)
  const trevorAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["trevorList"] !== undefined
      ? state.whitelist.whitelistStatuses["trevorList"].allotment
      : 0
  );

  // Hackatao allotment (max limit to purchase)
  const hackataoAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["hackataoList"] !== undefined
      ? state.whitelist.whitelistStatuses["hackataoList"].allotment
      : 0
  );

  // Public Allow allotment (max limit to purchase)
  const publicAllowAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["publicAllowList"] !== undefined
      ? state.whitelist.whitelistStatuses["publicAllowList"].allotment
      : 0
  );

  // // Free Claim allotment (max limit to claim)
  // const freeClaimAllotment = useSelector((state) =>
  //   state.whitelist.whitelistStatuses["freeClaimList"] !== undefined
  //     ? state.whitelist.whitelistStatuses["freeClaimList"].allotment
  //     : 0
  // );

  // Current purchased amount with the ape list
  const apeCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["apeList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["apeList"]
      : 0
  );

  // Current purchased amount with the crewmen list
  const crewmenCount = useSelector((state) =>  
    state.whitelist.whitelistTokenCounts["crewmenList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["crewmenList"]
      : 0
  );

  // Current purchased amount with the trevor list
  const trevorCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["trevorList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["trevorList"]
      : 0
  );

  // Current purchased amount with the hackatao list
  const hackataoCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["hackataoList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["hackataoList"]
      : 0
  );

  // Current purchased amount with the public allow list
  const publicAllowCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["publicAllowList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["publicAllowList"]
      : 0
  );

  // // Current claimed amount with the free claim list
  // const freeClaimCount = useSelector((state) =>
  //   state.whitelist.whitelistTokenCounts["freeClaimList"] !== undefined
  //     ? state.whitelist.whitelistTokenCounts["freeClaimList"]
  //     : 0
  // );

  const leftPurchaseCount = useSelector((state) =>
    state.whitelist.leftPurchaseCount !== undefined
      ? state.whitelist.leftPurchaseCount
      : 5000
  );

  // const isInWhitelist =
  //   isInApeWhitelist ||
  //   isInCrewmenWhitelist ||
  //   isInTrevorWhitelist ||
  //   isInHackataoWhitelist ||
  //   isInPublicAllowWhitelist;

  let minApePurchaseCount, maxApePurchaseCount;
  let minCrewmenPurchaseCount, maxCrewmenPurchaseCount;
  let minTrevorPurchaseCount, maxTrevorPurchaseCount;
  let minHackataoPurchaseCount, maxHackataoPurchaseCount;
  let minPublicAllowPurchaseCount, maxPublicAllowPurchaseCount;

  // let minClaimCount = 0,
  //   maxClaimCount = 0;

  minApePurchaseCount = apeAllotment - apeCount > 0 ? 1 : 0;
  maxApePurchaseCount =
    apeAllotment - apeCount >= 0 ? apeAllotment - apeCount : 0;

  minCrewmenPurchaseCount = crewmenAllotment - crewmenCount > 0 ? 1 : 0;
  maxCrewmenPurchaseCount =
    crewmenAllotment - crewmenCount >= 0 ? crewmenAllotment - crewmenCount : 0;

  minTrevorPurchaseCount = trevorAllotment - trevorCount > 0 ? 1 : 0;
  maxTrevorPurchaseCount =
    trevorAllotment - trevorCount >= 0 ? trevorAllotment - trevorCount : 0;

  minHackataoPurchaseCount = hackataoAllotment - hackataoCount > 0 ? 1 : 0;
  maxHackataoPurchaseCount =
    hackataoAllotment - hackataoCount >= 0
      ? hackataoAllotment - hackataoCount
      : 0;

  minPublicAllowPurchaseCount =
    publicAllowAllotment - publicAllowCount > 0 ? 1 : 0;
  maxPublicAllowPurchaseCount =
    publicAllowAllotment - publicAllowCount >= 0
      ? publicAllowAllotment - publicAllowCount
      : 0;

  // if (isInFreeClaimWhitelist) {
  //   minClaimCount = freeClaimAllotment - freeClaimCount > 0 ? 1 : 0;
  //   maxClaimCount =
  //     freeClaimAllotment - freeClaimCount >= 0
  //       ? freeClaimAllotment - freeClaimCount
  //       : 0;
  // } else {
  //   minClaimCount = 0;
  //   maxClaimCount = 0;
  // }

  const [purchaseCount, setPurchaseCount] = useState(1);

  const handleChangePurchaseCount = (event) => {
    let { value } = event.target;
    setPurchaseCount(value);
  };

  useEffect(() => {
    if (!isMinting) setPurchaseCount(1);
  }, [isMinting]);

  const dispatch = useDispatch();

  const onPurchaseDiscounted = (count, whitelistId) => {
    dispatch(purchaseDiscounted(count, whitelistId));
  };

  const validateCount = (count, min, max) => {
    return count >= min && count <= max;
  };

  return (
    <div className="PhaseContainer">
      {isWalletConnected ? (
        <div className="PhaseContent">
          <br />
          {isWalletConnected && !isFetchingData ? (
            <span
              style={{
                fontSize: "28px",
                display: "block",
                marginBottom: "1rem",
              }}
            >
              You currently have the following allow list eligibility:
            </span>
          ) : null}

          <span className="blueBox">
            Apelist Allotment:{" "}
            <span className="highlightedText">
              {maxApePurchaseCount} Apemo Army Avatars
            </span>
          </span>
          <div className="yellowTextContainer">
            <Square />
            <Square />
            <Square />
            <span className="yellowText">
              Open from August, 22nd at 9 AM PST to August 23rd at 9 AM PST -
              FCFS
            </span>
            <Square />
            <Square />
            <Square />
          </div>
          <span className="numberETH">0.05 ETH</span>
          <span className="blueBox">
            Crewmen’s List Allotment:{" "}
            <span className="highlightedText">
              {maxCrewmenPurchaseCount} Apemo Army Avatars
            </span>
          </span>
          <div className="yellowTextContainer">
            <Square />
            <Square />
            <Square />
            <span className="yellowText">
              Open from August, 22nd at 9 AM PST to August 23rd at 9 AM PST -
              FCFS
            </span>
            <Square />
            <Square />
            <Square />
          </div>
          <span className="numberETH">0.08 ETH</span>
          <span className="blueBox">
            Hackatao Q+K Texture Allotment:{" "}
            <span className="highlightedText">
              {maxHackataoPurchaseCount} Apemo Army Avatars
            </span>
          </span>
          <div className="yellowTextContainer">
            <Square />
            <Square />
            <Square />
            <span className="yellowText">
              Open from August, 22nd at 9 AM PST to August 23rd at 9 AM PST -
              FCFS
            </span>
            <Square />
            <Square />
            <Square />
          </div>
          <span className="numberETH">0.08 ETH</span>
          <span className="blueBox">
            Bitcoin Angel Texture Allotment:{" "}
            <span className="highlightedText">
              {maxTrevorPurchaseCount} Apemo Army Avatars
            </span>
          </span>
          <div className="yellowTextContainer">
            <Square />
            <Square />
            <Square />
            <span className="yellowText">
              Open from August, 22nd at 9 AM PST to August 23rd at 9 AM PST -
              FCFS
            </span>
            <Square />
            <Square />
            <Square />
          </div>
          <span className="numberETH">0.08 ETH</span>
          <span className="blueBox">
            Public Allow List Allotment:{" "}
            <span className="highlightedText">
              {" "}
              {maxPublicAllowPurchaseCount} Apemo Army Avatars{" "}
            </span>
          </span>
          <div className="yellowTextContainer">
            <Square />
            <Square />
            <Square />
            <span className="yellowText">
              Open from August, 22nd at 10 AM PST to August 23rd at 9 AM PST -
              FCFS
            </span>
            <Square />
            <Square />
            <Square />
          </div>
          <span className="numberETH">0.1 ETH</span>

          <span className="botText" style={{ marginBottom: "2rem" }}>
            Please note that you will not be able to mint using an allow list
            spot after 9 AM PST on August 23rd. At that time the mint will be
            open to the public and only public mints will be available.
          </span>
          {/* <span>Ape whitelist remaining allotment: {maxApePurchaseCount}</span>

          <span>
            Crewmen whitelist remaining allotment: {maxCrewmenPurchaseCount}
          </span>

          <span>
            Trevor whitelist remaining allotment: {maxTrevorPurchaseCount}
          </span>

          <span>
            Hackatao whitelist remaining allotment: {maxHackataoPurchaseCount}
          </span>

          <span>FreeClaim whitelist remaining allotment: {maxClaimCount}</span> */}
        </div>
      ) : null}
    </div>
  );
};

export default Phase0;
