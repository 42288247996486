//Libraries
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Form } from './Form';
import { ConnectButtons } from '../Connect';
import { ErrorBox } from '../ErrorBox';

//Styles
import {
  Div,
  Text,
  Button,
  MainContainer,
  ImageContainer,
  Input
} from '../styled';


const TypeClaim = ({ imagen, titleButton, isFetchingData, tokensCount, nameToken }) => (
  <Div width={isMobile ? "100%" : "22%"} mb={isMobile && 40} isFlex direction="column">
    <ImageContainer mb={30} width="100%" isCenter>
      <img src={imagen} alt="titleButton" />
    </ImageContainer>
    <Div isCenter width="100%">
      <Button isMobile>
        <Text size={30} color="yellow" isInline>
          {!isFetchingData ? (
            <>
              x{tokensCount[nameToken]}
            </>
          ) : (
            <>
              -
            </>
          )}
        </Text>
        <Text size={isMobile ? 20 : 24} color="white" isInline pl={30}>
          {titleButton}
        </Text>
      </Button>
    </Div>
  </Div>
);

const BottomButtons = ({
  quantity = 0, numTokens, onClaim, onClaimUnclaimed, getUnclaimedTokens, addQuantity, substractQuantity, onChange, isClaimingLoading, isBuyingLoading, validate
}) => (
  <Div isFlex width="90%">
    <Button isMobile={isMobile} width={isMobile ? "80%" : "30%"} radius="15px" pt={!isMobile && 15} pb={!isMobile && 15}>
      <Text size={isMobile ? 20 : 24}>
        Total unclaimed:
      </Text>
      <Text pl={isMobile ? 5 : 15} size={isMobile ? 40 : 50} isInline>
        {getUnclaimedTokens()}
      </Text>
    </Button>
    {/*<Div width={isMobile ? "80%" : "30%"} mt={isMobile && 15} mb={isMobile && 15}>
      <GhostButtons isMobile={isMobile} onClick={() => addQuantity()}>
        <Arrow isMobile={isMobile} src={upArrow} alt="upArrow" mb={5} />
      </GhostButtons>
      <Div isCenter>
        <Button isMobile={isMobile} width="auto" radius="15px" pt={!isMobile && 15} pb={!isMobile && 15} pl={!isMobile && 20} pr={!isMobile && 5}>
          <Text size={isMobile ? 20 : 28} isInline>
            Quantity to claim
          </Text>
          <Input
            isMobile={isMobile}
            color="#FFC200"
            size={isMobile ? 30 : 40}
            type="number"
            className="LaunchInput"
            name="quantity"
            placeholder=""
            onChange={onChange}
            value={quantity}
            min={0}
            max={255}
            step={1}
            required={true}
            disabled={(isClaimingLoading || isBuyingLoading )}
          />
        </Button>
      </Div>
      <GhostButtons isMobile={isMobile} onClick={() => substractQuantity()}>
        <Arrow isMobile={isMobile} src={downArrow} alt="upArrow" mt={10} />
      </GhostButtons>
    </Div>*/}
    {/* <Button isDisabled={validate} disabled={validate} isMobile={isMobile} width={isMobile ? "80%" : "30%"} radius="15px" pt={!isMobile && 15} pb={!isMobile && 15} ml={25} onClick={() => {
      onClaimUnclaimed()
    }}> */}
    {/* <img src={mint} alt="claim" /> */}
    {/* <Text size={30}>
        Claim
      </Text>
    </Button> */}
  </Div>
)
export const Claim3 = ({
  addQuantity,
  substractQuantity,
  connectWallet,
  onClaim,
  onClaimUnclaimed,
  validateUnclaimed,
  getUnclaimedTokens,
  quantity,
  isClaimingLoading,
  isBuyingLoading,
  onChange,
  disconnectWallet,
  ellipsify,
  address,
  setShowInstructions,
  setShowInstructionsMobile,
  isFetchingData,
  tokensCount,
  renderByPhase,
  msg,
  statusMsg,
  validateClaim,
  setIsClaim,
}) => {
  useEffect(() => {
    setIsClaim(true);
  }, [])
  return (
    <MainContainer>
      <Text color="#FFC200" size={40} mb={30} isCenter>
        Claim
      </Text>
      {/*Buttons */}
      <Div isCenter width="100%" direction="column" mb={40}>
        <Div
          width={isMobile ? '100%' : '50%'}
        >
          <ConnectButtons
            connectWallet={connectWallet}
            disconnectWallet={disconnectWallet}
            ellipsify={ellipsify}
            address={address}
            isMobile={isMobile}
            setShowInstructionsMobile={setShowInstructionsMobile}
            setShowInstructions={setShowInstructions}
            msg={msg}
          />
        </Div>
        {/* Section Tokens */}
        {/*<Div isFlex direction={isMobile && "column"} justify="space-between" mb={isMobile ? 20 : 70} width="90%">
          <TypeClaim isFetchingData={isFetchingData} tokensCount={tokensCount} imagen={legToken} nameToken="genesis" titleButton="Genesis Token" />
          <TypeClaim isFetchingData={isFetchingData} tokensCount={tokensCount} imagen={legSilver} nameToken="platinum" titleButton="Platinum Token" />
          <TypeClaim isFetchingData={isFetchingData} tokensCount={tokensCount} imagen={legGold} nameToken="gold" titleButton="Gold Token" />
          <TypeClaim isFetchingData={isFetchingData} tokensCount={tokensCount} imagen={legGray} nameToken="silver" titleButton="Silver Token" />
        </Div>*/}
        <BottomButtons
          onClaim={onClaim}
          onClaimUnclaimed={onClaimUnclaimed}
          getUnclaimedTokens={getUnclaimedTokens}
          quantity={quantity}
          addQuantity={addQuantity}
          substractQuantity={substractQuantity}
          isClaimingLoading={isClaimingLoading}
          isBuyingLoading={isBuyingLoading}
          onChange={onChange}
          renderByPhase={renderByPhase}
          numTokens={() => renderByPhase()}
          validate={validateUnclaimed}
        />
      </Div>
      <ErrorBox isMobile={isMobile} statusMsg={statusMsg} />
      {/*Buttons bottom */}

      {(getUnclaimedTokens() > 0 && (
        <Form getUnclaimedTokens={getUnclaimedTokens} address={address} />
      ))}

      <Div isCenter>
      </Div>
    </MainContainer>
  )
}

//Custom styles for this component.
export const Arrow = styled.img`
  
  width:${(props) => props.isMobile ? "25px" : "40px"};
  margin-top: ${(props) => props.mt && props.mt}px;
    margin-bottom: ${(props) => props.mb && props.mb}px;
`

export const GhostButtons = styled.button`
  position:relative;
  left:${(props) => props.isMobile ? "78.5%" : "73%"};
  border: none;
  background: none;
`