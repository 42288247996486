import React, { Component } from "react";
import "./TheHundredForm.css";

//import AvatarDropdown from "../AvatarDropdown/AvatarDropdown";
import CityDropdown from "../CityDropdown/CityDropdown";
import cities from "../cities";

import PreferenceDropdown from "../PreferenceDropdown/PreferenceDropdown";

import axios from "axios";

const apiPath = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "";

class TheHundredForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      project_name: "",
      email_address: "",

      avatar_name: "",

      // legion_nickname: "",

      cities: cities,
      city: "",
      cityErrorMsg: "",

      avatar: "",
      avatarErrorMsg: "",

      selectedLegionLogoFile: "",
      selectedLegionLogoFileErrorMsg: "",

      biography: "",
      media_links: "",

      preference: "",
      preferenceErrorMsg: "",

      comments: "",

      eth_address: "",

      errorList: [],

      isLoading: false,

      stateMsg: "",
    };
  }

  onFile = (e) => {
    if (e.target.files[0].size <= 50000000) {
      this.setState({
        selectedLegionLogoFile: e.target.files[0],
        selectedLegionLogoFileErrorMsg: "",
      });
    } else {
      this.setSelectedLegionLogoFileErrorMsg("Max file size exceeded!");
    }
  };

  onChange = async (e) => {
    let targetValue = e.target.value;
    await this.setState({
      [e.target.name]: targetValue,
    });

    if (e.target.name === "city") {
      this.setState({
        cities:
          this.state.city !== ""
            ? cities.filter((city) =>
                city.toLowerCase().includes(this.state.city.toLowerCase())
              )
            : cities,
      });
    } else if (e.target.name === "avatar") {
      //console.log(this.state.avatar);
    }
  };

  cityExists = (city) => {
    return cities.includes(city);
  };

  setCityErrorMsg = (msg) => {
    this.setState({
      cityErrorMsg: msg,
    });
  };

  setAvatarErrorMsg = (msg) => {
    this.setState({
      avatarErrorMsg: msg,
    });
  };

  setPreferenceErrorMsg = (msg) => {
    this.setState({
      preferenceErrorMsg: msg,
    });
  };

  setSelectedLegionLogoFileErrorMsg = (msg) => {
    this.setState({
      selectedLegionLogoFileErrorMsg: msg,
    });
  };

  onClickCity = (city) => {
    this.setState({ city });
  };

  onClickAvatar = (avatar) => {
    this.setState({ avatar });
  };

  onClickPreference = (preference) => {
    this.setState({ preference });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      project_name, // Optional
      email_address,
      avatar_name,
      city,
      // legion_nickname, // Optional
      selectedLegionLogoFile, // NOT Optional
      avatar,
      biography, // Optional
      media_links,
      preference,
      comments,
      eth_address
    } = this.state;

    /*console.log("avatar_name: ", avatar_name);
    console.log("city: ", city);
    console.log("legion_nickname: ", legion_nickname);
    console.log("selectedLegionLogoFile: ", selectedLegionLogoFile);
    console.log("avatar: ", avatar);
    console.log("biography: ", biography);
    console.log("media_links: ", media_links);
    console.log("preference: ", preference);
    console.log("comments: ", comments);*/

    if (
      name &&
      email_address &&
      avatar_name &&
      city &&
      this.cityExists(city) &&
      selectedLegionLogoFile &&
      avatar &&
      media_links &&
      preference &&
      comments &&
      eth_address
    ) {
      await this.setState({
        errorList: [],
        stateMsg: "",
        isLoading: true,
      });

      const formData = new FormData();
      formData.append("name", name);
      formData.append("project_name", project_name);
      formData.append("email_address", email_address);
      formData.append("avatar_name", avatar_name);
      formData.append("legion_city", city);
      // formData.append("legion_nickname", legion_nickname);
      formData.append("legion_logo", selectedLegionLogoFile);
      formData.append("avatar_style", avatar);
      formData.append("biography", biography);
      formData.append("media_links", media_links);
      formData.append("giveaway_preference", preference);
      formData.append("comments", comments);
      formData.append("eth_address", eth_address);

      try {
        const response = await axios({
          method: "post",
          url: `${apiPath}/legions`,
          data: formData,
          headers: {
            //"Content-Type": "multipart/form-data",
          },
        });

        this.setState({
          isLoading: false,
          stateMsg: "Operation Success!",
        });

        //console.log("response: ", response);
      } catch (error) {
        if (error.response) {
          //console.log(error.response.msg);
        }
        //console.log(error);

        this.setState({
          isLoading: false,
          stateMsg: "Operation Failed!",
        });
      }
    } else {
      //console.log("Fill the required fields: ");

      let errorList = [];

      if (!name) {
        errorList.push("Fill in your name.");
      }
      if (!email_address) {
        errorList.push("Fill in your email address.");
      }
      if (!avatar_name) {
        errorList.push("Fill in Avatar name.");
      }
      if (!selectedLegionLogoFile){
        errorList.push("Please upload a Legion logo/art")
      }
      if (!avatar) {
        errorList.push("Select an avatar style.");
      }
      if (!city || !this.cityExists(city)) {
        errorList.push("Select a valid country/city.");
      }
      if (!media_links) {
        errorList.push("Add some social media links.");
      }
      if (!preference) {
        errorList.push("Pick a giveaway preference.");
      }
      if (!comments) {
        errorList.push("Add some additional comments.");
      }
      if (!eth_address){
        errorList.push("Please include your Ethereum address");
      }

      this.setState({
        errorList,
      });
    }
  };

  render() {
    return (
      <form className="TheHundredForm" autoComplete="off">
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Your name</label>
          <p></p>
          <input
            className="-Text"
            name="name"
            type="text"
            value={this.state.name}
            onChange={this.onChange}
          ></input>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Project name (*Optional)</label>
          <p></p>
          <input
            className="-Text"
            name="project_name"
            type="text"
            value={this.state.project_name}
            onChange={this.onChange}
          ></input>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Email address</label>
          <p></p>
          <input
            className="-Text"
            name="email_address"
            type="email"
            value={this.state.email_address}
            onChange={this.onChange}
            required={true}
          ></input>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Avatar name</label>
          <p>
            (If you are an individual, this can be your real name or the name
            people know you by. If you are a company or project, this can be
            someone within your organization, your mascot, or a made up name
            that is representative of your organization.)
          </p>
          <input
            className="-Text"
            name="avatar_name"
            type="text"
            value={this.state.avatar_name}
            onChange={this.onChange}
          ></input>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Legion Country/City</label>
          <p>
            (Please choose from the dropdown list below. We selected 100 cities
            based on population and geography in order to be as inclusive as
            possible across the globe.)
          </p>
          <CityDropdown
            cities={this.state.cities}
            city={this.state.city}
            onClickCity={this.onClickCity}
            onChange={this.onChange}
            cityErrorMsg={this.state.cityErrorMsg}
            cityExists={this.cityExists}
            setCityErrorMsg={this.setCityErrorMsg}
          />
        </div>
        {/* <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Legion Nickname (*Optional)</label>
          <p>
            (This nickname will be used in promotional materials and may be used
            in the background of the Legionnaires for your Legion. E.g. Apemo
            Army, Delbo’s Cowboys, The Apollo Argonauts)
          </p>
          <input
            className="-Text"
            type="text"
            name="legion_nickname"
            value={this.state.legion_nickname}
            onChange={this.onChange}
          ></input>
        </div> */}
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Legion Logo/Art</label>
          <p>
            (This logo/art will be used on your The 100 avatar, in promotional
            materials for Satoshi’s Legions, and may be used in the background
            of your Legion. By uploading your logo, you grant Apollo NFT LLC a
            license to use the logo in connection with the Satoshi’s Legions
            NFTs and in promotional materials related thereto.1 If you do not
            wish to use your or your company’s logo or art, you can either
            submit a custom logo for us to use or we will generate a logo for
            your Legion.)
          </p>
          <div className="-File">
            <label htmlFor="file-upload" className="custom-file-upload">
              Upload
            </label>
            <span className="file-msg">
              {this.state.selectedLegionLogoFile
                ? this.state.selectedLegionLogoFile.name
                : "No file selected."}
            </span>
            <input
              id="file-upload"
              type="file"
              onClick={() => {
                if (this.state.selectedLegionLogoFileErrorMsg)
                  this.setSelectedLegionLogoFileErrorMsg("");
              }}
              onChange={this.onFile}
            />
            <span className="form-error-msg">Max file size 50MB</span>
            {this.state.selectedLegionLogoFileErrorMsg ? (
              <span className="form-error-msg">
                {this.state.selectedLegionLogoFileErrorMsg}
              </span>
            ) : null}
          </div>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Avatar Style</label>
          <p>
            (Please choose an outfit style from the options below. All avatars
            in the 100 will have the signature Gold mask.)
          </p>
          <div className="-RadioList">
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Assassin"
                onChange={this.onChange}
                checked={this.state.avatar === "Assassin"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Assassin.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Bandit"
                onChange={this.onChange}
                checked={this.state.avatar === "Bandit"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Bandit.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Commander"
                onChange={this.onChange}
                checked={this.state.avatar === "Commander"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Commander.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Cyborg"
                onChange={this.onChange}
                checked={this.state.avatar === "Cyborg"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Cyborg.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Drifter"
                onChange={this.onChange}
                checked={this.state.avatar === "Drifter"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Drifter.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Hacker"
                onChange={this.onChange}
                checked={this.state.avatar === "Hacker"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Hacker.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Knight"
                onChange={this.onChange}
                checked={this.state.avatar === "Knight"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Knight.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Ninja"
                onChange={this.onChange}
                checked={this.state.avatar === "Ninja"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Ninja.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Outlaw"
                onChange={this.onChange}
                checked={this.state.avatar === "Outlaw"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Outlaw.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Prophet"
                onChange={this.onChange}
                checked={this.state.avatar === "Prophet"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Prophet.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Rebel"
                onChange={this.onChange}
                checked={this.state.avatar === "Rebel"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Rebel.png`} />
            </label>
            <label className="-RadioItem">
              <input
                className="-RadioInput"
                type="radio"
                name="avatar"
                value="Renegade"
                onChange={this.onChange}
                checked={this.state.avatar === "Renegade"}
              />
              <img className="-RadioImg" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/CharactersThumbnails/Renegade.png`} />
            </label>
          </div>

          {/*<img style={{ width: "100px" }} src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/avatar4.png} />*/}

          {/*<AvatarDropdown
            avatar={this.state.avatar}
            onClickAvatar={this.onClickAvatar}
            avatarErrorMsg={this.state.avatarErrorMsg}
            setAvatarErrorMsg={this.setAvatarErrorMsg}
          />*/}
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Biography (*Optional)</label>
          <p>
            (Please describe yourself/your company in a few sentences. We will
            use this on our social media when we announce that you are a member
            of the 100 as well as on our website in your profile. If you do not
            provide a biography we will generate one from publicly available
            information.)
          </p>
          <textarea
            name="biography"
            rows="6"
            value={this.state.biography}
            onChange={this.onChange}
          ></textarea>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Website/Social Media Links</label>
          <p>
            (Please include any websites you would like us to link to as well as
            any relevant social media profiles you would like us to tag/link
            to.)
          </p>
          {/*<input
            className="-Text"
            type="text"
            name="media_links"
            value={this.state.media_links}
            onChange={this.onChange}
          ></input>*/}
          <textarea
            name="media_links"
            rows="6"
            value={this.state.media_links}
            onChange={this.onChange}
          ></textarea>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">
            TRADING CARD AIRDROP ON YOUR COMMUNITY
          </label>
          <p>
            (Would you like us to prepare a custom Satoshi The Creator trading
            card for your community and airdrop it on them on the Polygon
            blockchain? If so, all we will need from you is a list of Ethereum
            addresses to airdrop them on or the name of an NFT we can use as
            reference point for a snapshot.)
          </p>
          <PreferenceDropdown
            preference={this.state.preference}
            onClickPreference={this.onClickPreference}
            preferenceErrorMsg={this.state.preferenceErrorMsg}
            setPreferenceErrorMsg={this.setPreferenceErrorMsg}
          />
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Additional Comments</label>
          <p>
            (Please let us know if you have any questions or comments. If the
            questions/comments effect how you would complete this form, please
            email us at nick@apollonft.com)
          </p>
          <textarea
            name="comments"
            rows="6"
            value={this.state.comments}
            onChange={this.onChange}
          ></textarea>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">Ethereum Address</label>
          <p>
            Please include your Ethereum address
          </p>
          <input
            className="-Text"
            type="text"
            name="eth_address"
            value={this.state.eth_address}
            onChange={this.onChange}
          ></input>
        </div>
        <div className="TheHundredForm-Item">
          <label className="-ItemLabel">License</label>
          <p>
            1 By submitting any information/content through this form,
            including, but not limited to, any logos, text, and/or art you
            upload (“Content”), you are agreeing to be a member of The 100 and
            granting Apollo NFT LLC a non-exclusive, worldwide, royalty-free,
            license to access, use, store, copy, modify, prepare derivative
            works of, distribute, publish, transmit, stream, broadcast, and
            otherwise distribute the Content for the purposes of the creation of
            the Satoshi’s Legions avatar NFTs and the promotion of the sale of
            the Satoshi’s Legions avatar NFTs which will be usable within the
            Satoshiverse play-to-earn video game. No other uses are permitted
            without your prior consent.
          </p>
          {this.state.errorList.length > 0 ? (
            <div>
              <span
                className="form-error-msg"
                style={{ textDecoration: "underline" }}
              >
                Fix the following issues:
              </span>
              <ul className="error-list">
                {this.state.errorList.map((error, i) => (
                  <li key={i} className="list-error-msg">
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {this.state.stateMsg ? (
            <span className="response-error-msg">{this.state.stateMsg}</span>
          ) : null}
        </div>
        <button
          className="TheHundredForm-Submit"
          onClick={this.onSubmit}
          style={
            this.state.isLoading
              ? { background: "gray", color: "white", pointerEvents: "none" }
              : { pointerEvents: "auto" }
          }
        >
          {this.state.isLoading ? (
            <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/loading-icon.gif`} className="loading-icon"></img>
          ) : null}
          <span>Submit</span>
        </button>
      </form>
    );
  }
}

export default TheHundredForm;
