import React from "react";
import "./Store.css";

import NavBar from "components/NavBar/NavBar";
import StoreNav from "./StoreNav/StoreNav";

const StoreApemoArmy = (props) => {
  return (
    <div className="JS-Scroll BGSquare">
      <div className="StoreSection">
        <NavBar
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          location={props.location}
        />
        <div className="StoreContent">
          <StoreNav collectionId="apemo_army" />
          <iframe
            className="FramePortal"
            src="https://apemoarmy.wlbl.xyz"
            width="100%"
            height="80%"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default StoreApemoArmy;
