import React from "react";
import "./TeamSection.css";

import Team from "../Team/Team";

import NavBar from "components/NavBar/NavBar";
import SatoshiSocial from "components/SatoshiSocial/SatoshiSocial";

import Footer from "components/Footer/Footer";

const TeamSection = (props) => {
  return (
    <div className="JS-Scroll">
      <div className="TeamSection">
        <NavBar
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          location={props.location}
        />
        <SatoshiSocial />
        <Team width={props.width} />
        <Footer />
      </div>
    </div>
  );
};

export default TeamSection;
