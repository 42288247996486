import React, { useRef } from 'react'
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import StickyNav from 'components/StickyNav/StickyNav';
import { IoMdArrowDropdown } from 'react-icons/io'
import { Header } from "./Header";
import { Collection } from "./Collection";
import { Main } from "./styles";
import { LaunchDetails } from "./LaunchDetails";
import Footer from "components/Footer/Footer";
import { LaunchSchedule } from './LaunchSchedule';
import { FreeClaim } from './FreeClaim';
import { AllowLists } from './AllowLists';

const Apemo = (props) => {
  const ref = useRef(null);
  const refVideo = useRef(null);
  const goEndVideo = () => {
    refVideo.current.scrollIntoView();
  }

  return (
    <Main className='JS-Scroll' ref={ref}>
      <VideoContainer>
        <Video
          autoPlay
          playsInline
          muted
          loop
        >
          <source
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/Reel_apemo_army_02.mp4`}
            type="video/mp4"
          />
        </Video>
        <ArrowsContainer type='button' onClick={() => goEndVideo()}>
          <IoMdArrowDropdown className="arrowVideo" size={48} color="#4CBBF6" />
          <IoMdArrowDropdown className="arrowVideo" size={48} color="#4CBBF6" />
          <IoMdArrowDropdown className="arrowVideo" size={48} color="#4CBBF6" />
        </ArrowsContainer>
      </VideoContainer>
      <StickyNav
        onLoreClick={props.onLoreClick}
        onScrollClick={props.onScrollClick}
        changeRoute={props.changeRoute}
        width={props.width}
        toggleBurger={props.toggleBurger}
        closeBurger={props.closeBurger}
        isBurgerOpen={props.isBurgerOpen}
        useSmooth={true}
      />
      <Header
        isMobile={isMobile}
        onScrollClick={props.onScrollClick}
        refHeader={refVideo}
        useSmooth={true}
      />
      <Collection
        isMobile={isMobile}
        onScrollClick={props.onScrollClick}
        useSmooth={true}
      />
      <LaunchDetails
        isMobile={isMobile}
        // heightMain={height}
        onScrollClick={props.onScrollClick}
        useSmooth={true}

      />
      <LaunchSchedule
        isMobile={isMobile}
        // heightMain={height}
        onScrollClick={props.onScrollClick}
        useSmooth={true}>

      </LaunchSchedule>
      <AllowLists isMobile={isMobile} />
      <FreeClaim isMobile={isMobile} />
      <Footer
        isMobile={isMobile}
        onScrollClick={props.onScrollClick}
        useSmooth={true}
      />
    </Main>

  )
}
const VideoContainer = styled.div`
  position: relative;
  .arrowVideo {
  animation: MoveUpDownVideo 1.5s linear infinite;
  background-color: rgba(0,0,0,0.1);
  }
  @keyframes MoveUpDownVideo {
  0%, 100% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(0);
  }
  }
`

const Video = styled.video`
  width: 100%;
  position: relative;
`;

const ArrowsContainer = styled.button`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  background: none;
  border: none;
  cursor: pointer;
`

export default Apemo;