import React from 'react';
import { 
  Div,
  Text
} from '../styled';


export const ErrorBox = ({ statusMsg, isMobile, mt}) => {
  return (
    <Div isCenter>
      {statusMsg !== "" ? (
        <Div
          padding={isMobile ? "5px 5px" : "10px 15px"}
          width={isMobile ? "80%" : "40%"}
          backgroundColor="#474747"
          mt={mt ? mt : 0}
          mb={isMobile ? 20 : 40}
        >
          <Text
            isCenter
            size={16}
            color="white"
          >
            {statusMsg}
          </Text>
        </Div>
      ) : null
      }
    </Div>
  )
}
