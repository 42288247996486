import React from 'react';

import {
  Div,
  Text,
  Button,
} from '../styled';

const wallet = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/walletConnect.png`;
const meta = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/metaMask.png`;

export const ConnectButtons = ({
  connectWallet,
  disconnectWallet,
  ellipsify,
  address,
  isMobile,
  setShowInstructions,
  setShowInstructionsMobile,
  msg,
}) => {
  return (
    <Div mb={isMobile ? 20 : 100}>
      <Div
        isCenter
      >
        <Button
          isMobile={isMobile}
          onClick={isMobile ? setShowInstructionsMobile : setShowInstructions}
          isCenter
          mb={isMobile ? 20 : 40}
        >
          <Div>
            <Text size={isMobile ? "16" : "24"} color="white">
              Instructions
            </Text>
          </Div>
        </Button>
      </Div>
      {
        address ? (
          <Div isFlex direction="column">
            <Div
              padding={isMobile ? "5px 0" : "10px 0"}
              width="90%"
              backgroundColor="#474747"
              mb={isMobile ? 20 : 40}
            >
              <Text isCenter size={isMobile ? "18" : "26"} color="white">
                {ellipsify(address)}
              </Text>
            </Div>
            <Button onClick={() => disconnectWallet()}>
              <Text pl={!isMobile && 20} size={isMobile ? "16" : "24"}>
                Disconnect wallet
              </Text>
            </Button>
          </Div>
        ) : (
          <Div isFlex justify="space-between">
            <Button isMobile={isMobile} pt={5} pb={5} pl={isMobile ? 10 : 15} pr={isMobile ? 10 : 35} onClick={() => connectWallet("metamask")}>
              <img src={meta} alt="metaMask" />
              <Text pl={!isMobile && 20} size={isMobile ? "16" : "24"}>
                Meta Mask
              </Text>
            </Button>
            <Button isMobile={isMobile} pt={5} pb={5} pl={isMobile ? 10 : 15} pr={isMobile ? 10 : 35} onClick={() => connectWallet("walletconnect")}>
              <img src={wallet} alt="walletConnect" />
              <Text pl={isMobile ? 5 : 20} size={isMobile ? "16" : "24"}>
                Wallet Connect
              </Text>
            </Button>
          </Div >
        )
      }
      {msg !== "" ? (
        <Div
          padding={isMobile ? "5px 0" : "10px 0"}
          width="90%"
          backgroundColor="#474747"
          mt={20}
          mb={isMobile ? 20 : 40}
        >
          <Text
            size={16}
            pl={8}
            color="white"
          >
            {msg}
          </Text>
        </Div>
      ) : null}
    </Div>
  );
};

