import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
const  background = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/BKG.png`;

export const Main = styled.div`
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const MainContainer = styled.div`
    width: 100%;
    padding: 40px;
    background-image: url(${background});
    margin-top: 65px;
    margin-bottom:20px;
`;

export const spaces = css`
    position: ${(props) => (props.position ? props.position : 'relative')};
    left: ${(props) => (props.left && props.left)};
    right: ${(props) => (props.right && props.right)};
    bottom: ${(props) => (props.bottom && props.bottom)};
    top: ${(props) => (props.top && props.top)};

    margin-top: ${(props) => props.mt && props.mt}px;
    margin-bottom: ${(props) => props.mb && props.mb}px;
    margin-right: ${(props) => props.mr && props.mr}px;
    margin-left: ${(props) => props.ml && props.ml}px;
    margin: ${(props) => props.margin && props.margin};
   
    padding-top: ${(props) => props.pt && props.pt}px;
    padding-bottom: ${(props) => props.pb && props.pb}px;
    padding-right: ${(props) => props.pr && props.pr}px;
    padding-left: ${(props) => props.pl && props.pl}px;
    padding: ${(props) => props.padding && props.padding};
    width: ${(props) => props.fullWidth && '100%'};
    height: ${(props) => props.fullHeight && '100%'};

    z-index:${(props) => props.z && '10'};

    ${(props) => props.fluid && css`
        min-height: 100%;
        min-width: 100%;
    `}

`;

export const LinkR = styled(Link)`
  text-decoration: none;
`;

export const DivCustom = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const Card = styled.div`
  width: ${(props) => props.width ? props.width : "32%"};
  margin-bottom: 20px;
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: rgb(0 0 0 / 45%) 0px -50px 36px 34px inset;
    
  }
  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;

    }
`;

export const ContainerButton = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;  
  bottom: 8%;
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #2E3742 ;
  width: ${(props) => (props.width ? props.width : 'auto')};
  min-width: 10vw;
  padding: ${(props) => (props.isMobile ? "3px 15px" : "8px 35px")};
  color: white;
  border: 1px solid white;
  font-family: neuropolNova;
  transition: 0.3s;
  border-radius: ${(props) => props.radius ? props.radius : '15px'};
  box-shadow:  3px 3px 2px 1px rgba(0, 0, 0, 0.2);
  ${spaces};
  opacity: ${(props) => props.isDisabled && 0.4};
  &:disabled{
    opacity:0.4;
  }
    [disabled]{
      opacity:0.4;
    }
  &:hover {
    transform: scale(1.1);
  }
  img {
    object-fit: contain;
    width: ${(props) => (props.isMobile ? "25px" : "35px")};
    height: ${(props) => (props.isMobile ? "25px" : "35px")};
  }
`

export const Card1 = styled(Card)`
  &:hover {
    .card2 & {
      opacity: 0.4
    }
    .card3 & {
      opacity: 0.4
    }
  }
`;
export const Card2 = styled(Card)`
  &::hover {
    .card1 & {
      opacity: 0.4
    }
    .card3 & {
      opacity: 0.4
    }
  }
`;
export const Card3 = styled(Card)`
  ${Card}
  &::hover {
    .card1 & {
      opacity: 0.4
    }
    .card2 {
      opacity: 0.4
    }
  }
`;

export const Text = styled.div`
  ${spaces}
  font-family: neuropolNova;
  width: ${(props) => (props.width ? props.width : '100%')};
  color: ${(props) => props.color && props.color};
  text-transform: ${(props) => (props.isUpper ? 'uppercase' : 'unset')};
  text-decoration: ${(props) => props.decoration && props.decoration};
  text-transform: ${(props) => props.transform && props.transform};
  font-size: ${(props) => props.size && props.size}px;
  font-weight: ${(props) => props.weight && props.weight};
  text-align: ${(props) => props.align && props.align};
  font-style: ${(props) => props.fontStyle && props.fontStyle};
  overflow-wrap: break-word;

  ${(props) => props.isCenter && css`
        ${FlexCenter}
        text-align: center;
    `}
  ${(props) => props.isInline && css`
      display: inline-block;
      width: auto;
  `};
`;

export const FlexCenter = css`
    ${spaces}
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: center;
`;

export const Flex = css`
    ${spaces}
    display: flex;
    align-items: ${(props) => props.align || 'center'};
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: ${(props) => props.justify || 'center'};
`;

export const div = css`
    ${spaces}
    display: ${(props) => props.display || 'block'};
    width: ${(props) => props.width && props.width};
    height: ${(props) => props.height && props.height};
    min-height: ${(props) => props.minHeight && props.minHeight};
    background-color: ${(props) => props.backgroundColor && props.backgroundColor};
    border-radius: ${(props) => props.radius && props.radius};
    border: ${(props) => props.border && props.border};
    padding: ${(props) => props.p && props.p};
    padding: ${(props) => props.padding && props.padding};
    a {
      text-decoration: none;
    }


    ${(props) => props.isCenter && css`
        ${FlexCenter}
    `}
    
    ${(props) => props.isOverflow && css`
        overflow: hidden;
    `}
    
    ${(props) => props.isFlex && css`
        ${Flex}
    `}
    ${(props) => props.centerMargin && css`
      margin-left:auto;
      margin-right: auto;
    `}
`;

export const Div = styled.div`
    ${div}
`;

export const ImageContainer = styled.div`
  ${div}
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    filter: brightness(1);
  }
`;

export const Input = styled.input`
  font-family: neuropolNova;
  width: ${(props) => (props.isMobile ? "40px" : "80px")};
  border: black;
  background-color: transparent;
  padding-left:10px;
  font-size: ${(props) => props.size && props.size}px;
  color: ${(props) => props.color && props.color};

`