import React from "react";
import "./LegionnairesPart.css";

const LegionnairesPart = () => {
  return (
    <div className="PartLegionnaires Avatar-legionnaires">
      <video autoPlay muted loop className="video-reel">
        <source
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Video/Reel_Legionnaries.mp4`}
          type="video/mp4"
        />
      </video>
      <div className="PartLegionnairesContent">
        <h1>
          The <br /> legionnaires
        </h1>
        <p className="-p1">
          The Legionnaires are the Genesis avatar collection for the
          Satoshiverse and give holders a wide range of benefits within the
          Satoshiverse. In the Satoshiverse lore, after the Defenders Of Fiat
          take control of cities around the world, Satoshi The Creator returns
          as a half man, half machine superhero to assemble an army to take back
          control and restore the people’s basic freedoms. To do that, Satoshi
          contacts 100 leaders of the blockchain community from around the
          world, “The 100”, and asks each of them to secretly assemble “Legions”
          to lead rebellions against the Defenders Of Fiat. Each Legion is
          composed of “Legionnaires”, all of different ages, races, genders and
          backgrounds, who are unified in the fight to take back what was taken
          from them.
        </p>
        {/* <img
          className="sold-out-img"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/soul_out_2.png`}
        /> */}
        <div className="-btns">
          <a href="https://opensea.io/collection/legionnaires" target="_blank">
            <img
              className="opensea-btn"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Opensea_button.png`}
            />
          </a>
          <a
            href="https://www.niftygateway.com/marketplace/collection/0x5041a99684d38e280e4b0b356185bf18c991f88b"
            target="_blank"
          >
            <img
              className="nifty-btn"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/nifty_button_1.png`}
            />
          </a>
          <a
            href="https://raritysniper.com/satoshis-legions-the-legionnaires"
            target="_blank"
          >
            <img
              className="rs-btn"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Rs_button.png`}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LegionnairesPart;
