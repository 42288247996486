import React from "react";
import "./IntroPart.css";

const IntroPart = () => {
  return (
    <div className="PartIntro">
      <div className="BannerContainer">
        <img
          className="satoshiverse-banner"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/satoshiverse_homepage_1.png`}
        />
        <img
          className="satoshiverse-satoshi"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/satoshiverse_homepage_1_1.png`}
        />
      </div>
      <div className="PartIntroContent">
        <p className="-p1">
          Welcome to the Satoshiverse! The Satoshiverse is a blockchain enabled
          gaming ecosystem and community dedicated to bridging the gap between
          the traditional and Web 3 gaming and metaverse space. It tells the
          story of our hero, Satoshi The Creator and his quest to save the world
          from a corrupt aristocracy that has taken control and stripped the
          people of their basic freedoms. Originally, a comic collaboration
          between Apollo Entertainment and legendary comic artist Jose Delbo,
          known for his work with DC, Marvel, the Satoshiverse has evolved into
          an ecosystem featuring rich lore, comic art and collectibles, two high
          quality metaverse ready avatar collections, a weekly spaces called the
          Hideout with top guests and hundreds of listeners, and a AAA quality
          PC game - Legions 2029.
        </p>
        <br />
        <br />
      </div>
    </div>
  );
};

export default IntroPart;
