import React from 'react';
import { Div } from 'globalStyles';
import styled from 'styled-components';
import { Title, Text, Button } from './styles';

export const Collection = ({ isMobile }) => {
  return (
    <>
      <Title isMobile={isMobile} title="the collection" mt={80} mb={20} />
      <Div isFlex direction={isMobile && 'column'}>
        <Video
          autoPlay
          playsInline
          muted
          loop
        >
          <source
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/HoloVertical.mp4`}
            type="video/mp4" 
          />
        </Video>
        <Div width={isMobile ? 'auto' : '500px'} ml={!isMobile && 30}>
          <Text weight="bold" color='white' letterSpacing="5px" size={isMobile ? 16 : 24} isCenter>
            The Apemo Army collection
          </Text>
          <Text color='white' letterSpacing="5px" mt={8} size={isMobile ? 16 : 24} isCenter>
            will consist of a maximum of
          </Text>
          <Image src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/10000.png`} alt="10000" />
          <Text weight="100" color='white' letterSpacing="5px" mt={15} size={isMobile ? 16 : 24} align="justify" padding={isMobile && '10px'}>
            that will grant holders Alpha - Phase 2 access to the Satoshi’s Legions play and earn game scheduled to be released Q4 2022, as well as other soon to be announced perks within the
          </Text>
          <a href='/'>
            <ImageSatoshiverse src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/newSatoshiLogo.png`} alt="satoshiverse" />
          </a>
          <BotContainerBlue>
            <BotText isUpper weight="bold" size={isMobile ? 12 : 24} isCenter>
              Public Sale Price: 0.1 ETH
            </BotText>
          </BotContainerBlue>
        </Div>
      </Div>
    </>
  )
}

const Video = styled.video`
  height: 700px;
`;

const Image = styled.img`
  margin-top: 10px;
  object-fit: contain;
  width: 100%;
`
const ImageSatoshiverse = styled.img`
  object-fit: contain;
  width: 100%;
`

const BotContainerBlue = styled.div`
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
`

const BotText = styled(Text)`
  background-color: rgba(115, 250, 255, 0.2);
  color: #4CBBF6;
  letter-spacing: 5px;
  width: auto;
  padding: 6px;
`;