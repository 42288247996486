import React from "react";
import "./ActiveEarning.css";

const ActiveEarning = (props) => {
  return (
    <div
      className="Earning"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/BG_Earning_web.png')`,
      }}
    >
      <div className="ActiveEarning">
        {/* <div
          className="ActiveEarningTitle"
          style={{
            backgroundImage:
              props.width >= 1050
                ? `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/Tittle_R_web.png')`
                : `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG_phone/tittle_R_phone.png')`,
          }}
        >
          <h1 className="-Title">ACTIVE EARNING</h1>
        </div> */}

        {/* <p className="ActiveEarningDescription">
          There will be two primary ways that a player can actively earn by
          playing Satoshi’s Legions: (1) Avatar progression and (2) finding NFT
          items.
        </p> */}

        <ul className="ActiveEarningList">
          <li
            className="ActiveEarningItem"
            style={{
              backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/AE_screen_web.png')`,
            }}
          >
            <div className="ActiveEarningItemTitle">
              <h2 className="-SubTitle">AVATAR PROGRESSION</h2>
            </div>
            <div className="ActiveEarningItemContent">
              <div className="ActiveEarningItemVideo">
                <video autoPlay muted loop className="-Video">
                  <source
                    src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/ReelSatoshisLegions.mp4`}
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="ActiveEarningItemText">
                <p>
                  With each battle, your Biosynth gains valuable experience
                  allowing it to unlock new abilities and gear and progress
                  further into the Outskirts. And unlike traditional RPGs, in
                  Legions 2029, players truly own their progress and can buy,
                  sell, or transfer their Biosynths on Decentralized
                  marketplaces.
                </p>
              </div>
            </div>
          </li>

          <li
            className="ActiveEarningItem"
            style={{
              backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/AE_screen_web.png')`,
            }}
          >
            <div className="ActiveEarningItemTitle">
              <h2 className="-SubTitle">CUSTOM GEAR</h2>
            </div>
            <div className="ActiveEarningItemContent">
              <div className="ActiveEarningItemVideo">
                <video autoPlay muted loop className="-Video">
                  <source
                    src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/NFT_Items.mp4`}
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="ActiveEarningItemText">
                <p>
                  As you battle through the Outskirts, you can unlock powerful
                  upgrades to your gear, and if luck is on your side, find
                  legendary blueprints that allow you to forge the most powerful
                  items in the game. And through Legions 2029's Open Player
                  Economy, players can truly own and transfer the valuable
                  spoils of their exploits in the Outskirts.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="PassiveEarning">
        <div
          className="PassiveEarningTitle"
          style={{
            backgroundImage:
              props.width >= 1050
                ? `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/Tittle_L_web.png')`
                : `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG_phone/tittle_L_phone.png')`,
          }}
        >
          <h1 className="-Title">SATOSHI’S CRYSTALS</h1>
        </div>
        <div
          className="PassiveEarningContent"
          style={{
            backgroundImage:
              props.width >= 1050
                ? `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/PE_screen.png')`
                : `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG_phone/BG_PE_Phone.png')`,
          }}
        >
          <div className="PassiveEarningFlex">
            <div className="PassiveEarningText">
              <p>
                Satoshi’s Crystals are the key in-game resource in Legions 2029,
                unlocking key aspects of the game’s item progression such as
                Legendary and Supercharged items and allowing you to modify your
                items to reach their maximum potential.
                <br /> <br />
                Legionnaire and Apemo Army avatar holders can passively amass
                Satoshi's Crystals by opting in{" "}
                <a
                  className="link-game-info"
                  target="_blank"
                  href="https://game.satoshiverse.io/sat-crystal/opt-in"
                >
                  here
                </a>
                .
                <br /> <br />
                The rate at which you earn Satoshi’s Crystals will depend on
                which avatars you hold, with the following multipliers:
              </p>
            </div>
            <div className="PassiveEarningVideo">
              <img
                className="-Img"
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/SatoshiCrystalTransparent.png`}
              />
              {/* <video autoPlay muted loop className="-Video">
                <source
                  src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/Crystal_Holo.mp4`}
                  type="video/mp4"
                />
              </video> */}

              {/* <img
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/IMG_legionaries_web.png`}
              /> */}
            </div>
          </div>
          <ul className="PassiveEarningList">
            <li className="PassiveEarningItem">
              <img
                className="PassiveEarningItemImg"
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/1X.png`}
              />
            </li>
            <li className="PassiveEarningItem">
              <img
                className="PassiveEarningItemImg"
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/2X.png`}
              />
            </li>
            <li className="PassiveEarningItem">
              <img
                className="PassiveEarningItemImg"
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/3X.png`}
              />
            </li>
            <li className="PassiveEarningItem">
              <img
                className="PassiveEarningItemImg"
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/4X.png`}
              />
            </li>
            <li className="PassiveEarningItem">
              <img
                className="PassiveEarningItemImg"
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/10X.png`}
              />
            </li>
          </ul>
        </div>
      </div>

      <p className="EarningDetails">
        MORE DETAILS ON SATOSHI’S CRYSTALS AND OTHER BENEFITS FOR <br />
        HOLDERS WILL BE ANNOUNCED AT A LATER DATE.
      </p>
    </div>
  );
};

export default ActiveEarning;
