import React from 'react'

export const BodyTable = ({ data, titleTable }) => {
  return (
    <>
      <h2 className="mb-2">
        {titleTable}
      </h2>
      <div style={{
        display: 'flex',
        flexWrap: "wrap",
        justifyContent: 'space-around',
        width: '100%'
      }}>
        <table>
          <thead style={{
            marginBottom: '15px',
          }}>
            <tr>
              <th>
                Name
              </th>
              <th>
                Image
              </th>
              <th>
                Probability
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((info, i) =>
              i < data.length / 2 && (
                <tr key={i}>
                  <td style={{
                    width: '150px'
                  }}>
                    {info.name !== "" ? info.name : "None"}
                  </td>
                  <td>
                    <img
                      style={{
                        width: '100%',
                        height: '80px',
                        objectFit: "contain"
                      }}
                      className="zoom"
                      src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/${info.name.replace(" ", "+")}.png` || `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/question-mark.png`}
                      alt={info.name}
                    />
                  </td>
                  <td>
                    {parseFloat(info.probability * 100).toFixed(2)} %
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                Name
              </th>
              <th>
                Image
              </th>
              <th>
                Probability
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((info, i) =>
              i >= data.length / 2 && (
                <tr key={i}>
                  <td style={{
                    width: '150px'
                  }}>
                    {info.name !== "" ? info.name : "None"}
                  </td>
                  <td>
                    <img
                      style={{
                        width: '100%',
                        height: '80px',
                        objectFit: "contain"
                      }}
                      className="zoom"
                      src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/${info.name.replace(" ", "+")}.png` || `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/question-mark.png`}
                      alt={info.name} />
                  </td>
                  <td>
                    {parseFloat(info.probability * 100).toFixed(2)} %
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
