import React from "react";
import "./TheHideoutSection.css";

import NavBar from "components/NavBar/NavBar";
import SatoshiSocial from "components/SatoshiSocial/SatoshiSocial";
import TheHideoutIntroPart from "./TheHideoutIntroPart/TheHideoutIntroPart";

import Footer from "components/Footer/Footer";
import GuestsPart from "./GuestsPart/GuestsPart";

const TheHideoutSection = (props) => {
  return (
    <div className="JS-Scroll BGSquare">
      <div className="TheHideoutSection">
        <NavBar
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          location={props.location}
        />
        <SatoshiSocial />
        <TheHideoutIntroPart />
        <GuestsPart />
        <Footer />
      </div>
    </div>
  );
};

export default TheHideoutSection;
