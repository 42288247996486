import React from "react";
import SocialItem from "../SocialItem/SocialItem";

import "./SocialMedia.css";

const SocialMedia = (props) => {
  return (
    <div className="SocialMedia">
      <ul className="--list">
        <SocialItem
          name="Twitter"
          link={"https://twitter.com/Satoshiverse_io"}
          imgStyle={{ filter: "invert(100%)" }}
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/twitter-logo.png`}
          customStyle={{ marginRight: "2rem" }}
        />
        <SocialItem
          name="Discord"
          link={"https://discord.gg/satoshiverse"}
          imgStyle={{}}
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/discord-logo.png`}
          customStyle={{ marginRight: "2rem" }}
        />
        <SocialItem
          id="instagram"
          name="Instagram"
          link={"https://www.instagram.com/satoshiverse.io/"}
          imgStyle={{}}
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/instagram-logo.png`}
          customStyle={{}}
        />
      </ul>
    </div>
  );
};

export default SocialMedia;
