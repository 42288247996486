import React from "react";
import "./The100PartItem.css";

const The100PartItem = (props) => {
  return (
    <li className="PartThe100Item">
      <img src={props.imgUrl} />
      <span className="-highlight-h2">{props.label}</span>
    </li>
  );
};

export default The100PartItem;
