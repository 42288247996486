import React from "react";
import { Form } from "./Form";
import StickyNav from "../StickyNav/StickyNav";
import DiagonalSection from "../DiagonalSection/DiagonalSection";
import NavBar from "components/NavBar/NavBar";

const HundredForm = (props) => {
  return (
    <div className="JS-Scroll">
      <NavBar
        width={props.width}
        toggleBurger={props.toggleBurger}
        closeBurger={props.closeBurger}
        isBurgerOpen={props.isBurgerOpen}
        location={props.location}
      />
      {/* <StickyNav
        onLoreClick={props.onLoreClick}
        onScrollClick={props.onScrollClick}
        changeRoute={props.changeRoute}
        width={props.width}
        toggleBurger={props.toggleBurger}
        closeBurger={props.closeBurger}
        isBurgerOpen={props.isBurgerOpen}
        useSmooth={true}
      /> */}
      <Form />
    </div>
  );
};

export default HundredForm;
