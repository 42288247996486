import { SET_ERROR, CLEAR_ERROR } from "./types";

// SET ERRORS
export const setError = (msg, status, id = null) => {
  return {
    type: SET_ERROR,
    payload: { msg, status, id },
  };
};

// CLEAR ERRORS
export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const log = (msg) => {
  // console.log(msg);
};
