import React from "react";
import "./TheHundred.css";

import TheHundredForm from "./TheHundredForm/TheHundredForm";

const TheHundred = (props) => {
  return (
    <div className="TheHundred">
      <div className="TheHundredBlock">
        <h1>
          Satoshi’s Legions <br /> The 100 Onboarding Form
        </h1>
        <p>
          Welcome to The 100! We could not be more excited to have you as an
          integral member of our community. In the Satoshi The Creator story,
          the 100 are a group of leaders of the blockchain space who are called
          upon by Satoshi to assemble legions to fight back against the
          Defenders Of Fiat, who have taken over. We can’t wait to make you a
          part of our story in the coming months. To do that effectively, we
          need a little bit of information from you that will be used to create
          your custom “Satoshi’s Legions - The 100” avatar NFT, announce that
          you are part of the 100, and link our community to your projects. Our
          hope is that, as we grow, we will be able to keep our community in the
          loop with all the great things you are doing and vice versa. Please
          feel free to provide as much information as you would like. The more
          you give us, the more we can give to our community.
        </p>
        <TheHundredForm />
      </div>
    </div>
  );
};

export default TheHundred;
