import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part3.css";

const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 3.png`;

const Part3 = (props) => {
  return (
    <BasicLoreModal image={image}>
      <div className="LoreBlock">
        <p>
          What ensues will soon prove to be none other than the end of freedom
          as we know it. It begins with a broadcast. January 23rd, 2023 at 12:00
          PM PST. Every television channel and radio station is interrupted by
          two voices the people know quite well. President Morgan first
          introduces Frank Fazor. And then, Frank Fazor takes over. To many at
          the time Frank Fazor is an idol. Rich. Successful. Confident. What
          many aspire to be. But many others distrust him. Regardless of how
          they feel about Frank Fazor, around the world people stop what they
          are doing and focus all their attention on the broadcast. Frank Fazor
          begins:
        </p>
        <p>
          “Citizens of the world. I am Frank Fazor. Many of you know me and I
          know many of you. For the past three years we have been plagued by a
          disease that has crushed everything we hold dear. It has taken our
          families. Our friends. Our livelihoods. Governments have tried and
          failed to control it. And with time the scientific community has come
          to the sad realization that there is no cure for this Great Pandemic.
          There is only one solution to defeat the pandemic for good. And it
          will take all of us to make it happen. But this solution will come at
          a difficult cost. We will need to all sacrifice our freedom today so
          that we can achieve a brighter tomorrow. That is why, today, I
          announce that by vote of your leaders, all nations will be
          implementing a mandatory lockdown until the Great Pandemic has been
          defeated. The rules of the lockdown are as follows:
        </p>
        <ol className="ParentRulesList">
          <li className="ParentRulesItem">
            <strong>1.</strong> In the next 14 days all citizens of all
            countries must migrate to the designated cities appearing at the
            bottom of your screens. Citizens who already reside in their
            designated cities may remain in their homes. All other citizens must
            settle within a 20 mile radius of the city. Temporary housing
            structures have been constructed for you in addition to vacant
            permanent structures which have been commandeered by your
            Governments for your use. You are allowed only one suitcase per
            person and a maximum of 8 people per unit. These are the people you
            will spend the next several months with while we wait out the
            pandemic. So choose wisely.
          </li>
          <li className="ParentRulesItem">
            <strong>2.</strong> Once you arrive, you will not be allowed to
            leave your homes until the United Nations Counsel has determined
            that the Pandemic is fully extinguished. This mandate will be
            strictly enforced. Any breaches of this mandate will result in
            detention and confinement in holding facilities without warning.
          </li>
          <li className="ParentRulesItem">
            <strong>3.</strong> Meals will be provided by Alpha Corporation and
            delivered to you via drones to your windows at 9 AM, 12 PM and 5 PM
            sharp daily. You must be present at your windows at those times to
            receive the meals or your home will be skipped.
            <ul className="InnerRulesList">
              <li className="InnerRulesItem">
                <strong>a.</strong> To repeat, the rules are:
                <ol className="RulesList">
                  <li className="RulesItem">
                    <strong>i.</strong> All Citizens must settle within 20 miles
                    of their designated cities by February 6th.
                  </li>
                  <li className="RulesItem">
                    <strong>ii.</strong>No more than 8 persons to a home.
                  </li>
                  <li className="RulesItem">
                    <strong>iii.</strong> No more than one suitcase per person.
                  </li>
                  <li className="RulesItem">
                    <strong>iv.</strong> Meals at 9 AM, 12 PM, and 5 PM sharp
                    daily.
                  </li>
                  <li className="RulesItem">
                    <strong>v.</strong> AND MOST IMPORTANTLY, NO ONE MAY LEAVE
                    THEIR HOMES UNTIL THE PANDEMIC IS OVER.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
        </ol>
        <p style={{ paddingTop: "1rem" }}>
          I know this will not be easy, but together we can finally prevail!”
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part3" style={{ ...props.customStyle }}>
    //   <div className="LorePart-3 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part3Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>Freedom Is Taken</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Lockdown rules 2.png"></img>
    //     <p>
    //       What ensues will soon prove to be none other than the end of freedom
    //       as we know it. It begins with a broadcast. January 23rd, 2023 at 12:00
    //       PM PST. Every television channel and radio station is interrupted by
    //       two voices the people know quite well. President Morgan first introduces
    //       Frank Fazor. And then, Frank Fazor takes over. To many at the time Frank Fazor is an
    //       idol. Rich. Successful. Confident. What many aspire to be. But many
    //       others distrust him. Regardless of how they feel about Frank Fazor, around
    //       the world people stop what they are doing and focus all their
    //       attention on the broadcast. Frank Fazor begins:
    //     </p>
    //     <p>
    //       “Citizens of the world. I am Frank Fazor. Many of you know me and I know
    //       many of you. For the past three years we have been plagued by a
    //       disease that has crushed everything we hold dear. It has taken our
    //       families. Our friends. Our livelihoods. Governments have tried and
    //       failed to control it. And with time the scientific community has come
    //       to the sad realization that there is no cure for this Great Pandemic.
    //       There is only one solution to defeat the pandemic for good. And it
    //       will take all of us to make it happen. But this solution will come at
    //       a difficult cost. We will need to all sacrifice our freedom today so
    //       that we can achieve a brighter tomorrow. That is why, today, I
    //       announce that by vote of your leaders, all nations will be
    //       implementing a mandatory lockdown until the Great Pandemic has been
    //       defeated. The rules of the lockdown are as follows:
    //     </p>
    //     <ol className="ParentRulesList">
    //       <li className="ParentRulesItem">
    //         <strong>1.</strong> In the next 14 days all citizens of all
    //         countries must migrate to the designated cities appearing at the
    //         bottom of your screens. Citizens who already reside in their
    //         designated cities may remain in their homes. All other citizens must
    //         settle within a 20 mile radius of the city. Temporary housing
    //         structures have been constructed for you in addition to vacant
    //         permanent structures which have been commandeered by your
    //         Governments for your use. You are allowed only one suitcase per
    //         person and a maximum of 8 people per unit. These are the people you
    //         will spend the next several months with while we wait out the
    //         pandemic. So choose wisely.
    //       </li>
    //       <li className="ParentRulesItem">
    //         <strong>2.</strong> Once you arrive, you will not be allowed to
    //         leave your homes until the United Nations Counsel has determined
    //         that the Pandemic is fully extinguished. This mandate will be
    //         strictly enforced. Any breaches of this mandate will result in
    //         detention and confinement in holding facilities without warning.
    //       </li>
    //       <li className="ParentRulesItem">
    //         <strong>3.</strong> Meals will be provided by Alpha Corporation and delivered
    //         to you via drones to your windows at 9 AM, 12 PM and 5 PM sharp
    //         daily. You must be present at your windows at those times to receive
    //         the meals or your home will be skipped.
    //         <ul className="InnerRulesList">
    //           <li className="InnerRulesItem">
    //             <strong>a.</strong> To repeat, the rules are:
    //             <ol className="RulesList">
    //               <li className="RulesItem">
    //                 <strong>i.</strong> All Citizens must settle within 20 miles
    //                 of their designated cities by February 6th.
    //               </li>
    //               <li className="RulesItem">
    //                 <strong>ii.</strong>No more than 8 persons to a home.
    //               </li>
    //               <li className="RulesItem">
    //                 <strong>iii.</strong> No more than one suitcase per person.
    //               </li>
    //               <li className="RulesItem">
    //                 <strong>iv.</strong> Meals at 9 AM, 12 PM, and 5 PM sharp
    //                 daily.
    //               </li>
    //               <li className="RulesItem">
    //                 <strong>v.</strong> AND MOST IMPORTANTLY, NO ONE MAY LEAVE
    //                 THEIR HOMES UNTIL THE PANDEMIC IS OVER.
    //               </li>
    //             </ol>
    //           </li>
    //         </ul>
    //       </li>
    //     </ol>
    //     <p style={{ paddingTop: "1rem" }}>
    //       I know this will not be easy, but together we can finally prevail!”
    //     </p>
    //   </div>
    // </div>
  );
};

export default Part3;
