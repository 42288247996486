import React from "react";
import "./RoadmapPart.css";

const RoadmapPart = () => {
  return (
    <div className="PartRoadmap">
      <h1>Roadmap</h1>
      <img
        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/roadmap horizontal no BG.png`}
      />
    </div>
  );
};

export default RoadmapPart;
