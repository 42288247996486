import React from "react";
import { Link } from "react-router-dom";
import "./AvatarsSection.css";

import NavBar from "components/NavBar/NavBar";
import SatoshiSocial from "components/SatoshiSocial/SatoshiSocial";
import AvatarsPart from "./AvatarsPart/AvatarsPart";
import LegionnairesPart from "./LegionnairesPart/LegionnairesPart";
import ApemoArmyPart from "./ApemoArmyPart/ApemoArmyPart";
import UtilityPart from "./UtilityPart/UtilityPart";

import Footer from "components/Footer/Footer";

const AvatarsSection = (props) => {
  return (
    <div className="JS-Scroll BGSquare">
      <div className="AvatarsSection">
        <NavBar
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          location={props.location}
        />
        <SatoshiSocial />
        <AvatarsPart />
        <LegionnairesPart />
        <ApemoArmyPart />
        {/* <UtilityPart /> */}
        <Footer />
      </div>
    </div>
  );
};

export default AvatarsSection;
