import React from 'react';
import ReactDOM from 'react-dom';
import { Layout } from './style';

export const Modal = ({
  children,
  isOpen,
  isBackground,
  handleClick,
  modalType,
  zIndex,
  isTransparent,
  top,
}) => {
  if (!isOpen) { return null; }
  return ReactDOM.createPortal(
    <Layout
      isBackground={isBackground}
      onClick={handleClick}
      zIndex={zIndex}
      isTransparent={isTransparent}
      top={top}
    >
      {children}
    </Layout>,
    document.getElementById(modalType || 'modal'),
  );
};
