import React from 'react';
import styled, { css } from 'styled-components';
import { Div, spaces, Row } from './style';
import { Modal } from './Modal';


export const Basic = ({
  isOpen,
  width,
  height,
  isBackground,
  radius,
  onClick,
  scroll,
  children,
  zIndex,
}) => {
  return (
    <Modal isBackground={isBackground} isOpen={isOpen} zIndex={zIndex}>
      <Row fluid isCenter hCenter>
        <Box
          isBackground={isBackground}
          width={width}
          height={height}
          radius={radius}
        >
          <Div isScroll={scroll} height="100%">
            {children}
          </Div>
        </Box>
      </Row>
    </Modal>
  )
}

const Box = styled.div`
    ${spaces}
    background-color: transparent;
    width: ${(props) => props.width && props.width};
    height: ${(props) => props.height && props.height};
    border-radius: ${(props) => props.radius}px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    ${(props) => !props.isBackground && css`
        -webkit-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
        -moz-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
        box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
    `}
    overflow: hidden;
`;
