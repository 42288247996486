import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part11.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore_Part_11a.png`

const Part11 = (props) => {
  return (
    <BasicLoreModal
      image={image}
    >
      <div className="LoreBlock">
        <p>


          While the DOF was busy plotting and executing their plan to take over the world, and Satoshi was attempting to find a way to stop them, an unlikely hero's story was just beginning on a remote Caribbean island.
        </p>
        <p>
          Like most hero stories, this story began with tragedy. Only a baby at the time, a white furred ape named Apemo lived peacefully with his twin brother Boro and his parents Mongo and Madora. During the days, they would play happily together enjoying the island’s plentiful supply of food and trees to swing from. But at night, they sheltered within a cave to avoid the dangerous predators that lurked the island. One day, as the sun began to fade away, Apemo and Boro sat atop a banana tree just outside their cave. Ignoring their mother’s plees to come down from the tree, Apemo and Boro sat captivated by the island’s beautiful sunset.
        </p>
        <p>
          As the sun quickly began to fade below the horizon, Apemo and Boro realized the mistake they had made by not listening to their mother. The silence of the night began to set in. Knowing they must get inside, Apemo and Boro began to make their way down the tree, but suddenly they heard a growling sound they had never heard before. They looked down and saw a set of green eyes peering up from the bushes. With a loud roar, a fully grown black jaguar leaped at the tree. Freightened, Apemo and Boro scurried to the top of the tree holding onto the branches as the Jaguar shook it. They screamed for their parents who quickly charged out of the cave.
        </p>
        <p>
          Apemo’s father Mongo was a powerful ape and likely would have had a chance against the jaguar if it was alone. But it was not. As Mongo and Madora emerged from the cave, several other jaguars crept out of the bushes. Madora screamed to her children telling them to run away in different directions. Heeding their mother’s orders, the small apes split up jumping from branch to branch as they fearfully watched the jaguars chase them below, looking back just long enough to see the terrible image of their parents falling to the jaguars - an image that would remain with them the rest of their lives.
          <br></br>
          <br></br>
          Apemo jumped from branch to branch as fast as he could. He had never ventured this far from his home. As he got further and further away, he began to see something he had never seen before, a small sandy beach with a vast body of water beyond it. On that beach, there were red and orange flames and a loud group of animals Apemo had yet to encounter. They resembled apes but clearly were not. As Apemo neared closer to them, the jaguars stopped following him, seemingly frightened by their presence. Taking advantage of this moment of safety, Apemo climbed to the top of a tall tree on the edge of the beach and sat awake silently wondering what had become of his brother and weeping over the fate of his parents.
        </p>
        <p>
          The sun began to rise, and Apemo, sad and afraid, wondered what he would do next. At that moment, one of the older animals, which Apemo would soon learn were called “humans”, on the beach spotted Apemo and started walking towards him. He pulled a banana out of his pocket and offered it to Apemo reaching up towards him. While Apemo had never seen these strange animals before, he instantly felt a sense of belonging with them. Without many other options, and sensing a certain kindness in the man’s heart, Apemo hopped down from the tree into the man’s arms. The man gave Apemo the banana and started petting his head. Apemo clinged to the man's arms while eating a piece of the banana. The man started looking around for Apemo’s family. Seeing Apemo’s fearful and sad look, the man realized that Apemo was alone. He started comforting Apemo and to lift his spirit up he placed a small white sailor’s hat on Apemo’s head and told him “I will take care of you from now on.” Apemo would quickly learn that this was no ordinary man. His name was Zhao, he was kind hearted and wise, and he was captain of a group of pirates whose ship sat anchored just off shore!
        </p>
        <p>
          Instantly, the band of pirates fell in love with Apemo and took him in as one of their own. Apemo quickly learned the pirate ways of drinking, smoking, and pillaging! He learned their language, including all of the crude sayings, from hanging around the young British buccaneers onboard. He was sarcastic and witty and did not care what anyone thought of him. He did what he pleased only taking orders from Captain Zhao himself, who he deeply loved and respected, as much as he would a father. Apemo learned to fight beside the pirates with an anger fueled by the memory of his parents. He inherited his father’s strength and prowess in battle and with the aid of the pirate weaponry, became one of the fiercest warriors on the high seas. Even the most able of pirates feared him. And one day, seeing the ape that Apemo had become and realizing that his own body was no longer what it once was, Captain Zhao made a decision that would change Apemo’s life forever…. He made him captain of the ship! Captain Apemo!
        </p>
      </div>
    </BasicLoreModal>
  );
};

export default Part11;
