import styled, { keyframes, css } from 'styled-components';

const opacity = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const Layout = styled.div`   
    position: fixed;
    top: ${(props) => props.top || '0px'};
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${(props) => props.zIndex && props.zIndex};
    overflow: auto;
    ${(props) => props.isTransparent && css` background-color: rgba(0, 0, 0, 0);`}

    ${(props) => props.isBackground && css`
        animation: ${opacity} .1s ease-in;
        background-color: rgba(0, 0, 0, .7);
    `}
`;

export const spaces = css`
    position: ${(props) => (props.position ? props.position : 'relative')};
    left: ${(props) => (props.left && props.left)};
    right: ${(props) => (props.right && props.right)};
    bottom: ${(props) => (props.bottom && props.bottom)};
    top: ${(props) => (props.top && props.top)};

    margin-top: ${(props) => props.mt && props.mt}px;
    margin-bottom: ${(props) => props.mb && props.mb}px;
    margin-right: ${(props) => props.mr && props.mr}px;
    margin-left: ${(props) => props.ml && props.ml}px;
    margin: ${(props) => props.margin && props.margin};
   
    padding-top: ${(props) => props.pt && props.pt}px;
    padding-bottom: ${(props) => props.pb && props.pb}px;
    padding-right: ${(props) => props.pr && props.pr}px;
    padding-left: ${(props) => props.pl && props.pl}px;
    padding: ${(props) => props.padding && props.padding};
    width: ${(props) => props.fullWidth && '100%'};
    height: ${(props) => props.fullHeight && '100%'};

    z-index:${(props) => props.z && '10'};

    ${(props) => props.fluid && css`
        min-height: 100%;
        min-width: 100%;
    `}

`;

export const FlexCenter = css`
    ${spaces}
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: center;
`;

export const Flex = css`
    ${spaces}
    display: flex;
    align-items: ${(props) => props.align || 'center'};
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: ${(props) => props.justify || 'center'};
`;

export const div = css`
    ${spaces}
    display: ${(props) => props.display || 'block'};
    width: ${(props) => props.width && props.width};
    height: ${(props) => props.height && props.height};
    min-height: ${(props) => props.minHeight && props.minHeight};
    background-color: ${(props) => props.backgroundColor && props.backgroundColor};
    border-radius: ${(props) => props.radius && props.radius};
    border: ${(props) => props.border && props.border};
    padding: ${(props) => props.p && props.p};
    padding: ${(props) => props.padding && props.padding};
    a {
      text-decoration: none;
    }


    ${(props) => props.isCenter && css`
        ${FlexCenter}
    `}
    
    ${(props) => props.isOverflow && css`
        overflow: hidden;
    `}
    
    ${(props) => props.isFlex && css`
        ${Flex}
    `}
    ${(props) => props.centerMargin && css`
      margin-left:auto;
      margin-right: auto;
    `}
`;

export const Div = styled.div`
    ${div}
`;

export const Row = styled.div`
    ${spaces}
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.content || 'center'};
    
    ${(props) => props.isCenter && css`
            ${FlexCenter}
        `}

    
    @media (min-width: 768px) {
        align-items: ${(props) => props.isAlign && 'center'};
        flex-direction: ${(props) => props.direction || 'row'};
        flex-wrap: ${(props) => (props.isWrap ? 'wrap' : 'nowrap')};
        justify-content: ${(props) => props.content || 'space-between'};

        align-items: ${(props) => props.vCenter && 'center'};
        justify-content: ${(props) => props.hCenter && 'center'};

        ${(props) => props.isCenter && css`
            ${FlexCenter}
        `}


        ${(props) => props.isContainer && css`
            max-width: ${(props) => props.width || '80%'};
            margin: ${(props) => props.top || 0}px auto ${(props) => props.bottom || 0}px auto;
        `}

        & > * {
            margin-right: ${(props) => props.allRight && props.allRight}px;
            margin-right: ${(props) => props.amr && props.amr}px;
            &:last-child{
                margin: 0;
            }
        }
    }

`;