export const claimList = [
  {
    image: 'satoshi_37',
    piece: 'Legionnaires',
    entitlement: '1 per 2 legionnaires',
    buyLink: 'https://opensea.io/collection/legionnaires'
  },
  {
    image: 'Recurso36',
    piece: 'Satoshi The Creator - Genesis Token',
    entitlement: '1',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x261a2feaa8ddcbbb3347fa4409a26d41dc1827f8/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso35',
    piece: 'Captain Apemo Genesis',
    entitlement: '1',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x8a655cdb00af99d379d5335fc3884e14465219fb/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso34',
    piece: 'Captain Apemo Trading Card',
    entitlement: '1',
    buyLink: 'https://niftygateway.com/marketplace/collection/0xab636ca0ef439c311911921b991e743b8187559d/5?filters%5BonSale%5D=true&sortBy=lowest'
  },
  {
    image: 'Recurso33',
    piece: 'When Worlds Collide',
    entitlement: '1',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x3c2dce0f43333f6de6ac82375137b9a73c6bb6b8/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso32',
    piece: 'The Mutant',
    entitlement: '1',
    buyLink: 'https://niftygateway.com/marketplace/collection/0xb413752d7d6bea71d911c3310c18d24e33d4ddf1/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso31',
    piece: 'A Not So Boring Kiss',
    entitlement: '1',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x51b70484c7e08cebd633a3751518da9fa51adb62/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso30',
    piece: 'A Boring Game Of Poker',
    entitlement: '2',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x203812dc906b9912b2121de451053cbc951240b8/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso29',
    piece: 'A Boring Game Of Billiards',
    entitlement: '2',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x51b70484c7e08cebd633a3751518da9fa51adb62/2?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'apemo_green',
    piece: 'Captain Apemo - A Pirate’s Best Friend - Jack The Parrot',
    entitlement: '2',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x75b847e9b5cdd872035502dca0d79c0812bbfad8/4?filters%5bonSale%5d=true&sortBy=lowest'
  },
  {
    image: 'apemo_red',
    piece: 'Captain Apemo - A Pirate’s Best Friend - Morgan The Parrot',
    entitlement: '2',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x75b847e9b5cdd872035502dca0d79c0812bbfad8/5?filters%5bonSale%5d=true&sortBy=lowest'
  },
  {
    image: 'apemo_brown',
    piece: 'Captain Apemo - A Pirate’s Best Friend - Hook The Parrot',
    entitlement: '2',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x75b847e9b5cdd872035502dca0d79c0812bbfad8/3?filters%5bonSale%5d=true&sortBy=lowest'
  },
  {
    image: 'Recurso28',
    piece: 'Baby Apemo',
    entitlement: '3',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x75b847e9b5cdd872035502dca0d79c0812bbfad8/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso27',
    piece: 'The Creation of Ape',
    entitlement: '3',
    buyLink: 'https://niftygateway.com/marketplace/collection/0xb413752d7d6bea71d911c3310c18d24e33d4ddf1/2?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso26',
    piece: 'The Mutant - Classic',
    entitlement: '3',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x54731013b9a1d021293ac8a17925d971a4043de1/3?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso25',
    piece: 'A Not So Boring Kiss - Classic',
    entitlement: '3',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x54731013b9a1d021293ac8a17925d971a4043de1/2?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso24',
    piece: 'The Creation Of Ape - Classic',
    entitlement: '3',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x54731013b9a1d021293ac8a17925d971a4043de1/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso23',
    piece: 'Baby Apemo - Classic',
    entitlement: '3',
    buyLink: 'https://niftygateway.com/marketplace/collection/0xab636ca0ef439c311911921b991e743b8187559d/2?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso22',
    piece: "Captain Apemo - An Ape's Revenge",
    entitlement: '5',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x75b847e9b5cdd872035502dca0d79c0812bbfad8/2?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso21',
    piece: "A Boring Game Of Poker - Classic",
    entitlement: '5',
    buyLink: 'https://niftygateway.com/marketplace/collection/0xab636ca0ef439c311911921b991e743b8187559d/1?filters[onSale]=true&sortBy=lowest'
  },
  {
    image: 'Recurso18',
    piece: "A Boring Game Of Billiards - Classic",
    entitlement: '5',
    buyLink: 'https://niftygateway.com/marketplace/collection/0x3c2dce0f43333f6de6ac82375137b9a73c6bb6b8/2?filters%5bonSale%5d=true&sortBy=lowest'
  },
  {
    image: 'Recurso19',
    piece: "Captain Apemo An Ape’s Revenge - Classic",
    entitlement: '5',
    buyLink: 'https://niftygateway.com/marketplace/collection/0xab636ca0ef439c311911921b991e743b8187559d/3'
  },
  {
    image: 'Recurso37',
    piece: "Captain Apemo Genesis - Classic",
    entitlement: '5',
    buyLink: 'https://niftygateway.com/marketplace/collection/0xab636ca0ef439c311911921b991e743b8187559d/4?filters[onSale]=true&sortBy=lowest'
  },
]

export const allowLists = [
  {
    name: 'APELIST',
    price: '.05 ETH',
    link: 'https://www.premint.xyz/apelist/'
  },
  {
    name: 'CREWMEN\'S LIST',
    price: '.08 ETH',
    link: 'https://www.premint.xyz/crewmens-list/'
  },
  {
    name: 'PUBLIC ALLOW LIST',
    price: '0.1 ETH',
    link: 'https://www.premint.xyz/apemo-army-public-allowlist/'
  }
]