import React from 'react'
import "./NYCInfo.css";

const NYCInfo = (props) => {
    return (
        <div className="NYCInfo">
            <div className="InfoContent">
                <div className='LeftInfoContainer'
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesNYC/IMG/satoshi_img.png)`
                    }}
                >
                    <div className='LeftInfo'>
                        <h1>
                            Satoshiverse
                        </h1>
                        <p>
                            Welcome to the Satoshiverse! The Satoshiverse is an epic comic
                            NFT collaboration between Apollo Entertainment, Jose Delbo, and
                            YOU, the NFT community. It tells the story of our hero, Satoshi
                            The Creator and his quest to save the world from the Defenders
                            of Fiat and the many other foes who lie ahead.
                        </p>
                        <p>
                            The Genesis avatar collection for the Satoshiverse are the Legionnaires.
                            Owning a Legionnaire gives holders a wide range of benefits within the universe,
                            including early Alpha Phase 1 access to the Satoshi's Legions: The Battle for
                            the Outskirts game.
                        </p>
                        <a
                            href="https://www.satoshiverse.io/legions"
                            target="_blank"
                            rel="noreferrer"
                        >
                            More info
                        </a>
                    </div>
                </div>

                <div className='RightInfoContainer'
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesNYC/IMG/apemo_img.png)`
                    }}
                >
                    <div className='RightInfo'>
                        <h1>
                            Apemo Army
                        </h1>
                        <p>
                            The Apemo Army gives collectors and gamers alike a new way to join
                            the Satoshiverse and access holder benefits for the Satoshiverse
                            play-and-earn game. The Apemo Army is an army of half-human-half-animal
                            heroes led by Captain Apemo who join Satoshi The Creator in his quest
                            to defeat the Defenders Of Fiat. Owning an Apemo Army avatar will
                            give you access to holder benefits within the Satoshiverse including
                            early Alpha Phase 2 access to the Satoshi's Legions: The Battle for
                            the Outskirts game.
                        </p>
                        <a
                            href="https://www.satoshiverse.io/apemo-army"
                            target="_blank"
                            rel="noreferrer"
                        >
                            More info
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NYCInfo
