import React from "react";
import "../Terms/Terms.css";
import "./Privacy.css";

import NavBar from "components/NavBar/NavBar";
import Footer from "components/Footer/Footer";

const index = (props) => {
  return (
    <div className="Principal BGSquare">
      <NavBar
        width={props.width}
        toggleBurger={props.toggleBurger}
        closeBurger={props.closeBurger}
        isBurgerOpen={props.isBurgerOpen}
        location={props.location}
      />
      <div className="privacy">
        <h1 className="mb-2 mt-5">
          The Satoshiverse Privacy and Cookies Policy
        </h1>
        <p className="mb-4">
          This “Satoshiverse” Privacy and Cookies Policy (the “Policy”)
          describes how Satoshiverse LLC (“Satoshiverse” “Company”, “we”, “our”,
          or “us”) collects, uses, shares, and stores personal information of
          users and the way we use cookies on our website,
          https://www.satoshiverse.io (the “Site”) and in our Satoshi’s Legions:
          The Battle for The Outskirts game (“Game”). This Policy applies to the
          Site and the Game and, applications, products and services
          (collectively, “Services”) on or in which they are posted, linked, or
          referenced.
          <br />
          <br />
          By using the Services, you accept the terms of this Policy and our
          Satoshiverse Terms Of Service And End User License Agreement available
          at https://www.satoshiverse.io/terms (“Terms of Service”), and consent
          to our collection, use, disclosure, and retention of your information
          as described in this Policy. If you have not done so already, please
          also review our Terms of Service. The Terms of Service contain
          provisions that limit our liability to you and require you to resolve
          any dispute with us on an individual basis and not as part of any
          class or representative action.
          <b>
            IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR
            TERMS OF SERVICE, THEN PLEASE DO NOT USE ANY OF THE SERVICES.
          </b>
          <br />
          <br />
          Please note that this Policy does not apply to information collected
          through third-party websites or services that you may access through
          the Site, the Game, or the Services or that you submit to us through
          email, text message or other electronic message or offline.
          <br />
          <br />
          Please also note that the Company has offices outside of the European
          Union (“EU”) and has affiliates and service providers in the United
          States and in other countries. Your personal information may be
          transferred to or from the United States or other locations outside of
          your state, province, country or other governmental jurisdiction where
          privacy laws may not be as protective as those in your jurisdiction.
          If you are visiting this Site from the EU, see our Notice to EU Data
          Subjects below for our legal bases for processing and transfer of your
          data.
        </p>

        <ol className="numbers">
          <li className="li_principal">
            <p>
              <h3>The Types of Data We Collect. </h3>
              We collect various types of data from you as described below. This
              may include, but is not limited to, both “Personal Data” and
              “Anonymous Data.” “Personal Data” means data that allows someone
              to identify or contact you, including, for example, your name,
              address, telephone number, e-mail address, government issued
              identification documents (such as a passport or driver’s license),
              as well as any other non-public information about you that is
              associated with or linked to any of the foregoing data. “Anonymous
              Data” means data, including aggregated and de-identified data,
              that is not associated with or linked to your Personal Data;
              Anonymous Data does not, by itself, permit the identification of
              individual persons.
            </p>
            <ol className="letters">
              <li>
                <p>
                  <b>Information You Give Us </b>
                </p>
                <ol className="roman">
                  <li>
                    <p>
                      We may collect Personal Data from you, such as your first
                      and last name, e-mail and mailing addresses, payment
                      details, telephone number, and password when you create an
                      account to log in to the Game, the Site, or our Services;
                    </p>
                  </li>
                  <li>
                    <p>
                      Profile information, such as your interests, preferences,
                      feedback and survey responses;{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      Feedback and correspondence, such as information you
                      provide in your responses to surveys, when you participate
                      in market research activities, report a problem with the
                      Game, Site, or Service, receive customer support or
                      otherwise correspond with us;{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      Usage information, such as information about how you use
                      the Game, Site, or Service and interact with us; and{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      Marketing information, such as your preferences for
                      receiving marketing communications and details about how
                      you engage with them.{" "}
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Information Collected Via Technology </b>
                  As you interact with our Site, Game, or Services, we may use
                  automatic data collection technologies to collect certain
                  information about your equipment, browsing actions and
                  patterns, including:
                </p>
                <ol className="roman">
                  <li>
                    <p>
                      <b>Information Automatically Collected. </b>
                      We may automatically record certain information about how
                      you use our Site or Game (we refer to this information as
                      “Log Data“). Log Data may include information such as a
                      user’s Internet Protocol (IP) address, device and browser
                      type, operating system, the pages or features of our Site
                      or Game to which a user browsed and the time spent on
                      those pages or features, the frequency with which the Site
                      or Game is used by a user, search terms, the links on our
                      Site or Game that a user clicked on or used, and other
                      statistics. We use this information to administer the
                      Game, Site, or Services and we analyze (and may engage
                      third parties to analyze) this information to improve and
                      enhance the Game, Site, or Service by expanding their
                      features and functionality and tailoring it to our users’
                      needs and preferences.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Cookies. </b>
                      We may use cookies or similar technologies to analyze
                      trends, administer the Game, Site, or Services, track
                      users’ movements around the Site and Game, and to gather
                      demographic information about our user base as a whole.
                      Users can control the use of cookies at the individual
                      browser level. For more information, please see Section 10
                      entitled “Cookies” below.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Analytics Services. </b>
                      In addition to the tracking technologies we place, other
                      companies may set their own cookies or similar tools when
                      you visit our Site or Game. This includes third party
                      analytics services, including but not limited to Google
                      Analytics, HotJar, and Facebook Analytics (“Analytics
                      Services”), that we may engage to help analyze how users
                      use the Site, Game, and/or Services. We may receive
                      reports based on these parties’ use of these tools on an
                      individual or aggregate basis. We use the information we
                      get from Analytics Services only to improve our Site,
                      Game, and Services. The information generated by the
                      Cookies or other technologies about your use of our Site,
                      Game, and Services (the “Analytics Information”) is
                      transmitted to the Analytics Services. The Analytics
                      Services use Analytics Information to compile reports on
                      user activity. The Analytics Services may also transfer
                      information to third parties where required to do so by
                      law, or where such third parties process Analytics
                      Information on their behalf. Each Analytics Services’
                      ability to use and share Analytics Information is
                      restricted by such Analytics Services’ terms of use and
                      privacy policy. By using our Site, Game and Services, you
                      consent to the processing of data about you by Analytics
                      Services in the manner and for the purposes set out above.
                      For a full list of Analytics Services, please contact us
                      at satoshiverse@apollonft.com.
                    </p>
                    <ol className="numbers">
                      <li>
                        Google Analytics: You can find more information about
                        Google Analytics’ use of your personal data here:
                        https://www.google.com/analytics/terms/us.html
                      </li>
                      <li>
                        Facebook Analytics: You can find more information about
                        Facebook Analytics’ use of your personal data here:
                        https://developers.facebook.com/policy{" "}
                      </li>
                      <li>
                        Hotjar: You can find more information about Hotjar’s use
                        of your personal data here:
                        https://www.hotjar.com/legal/policies/privacy/{" "}
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Information We Get From Others. </b>
                  We may receive Personal and/or Anonymous Data about you from
                  companies that provide our Services by way of a co-branded or
                  private-labeled website or companies that offer their products
                  and/or services on our Site or Game. These third party
                  companies may supply us with Personal Data. We may add this to
                  the information we have already collected from you via our
                  Site or Game in order to improve the Services we provide. Such
                  information may include, but is not limited to:{" "}
                </p>
                <ol className="roman">
                  <li>
                    <p>
                      <b>Social Media Widgets. </b>
                      Our Site or Game may include social media features, such
                      as the Facebook “like” button and widgets, such as the
                      “share this” button. These features may collect your
                      Personal Data and track your use of the Site or Game.
                      These social media features are either hosted by a third
                      party or hosted directly on our Site or Game. Your
                      interactions with these features are governed by the
                      privacy policy of the company providing such
                      functionality.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        Information collected from Your Third Party Blockchain
                        Wallet Provider.{" "}
                      </b>
                      Your third party blockchain wallet provider (e.g.
                      Metamask) provides us with your Ethereum address and
                      certain other information you choose to share with that
                      provider.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Information we will never collect. </b>
                  We will never ask you to share your private keys or wallet
                  seed. Never trust anyone or any site that asks you to enter
                  your private keys or wallet seed.
                </p>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <h3>Use of Your Personal Data</h3>
            <ol className="letters">
              <li>
                <p>
                  <b>General Use. </b>
                  In general, Personal Data you submit to us is used either to
                  respond to requests that you make, or to aid us to better
                  provide our Services. We use your Personal Data in the
                  following ways:
                </p>
                <ol className="roman">
                  <li>
                    <p>
                      To enable you to access and use the Game, Site, or
                      Services;
                    </p>
                  </li>
                  <li>
                    <p>
                      To facilitate the creation of and secure your Account on
                      our Site or Game;
                    </p>
                  </li>
                  <li>
                    <p>To identify you as a user in our system;</p>
                  </li>
                  <li>
                    <p>
                      To provide and deliver products and services that you may
                      request including but not limited to facilitating your
                      cryptocurrency transactions through your third-party
                      blockchain wallet provider;
                    </p>
                  </li>
                  <li>
                    <p>
                      To protect you and other users from any conduct that
                      violates the Terms of Service or to prevent abuse or
                      harassment of any user;
                    </p>
                  </li>
                  <li>
                    <p>
                      To send information, including confirmations, technical
                      notices, updates, security alerts, and support and
                      administrative messages;
                    </p>
                  </li>
                  <li>
                    <p>
                      To communicate with you. We use your Personal Data to
                      communicate about promotions, upcoming events, and other
                      news about products and services offered by us and our
                      selected partners;
                    </p>
                  </li>
                  <li>
                    <p>
                      To comply with law. We use your Personal Data as we
                      believe necessary or appropriate to comply with applicable
                      laws, lawful requests, and legal processes, such as to
                      respond to subpoenas or requests from government
                      authorities;
                    </p>
                  </li>
                  <li>
                    <p>
                      To optimize our platform. In order to optimize your user
                      experience, we may use your Personal Data to operate,
                      maintain, and improve the Site, Game or our Services. We
                      may also use your information to respond to your comments
                      and questions regarding the Services and to provide you
                      and other users with general customer service;
                    </p>
                  </li>
                  <li>
                    <p>
                      For compliance, fraud prevention, and safety. We may use
                      your Personal Data to protect, investigate, and deter
                      against fraudulent, unauthorized, or illegal activity;
                    </p>
                  </li>
                  <li>
                    <p>
                      With your consent. We may use your Personal Data with your
                      consent, such as when you consent to let us post your
                      testimonials or endorsements on our Site or on social
                      media, you instruct us to take a specific action with
                      respect to your Personal Data, or you opt into third party
                      marketing communications; and
                    </p>
                  </li>
                  <li>
                    <p>
                      In any other way we may describe when you provide the
                      Personal Data.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <p>
              <h3>Disclosure of Your Personal Data. </h3>
              We do not share or sell the Personal Data that you provide us with
              other organizations without your express consent, except as
              described in this Policy. We disclose Personal Data to third
              parties under the following circumstances:
            </p>
            <ol className="letters">
              <li>
                <p>
                  <b>
                    Third Party Professional Advisors and Service Providers.{" "}
                  </b>
                  We may share information with those who need it to do work for
                  us. These recipients may include third party companies and
                  individuals to administer and provide the Service on our
                  behalf (such as customer support, hosting, email delivery and
                  database management services), as well as lawyers, bankers,
                  auditors, and insurers.
                </p>
              </li>
              <li>
                <p>
                  <b>Affiliates. </b>
                  We may disclose your Personal Data to our subsidiaries and
                  corporate affiliates for purposes consistent with this Policy.
                </p>
              </li>
              <li>
                <p>
                  <b>Business Transfers. </b>
                  We may share some or all of your Personal Data in connection
                  with or during negotiation of any merger, financing,
                  acquisition or dissolution transaction or proceeding involving
                  sale, transfer, divestiture, or disclosure of all or a portion
                  of our business or assets. In the event of an insolvency,
                  bankruptcy, or receivership, Personal Data may also be
                  transferred as a business asset. If another company acquires
                  our company, business, or assets, that company will possess
                  the Personal Data collected by us and will assume the rights
                  and obligations regarding your Personal Data as described in
                  this Policy.
                </p>
              </li>
              <li>
                <p>
                  <b>As Legally Required. </b>
                  Regardless of any choices you make regarding your Personal
                  Data (as described below), we may disclose Personal Data if we
                  believe in good faith that such disclosure is necessary (a) in
                  connection with any legal investigation; (b) to comply with
                  relevant laws or to respond to subpoenas or warrants served on
                  Company; (c) to protect or defend the rights or property of
                  Company or users of the Site or Services; and/or (d) to
                  investigate or assist in preventing any violation or potential
                  violation of the law, this Policy, or our Terms of Service. We
                  may share information in an emergency.
                </p>
              </li>
              <li>
                <p>
                  <b>Emergencies. </b>
                  This includes protecting the safety of our employees and
                  agents, our customers, or any person.
                </p>
              </li>
              <li>
                <p>
                  <b>Other Disclosures. </b>
                  We may also disclose your Personal Data, to fulfill the
                  purpose for which you provide it; for any other purpose
                  disclosed by us when you provide it; or with your consent. You
                  may also permit us to share your Personal Data with other
                  companies or entities of your choosing. Those uses will be
                  subject to the privacy policies of the recipient entity or
                  entities. We may also share aggregated and/or anonymized data
                  with others for their own uses.
                </p>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <h3>How Your Information is Secured</h3>
            <ol className="letters">
              <li>
                <p>
                  <b>Retention of Personal Data. </b>
                  We retain information we collect as long as it is necessary
                  and relevant to fulfill the purposes outlined in this Policy.
                  In addition, we retain Personal Data to comply with applicable
                  law where required, prevent fraud, resolve disputes,
                  troubleshoot problems, assist with any investigation, enforce
                  our Terms of Service, and other actions permitted by law. To
                  determine the appropriate retention period for Personal Data,
                  we consider the amount, nature, and sensitivity of the
                  Personal Data, the potential risk of harm from unauthorized
                  use or disclosure of your Personal Data, the purposes for
                  which we process your Personal Data and whether we can achieve
                  those purposes through other means, and the applicable legal
                  requirements. In some circumstances we may anonymize your
                  Personal Data (so that it can no longer be associated with
                  you) in which case we may use this information indefinitely
                  without further notice to you.
                </p>
              </li>
              <li>
                <p>
                  <b>Data Protection. </b>
                  We employ industry standard security measures designed to
                  protect the security of all information submitted through the
                  Game, Site, or Services. However, the security of information
                  transmitted through the Internet can never be guaranteed. We
                  are not responsible for any interception or interruption of
                  any communications through the Internet or for changes to or
                  losses of data. Users of the Game, Site, or Services are
                  responsible for maintaining the security of any password,
                  biometrics, user ID or other form of authentication involved
                  in obtaining access to password protected or secure areas of
                  any of our digital services. In order to protect you and your
                  data, we may suspend your use of any of the Game, Site, or
                  Services, without notice, pending an investigation, if any
                  breach of security is suspected.
                </p>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <p>
              <h3>Third Party Websites. </h3>
              Our Game and/or Site may contain links to third party websites.
              When you click on a link to any other website or location, you
              will leave our Site or Game and go to another site, and another
              entity may collect Personal Data or Anonymous Data from you. We
              cannot control, do not review, and cannot be responsible for,
              these outside websites or their content. The terms of this Policy
              do not apply to those outside websites or content, or to any
              collection of your Personal Data after you click on links to those
              websites. We encourage you to read the privacy policies of every
              website you visit. The links to third party websites or locations
              are for your convenience and do not signify our endorsement of
              those third parties or their content or websites.
            </p>
          </li>
          <li className="li_principal">
            <p>
              <h3>Your Choices Regarding Your Information and Changes. </h3>
              You have various choices regarding the use of information on our
              Game, Site, or Services:
            </p>
            <ol className="letters">
              <li>
                <p>
                  <b>
                    Accessing, Updating, Correcting, and Deleting your
                    Information.{" "}
                  </b>
                  You may access information that you have voluntarily provided
                  through your account on the Game, Site, or Services, and to
                  review, correct, or delete it by sending a request to
                  satoshiverse@apollonft.com. You can request to change contact
                  choices, opt-out of our sharing with others, and update your
                  Personal Data and preferences.
                </p>
              </li>
              <li>
                <p>
                  <b>Opting Out of Marketing Communications. </b>
                  We may use some of the information we collect for marketing
                  purposes, including to send you promotional communications
                  about the Game, Site, or the Services. If you wish to stop
                  receiving these communications or to opt out of use of your
                  information for these purposes, please follow the opt-out
                  instructions by clicking “Unsubscribe” (or similar opt-out
                  language) in those communications. You can also contact us at
                  satoshiverse@apollonft.com to opt out.
                </p>
              </li>
              <li>
                <p>
                  <b>Disabling Cookies. </b>
                  Regular cookies may generally be disabled or removed by tools
                  available as part of most commercial browsers, and in some
                  instances blocked in the future by selecting certain settings.
                  For more information, please see Section 10 entitled “Cookies”
                  below.
                </p>
              </li>
              <li>
                <p>
                  <b>Google Analytics. </b>
                  You may exercise choices regarding the use of cookies from
                  Google Analytics by going to
                  https://tools.google.com/dlpage/gaoptout and downloading the
                  Google Analytics Opt-out Browser Add-on.
                </p>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <p>
              <h3>Notice to California Residents. </h3>
              Under California Civil Code Section 1789.3, California users are
              entitled to the following consumer rights notice: California
              residents may reach the Complaint Assistance Unit of the Division
              of Consumer Services of the California Department of Consumer
              Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834,
              or by telephone at (916) 445-1254 or (800) 952-5210.
            </p>
            <ol className="letters">
              <li>
                <p>
                  This section provides additional details about the Personal
                  Data we collect about California consumers and the rights
                  afforded to them under the California Consumer Privacy Act or
                  “CCPA.”
                </p>
              </li>
              <li>
                <p>
                  For more details about the Personal Data we collect from you,
                  please see the “The Types of Data We Collect” section above.
                  We collect this information for the business and commercial
                  purposes described in the “Use of Your Personal Data” section
                  above. We share this information with the categories of third
                  parties described in the “Disclosure of Your Personal Data”
                  section above. Company does not sell (as such term is defined
                  in the CCPA) the Personal Data we collect (and will not sell
                  it without providing a right to opt out). Please refer to
                  Section 10 below entitled “Cookies” for more information
                  regarding the types of third-party cookies, if any, that we
                  use.
                </p>
              </li>
              <li>
                <p>
                  Subject to certain limitations, the CCPA provides California
                  consumers the right to request to know more details about the
                  categories or specific pieces of Personal Data we collect
                  (including how we use and disclose this information), to
                  delete their Personal Data, to opt out of any “sales” that may
                  be occurring, and to not be discriminated against for
                  exercising these rights.
                </p>
              </li>
              <li>
                <p>
                  California consumers may make a request pursuant to their
                  rights under the CCPA by contacting us at
                  satoshiverse@apollonft.com. Please note that you must verify
                  your identity and request before further action is taken. As a
                  part of this process, government identification may be
                  required. Consistent with California law, you may designate an
                  authorized agent to make a request on your behalf. In order to
                  designate an authorized agent to make a request on your
                  behalf, you must provide a valid power of attorney, the
                  requester’s valid government issued identification, and the
                  authorized agent’s valid government issued identification.{" "}
                </p>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <h3>Notice to EU Data Subjects</h3>
            <ol className="letters">
              <li>
                <p>
                  <b>Personal Data. </b>
                  With respect to EU data subjects, “Personal Data,” as used in
                  this Policy, is equivalent to “personal data” as defined in
                  the European Union General Data Protection Regulation (GDPR).
                </p>
              </li>
              <li>
                <p>
                  <b>Sensitive Data. </b>
                  Some of the information you provide us may constitute
                  sensitive data as defined in the GDPR (also referred to as
                  special categories of personal data), including identification
                  of your race or ethnicity on government-issued identification
                  documents.
                </p>
              </li>
              <li>
                <p>
                  <b>Legal Bases for Processing: </b>
                  We process personal data subject to GDPR on one or more of the
                  following legal bases:
                </p>
                <ol className="roman">
                  <li>
                    <p>
                      <b>Legal Obligation: </b>
                      to conduct anti-fraud and to fulfill our retention and
                      other legal obligations;
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Contractual Obligation: </b>
                      to satisfy our obligations to you under our Terms of
                      Service, including to provide you with our Services and
                      customer support services, and to optimize and enhance the
                      Game, Site, and our Services;
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Legitimate Interest: </b>
                      to monitor the usage of the Game, Site, or our Services,
                      conduct automated and manual security checks of the Game,
                      Site, or our Services, to protect our rights; and
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Consent: </b>
                      to market our Game or Services. You may withdraw your
                      consent at any time without affecting the lawfulness of
                      processing based on consent before consent is withdrawn.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Use for New Purposes. </b>
                  We may use your Personal Data for reasons not described in
                  this Policy, where we are permitted by law to do so and where
                  the reason is compatible with the purpose for which we
                  collected it. If we need to use your Personal Data for an
                  unrelated purpose, we will notify you and explain the
                  applicable legal basis for that use. If we have relied upon
                  your consent for a particular use of your Personal Data, we
                  will seek your consent for any unrelated purpose.
                </p>
              </li>
              <li>
                <p>
                  <b>Your Rights. </b>
                  Under the GDPR, you have certain rights regarding your
                  Personal Data. You may ask us to take the following actions in
                  relation to your Personal Data that we hold:
                </p>
                <ol className="roman">
                  <li>
                    <p>
                      <b>Opt-out. </b>
                      Stop sending you direct marketing communications which you
                      have previously consented to receive. We may continue to
                      send you Service-related and other non-marketing
                      communications.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Access. </b>
                      Provide you with information about our processing of your
                      Personal Data and give you access to your Personal Data.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Correct. </b>
                      Update or correct inaccuracies in your Personal Data.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Delete. </b>
                      Delete your Personal Data.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Transfer. </b>
                      Transfer a machine-readable copy of your Personal Data to
                      you or a third party of your choice.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Restrict </b>
                      Restrict the processing of your Personal Data.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Object. </b>
                      Object to our reliance on our legitimate interests as the
                      basis of our processing of your Personal Data that impacts
                      your rights.
                    </p>
                  </li>
                  <li>
                    <p>
                      You can submit these requests by email to
                      satoshiverse@apollonft.com. We may request specific
                      information from you to help us confirm your identity and
                      process your request. Applicable law may require or permit
                      us to decline your request. If we decline your request, we
                      will tell you why, subject to legal restrictions. If you
                      would like to submit a complaint about our use of your
                      Personal Data or response to your requests regarding your
                      Personal Data, you may contact us at
                      satoshiverse@apollonft.com or submit a complaint to the
                      data protection regulator in your jurisdiction. You can
                      find your data protection regulator in this list of data
                      protection authorities.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Cross-Border Data Transfer. </b>
                  Please be aware that your Personal Data will be transferred
                  to, processed, and stored in the United States. Data
                  protection laws in the U.S. may be different from those in
                  your country of residence. You consent to the transfer of your
                  information, including Personal Data, to the U.S. as set forth
                  in this Policy by visiting our Site, using our Game, or using
                  our Services. Whenever we transfer your Personal Data out of
                  the EU to the U.S. or countries not deemed by the European
                  Commission to provide an adequate level of Personal Data
                  protection, the transfer will be based on a data transfer
                  mechanism recognized by the European Commission as providing
                  adequate protection for Personal Data. Please contact us if
                  you want further information on the specific mechanism used by
                  us when transferring your Personal Data out of the EU.
                </p>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <p>
              <h3>A Note About Children. </h3>
              If you are under the age of majority in your jurisdiction of
              residence, you may use the Site, Game or Services only with the
              consent of or under the supervision of your parent or legal
              guardian. Consistent with the requirements of the Children’s
              Online Privacy Protection Act (COPPA), if we learn that we have
              received any information directly from a child under age 13
              without first receiving his or her parent’s verified consent, we
              will use that information only to respond directly to that child
              (or his or her parent or legal guardian) to inform the child that
              he or she cannot use the Site, Game or Services and subsequently
              we will delete that information.
            </p>
          </li>
          <li className="li_principal">
            <p>
              <h3>Cookies. </h3>
              We understand that your privacy is important to you and we are
              committed to being transparent about the technologies we use. In
              the spirit of transparency, this Policy provides detailed
              information about how and when we use cookies on our Site.
            </p>
            <ol className="letters">
              <li>
                <p>
                  <b>Use of Cookies </b>
                </p>
                <ol className="roman">
                  <li>
                    <p>
                      Our Site uses cookies to distinguish you from other
                      visitors of our Site. Cookies are small text files that
                      may be stored on your computer (or other Internet enabled
                      devices, such as a smartphone or tablet). They are sent by
                      a server to your computer and stored on your hard drive to
                      allow a website to recognise you when you visit. Cookies
                      can be used by a website to make the user’s experience
                      more efficient.
                    </p>
                  </li>
                  <li>
                    <p>
                      Our Site uses different types of cookies. Some cookies are
                      placed by third party services that appear on our pages.
                      We use cookies to personalize content, to provide social
                      media features and to analyze our traffic. This improves
                      your experience when you browse our Site and allows us to
                      improve the service we provide. By continuing to browse
                      our Site, you are agreeing to our use of cookies.
                    </p>
                  </li>
                  <li>
                    <p>
                      We also may share information about your use of our Site
                      and Game with our social media and analytics partners who
                      may combine it with other information that you have
                      provided to them or that they have collected from your use
                      of their services.
                    </p>
                  </li>
                  <li>
                    <p>We may use the following cookies:</p>
                    <ol className="numbers">
                      <li>
                        <p>
                          <b>Strictly Necessary Cookies. </b>
                          These cookies are necessary for the website to
                          function and cannot be switched off in our systems.
                          They are usually only set in response to actions made
                          by you which amount to a request for services, such as
                          setting your privacy preferences, logging in or
                          filling in forms. These cookies enable basic functions
                          like page navigation and access to secure areas of the
                          website. The website cannot function properly without
                          these cookies.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Targeting Cookies. </b>
                          These cookies may be set through our Site by our
                          advertising partners. Marketing cookies are used to
                          track visitors across websites. They may be used by
                          those companies to build a profile of your interests
                          and show you relevant advertisements on other sites.
                          The intention is to display ads that are relevant and
                          engaging for the individual user and thereby more
                          valuable for publishers and third party advertisers.
                          They do not store directly Personal Data, but are
                          based on uniquely identifying your browser and
                          internet device. If you do not allow these cookies,
                          you will experience less targeted advertising.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Performance Cookies. </b>
                          These cookies are usually referred to as statistical
                          cookies and allow us to count visits, traffic sources
                          so we can measure and improve the performance of our
                          Site. They help us to know which pages are the most
                          and least popular and see how visitors move around the
                          Site. All information these cookies collect is
                          aggregated and therefore anonymous. If you do not
                          allow these cookies we will not know when you have
                          visited our Site, and will not be able to monitor its
                          performance.{" "}
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>How To Disable Cookies. </b>
                  You can generally activate or later deactivate the use of
                  cookies through a functionality built into your web browser.
                  To learn more about how to control cookie settings through
                  your browser:
                </p>
                <ol className="roman">
                  <li>
                    Go to the “Private Browsing” setting and managing cookie
                    settings in Firefox;
                  </li>
                  <li>
                    Go to the “Incognito” and managing cookie settings in
                    Chrome;
                  </li>
                  <li>
                    Go to the “InPrivate” and managing cookie settings in
                    Internet Explorer; or
                  </li>
                  <li>
                    Go to the “Private Browsing” and managing cookie settings in
                    Safari.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Additional Information. </b>
                  If you want to learn more about cookies, or how to control,
                  disable or delete them, please visit
                  https://www.aboutcookies.org for detailed guidance. In
                  addition, certain third party advertising networks, including
                  Google, permit users to opt out of or customize preferences
                  associated with your internet browsing.
                </p>
              </li>
            </ol>
          </li>
          <li className="li_principal">
            <p>
              <h3>Contact Information. </h3>
              We welcome your comments or questions about this Policy, and you
              may contact us at: satoshiverse@apollonft.com.
            </p>
          </li>
          <li className="li_principal">
            <p>
              <h3>Changes To This Policy. </h3>
              We may change this Policy at any time. We encourage you to
              periodically review this page for the latest information on our
              privacy practices. If we make any changes, we will change the Last
              Updated date below. Any modifications to this Policy will be
              effective upon our posting of the new terms and/or upon
              implementation of the changes to the Site or Game (or as otherwise
              indicated at the time of posting). In all cases, your continued
              use of the Site, Game or Services after the posting of any
              modified version of this Policy indicates your acceptance of the
              terms of the modified Policy.
            </p>
          </li>
          <li className="li_principal">
            <p>
              <h3>Review of this Policy. </h3>
              We keep this Policy under regular review. This Policy was last
              updated in January 2023.
            </p>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};

export default index;
