import React from 'react';
import styled from 'styled-components';
import { Div } from 'globalStyles';
import { Title, Text, Background } from './styles';

export const LaunchDetails = ({ isMobile, heightMain }) => {
  return (
    <>
      <Title isMobile={isMobile} title="Launch details" mt={20} />
      <Background >
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <Text size="20" letterSpacing="3px" align="left">
            The goal of this launch is to reward holders of various Jose Delbo and Satoshiverse NFTs, bring new collectors into the Satoshiverse leading to the release of the Alpha version of our play and earn game, and to raise additional funds - 100% of which will be deployed into the further development of the Satoshiverse.
            <br />
            <br />
            In order to perfectly pair supply and demand for the launch of this collection, we will be using a “Capped Open Edition” launch model. This means that there will be a maximum of 10,000 avatars minted, but the contract will allow us to adjust the maximum supply (downward only) to the following supply caps to reflect the amounts actually purchased/claimed within the launch period: 3,333; 4,444; 5,555; 6,666; 7,777, 8,888. Any remaining editions up to those caps will be retained by the team for future promotion of the project.
          </Text>
          {!isMobile && (
            <Imagen isMobile={isMobile} src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/apemo_pose.png`} />
          )}
        </Div>
      </Background>
    </>
  )
}

const Imagen = styled.img`
  margin-top: ${(props) => (!props.isMobile &&'-120px')};
  width: 40%;
  object-fit: contain;
`
