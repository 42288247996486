import { combineReducers } from "redux";
import blockReducer from "./blockReducer";
import errorReducer from "./errorReducer";
import whitelistReducer from "./whitelistReducer";

export default combineReducers({
  block: blockReducer,
  error: errorReducer,
  whitelist: whitelistReducer
});
