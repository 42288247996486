import React from "react";
import "./LorePart.css";

import { Link } from "react-router-dom";

const LorePart = () => {
  return (
    <div className="PartLore">
      <h1>Lore</h1>
      <div className="PartLoreContent">
        <div className="-relative">
          <img
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/lore.png`}
          />
          <Link to="/lore" className="btn-link" style={{}}>
            Read
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LorePart;
