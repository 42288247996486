import {
  SETUP_APEMO,
  CHECK_WHITELIST_STATUS,
  CLEAR_WHITELIST_STATUS,
  CLEAR_TRANSACTION_DATA,
  CHECK_WHITELIST_TOKEN_COUNTS,
  SET_TRANSACTION_DATA,
  SHOW_INSTALL_METAMASK,
  IS_FETCHING_DATA,
  IS_MINTING,
  SET_PHASE_NUMBER,
} from "./types";
import axios from "axios";

import { setError, clearError } from "./errorActions";
import {
  clearStatusMsg,
  setStatusMsg,
  setSuccessMsg,
  clearSuccessMsg,
} from "./blockActions";
import { ethers } from "ethers";
import ApemoArtifact from "../contracts/Apemo/Apemo.json";
import { environment } from "../config";

import { log } from "./errorActions";

const APEMO_CONTRACT_ADDRESS = environment.APEMO_CONTRACT_ADDRESS;
const CONTRACT_HELPER_API_URL = process.env.REACT_APP_CONTRACT_HELPER_API_URL;

const waitXSeconds = (s) =>
  new Promise((resolve, reject) => setTimeout(() => resolve(), s * 1000));

export const setupApemo = () => async (dispatch, getState) => {
  try {
    if (window.ethereum === undefined)
      return dispatch({
        type: SHOW_INSTALL_METAMASK,
      });

    const ethersProvider = new ethers.providers.Web3Provider(
      window.web3.currentProvider
    );

    const apemoQueryContract = new ethers.Contract(
      APEMO_CONTRACT_ADDRESS,
      ApemoArtifact,
      ethersProvider
    );

    await dispatch({
      type: IS_FETCHING_DATA,
      payload: { isFetchingData: true },
    });

    await dispatch({
      type: SETUP_APEMO,
      payload: {
        apemoQueryContract,
      },
    });

    // await dispatch(getPhaseNumber());
    await dispatch(getLeftPurchaseCount());

    await dispatch({
      type: IS_FETCHING_DATA,
      payload: { isFetchingData: false },
    });
  } catch (e) {
    log(e);
  }
};

export const setPhaseNumber = (phaseNumber) => async (dispatch, getState) => {
  // const { apemoQueryContract } = getState().whitelist;

  try {
    // let phaseNumber = 4;

    await dispatch({
      type: SET_PHASE_NUMBER,
      payload: phaseNumber,
    });
  } catch (e) {
    log(e);
    log("Couldn't fetch phase number");
  }
};

export const getLeftPurchaseCount = () => async (dispatch, getState) => {
  const { address, provider, apemoContract } = getState().block;

  if (address && provider) {
    try {
      const signer = provider.getSigner(0);

      let purchaseCount = await apemoContract.connect(signer).apemoArmySold();
      let leftPurchaseCount = 5000 - purchaseCount;

      dispatch({
        type: SETUP_APEMO,
        payload: {
          leftPurchaseCount,
        },
      });
    } catch (e) {
      log(e);
      log("Couldn't fetch left purchase count");
    }
  }
};

export const checkWhitelistStatus =
  (whitelistId) => async (dispatch, getState) => {
    const { address } = getState().block;

    if (address) {
      log(address);
      try {
        const res = await axios.get(
          `${CONTRACT_HELPER_API_URL}/whitelist/${whitelistId}/proof/${address}/allotment`
        );

        const { proof, isInWhitelist, quantity } = res.data;
        // log(proof, isInWhitelist, quantity);

        dispatch({
          type: CHECK_WHITELIST_STATUS,
          payload: {
            whitelistId,
            allotment: quantity,
            proof,
            isInWhitelist,
          },
        });

        log("Successfully setted whitelist status!");
      } catch (e) {
        log("ERROR: Could not fetch whitelist status!");
      }
    }
  };

export const DiscountedSalesList = {
  apeList: 0, // FIFTY_PERCENT
  crewmenList: 1, // TWENTY_PERCENT
  trevorList: 2, // TWENTY_PERCENT
  hackataoList: 3, // TWENTY_PERCENT
  publicAllowList: 4,
};

export const checkTokenCounts = () => async (dispatch, getState) => {
  await dispatch(getLeftPurchaseCount());
  await dispatch(checkTokenCount("apeList"));
  await dispatch(checkTokenCount("crewmenList"));
  await dispatch(checkTokenCount("trevorList"));
  await dispatch(checkTokenCount("hackataoList"));
  await dispatch(checkTokenCount("publicAllowList"));
  await dispatch(checkTokenCount("freeClaimList"));
};

const checkTokenCount = (whitelistId) => async (dispatch, getState) => {
  const { address, provider, apemoContract } = getState().block;

  if (address && provider) {
    try {
      const signer = provider.getSigner(0);

      let count = 0;
      switch (whitelistId) {
        case "apeList":
        case "crewmenList":
        case "trevorList":
        case "hackataoList":
        case "publicAllowList":
          count = await apemoContract
            .connect(signer)
            .allowListPurchases(DiscountedSalesList[whitelistId], address);
          break;
        case "freeClaimList":
          count = await apemoContract.connect(signer).claimedAmount(address);
          break;
        default:
          throw Error("Whitelist type doesn't exist");
      }

      dispatch(setWhitelistCount(whitelistId, count));
    } catch (e) {
      log(e);
    }
  }
};

export const clearWhitelistStatus = () => {
  return {
    type: CLEAR_WHITELIST_STATUS,
  };
};

export const setWhitelistCount = (whitelistId, count) => {
  return {
    type: CHECK_WHITELIST_TOKEN_COUNTS,
    payload: {
      whitelistId,
      count,
    },
  };
};

const publicSalePrice = 0.1;
const getPrice = (whitelistId) => {
  switch (whitelistId) {
    case "apeList":
      return publicSalePrice / 2;
    case "crewmenList":
    case "trevorList":
    case "hackataoList":
      return (publicSalePrice * 8) / 10;
    default:
      return publicSalePrice;
  }
};

const goToAnchor = () => {
  let el = document.querySelector(`#anchor`);
  if (el) el.scrollIntoView({});
};

export const claim = (quantity, whitelistId) => async (dispatch, getState) => {
  await dispatch({ type: CLEAR_TRANSACTION_DATA });
  dispatch(clearError());
  dispatch(clearSuccessMsg());

  const { provider, address, apemoContract } = getState().block;
  log("Claiming using apemo contract...", apemoContract);
  const { whitelistStatuses } = getState().whitelist;

  if (address && provider) {
    if (quantity === "") return dispatch(setError("Fill out quantity."));

    let count = parseInt(quantity);
    if (count <= 0)
      return dispatch(setError("Quantity has to be greater than zero."));

    if (!whitelistStatuses[whitelistId])
      return dispatch(setError("Couldn't fetch whitelist status."));

    const { allotment, proof } = whitelistStatuses[whitelistId];

    log(whitelistId);

    try {
      goToAnchor();

      dispatch(setStatusMsg("Please, wait a moment..."));
      log("Using proof -> ", proof);

      dispatch({
        type: IS_MINTING,
        payload: true,
      });

      log(`count: ${count}, allotment: ${allotment}, ${proof}`);

      const tx = await apemoContract.claim(count, allotment, proof);

      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionData: tx,
        },
      });

      const receipt = await tx.wait();

      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionReceipt: receipt,
        },
      });

      log(receipt);
      log(`gasUsed: ${receipt.gasUsed.toNumber()}`);

      dispatch(
        setSuccessMsg(
          `Successfully claimed ${count} Apemo Army Avatar${
            count > 1 ? "s" : ""
          }!`
        )
      );

      dispatch(clearStatusMsg());

      dispatch({
        type: IS_MINTING,
        payload: false,
      });

      await dispatch(checkTokenCounts());
    } catch (error) {
      dispatch(clearStatusMsg());

      dispatch({
        type: IS_MINTING,
        payload: false,
      });

      log(error);

      let errorStatusMsg = "";
      let strError = error.message;
      if (strError.includes("execution reverted: ")) {
        strError = strError.split("execution reverted: ");
        if (strError.length >= 2) {
          strError = strError[1].split('"');
          if (strError.length >= 1) {
            errorStatusMsg = strError[0];
          }
        }
      }

      if (strError.includes("MetaMask Tx Signature: ")) {
        strError = strError.split("MetaMask Tx Signature: ");
        if (strError.length >= 2) {
          errorStatusMsg = strError[1];
        }
      }

      if (error.message.includes("insufficient funds")) {
        errorStatusMsg = "Insufficient funds";
      }

      if (errorStatusMsg !== "") {
        dispatch(setError("ERROR: " + errorStatusMsg, null, null));
      } else {
        dispatch(setError("ERROR: Unknown error.", null, null));
      }
    }
  } else {
    dispatch(setError("Connect to metamask!", null, null));
  }
};

export const purchase = (quantity) => async (dispatch, getState) => {
  await dispatch({ type: CLEAR_TRANSACTION_DATA });
  dispatch(clearError());
  dispatch(clearSuccessMsg());
  log(`Purchasing...`);

  const { provider, address, apemoContract } = getState().block;

  if (address && provider) {
    if (quantity === "") return dispatch(setError("Fill out quantity."));

    let count = parseInt(quantity);
    if (count <= 0)
      return dispatch(setError("Quantity has to be greater than zero."));

    try {
      goToAnchor();

      dispatch(setStatusMsg("Please, wait a moment..."));

      dispatch({
        type: IS_MINTING,
        payload: true,
      });

      const tx = await apemoContract.purchase(count, {
        value: ethers.utils.parseEther(`${publicSalePrice * count}`),
      });

      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionData: tx,
        },
      });

      const receipt = await tx.wait();

      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionReceipt: receipt,
        },
      });

      log(receipt);
      log(`gasUsed: ${receipt.gasUsed.toNumber()}`);

      dispatch(
        setSuccessMsg(
          `Successfully purchased ${count} Apemo Army avatar${
            count > 1 ? "s" : ""
          }!`
        )
      );

      dispatch(clearStatusMsg());
      dispatch({
        type: IS_MINTING,
        payload: false,
      });
    } catch (error) {
      dispatch(clearStatusMsg());
      dispatch({
        type: IS_MINTING,
        payload: false,
      });
      log(error);

      let errorStatusMsg = "";
      let strError = error.message;
      if (strError.includes("execution reverted: ")) {
        strError = strError.split("execution reverted: ");
        if (strError.length >= 2) {
          strError = strError[1].split('"');
          if (strError.length >= 1) {
            errorStatusMsg = strError[0];
          }
        }
      }

      if (strError.includes("MetaMask Tx Signature: ")) {
        strError = strError.split("MetaMask Tx Signature: ");
        if (strError.length >= 2) {
          errorStatusMsg = strError[1];
        }
      }

      if (error.message.includes("insufficient funds")) {
        errorStatusMsg = "Insufficient funds";
      }

      if (errorStatusMsg !== "") {
        dispatch(setError("ERROR: " + errorStatusMsg, null, null));
      } else {
        dispatch(setError("ERROR: Unknown error.", null, null));
      }
    }
  } else {
    dispatch(setError("Connect to metamask!", null, null));
  }
};

export const purchaseDiscounted =
  (quantity, whitelistId) => async (dispatch, getState) => {
    await dispatch({ type: CLEAR_TRANSACTION_DATA });
    dispatch(clearError());
    dispatch(clearSuccessMsg());
    log(`Purchasing ${quantity} discounted for ${whitelistId}...`);

    const { provider, address, apemoContract } = getState().block;
    const { whitelistStatuses } = getState().whitelist;

    if (address && provider) {
      if (quantity === "") return dispatch(setError("Fill out quantity."));

      let count = parseInt(quantity);
      if (count <= 0)
        return dispatch(setError("Quantity has to be greater than zero."));

      if (!whitelistStatuses[whitelistId])
        return dispatch(setError("Couldn't fetch whitelist status."));

      const { allotment, proof } = whitelistStatuses[whitelistId];

      try {
        goToAnchor();

        dispatch(setStatusMsg("Please, wait a moment..."));

        dispatch({
          type: IS_MINTING,
          payload: true,
        });

        const tx = await apemoContract.allowListPurchase(
          count,
          allotment,
          DiscountedSalesList[whitelistId],
          proof,
          {
            value: ethers.utils.parseEther(`${getPrice(whitelistId) * count}`),
          }
        );

        await dispatch({
          type: SET_TRANSACTION_DATA,
          payload: {
            transactionData: tx,
          },
        });

        const receipt = await tx.wait();

        await dispatch({
          type: SET_TRANSACTION_DATA,
          payload: {
            transactionReceipt: receipt,
          },
        });

        log(receipt);
        log(`gasUsed: ${receipt.gasUsed.toNumber()}`);

        dispatch(
          setSuccessMsg(
            `Successfully purchased ${count} Apemo Army Avatar${
              count > 1 ? "s" : ""
            }!`
          )
        );

        dispatch(clearStatusMsg());
        dispatch({
          type: IS_MINTING,
          payload: false,
        });

        await dispatch(checkTokenCounts());
      } catch (error) {
        dispatch(clearStatusMsg());
        dispatch({
          type: IS_MINTING,
          payload: false,
        });
        log(error);

        let errorStatusMsg = "";
        let strError = error.message;
        if (strError.includes("execution reverted: ")) {
          strError = strError.split("execution reverted: ");
          if (strError.length >= 2) {
            strError = strError[1].split('"');
            if (strError.length >= 1) {
              errorStatusMsg = strError[0];
            }
          }
        }

        if (strError.includes("MetaMask Tx Signature: ")) {
          strError = strError.split("MetaMask Tx Signature: ");
          if (strError.length >= 2) {
            errorStatusMsg = strError[1];
          }
        }

        if (error.message.includes("insufficient funds")) {
          errorStatusMsg = "Insufficient funds";
        }

        if (errorStatusMsg !== "") {
          dispatch(setError("ERROR: " + errorStatusMsg, null, null));
        } else {
          dispatch(setError("ERROR: Unknown error.", null, null));
        }
      }
    } else {
      dispatch(setError("Connect to metamask!", null, null));
    }
  };
