import React from "react";
import "./TheHideoutBanner.css";

export default function TheHideoutBanner() {
  return (
    <div className="TheHideoutBannerContainer">
      <img
        className="TheHideoutBanner-Framework-izq"
        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Banner_avatars_framework_izq.png`}
      />
      <img
        className="TheHideoutBanner-Img"
        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/TheHideoutGraphic.png`}
      />
      <img
        className="TheHideoutBanner-Framework-der"
        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Banner_avatars_framework_der.png`}
      />
    </div>
  );
}
