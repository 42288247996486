import React from "react";
import TeamMember from "../TeamSection/TeamMember/TeamMember";
import TeamMember2 from "components/TeamSection/TeamMember2/TeamMember2";
import Footer from "components/Footer/Footer";

import "./Team.css";

const Team = (props) => {
  return (
    <div className="Team">
      <div className="TeamBanner">
        <div
          className="TeamBanner-bg"
          style={{
            backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/background.png')`,
          }}
        ></div>
        <h1 className="TeamBanner-title">Team</h1>
      </div>
      <ul className="TeamListCofounder" style={{ marginBottom: "2rem" }}>
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Nick.png`}
          name="Nick"
          complete_name="Nick Frontera"
          title="Founder"
          isCofounder={true}
          width={props.width}
          bio={
            <span>
              Nick is the CEO of Apollo Entertainment. Prior to founding Apollo
              Entertainment, Nick spent 5 years as a lawyer at two top law firms
              in the entertainment industry practicing intellectual property
              law. Nick has over 7 years of experience in the blockchain space,
              consulting on some of the most significant drops in NFT history
              including the production of the first ever comic book NFT (Death
              by Jose Delbo) and the first six-figure sale of an art NFT
              (Genesis by Trevor Jones and Jose Delbo). Prior to founding Apollo
              Entertainment, Nick served as the COO and General Counsel of
              Gamecredits - the first gaming cryptocurrency. Nick is also the
              creator of Satoshi The Creator and the writer of the Satoshiverse
              Lore.
            </span>
          }
          twitterUrl={"https://twitter.com/MetaverseNft"}
          linkedUrl={"https://www.linkedin.com/in/nick-frontera/"}
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Santi.png`}
          name="Santiago"
          complete_name="Santiago Angulo"
          title="Founder"
          isCofounder={true}
          width={props.width}
          bio={
            <span>
              As the Chief Creative Officer of Apollo Entertainment, Santi leads
              the artistic and creative direction of the Satoshiverse. Prior to
              joining Apollo Entertainment, Santi trained as an architect and
              journalist in Bogota, Colombia where he had the opportunity to
              work with esteemed architecture studios Equipo MAZZANTI and
              Lorenzo Castro Arquitectos. Santi’s career pivoted six years ago
              as he co-founded virtual experience lab “Vuho'', where he worked
              on an array of industries, from short films and video games to
              digital art and XR productions. Vuho collaborated with top clients
              such as Disney, Doritos, Piico, Viira, Superforest and Alpina.
            </span>
          }
          twitterUrl={"https://twitter.com/SantiAngulo94"}
          linkedUrl={"https://www.linkedin.com/in/santiagoangulo/"}
        />
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/delbo-profile.jpeg`}
          name="José Delbo"
          complete_name="José Delbo"
          title="Comic Art Lead"
          isCofounder={true}
          width={props.width}
          bio={
            <span>
              José is the lead comic illustrator of the Satoshiverse. For more
              information on José Delbo’s career and work, please go to{" "}
              <a
                href="http://delbocomics.com/ "
                target="_blank"
                rel="noreferrer"
              >
                Delbo Comics site
              </a>
            </span>
          }
          twitterUrl={""}
          linkedUrl={""}
        /> */}
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Migue.png`}
          name="Miguel"
          complete_name="Miguel de Ávila"
          title="Founder"
          isCofounder={true}
          width={props.width}
          bio={
            <span>
              As CTO, Migue oversees the technical development of the
              Satoshiverse and spearheads all technical endeavors at Apollo
              Entertainment. Migue first got involved with Blockchain technology
              back in 2016, when he explored the strengths and weaknesses of
              Blockchain voting systems using smart contracts for his M.Sc.
              thesis. He then went on to co-found virtual experiences lab Vúho,
              where he was able to work on video games, web experiences, and
              short interactive experiences using 3D/AR/VR.
            </span>
          }
          twitterUrl={"https://twitter.com/Farthou"}
          linkedUrl={"https://www.linkedin.com/in/miguel-de-ávila-085288126/"}
        />

        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Josh.png`}
          name="Josh"
          complete_name="Josh Morton"
          title="Founder"
          isCofounder={true}
          width={props.width}
          bio={
            <span>
              Josh has over 20 years of experience in the video game industry,
              working with AAA Studios and Publishers including: Firaxis,
              Gearbox, Electronics Arts, Sega of America, and 2K Games, where
              Josh was a Lead Producer. Josh worked on titles we all know and
              love such as: Civilization V: Brave New World, XCom, Duke Nukem
              Forever, Darkness 2, and Spec Ops: The Line. Josh also spent 4
              years deeply immersed in the blockchain/NFT space as the Chief
              Products Officer of Gamecredits.
            </span>
          }
          twitterUrl={"https://twitter.com/CannabourneETH"}
          linkedUrl={"https://www.linkedin.com/in/jpmorton/"}
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Alejo.png`}
          name="Alejandro"
          complete_name="Alejandro Orozco"
          title="Founder"
          isCofounder={true}
          width={props.width}
          bio={
            <span>
              Ale has more than 4 years of experience managing businesses,
              taking care of various operations areas, such as accounting,
              legal, commercial and H.R. areas. Moreover, Ale has had lengthy
              musical training with more than 10 years in the piano and more
              than 3 years learning musical production and sound theory, in
              general.
            </span>
          }
          twitterUrl={""}
          linkedUrl={"https://www.linkedin.com/in/alejandro-orozco92/"}
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Daniela.png`}
          name="Dani"
          complete_name="Daniella Restrepo Orozco"
          title="Founder"
          isCofounder={true}
          width={props.width}
          bio={
            <span>
              Dani joined Apollo Entertainment in June of 2021 as the Chief
              Operations Officer and General Counsel. In addition to leading
              internal operations, Dani leads the marketing and communications
              team for the Satoshiverse and handles all legal matters. Prior to
              joining the team, Dani spent more than 6 years in communications,
              including structuring public relations and influencer marketing
              campaigns for major entertainment and lifestyle brands, such as
              Warner Bros. Studios, Victoria’s Secret, Lionsgate, Converse, and
              Paramount, among others. Dani also practiced law for 9 years at
              several major law firms.
            </span>
          }
          twitterUrl={"https://twitter.com/DanResOro"}
          linkedUrl={
            "https://www.linkedin.com/in/daniella-restrepo-orozco-3608a984/"
          }
        />
      </ul>
      <ul className="TeamList">
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Spencer.png`}
          name="Spencer"
          title="Business Development Manager"
        />

        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Angelo.png`}
          name="Angelo"
          title="Artist"
        />
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Cristian.png`}
          name="Cristian"
          title="Artist"
        /> */}
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Edward.png`}
          name="Edward"
          title="Artist"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Daniel.png`}
          name="Daniel"
          title="Artist"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Stib.png`}
          name="Stib"
          title="Artist"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Jorge.png`}
          name="Jorge"
          title="Artist"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/IanConde.png`}
          name="Ian"
          title="Artist"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/David.png`}
          name="David"
          title="Artist"
        />

        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Juan.png`}
          name="Juan"
          title="Artist"
        /> */}
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Esteban.png`}
          name="Esteban"
          title="Artist"
        />
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Arevalo.png`}
          name="Alejandro"
          title="Artist"
        /> */}
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Will.png`}
          name="Will"
          title="Artist"
        /> */}
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Nestor.png`}
          name="Nestor"
          title="Artist"
        /> */}
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Junior.png`}
          name="Junior"
          title="Artist"
        />
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Carlos.png`}
          name="Carlos"
          title="Artist"
        /> */}

        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Andres.png`}
          name="Andres"
          title="Artist"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Sergio.png`}
          name="Sergio"
          title="Artist"
        />
        {/* 
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Valeria.png`}
          name="Valeria"
          title="Designer"
        /> */}

        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Ian.png`}
          name="Ian"
          title="Developer"
        />
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Jesus.png`}
          name="Jesus"
          title="Developer"
        /> */}
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/JuanLagares.png`}
          name="Juan"
          title="Developer"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Renato.png`}
          name="Renato"
          title="Developer"
        />
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/CarlosAgudelo.png`}
          name="Carlos"
          title="Developer"
        /> */}
        {/* <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Fredye.png`}
          name="Fredye"
          title="Developer"
        />
        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Sebastian.png`}
          name="Sebastian"
          title="UI/UX"
        /> */}

        <TeamMember2
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Team/Laura.png`}
          name="Laura"
          title="C.M."
        />
      </ul>
    </div>
  );
};

export default Team;
