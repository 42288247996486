import React, { useEffect } from "react";
import "./NavBar.css";
import NavDropdown from "./NavDropdown/NavDropdown";

import { Link } from "react-router-dom";

let routes = [
  { name: "Home", to: "/", local: true },

  {
    name: "Legions 2029",
    to: "https://legions2029.com",
    local: false,
  },

  {
    name: "The Hideout",
    to: "/the-hideout",
    local: true,
  },
  // {
  //   name: "Game App",
  //   to: "https://game.satoshiverse.io",
  //   local: false,
  // },
  {
    name: "Avatars",
    to: "/avatars",
    local: true,
  },
  {
    name: "Marketplace",
    tos: ["/marketplace/legionnaires", "/marketplace/apemo-army"],
    local: true,
  },
  {
    name: "Lore",
    to: "/lore",
    local: true,
  },
  {
    name: "Team",
    to: "/team",
    local: true,
  },
];

const NavBar = (props) => {
  useEffect(() => {
    if (props.width >= 1000) props.closeBurger();
  }, [props.width]);

  return (
    <nav className="NavBar">
      <div className="-Left">
        <Link to="/">
          <img
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/NewLogo.png`}
          />
        </Link>
      </div>
      <div className="-Right">
        {props.width < 1000 ? (
          <>
            <div
              className="Burger"
              onClick={() => {
                props.toggleBurger();
              }}
            >
              <div className="-BurgerLine" />
              <div className="-BurgerLine" />
              <div className="-BurgerLine" />
            </div>
            <NavDropdown routes={routes} isOpen={props.isBurgerOpen} />
          </>
        ) : (
          <div className="NavList">
            {routes.map((route, i) => (
              <div key={i} className="NavItem">
                {" "}
                {route.local ? (
                  route.tos && route.tos[0] ? (
                    <Link
                      className={
                        route.tos.includes(props.location.pathname)
                          ? "-marked"
                          : ""
                      }
                      to={route.tos[0]}
                    >
                      {route.name}
                    </Link>
                  ) : (
                    <Link
                      className={
                        props.location.pathname === route.to ? "-marked" : ""
                      }
                      to={route.to}
                    >
                      {route.name}
                    </Link>
                  )
                ) : (
                  <a href={route.to} target="_blank" rel="noopener noreferrer">
                    {route.name}
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};
export default NavBar;
