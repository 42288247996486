import React from "react";
import "./AvatarsCarouselPart.css";
import AvatarsCarousel from "./AvatarsCarousel/AvatarsCarousel";

const AvatarsCarouselPart = (props) => {
  return (
    <div className="PartAvatarsCarousel">
      <h1>Avatars</h1>
      <div className="CarouselContainer">
        <AvatarsCarousel
          isRight={false}
          collectionId={"apemo_army"}
          width={props.width}
          onAvatarClick={props.onAvatarClick}
        />
        <img
          className="galery-img"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/Galery_avatars.png`}
        />
        <AvatarsCarousel
          isRight={true}
          collectionId={"legionnaires"}
          width={props.width}
          onAvatarClick={props.onAvatarClick}
        />
      </div>
    </div>
  );
};

export default AvatarsCarouselPart;
