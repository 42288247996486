import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { purchaseDiscounted, purchase } from "actions/whitelistActions";

import "./Phase1.css";

const Phase1 = (props) => {
  const isWalletConnected = useSelector(
    (state) => state.block.isWalletConnected
  );

  const isMinting = useSelector((state) => state.whitelist.isMinting);
  const isFetchingData = useSelector((state) => state.block.isFetchingData);

  // Ape allotment (max limit to purchase)
  const apeAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["apeList"] !== undefined
      ? state.whitelist.whitelistStatuses["apeList"].allotment
      : 0
  );

  // Crewmen allotment (max limit to purchase)
  const crewmenAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["crewmenList"] !== undefined
      ? state.whitelist.whitelistStatuses["crewmenList"].allotment
      : 0
  );

  // Trevor allotment (max limit to purchase)
  const trevorAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["trevorList"] !== undefined
      ? state.whitelist.whitelistStatuses["trevorList"].allotment
      : 0
  );

  // Hackatao allotment (max limit to purchase)
  const hackataoAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["hackataoList"] !== undefined
      ? state.whitelist.whitelistStatuses["hackataoList"].allotment
      : 0
  );

  // Public Allow allotment (max limit to purchase)
  const publicAllowAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["publicAllowList"] !== undefined
      ? state.whitelist.whitelistStatuses["publicAllowList"].allotment
      : 0
  );

  // Current purchased amount with the ape list
  const apeCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["apeList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["apeList"]
      : 0
  );

  // Current purchased amount with the crewmen list
  const crewmenCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["crewmenList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["crewmenList"]
      : 0
  );

  // Current purchased amount with the trevor list
  const trevorCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["trevorList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["trevorList"]
      : 0
  );

  // Current purchased amount with the hackatao list
  const hackataoCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["hackataoList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["hackataoList"]
      : 0
  );

  // Current purchased amount with the public allow list
  const publicAllowCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["publicAllowList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["publicAllowList"]
      : 0
  );

  const leftPurchaseCount = useSelector((state) =>
    state.whitelist.leftPurchaseCount !== undefined
      ? state.whitelist.leftPurchaseCount
      : 5000
  );

  let minApePurchaseCount, maxApePurchaseCount;
  let minCrewmenPurchaseCount, maxCrewmenPurchaseCount;
  let minTrevorPurchaseCount, maxTrevorPurchaseCount;
  let minHackataoPurchaseCount, maxHackataoPurchaseCount;
  let minPublicAllowPurchaseCount, maxPublicAllowPurchaseCount;

  minApePurchaseCount = apeAllotment - apeCount > 0 ? 1 : 0;
  maxApePurchaseCount =
    apeAllotment - apeCount >= 0 ? apeAllotment - apeCount : 0;

  minCrewmenPurchaseCount = crewmenAllotment - crewmenCount > 0 ? 1 : 0;
  maxCrewmenPurchaseCount =
    crewmenAllotment - crewmenCount >= 0 ? crewmenAllotment - crewmenCount : 0;

  minTrevorPurchaseCount = trevorAllotment - trevorCount > 0 ? 1 : 0;
  maxTrevorPurchaseCount =
    trevorAllotment - trevorCount >= 0 ? trevorAllotment - trevorCount : 0;

  minHackataoPurchaseCount = hackataoAllotment - hackataoCount > 0 ? 1 : 0;
  maxHackataoPurchaseCount =
    hackataoAllotment - hackataoCount >= 0
      ? hackataoAllotment - hackataoCount
      : 0;

  minPublicAllowPurchaseCount =
    publicAllowAllotment - publicAllowCount > 0 ? 1 : 0;
  maxPublicAllowPurchaseCount =
    publicAllowAllotment - publicAllowCount >= 0
      ? publicAllowAllotment - publicAllowCount
      : 0;

  const [purchaseCount, setPurchaseCount] = useState(1);

  const handleChangePurchaseCount = (event) => {
    let { value } = event.target;
    setPurchaseCount(value);
  };

  useEffect(() => {
    if (!isMinting) setPurchaseCount(1);
  }, [isMinting]);

  const dispatch = useDispatch();

  const onPurchaseDiscounted = (count, whitelistId) => {
    dispatch(purchaseDiscounted(count, whitelistId));
  };

  const onPurchase = (count, whitelistId) => {
    dispatch(purchase(count, whitelistId));
  };

  const validateCount = (count, min, max) => {
    return count >= min && count <= max;
  };

  return (
    <div className="PhaseContainer">
      {isWalletConnected ? (
        <div className="PhaseContent">
          <span className="YellowText">
            Apelist Remaining Allotment: {maxApePurchaseCount}
          </span>

          <span className="YellowText">
            Crewmen's List Remaining Allotment: {maxCrewmenPurchaseCount}
          </span>

          <span className="YellowText">
            Trevor Jones Remaining Allotment: {maxTrevorPurchaseCount}
          </span>

          <span className="YellowText">
            Hackatao Remaining Allotment: {maxHackataoPurchaseCount}
          </span>

          <span className="YellowText">
            Public Allowlist Remaining Allotment: {maxPublicAllowPurchaseCount}
          </span>

          <div className="phase-field">
            <label>Quantity: </label>
            <input
              type="number"
              value={purchaseCount}
              onChange={handleChangePurchaseCount}
              disabled={
                !isWalletConnected ||
                isFetchingData ||
                isMinting ||
                !props.checkNetwork()
              }
            />
          </div>
          <p>
            Please enter either 1 or 2 and then select the appropriate mint
            button.
          </p>
          <button
            className="phase-purchase-btn"
            onClick={() => onPurchaseDiscounted(purchaseCount, "apeList")}
            disabled={
              !isWalletConnected ||
              isFetchingData ||
              isMinting ||
              !props.checkNetwork() ||
              maxApePurchaseCount <= 0 ||
              !validateCount(
                purchaseCount,
                minApePurchaseCount,
                maxApePurchaseCount
              )
            }
          >
            Purchase Apelist - 0.05 ETH
          </button>
          <span className="btn-msg">Closes August 23rd at 9:00 AM PST</span>
          <button
            className="phase-purchase-btn"
            onClick={() => onPurchaseDiscounted(purchaseCount, "crewmenList")}
            disabled={
              !isWalletConnected ||
              isFetchingData ||
              isMinting ||
              !props.checkNetwork() ||
              maxCrewmenPurchaseCount <= 0 ||
              !validateCount(
                purchaseCount,
                minCrewmenPurchaseCount,
                maxCrewmenPurchaseCount
              )
            }
          >
            Purchase Crewmen's List - 0.08 ETH
          </button>
          <span className="btn-msg">Closes August 23rd at 9:00 AM PST</span>
          <button
            className="phase-purchase-btn"
            onClick={() => onPurchaseDiscounted(purchaseCount, "trevorList")}
            disabled={
              !isWalletConnected ||
              isFetchingData ||
              isMinting ||
              !props.checkNetwork() ||
              maxTrevorPurchaseCount <= 0 ||
              !validateCount(
                purchaseCount,
                minTrevorPurchaseCount,
                maxTrevorPurchaseCount
              )
            }
          >
            Purchase Trevor Jones Texture - 0.08 ETH
          </button>
          <span className="btn-msg">Closes August 23rd at 9:00 AM PST</span>
          <button
            className="phase-purchase-btn"
            onClick={() => onPurchaseDiscounted(purchaseCount, "hackataoList")}
            disabled={
              !isWalletConnected ||
              isFetchingData ||
              isMinting ||
              !props.checkNetwork() ||
              maxHackataoPurchaseCount <= 0 ||
              !validateCount(
                purchaseCount,
                minHackataoPurchaseCount,
                maxHackataoPurchaseCount
              )
            }
          >
            Purchase Hackatao Texture - 0.08 ETH
          </button>
          <span className="btn-msg">Closes August 23rd at 9:00 AM PST</span>

          <button
            className="phase-purchase-btn"
            onClick={() =>
              onPurchaseDiscounted(purchaseCount, "publicAllowList")
            }
            disabled={true}
          >
            Purchase Public Allow List - 0.1 ETH
          </button>
          <span className="btn-msg">
            Opens{" "}
            <strong style={{ fontSize: "1.5rem", textDecoration: "underline" }}>
              TODAY
            </strong>{" "}
            at 10:00 AM PST
          </span>
          <button
            className="phase-purchase-btn"
            onClick={() => onPurchase(purchaseCount)}
            disabled={true}
          >
            Purchase - 0.1 ETH
          </button>
          <span className="btn-msg">
            Public Mint Opens August 23rd at 9:00 AM PST
          </span>

          <p>
            The free claim period will run from August 24th at 9:00 AM PST to
            August 27th at 9:00 AM PST.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default Phase1;
