import React, { useEffect, useRef, useState, Component } from "react";
import { useSelector, useDispatch, connect, shallowEqual } from "react-redux";
import { setPhaseNumber } from "actions/whitelistActions";
import "./Countdown2.css";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import PropTypes from "prop-types";

import { log } from "actions/errorActions";

// const useConstructor = (callBack = () => {}) => {
//   const hasBeenCalled = useRef(false);
//   if (hasBeenCalled.current) return;
//   callBack();
//   hasBeenCalled.current = true;
// };

class Countdown2 extends Component {
  constructor(props) {
    super(props);

    // dayjs.extend(utc);
    // dayjs.extend(timezone);
    // dayjs.tz.setDefault("America/New_York");

    // dayjs.extend(utc);
    // dayjs.extend(timezone);
    // dayjs.tz.setDefault("America/Los_Angeles");

    // dayjs.tz.setDefault("America/Bogota");

    // seconds (UNIX time)
    let phase1Date = 1661184000; //1661184000;
    let phase2Date = phase1Date+ 3600;
    let phase3Date = phase1Date+ (3600 + 3600 * 23);
    let phase4Date = phase1Date+ (3600 + 3600 * 23 + 3600 * 24);
    let phase5Date = phase1Date + (3600 + 3600 * 23 + 3600 * 24 + 3600 * 72);

    // let phase1Date = 1660692921;
    // let phase2Date = phase1Date + 30;
    // let phase3Date = phase1Date + 30 * 2;
    // let phase4Date = phase1Date + 30 * 3;
    // let phase5Date = phase1Date + 30 * 4;

    // log("phase1Date: ", phase1Date);
    // log("phase2Date: ", phase2Date);
    // log("phase3Date: ", phase3Date);
    // log("phase4Date: ", phase4Date);
    // log("phase5Date: ", phase5Date);

    this.phases = [
      dayjs(new Date(phase1Date * 1000)),
      dayjs(new Date(phase2Date * 1000)),
      dayjs(new Date(phase3Date * 1000)),
      dayjs(new Date(phase4Date * 1000)),
      dayjs(new Date(phase5Date * 1000)),
    ];

    this.state = {
      time: {},
      seconds: 0,
      currentPhase: 0,
    };
  }

  static propTypes = {
    phaseNumber: PropTypes.number.isRequired,
  };

  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.floor(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };

    return obj;
  };

  componentDidMount = async () => {
    let phaseNumber = this.getPhaseNumber();
    log("phaseNumber: ", phaseNumber);
    this.setRemainingSeconds(phaseNumber);

    let timeLeftVar = this.secondsToTime(this.state.seconds);
    await this.setState({ time: timeLeftVar });
    this.startTimer();
  };

  setRemainingSeconds = async (phaseNumber) => {
    await this.setState({
      seconds:
        this.phases[phaseNumber] !== undefined
          ? (this.phases[phaseNumber].valueOf() - dayjs().valueOf()) / 1000 <= 0
            ? 0
            : (this.phases[phaseNumber].valueOf() - dayjs().valueOf()) / 1000
          : 0,
    });
  };

  componentDidUpdate() {
    if (this.state.currentPhase !== this.props.phaseNumber) {
      this.setState(
        {
          currentPhase: this.props.phaseNumber,
        },
        () => this.setRemainingSeconds(this.state.currentPhase)
      );
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  getPhaseNumber = () => {
    let phaseNumber = 0;
    for (let i = 0; i < this.phases.length; i++) {
      if (dayjs().valueOf() > this.phases[i].valueOf()) {
        phaseNumber = i + 1;
      } else {
        break;
      }
    }

    return phaseNumber;
  };

  startTimer = () => {
    this.handleTick();
    this.timer = setInterval(this.countDown, 1000);

    // if (this.timer == 0 && this.state.seconds > 0) {} else {}
  };

  handleTick = () => {
    let phaseNumber = this.getPhaseNumber();
    if (phaseNumber !== this.state.currentPhase) {
      this.props.setPhaseNumber(phaseNumber);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    // Check if we're at zero.

    let seconds = this.state.seconds - 1;
    if (seconds > 0) {
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
    }

    this.handleTick();
  };

  getMessageByPhase = () => {
    switch (this.props.phaseNumber) {
      case 0:
        return (
          <h1 className="Countdowm-msg">Countdown Until Start Of Phase 1</h1>
        );
      case 1:
      case 2:
      case 3:
        return (
          <h1 className="Countdowm-msg">
            Countdown Until End Of Phase {this.props.phaseNumber}
          </h1>
        );
      case 4:
        return <h1 className="Countdowm-msg">Countdown Until End Of Mint</h1>;
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="Countdown">
        {this.getMessageByPhase()}
        <ul className="Numbers">
          <li>
            <span className="timer-amount">
              {this.state.time.h < 10
                ? `0${this.state.time.h}`
                : this.state.time.h}
            </span>
          </li>
          <li>
            <span className="timer-separator">:</span>
          </li>
          <li>
            <span className="timer-amount">
              {this.state.time.m < 10
                ? `0${this.state.time.m}`
                : this.state.time.m}
            </span>
          </li>
          <li>
            <span className="timer-separator">:</span>
          </li>
          <li>
            <span className="timer-amount">
              {this.state.time.s < 10
                ? `0${this.state.time.s}`
                : this.state.time.s}
            </span>
          </li>
        </ul>
        <ul className="Bot">
          <li>
            <span className="timer-label">hours</span>
          </li>
          <li>
            <span className="timer-label">minutes</span>
          </li>
          <li>
            <span className="timer-label">seconds</span>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  phaseNumber: state.whitelist.phaseNumber,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPhaseNumber: (phaseNumber) => dispatch(setPhaseNumber(phaseNumber)),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Countdown2);
