import React, { Component } from 'react';
import "./Instructions.css";

import onClickOutside from "react-onclickoutside";

class Instructions extends Component {
    handleClickOutside = (e) => {
        this.props.setShowInstructions(false)
    };

    render() {
        return (
            <div className="Instructions">

                <div className="--content">
                    <div className="close-btn" onClick={(e) => this.props.setShowInstructions(false)}>
                        <div className="--x">
                            <div className="stick-1"></div>
                            <div className="stick-2"></div>
                        </div>
                    </div>
                    <h2>Instructions</h2>


                    <div className="--instructions">
                        <div className="--steps">
                            {this.props.isClaim && (
                                <p>
                                    The purpose of this page is to facilitate "late claims" by those who purchased Satoshi's Legions Presale Tokens, but did not claim their Legionnaires before the minting deadline. To qualify for a "late claim" you must: (1) have held a Satoshi's Legions Presale Token during the snapshot taken on November 22, 2021 at 12:00 PM EST and (2) not have claimed your Legionnaire prior to the December 20, 2021 minting cutoff. Filling out this form will allow you to claim one or more randomized revealed Legionnaires from the Satoshiverse Vault using your presale tokens. This extended claim period will be open until April 30, 2022. After that date, it will no longer be possible to claim a Legionnaire.

                                    To claim your Legionnaires:

                                    {'1)'} Connect your Ethereum wallet
                                   {' 2)'} Fill out the form below ({'if you do not see the form, this means that you do not have any unclaimed legionnaires)'}.

                                    Within 14 days, you will receive an email to the provided email address with instructions on how to claim your randomized Legionnaires. You will only be required to pay the gas fees for the transaction.
                                </p>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default onClickOutside(Instructions);