import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part7.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore part 7.png`;

const Part7 = (props) => {
  return (
    <BasicLoreModal image={image}>
      <div className="LoreBlock">
        <p>
          Even without knowing the sinister backstory to what caused the
          pandemic that upended their lives and took away their freedoms, the
          people soon come to realize that the D.O.F. have only their own
          wellbeing and that of their followers in mind.
        </p>
        <p>
          While things have gotten progressively worse in the Outskirts, those
          within the walls of the city have lived far more comfortably. And
          while many within the walls have known they have it better, most have
          been completely unaware of the D.O.F. and their true intentions until
          the day of the broadcast. Then they are given a choice: “Join us. Or
          join THEM on the Outskirts.” Afraid of the fate that lies beyond the
          walls of the city and eager to keep their comfortable lives, most
          pledge their allegiance to the D.O.F. Those who do not are cast out.
        </p>
        <p>
          Many things change during the Rebuilding. To begin, the people of the
          outskirts are now allowed outside their homes. But this “freedom” they
          are given is carefully limited by the watchful eye of Providence. The
          people are under surveillance at all times. Satellites in the sky.
          G.E.U.’s on the streets. Listening devices in their homes. They are
          made to work in large manufacturing plants creating all the luxuries
          to be consumed by the D.O.F. and their followers in the cities. They
          are paid in their local fiat currency which is loaded onto debit cards
          provided and tracked by the D.O.F. Anything the people buy is recorded
          and scrutinized by the D.O.F.’s artificial intelligence monitoring
          system.
        </p>
        <p>
          This continues for years. The people want to rebel, but they feel they
          cannot. Everyone is being closely watched and even the slightest hint
          of an uprising is quickly squashed with brutal force. The days of
          tranquilizing are no more. The drones and G.E.U.s are set to kill
          those who resist the D.O.F.
        </p>
        <p>
          Meanwhile, within the walls of the cities, the people’s loyalty
          continues to be bought, as they are spoiled with the riches produced
          in the Outskirts. Over time, the city people embrace their places in
          society. Brainwashed by the D.O.F. controlled media, they believe it
          is their right to benefit from the labor of those on the Outskirts.
          That somehow they are better... Chosen.
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part7" style={{ ...props.customStyle }}>
    //   <div className="LorePart-7 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part7Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>The Rebuilding</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/SatoshiLore07.png"></img>
    //     <p>
    //       Even without knowing the sinister backstory to what caused the pandemic
    //       that upended their lives and took away their freedoms, the people soon
    //       come to realize that the D.O.F. have only their own wellbeing and that
    //       of their followers in mind.
    //     </p>
    //     <p>
    //       While things have gotten progressively worse in the Outskirts, those within
    //       the walls of the city have lived far more comfortably. And while many within
    //       the walls have known they have it better, most have been completely unaware
    //       of the D.O.F. and their true intentions until the day of the broadcast. Then
    //       they are given a choice: “Join us. Or join THEM on the Outskirts.” Afraid of
    //       the fate that lies beyond the walls of the city and eager to keep their
    //       comfortable lives, most pledge their allegiance to the D.O.F. Those who do
    //       not are cast out.
    //     </p>
    //     <p>
    //       Many things change during the Rebuilding. To begin, the people of the outskirts
    //       are now allowed outside their homes. But this “freedom” they are given is carefully
    //       limited by the watchful eye of Providence. The people are under surveillance at all
    //       times. Satellites in the sky. G.E.U.’s on the streets. Listening devices in their
    //       homes. They are made to work in large manufacturing plants creating all the luxuries
    //       to be consumed by the D.O.F. and their followers in the cities. They are paid in
    //       their local fiat currency which is loaded onto debit cards provided and tracked by
    //       the D.O.F. Anything the people buy is recorded and scrutinized by the D.O.F.’s
    //       artificial intelligence monitoring system.
    //     </p>
    //     <p>
    //       This continues for years. The people want to rebel, but they feel they cannot. Everyone
    //       is being closely watched and even the slightest hint of an uprising is quickly squashed
    //       with brutal force. The days of tranquilizing are no more. The drones and G.E.U.s are
    //       set to kill those who resist the D.O.F.
    //     </p>
    //     <p>
    //       Meanwhile, within the walls of the cities, the people’s loyalty continues to be bought,
    //       as they are spoiled with the riches produced in the Outskirts. Over time, the city people
    //       embrace their places in society. Brainwashed by the D.O.F. controlled media, they believe
    //       it is their right to benefit from the labor of those on the Outskirts. That somehow they
    //       are better... Chosen.
    //     </p>
    //   </div>
    // </div>
  );
};

export default Part7;
