import React from "react";
import "./MailingListPreferences.css";
import queryString from 'query-string';

import StickyNav from "../StickyNav/StickyNav";
import Formulary from "./Formulary/Formulary";

const MailingListPreferences = (props) => {
  let params = queryString.parse(props.location.search);
  // console.log(params);
  let email= params.email;
  let token= params.token;
  return (
    <div className="JS-Scroll">
      <div
        className="HomeSection"
      >
        {/* <StickyNav
          onLoreClick={props.onLoreClick}
          onScrollClick={props.onScrollClick}
          changeRoute={props.changeRoute}
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          useSmooth={true}
        /> */}
        <Formulary width={props.width} email={email} token={token}/>
      </div>
    </div>
  );
};

export default MailingListPreferences;
