import React from "react";
import "./TheHideoutPart.css";

import { Link } from "react-router-dom";
import TheHideoutBanner from "./TheHideoutBanner/TheHideoutBanner";

const TheHideoutPart = () => {
  return (
    <div className="PartTheHideout">
      <div className="PartTheHideoutContent" style={{ marginTop: "3rem" }}>
        <img
          className="HideoutTitle"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/The Hideout Logo.png`}
        />
        <TheHideoutBanner />
        <p className="-p1">
          The Hideout is a weekly panel-style X Spaces featuring discussions on
          Web3, gaming, NFTs, the blockchain, and the metaverse.
        </p>
        <Link
          to="/the-hideout"
          className="btn-link"
          style={{ marginBottom: "4rem", marginTop: "2rem" }}
        >
          Learn more
        </Link>
      </div>
    </div>
  );
};

export default TheHideoutPart;
