import React from "react";
import "./Terms.css";

import NavBar from "components/NavBar/NavBar";
import Footer from "components/Footer/Footer";

const Terms = (props) => {
  return (
    <div className="Principal BGSquare">
      <NavBar
        width={props.width}
        toggleBurger={props.toggleBurger}
        closeBurger={props.closeBurger}
        isBurgerOpen={props.isBurgerOpen}
        location={props.location}
      />
      <div className="terms">
        <h1>SATOSHIVERSE TERMS OF SERVICE AND END USER LICENSE AGREEMENT</h1>
        <span className="-lastupdated">Last Updated: January 2024</span>
        <h2 className="-justify">
          IMPORTANT NOTICE: THIS TERMS OF SERVICE AND END USER LICENSE AGREEMENT
          (“AGREEMENT”) IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS
          ACTION RIGHTS AS DETAILED IN SECTION 25
        </h2>
        <ul className="EUAList">
          <li className="EUAItem">
            <h2>
              <span className="-num">1.</span>
              <span>Acceptance Of Terms</span>
            </h2>
            <p>
              Satoshiverse LLC, a company operating under the laws of Delaware,
              United States of America, (referred to as “Satoshiverse”, “we”,
              “us”, or “our”) provides the website{" "}
              <a
                className="-link"
                href="https://www.satoshiverse.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.satoshiverse.io/
              </a>
              ,{" "}
              <a
                className="-link"
                href="https://www.legions2029.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.legions2029.com/
              </a>
              , and{" "}
              <a
                className="-link"
                href="https://www.game.legions2029.com "
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.game.legions2029.com/
              </a>{" "}
              (the “Sites”) and the game “Legions 2029” (the “Game”) and also
              creates and makes available, either for purchase or through other
              means of acquisition, various digital goods, either as
              non-fungible tokens minted on the blockchain (the “Satoshiverse
              NFTs”), as further defined in{" "}
              <span className="-highlighted">Section 11</span> below, or as
              licensed digital items which exist solely on Satoshiverse’s
              servers (“Non-NFT Entitlements”). References to Satoshiverse’s
              “Affiliates” in these terms means the other companies within the
              Satoshiverse corporate group (now or in the future). SATOSHIVERSE
              IS NOT A BROKER, MONEY TRANSMITTER, FINANCIAL INSTITUTION, OR
              CREDITOR.
            </p>
            <p>
              Please read this Agreement carefully and make sure you understand
              it. Your use of the Sites and/or Game, including any patches,
              updates, and downloadable content associated with the Sites, Game,
              or other software, and your purchase and use of the Satoshiverse
              NFTs is subject to this Agreement and the Satoshiverse Privacy And
              Cookies Policy (“Privacy Policy”), available at{" "}
              <a
                className="-link"
                href="https://satoshiverse.io/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://satoshiverse.io/privacy
              </a>{" "}
              (incorporated herein by reference), both of which may be updated
              from time to time. Any other links or references provided in this
              Agreement are for informational use only and are not part of the
              Agreement.
            </p>
            <p>
              By using or otherwise accessing the Game, the Sites or purchasing
              or using the Satoshiverse NFTs, you: (1) accept and agree to be
              bound by this Agreement; (2) accept that the Game is still in a
              development and testing phase and that you use at your own risk,
              as further explained in{" "}
              <span className="-highlighted">Section 7</span> below; (3) consent
              to the collection, use, disclosure and other handling of
              information as described in our Privacy Policy and (4) accept and
              agree to any additional terms, rules and conditions of
              participation issued by Satoshiverse from time to time.
            </p>
            <p>
              IF YOU DO NOT UNDERSTAND THE AGREEMENT, OR DO NOT ACCEPT ANY PART
              OF IT, THEN YOU MAY NOT USE THE GAME OR THE SITES AND/OR BUY THE
              SATOSHIVERSE NFTS. Satoshiverse has no continued obligation to
              operate the Game and the Sites in the future, at its exclusive
              discretion, with no liability whatsoever in connection thereto.
            </p>
            <p>
              This Agreement, as well as the Privacy Policy form legally binding
              contracts between you and Satoshiverse. You agree to check{" "}
              <a
                className="-link"
                href="https://www.satoshiverse.io/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.satoshiverse.io/terms
              </a>{" "}
              periodically for new information and terms that govern your use of
              the Game, Sites, and Satoshiverse NFTs.
            </p>
            <p>
              All rights not expressly granted to you are reserved by
              Satoshiverse and its licensors.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">2.</span>
              <span>Eligibility & Minors</span>
            </h2>
            <p>
              You hereby represent and warrant that you are fully able and
              competent to enter into the terms, conditions, obligations,
              affirmations, representations and warranties set forth in this
              Agreement and to abide by and comply with this Agreement.
              Satoshiverse operates a global platform and by accessing the Sites
              or Game, you are representing and warranting that you are 13 years
              old or older.
            </p>
            <p>
              IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE
              OF MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION HAS AN OLDER AGE
              OF MAJORITY, THEN YOU AGREE TO REVIEW THIS AGREEMENT WITH YOUR
              PARENT OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR PARENT OR
              GUARDIAN UNDERSTAND AND AGREE TO THIS AGREEMENT. YOU AGREE TO HAVE
              YOUR PARENT OR GUARDIAN REVIEW AND ACCEPT THIS AGREEMENT ON YOUR
              BEHALF. IF YOU ARE A PARENT OR GUARDIAN AGREEING TO THIS AGREEMENT
              FOR THE BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO ACCEPT FULL
              RESPONSIBILITY FOR THAT CHILD’S USE OF THE GAME AND THE SITE,
              INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE OR SHE
              MAY INCUR.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">3.</span>
              <span>The Sites</span>
            </h2>
            <p>
              The Sites includes the domain{" "}
              <a
                className="-link"
                href="https://www.satoshiverse.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.satoshiverse.io/
              </a>
              ,{" "}
              <a
                className="-link"
                href="https://www.legions2029.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.legions2029.com/
              </a>{" "}
              and{" "}
              <a
                className="-link"
                href="https://www.game.legions2029.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.game.legions2029.com
              </a>{" "}
              and all subdomains. The purpose of the Sites is to provide
              information on the Satoshiverse and the Legions 2029 game as well
              as to offer certain blockchain based functionalities that are not
              currently available in-game. Some of the Sites also contains an
              embedded third party blockchain marketplace provided by Rarible
              Inc. In order to interact with the Marketplace users must connect
              their blockchain wallet to Rarible. Satoshiverse has no control
              over Rarible and users wishing to make use of the embedded NFT
              marketplace should ensure that they understand and agree to
              Rarible’s{" "}
              <a
                className="-link"
                href="https://static.rarible.com/terms.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              as well as its{" "}
              <a
                className="-link"
                href="https://static.rarible.com/privacy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">4.</span>
              <span>The Game</span>
            </h2>
            <p>
              The Game is a third person role playing game developed using
              Unreal Engine 5. The Game is currently being developed to be
              compatible with Windows based operating systems on personal
              computers. The Game is currently being developed to be a single
              player game, but may include multiplayer components in the future.
              By playing the Game, players can earn in-game points, resources,
              and items that can be used to enhance their avatars and progress
              further into the Game. The game also features a Speedrun Mode
              where players are able to compete in Tournaments to win prizes
              announced periodically by Satoshiverse. Players who connect their
              blockchain wallets to their accounts are also able to find or
              create certain in-game items they can mint to the blockchain as
              NFTs. The Game uses various blockchains to, among other things,
              mint and transfer these items to the player as well as to confirm
              the player’s ownership of the items. The Alpha version of the Game
              is currently available for download on our Sites as well as on
              various third party launchers, including HyperPlay.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">5.</span>
              <span>Speedrun Mode, Tournaments, and Prizes</span>
            </h2>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.1</span>
                    <span className="-label">Speedrun Mode:</span>
                  </h3>
                  <span>
                    The Game includes a game mode called “Speedrun Mode.” In
                    this mode, players are challenged to complete a playthrough
                    of the Game in the shortest time possible. Their times are
                    recorded and ranked on a leaderboard to foster a competitive
                    environment.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.2</span>
                    <span className="-label">Tournaments and Leaderboard:</span>
                  </h3>
                  <span>
                    Satoshiverse may organize tournaments periodically
                    (“Tournaments”). These Tournaments will utilize the Speedrun
                    Mode as their basis. Players' positions on the leaderboard,
                    determined by their completion times, will be a key factor
                    in their qualification and progression in these Tournaments.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.3</span>
                    <span className="-label">Prizes:</span>
                  </h3>
                  <span>
                    Prizes may be awarded to players who achieve certain ranks
                    on the leaderboard during these Tournaments. The nature,
                    quantity, and distribution of these prizes are at the sole
                    discretion of Satoshiverse.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.4</span>
                    <span className="-label">
                      Prize Eligibility and Discretion:
                    </span>
                  </h3>
                  <span>
                    Eligibility for receiving prizes is contingent upon
                    compliance with the Game's rules and Terms of Service.
                    Satoshiverse reserves the right to award prizes at its sole
                    discretion and may withhold prizes for any reason, including
                    but not limited to, suspicion of cheating or unsportsmanlike
                    conduct.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.5</span>
                    <span className="-label">Anti-Cheating Policy:</span>
                  </h3>
                  <span>
                    Cheating in any form is strictly prohibited. This includes,
                    but is not limited to, any actions outlined in Section 18
                    (i-x) of this Agreement. Players found to be cheating will
                    be immediately disqualified from the current and future
                    Tournaments and will be ineligible for any prizes.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.6</span>
                    <span className="-label">Determination of Cheating:</span>
                  </h3>
                  <span>
                    Satoshiverse holds the exclusive right to determine what
                    constitutes cheating or unsportsmanlike behavior. This
                    determination will be made at Satoshiverse’s sole
                    discretion, and may be based on monitoring gameplay,
                    analyzing game data, player reports, or other methods deemed
                    appropriate.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.7</span>
                    <span className="-label">Disqualification:</span>
                  </h3>
                  <span>
                    Players disqualified for cheating will be removed from the
                    leaderboard and barred from future participation in
                    Tournaments. This disqualification may extend to other game
                    modes and features, at the discretion of Satoshiverse.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.8</span>
                    <span className="-label">Appeals:</span>
                  </h3>
                  <span>
                    Players who have been disqualified may appeal the decision
                    by contacting Satoshiverse through the official channels
                    provided.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.9</span>
                    <span className="-label">Changes to Terms:</span>
                  </h3>
                  <span>
                    Satoshiverse reserves the right to modify the rules of
                    Speedrun Mode, the structure of Tournaments, and the
                    criteria for awarding prizes at any time. These changes will
                    be communicated through official channels and will take
                    effect as specified in such communications.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">5.10</span>
                    <span className="-label">Acceptance of Terms:</span>
                  </h3>
                  <span>
                    Participation in Speedrun Mode and associated Tournaments
                    implies full acceptance of this Agreement. It is the
                    responsibility of the players to stay informed about the
                    rules and terms governing these events.
                  </span>
                </p>
              </li>
            </ul>
          </li>

          <li className="EUAItem">
            <h2>
              <span className="-num">6.</span>
              <span>Your Use Of The Sites And The Game</span>
            </h2>
            <p>
              You acknowledge and agree that you are responsible for your own
              conduct while accessing or using the Sites and the Game, and for
              any consequences thereof. You agree to use the Sites and the Game
              only for purposes that are legal, proper and in accordance with
              this Agreement, including but not limited to, the limitations set
              forth in Sections 14-18, and any applicable laws or regulations.{" "}
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">7.</span>
              <span>Your Account</span>
            </h2>
            <p>
              To access the Game and parts of the Sites you need to create an
              account (the “Account”), with a user name and a password.
            </p>
            <p>
              You are solely responsible for all activities conducted through
              your Account whether or not you authorize the activity. In the
              event that fraud, illegality or other conduct that violates this
              Agreement is discovered or reported (whether by you or someone
              else) that is connected with your Account, Satoshiverse may
              terminate your Account (or Accounts) as described in Section 19.
            </p>
            <p>
              You are solely responsible for maintaining the confidentiality of
              your password and for restricting access to your devices. You are
              solely responsible for any harm resulting from your disclosure, or
              authorization of the disclosure, of your password or from any
              person’s use of your password to gain access to your Account.
            </p>
            <p>
              Satoshiverse bears no responsibility for any breach of security or
              unauthorized access to your Account. You are advised to: (a) avoid
              any use of the same password with your account that you have ever
              used elsewhere; and (b) keep your password and any related secret
              information secure and confidential and do not share them with
              anyone else.
            </p>
            <p>
              Any loss or compromise of your electronic device or your security
              details may result in unauthorized access to your Account by
              third-parties. You must keep your security details safe at all
              times.
            </p>
            <p>
              You must immediately notify Satoshiverse of any unauthorized use
              of, or access to, your Account, password, or other breach of
              security at{" "}
              <span className="-highlighted">satoshiverse@apollonft.com</span>.
            </p>
            <p>
              Satoshiverse will not be liable for any loss or damage (of any
              kind and under any legal theory) to you or any third party arising
              from your inability or failure for any reason to comply with any
              of the foregoing obligations, or for any reason whatsoever, except
              fraud on our part.
            </p>
            <p>
              Satoshiverse cannot and will not be liable for any loss or damage
              arising from your sharing or other loss of your password, private
              key or related information, or any other damage or loss arising
              from unauthorized access to your Account.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">8.</span>
              <span>Game Testing Disclaimer</span>
            </h2>
            <p>
              The Game and the Sites are still in the Alpha and/or Beta testing
              phases (“Testing Phases”), are provided on an “as is” and “as
              available” basis and may contain defects and software bugs. You
              are advised to safeguard important data, property and content, to
              use caution, and to not rely in any way on the correct or secure
              functionality or performance of the Game or the Sites.
            </p>
            <p>
              During the testing phase, it may become necessary, from time to
              time to make modifications that alter various functionalities
              within the Game or on the Sites, including, but not limited to, as
              explained further below, resetting all account progress gained
              during the testing phase on all accounts (“Account Wipes”) and
              eliminating or modifying the ability to use some or all of the
              items minted as NFTs on testnet servers during the testing phase,
              including eliminating or modifying the items themselves.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">9.</span>
              <span>Access And Costs</span>
            </h2>
            <p>
              You acknowledge and agree that you will provide at your own cost
              and expense the equipment, internet or other connection charges
              required to access and use the Game, the Sites, and the
              Satoshiverse NFTs. Satoshiverse makes no warranty that the Game,
              the Sites, and/or the Satoshiverse NFTs can be accessed on all
              personal computers, game consoles, smartphones, tablets or other
              devices (each, a “Device”, or in the plural, “Devices”), by means
              of any specific internet or other connection provider, or in all
              countries.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">10.</span>
              <span>Links to Third-Party Sites</span>
            </h2>
            <p>
              The Sites and the Game may include hyperlinks to websites,
              applications, or resources operated by third parties including
              advertisers and other content providers. Those sites may collect
              data or solicit personal information from you. Satoshiverse does
              not control such websites, and is not responsible for their
              content, privacy policies, or for the collection, use or
              disclosure of any information those sites may collect. Further,
              because Satoshiverse has no control over such sites, applications
              and resources, you acknowledge and agree that Satoshiverse is not
              responsible for the availability of such external sites,
              applications or resources, and does not endorse and is not
              responsible or liable for any content, advertising, products or
              other materials on or available from such sites or resources. You
              further acknowledge and agree that Satoshiverse shall not be
              responsible or liable, directly or indirectly, for any damage or
              loss caused or alleged to be caused by or in connection with use
              of or reliance on any such content, goods or services available on
              or through any such site or resource.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">11.</span>
              <span>The Satoshiverse NFTs</span>
            </h2>
            <p>
              The “Satoshiverse NFTs” are transferable blockchain-based
              non-fungible tokens (“NFTs”) existing on the Ethereum, Polygon or
              other blockchain networks. “NFTs” are blockchain-backed tokens
              complying with the ERC-721 standard, ERC-1155 standard or other
              similar “non-fungible” token standard. NFTs are intended to be
              “non-fungible” tokens representing a unique asset.{" "}
            </p>
            <p>
              The Satoshiverse NFTs include, but are not limited to, (i) art
              NFTs not directly usable within the Game that are created and sold
              by Satoshiverse and include Satoshiverse intellectual property
              (“Satoshiverse Art NFTs”), (ii) collectible NFTs not directly
              usable within the Game that are created and sold by Satoshiverse
              and include Satoshiverse intellectual property (“Satoshiverse
              Collectible NFTs”); (iii) The Satoshi’s Legions - The Legionnaires
              avatars and the Apemo Army avatars (“Satoshiverse Avatar NFTs”),
              (iv) any future NFT avatar collections usable within the Game yet
              to be released (“Legions 2029 Avatars”) and (v) NFTs of in-game
              items that are earned or purchased by the player, including, but
              not limited to, NFT Weapons, NFT Computer Components, and NFT
              Resources (e.g. Augmentations, Satoshi’s Crystals, Satoshi’s
              Charges and Blueprints) (“Game NFTs”).
            </p>
            <p>
              The Satoshiverse NFTS evidence a limited license to related
              content, and, in some cases, a limited license to third-party
              content and/or to access additional benefits, that is exclusive to
              the owner and thereby collectible as a form of alienable digital
              property. Like physical collectibles, the price of non-fungible
              tokens may be subject to fluctuation based upon broader industry
              trends and sentiment. Legislative and regulatory changes or
              actions at the state, federal, or international level may
              adversely affect the use, transfer, exchange, and value of
              non-fungible tokens. Unlike cryptocurrencies and other fungible
              digital assets, non-fungible tokens are not used to make payments.
              Non-fungible tokens are not mutually interchangeable and cannot be
              divided into smaller parts. These design features limit the
              usefulness of non-fungible tokens as a form of payment or
              substitute for currency. Instead, non-fungible tokens are enjoyed
              as digital collectibles and/or in-game resources.
            </p>
            <p>
              There is no guaranteed future value for the Satoshiverse NFTs. Any
              future value of a Satoshiverse NFT is based solely on consumer
              interest and demand for that digital collectible and not something
              that Satoshiverse or any third party developer or other third
              party can control or will attempt to control or influence. We are
              not responsible for sustained losses due to vulnerability or any
              kind of failure, abnormal behavior of software (e.g., wallet,
              smart contract), the applicable blockchain or other third party
              service or infrastructure, including supported wallets and
              supported wallet providers, or any other features of digital
              collectibles. We are not responsible for sustained losses due to
              late reports by developers or representatives (or no report at
              all) of any issues with the applicable blockchain supporting
              digital collectibles including forks, technical node issues or any
              other issues having fund losses as a result.
            </p>
            <p>
              The Satoshiverse NFTs are unique, non-fungible tokens used
              exclusively within our game's ecosystem and are not Convertible
              Virtual Currencies (CVCs). These NFTs do not have an equivalent
              real-world currency value and cannot serve as a substitute for
              real currency. They are distinct digital collectibles, valued for
              their rarity and collectibility within the game, not for monetary
              exchange outside of it.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">12.</span>
              <span>Satoshi's Crystals</span>
            </h2>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">12.1</span>
                    <span className="-label">
                      Definition and Functionality.
                    </span>
                  </h3>
                  <span>
                    Satoshiverse NFTs are not meant to be an investment. We make
                    no claims or guarantees that your NFT will be worth
                    anything. You understand that they have no inherent monetary
                    value, and they should be treated as nothing more than
                    assets with utility within the Satoshiverse ecosystem, art,
                    and/or collectibles.
                  </span>
                  <br />
                  <br />
                  <span>
                    Like all other Satoshiverse NFTs, Satoshi's Crystals have no
                    cash value and cannot be exchanged for any form of
                    real-world currency. They are not considered a form of
                    investment or financial instrument.
                  </span>
                  <br />
                  <br />
                  <span>
                    Accumulation of Satoshi's Crystals is not related to staking
                    or any form of token lock-up. You retain full custody and
                    control over your NFTs throughout the accumulation process.
                  </span>
                  <br />
                  <br />
                  <span>
                    Like the other Satoshiverse NFTs, Satoshi’s Crystals are
                    unique, non-fungible tokens used exclusively within our
                    game's ecosystem and are not CVCs. Satoshi’s Crystals do not
                    have an equivalent real-world currency value and cannot
                    serve as a substitute for real currency. They are distinct
                    digital collectibles, valued for their utility as a resource
                    within the game, not for monetary exchange outside of it.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">12.2</span>
                    <span className="-label">
                      Accumulation of Satoshi's Crystals.
                    </span>
                  </h3>
                  <span>
                    Holders of Legionnaire, Apemo Army, and other "Special"
                    avatar collections, as announced by Satoshiverse, may
                    passively accumulate Satoshi's Crystals by opting in through
                    the Satoshi's Legions Game App at
                    https://game.satoshiverse.io/. By opting in, you agree to
                    the Satoshiverse Terms of Service and End User License
                    Agreement (EULA).
                  </span>
                  <br />
                  <br />
                  <span>
                    In addition to passive accumulation, you may also acquire
                    Satoshi's Crystals in the Game, subject to limitations as
                    specified in the game mechanics.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">12.3</span>
                    <span className="-label">Claiming Satoshi’s Crystals.</span>
                  </h3>
                  <span>
                    You can verify the number of Satoshi's Crystals you have
                    accumulated during each period by referring to the
                    Satoshiverse Game APP. The Game APP provides functionality
                    for players to claim those Satoshi’s Crystals as either
                    Non-NFT Entitlements or NFTs.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">12.4</span>
                    <span className="-label">
                      Entitlement and Transfer of Avatars.
                    </span>
                  </h3>
                  <span>
                    To be eligible for the accumulation of Satoshi's Crystals,
                    an avatar must remain in the same wallet for the entire
                    accumulation period. If an avatar is transferred out of the
                    wallet, the user will not receive the crystals for the
                    period in which it was transferred.
                  </span>
                  <br />
                  <br />
                  <span>
                    If a user purchases an avatar during one accumulation
                    period, it will be eligible for the accumulation of
                    Satoshi's Crystals during the subsequent period.
                  </span>
                  <br />
                  <br />
                  <span>
                    Transferring an avatar will not affect the user's
                    entitlement to Satoshi's Crystals for past accumulation
                    periods that have already been completed. The entitlement
                    for those past periods will remain unaffected.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">12.5</span>
                    <span className="-label">
                      Modification and Termination of Satoshi's Crystals
                      Program.
                    </span>
                  </h3>
                  <span>
                    Satoshiverse reserves the right to modify, suspend, or
                    terminate the Satoshi's Crystals program at any time, for
                    any reason, and without prior notice. This may include
                    changes to accumulation rates, eligible avatar collections,
                    or other aspects of the program.
                  </span>
                  <br />
                  <br />
                  <span>
                    You are responsible for staying informed about any changes
                    to the Satoshi's Crystals program by monitoring
                    announcements and updates provided by Satoshiverse.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">12.6</span>
                    <span className="-label">
                      Compliance with Terms of Service and EULA.
                    </span>
                  </h3>
                  <span>
                    By participating in the Satoshi's Crystals program, you
                    agree to abide by the Satoshiverse Terms of Service and End
                    User License Agreement (EULA), as well as any additional
                    rules or guidelines set forth by Satoshiverse.
                  </span>
                  <br />
                  <br />
                  <span>
                    Failure to comply with the Terms of Service, EULA, or any
                    rules or guidelines may result in the forfeiture of
                    accumulated Satoshi's Crystals, suspension or termination of
                    the user's Account, or other actions deemed appropriate by
                    Satoshiverse.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">12.7</span>
                    <span className="-label">Limitation of Liability.</span>
                  </h3>
                  <span>
                    You acknowledge that participation in the Satoshi's Crystals
                    program is at your own risk. Satoshiverse shall not be
                    liable for any loss, damage, or harm resulting from you'
                    participation in the program, including but not limited to
                    the loss of accumulated Satoshi's Crystals or any issues
                    arising from the transfer or use of avatars.
                  </span>
                  <br />
                  <br />
                  <span>
                    You are solely responsible for the security and proper use
                    of your wallets, NFTs, and any Satoshi's Crystals you have
                    accumulated. Satoshiverse shall not be held responsible for
                    any loss or theft of assets resulting from your negligence
                    or improper handling of your digital assets{" "}
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">13.</span>
              <span>Ownership of The Satoshiverse NFTs</span>
            </h2>
            <p>
              When you purchase or acquire a Satoshiverse NFT, the ownership of
              the non-fungible token will be transferred to you. Ownership of
              the non-fungible token is ruled by smart contracts and the terms
              of the specific blockchain network the NFTs are minted on.
              Satoshiverse has no ability to alter, freeze, seize or modify the
              ownership of the non-fungible token.
            </p>
            <p>
              You agree that by purchasing or earning a Satoshiverse NFT you
              understand that, because the Satoshiverse smart contracts run on
              the Ethereum or other blockchain networks and are bound by those
              networks’ systems and terms, there is no ability to undo, reverse,
              or restore any transactions affecting the ownership of the
              non-fungible token. Further, you are solely responsible for the
              safety and the management of your own private assets, which
              include but are not limited to your Blockchain Wallet and your
              private keys.
            </p>
            <p>
              The Satoshiverse NFTs may only be held by legal residents of
              countries where access to and use of the non-fungible tokens is
              permitted. Satoshiverse NFTs may be purchased or acquired only
              from Satoshiverse or an authorized retailer. Satoshiverse reserves
              the right to refuse your request(s) to acquire Satoshiverse NFTs,
              and Satoshiverse reserves the right to limit or block any request
              to acquire Satoshiverse NFTs for any reason. We do not guarantee
              that the Satoshiverse NFTs will be available at all times, in all
              countries and/or geographic locations, or at any given time or
              that we will continue to offer particular Satoshiverse NFTs for
              any particular length of time.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">14.</span>
              <span>Intellectual Property Rights In The Satoshiverse NFTs</span>
            </h2>
            <p>
              The intellectual property comprising the Satoshiverse NFTs,
              including the text, graphics, images, photographs, videos,
              illustrations and other content contained therein (“Satoshiverse
              NFT IP”), are owned by Satoshiverse or our licensors and are
              protected under both United States and foreign intellectual
              property laws. Except as explicitly stated in this Agreement, all
              rights in and to the Satoshiverse NFTs are reserved by us or our
              licensors. Satoshiverse reserves all right, title and interest in
              any Satoshiverse NFT IP and all associated copyrights, trademarks,
              and other intellectual property rights therein that are not
              expressly granted to you in this Agreement.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">15.</span>
              <span>License To Use The Satoshiverse NFT IP</span>
            </h2>
            <p>
              Satoshiverse grants you a worldwide, non-exclusive, royalty-free
              license to use and display the Satoshiverse NFT IP associated with
              Satoshiverse NFTs that you own, along with any derivative artwork
              or extensions that you could create or use, solely for the
              following purposes: (i) for your own personal, private,
              non-commercial, non-transferable, use; (ii) as part of a
              marketplace that allows the purchase and sales of your individual
              Satoshiverse NFT, as long as the marketplace cryptographically
              verifies that you are the owner, and therefore have the right to
              display the Satoshiverse NFT; or (iii) as part of a third party
              website or application that permits the inclusion, involvement or
              participation of your Satoshiverse NFT, provided that the website
              or the application cryptographically verifies that you are the
              owner and therefore have the right to display the Satoshiverse
              NFT.
            </p>
            <p>
              At this time, commercial use of the Satoshiverse NFT IP is
              prohibited without the prior written consent of Satoshiverse. If
              you would like to make commercial use of any of the Satoshiverse
              NFT IP, please contact
              <span className="-highlighted">satoshiverse@apollonft.com</span>.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">16.</span>
              <span>Non-NFT Entitlements Within The Game And The Sites</span>
            </h2>
            <p>
              “Non-NFT Entitlements" consist of all virtual materials,
              information and content provided to you (e.g., unlockable content,
              accounts, stats, virtual assets, virtual currencies, Game items,
              including, but not limited to, non-NFT Avatars, non-NFT Weapons,
              non-NFT Computer Components, non-NFT Resources, non-NFT
              Augmentations, Training Data, Data Points, Energy, codes,
              achievements, virtual rewards, credits, access, shows, tokens,
              coins, power-ups, and customizations) in connection with your use
              of the Game and the Sites, which you need to "earn", "grind",
              "buy" and/or "purchase" in order to obtain additional content, but
              that have not and/or cannot be minted to the blockchain as an NFT
              and are thus not owned and held in a blockchain wallet in your
              custody.
            </p>
            <p>
              WHILE THE GAME MAY ALLOW YOU TO “EARN,” “GRIND,” "BUY," OR
              "PURCHASE" NON-NFT ENTITLEMENTS WITHIN OR IN CONNECTION WITH
              GAMEPLAY, YOU DO NOT IN FACT OWN OR HAVE ANY PROPERTY INTEREST IN
              THE NON-NFT ENTITLEMENTS AND THE PRICE OF ANY NON-NFT ENTITLEMENTS
              DOES NOT REFER TO ANY CREDIT BALANCE OF REAL CURRENCY OR ITS
              EQUIVALENT. UNLESS OTHERWISE SPECIFIED IN WRITING, ANY NON-NFT
              ENTITLEMENTS THAT YOU RECEIVE ARE LICENSED TO YOU AS SET FORTH
              HEREIN, AND YOU SHALL HAVE NO OWNERSHIP RIGHT THERETO IN ANY
              NON-NFT ENTITLEMENTS.
            </p>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">16.1</span>
                  </h3>
                  <span>
                    You may not sell, lend, rent, trade, or otherwise transfer
                    any Non-NFT Entitlements, except for other Non-NFT
                    Entitlements where applicable. Any sale of Non-NFT
                    Entitlements, including, but not limited to, virtual
                    currency for “real” money or exchange of those items or
                    virtual currency for value outside of the Game or the Sites
                    is prohibited.{" "}
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">16.2</span>
                  </h3>
                  <span>
                    Non-NFT Entitlements may be altered, removed, deleted, or
                    discontinued by Satoshiverse (e.g., upon termination of this
                    Agreement and/or cessation of online support for the Game or
                    the Sites as set out in Section 19) even if you have not
                    “used”, “consumed”, or “minted” as NFTs the Non-NFT
                    Entitlements prior to alteration, removal, deletion, or
                    discontinuation.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">16.3</span>
                  </h3>
                  <span>
                    By purchasing or otherwise acquiring Non-NFT Entitlements,
                    you obtain a limited license (which is revocable by
                    Satoshiverse at any time unless otherwise required by
                    applicable laws) to access and use the Non-NFT Entitlements.{" "}
                    <span className="-bold">
                      Non-NFT Entitlements have no monetary value and do not
                      constitute currency or property of any type
                    </span>
                    . Non-NFT Entitlements may be redeemed for other Non-NFT
                    Entitlements only, if at all. Subject to applicable local
                    law, Non-NFT Entitlements are non-refundable and you are not
                    entitled to a refund or any other compensation such as
                    Non-NFT Entitlements for any unused Non-NFT Entitlements.
                    Any unused Non-NFT Entitlements are non-exchangeable.
                    Satoshiverse may revise the pricing for the Non-NFT
                    Entitlements offered through the Game and/or the Sites at
                    any time. Satoshiverse may limit the total amount of Non-NFT
                    Entitlements that may be purchased at any one time, and/or
                    limit the total amount of Non-NFT Entitlements that may be
                    held in your account in the aggregate.{" "}
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">16.4</span>
                  </h3>
                  <span>
                    You are only allowed to purchase Non-NFT Entitlements from
                    Satoshiverse or our authorized partners through the Game or
                    the Sites, and not in any other way.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">16.5</span>
                  </h3>
                  <span>
                    Satoshiverse reserves the right to refuse your request(s) to
                    acquire Non-NFT Entitlements. You agree that you will be
                    solely responsible for paying any applicable taxes related
                    to the acquisition of, use of or access to Non-NFT
                    Entitlements.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">16.6</span>
                  </h3>
                  <span>
                    There may be Non-NFT Entitlements (should you choose to
                    purchase them) which will require you to make a payment with
                    real money or cryptocurrency, the amount of which will be
                    set out in the Game and/or the Sites. All Non-NFT
                    Entitlements will be made available immediately when you
                    purchase them with real money or cryptocurrency and you
                    acknowledge that this is the case and that you will have no
                    right to change your mind and cancel (sometimes known as a
                    'cooling off' right) once your purchase is complete.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">17.</span>
              <span>
                Intellectual Property Rights In The Game, the Sites, and the
                Non-NFT Entitlements
              </span>
            </h2>
            <p>
              All title, ownership and intellectual property rights in and to
              the Sites, the Game, and the Non-NFT Entitlements are owned
              exclusively by Satoshiverse or its licensors. You acknowledge and
              agree that the Sites, the Game, and the Non-NFT Entitlements
              contain proprietary and confidential information that is protected
              by applicable intellectual property and other laws. You agree not
              to copy, modify, rent, lease, loan, sell, distribute, perform,
              display or create derivative works based on the Sites, the Game,
              and the Non-NFT Entitlements, in whole or in part. Satoshiverse’s
              exclusive ownership shall include all elements of the Sites, the
              Game, and the Non-NFT Entitlements, and all intellectual property
              rights therein.{" "}
            </p>
            <p>
              The visual interfaces, graphics (including, without limitation,
              all art and drawings associated with the Sites, the Game, and the
              Non-NFT Entitlements), design, systems, methods, information,
              computer code, software, “look and feel”, organization,
              compilation of the content, code, data, and all other elements of
              the Sites, the Game, and the Non-NFT Entitlements (collectively,
              the “Satoshiverse Materials”) are owned by Satoshiverse, and are
              protected by copyright, trade dress, patent, and trademark laws,
              international conventions, other relevant intellectual property
              and proprietary rights, and applicable laws. All Satoshiverse
              Materials are the copyrighted property of Satoshiverse or its
              licensors, and all trademarks, service marks, and trade names
              contained in Satoshiverse Materials are proprietary to
              Satoshiverse or its licensors.
            </p>
            <p>
              Except as expressly set forth herein, your use of the Sites, the
              Game and/or the Non-NFT Entitlements does not grant you ownership
              of or any other rights with respect to any content, code, data, or
              other materials that you may access on or through the Sites, the
              Game, and/or the Non-NFT Entitlements. Satoshiverse reserves all
              rights in and to the Sites, the Game and/or the Non-NFT
              Entitlements not expressly granted to you in this Agreement. For
              the sake of clarity, you understand and agree that you do not have
              the right to reproduce, distribute, or otherwise commercialize any
              elements of the Sites, the Game, and/or the Non-NFT Entitlements
              (including, without limitation, our copyright in and to the art
              and drawings associated with the Sites, the Game and/or the
              Non-NFT Entitlements) in any way without our prior written consent
              in each case, which consent Satoshiverse may withhold in its sole
              and absolute discretion.
            </p>
            <p>
              You may choose to submit comments, bug reports, ideas or other
              feedback about the Sites or the Game, including without limitation
              about how to improve the Sites or the Game (collectively,
              “Feedback”). By submitting any Feedback, you agree that
              Satoshiverse is free to use such Feedback at our discretion and
              without additional compensation to you, and to disclose such
              Feedback to third parties (whether on a non-confidential basis, or
              otherwise). You hereby grant us a perpetual, irrevocable,
              nonexclusive, worldwide license under all rights necessary for us
              to incorporate and use your Feedback for any purpose.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">18.</span>
              <span>
                License To Use The Sites, Game, and Non-NFT Entitlements
              </span>
            </h2>
            <p>
              Satoshiverse grants you a personal, limited, non-exclusive license
              to use the Sites, the Game, and the Non-NFT Entitlements for your
              personal, non-commercial use. To the fullest extent permitted by
              applicable law, this license granted to use the Sites, the Game,
              and the Non-NFT Entitlements is non-transferable. You may not
              rent, lease, lend, sell, redistribute or sublicense the Sites, the
              Game, and the Non-NFT Entitlements. You may not copy (except as
              expressly permitted by this license and any other applicable
              terms, conditions, or usage rules), decompile, reverse engineer,
              disassemble, attempt to derive the source code of, modify, or
              create derivative works of the Game or the Sites, any updates, or
              any part thereof (except as and only to the extent allowed by
              applicable law or to the extent as may be permitted by the
              licensing terms governing use of any open sourced components
              included with the licensed application). You may not remove or
              alter Satoshiverse's or its licensors trademarks or logos, or
              legal notices included on the Sites, the Game and/or in the
              Non-NFT Entitlements or related assets. Any attempt to use the
              Sites, the Game and/or the Non-NFT Entitlements in breach of the
              terms of this Agreement is a violation of the rights of
              Satoshiverse and its licensors. The terms of this Agreement will
              govern any upgrades provided by Satoshiverse that replace and/or
              supplement the Sites, the Game, and the Non-NFT Entitlements,
              unless such upgrade is accompanied by a separate (and/or updated)
              agreement in which case the terms of that agreement will govern.
            </p>
            <p>
              The license rights granted to you herein are also subject to the
              limitations set forth below. Any use of the Sites, the Game, and
              the Non-NFT Entitlements in violation of these limitations is a
              serious violation of the Agreement, subjects you to immediate
              termination of your license, and will be regarded as an
              infringement of Satoshiverse's copyrights and other rights in and
              to the Sites, the Game, and the Non-NFT Entitlements. You agree
              that you will not, under any circumstances:
            </p>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">i.</span>
                  </h3>
                  <span>
                    use, develop, host or distribute cheats, automation software
                    (bots), modded lobbies, hacks, mods or any other
                    unauthorized third-party software in connection with the
                    Sites, the Game, and the Non-NFT Entitlements, or engage in
                    any form of cheating, boosting, or booting;
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">ii.</span>
                  </h3>
                  <span>
                    exploit or reverse engineer the Sites, the Game, and the
                    Non-NFT Entitlements, for any commercial purpose, including
                    without limitation (a) to enable use at a cyber cafe,
                    computer gaming center or any other location-based site
                    without the express written consent of Satoshiverse; (b) for
                    gathering in-game currency, items or resources for sale
                    outside the Sites or Game; (c) performing in-game services
                    in exchange for payment outside the Sites or the Game, e.g.,
                    power-leveling; or (d) the sale of accounts, log-in
                    information, or other Game materials, access, or rights;
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">iii.</span>
                  </h3>
                  <span>
                    use any unauthorized third-party software that intercepts,
                    "mines", or otherwise collects information from or through
                    the Sites or the Game;
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">iv.</span>
                  </h3>
                  <span>
                    except as authorized by Satoshiverse in writing, host,
                    provide or develop matchmaking services for the Sites or the
                    Game, or intercept, emulate or redirect the communication
                    protocols used by Satoshiverse in any way, for any purpose,
                    including without limitation unauthorized play over the
                    Internet, network play, or as part of content aggregation
                    networks;
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">v.</span>
                  </h3>
                  <span>
                    facilitate, create or maintain any unauthorized connection
                    to the Sites or the Game, including without limitation (a)
                    any connection to any unauthorized server that emulates, or
                    attempts to emulate, the Sites or the Game; and (b) any
                    connection using programs or tools not expressly approved by
                    Satoshiverse in writing;
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">vi.</span>
                  </h3>
                  <span>
                    violate any applicable law or regulation in connection with
                    your use of Sites or the Game;
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">vii.</span>
                  </h3>
                  <span>
                    disrupt or assist in the disruption of: (i) any computer,
                    device or server used to support the Sites or the Game; or
                    (ii) any other player's use of the Sites or the Game;
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">viii.</span>
                  </h3>
                  <span>
                    interfere or attempt to interfere with the operation of the
                    Sites or the Game in any way through any means or device
                    including, but not limited to, launching a denial of service
                    attack, spamming, hacking, or uploading computer viruses or
                    time bombs; or
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">ix.</span>
                  </h3>
                  <span>
                    reproduce, distribute, display, transfer or use any part of
                    the Sites, the Game, and the Non-NFT Entitlements except as
                    expressly authorized by Satoshiverse; or
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">x.</span>
                  </h3>
                  <span>
                    scrape, copy, aggregate, redistribute, alter, reproduce or
                    re-use any user's (whether specific to any particular user
                    or as an aggregation) information accessible through the
                    Sites or the Game. You understand that access to the Sites
                    or the Game may result in access to other user’s names,
                    screen names, email addresses and other information as set
                    out in the Privacy Policy (“Personal Information”) which is
                    protected by applicable data protection and privacy laws and
                    regulations. You understand, acknowledge and agree that any
                    Personal Information from other users constitute the
                    confidential information of Satoshiverse.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">19. </span>
              <span>Termination</span>
            </h2>
            <p>
              The license granted under this Agreement is effective until
              terminated by you or Satoshiverse. Upon termination of the
              license, you shall cease all use of the Game and the Sites, and
              destroy all copies, full or partial, of the Game.
            </p>
            <h2>
              <span>
                FOR RESIDENTS OUTSIDE THE EUROPEAN UNION AND THE UNITED KINGDOM:
              </span>
            </h2>
            <p>
              Your rights under this Agreement will terminate automatically
              without notice from Satoshiverse if you fail to comply with any
              term(s) of this Agreement or applicable Third Party Terms.
            </p>
            <p>
              Satoshiverse reserves the right to terminate this Agreement
              without cause on immediate written notice.
            </p>
            <p>
              Nothing herein limits Satoshiverse's rights to suspend, terminate
              or delete any account.
            </p>
            <p>
              Satoshiverse MAY SUSPEND, TERMINATE, MODIFY, OR DELETE ANY ACCOUNT
              AT ANY TIME FOR ANY REASON OR FOR NO REASON, WITH OR WITHOUT
              NOTICE TO YOU, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
              For purposes of explanation and not limitation, many account
              suspensions, terminations and/or deletions may be the result of
              violations of this Agreement.
            </p>
            <h2>
              <span>
                FOR RESIDENTS IN THE EUROPEAN UNION AND UNITED KINGDOM:
              </span>
            </h2>
            <p>
              SATOSHIVERSE MAY TERMINATE THE LICENSE GRANTED TO YOU UNDER THIS
              AGREEMENT AND/OR SUSPEND, MODIFY, OR DELETE YOUR ACCOUNT AT ANY
              TIME WITHOUT GIVING YOU ANY PRIOR NOTICE IF YOU SERIOUSLY VIOLATE
              THIS AGREEMENT. SERIOUS VIOLATIONS ARE VIOLATIONS OF IMPORTANT
              PROVISIONS WHICH INCLUDE SECTIONS 18(i)-(x) OF THIS AGREEMENT OR
              REPEATED VIOLATIONS OF OTHER PROVISIONS OF THIS AGREEMENT
              (INCLUDING FURTHER NON-COMPLIANCE WHERE YOU ALREADY HAVE RECEIVED
              A PRIOR WARNING).
            </p>
            <p>
              IF YOU THINK THAT SUCH DECISION WAS TAKEN WRONGLY, YOU CAN CONTACT
              SATOSHIVERSE BY EMAIL AT satoshiverse@apollonft.com. IF
              SATOSHIVERSE CANNOT RESOLVE YOUR COMPLAINT AND YOU ARE A RESIDENT
              OF THE EUROPEAN UNION, YOU CAN USE THE ONLINE DISPUTE RESOLUTION
              PLATFORM SET UP BY THE EUROPEAN COMMISSION WHICH YOU CAN ACCESS AT{" "}
              <a
                className="-link"
                href="http://ec.europa.eu/odr"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://ec.europa.eu/odr
              </a>
              .
            </p>
            <p>
              SATOSHIVERSE MAY TERMINATE THE LICENSE GRANTED TO YOU UNDER THIS
              AGREEMENT AND/OR SUSPEND, MODIFY, OR DELETE YOUR ACCOUNT IF IT HAS
              ANOTHER VALID REASON TO DO SO (FOR EXAMPLE, CEASING AN ONLINE
              SERVICE FOR ECONOMIC REASONS DUE TO A LIMITED NUMBER OF USERS
              CONTINUING TO MAKE USE OF THE SERVICE OVER TIME) OR WITHOUT REASON
              BY GIVING YOU REASONABLE PRIOR NOTICE.
            </p>
            <p>
              IF SATOSHIVERSE TERMINATES THE LICENSE GRANTED TO YOU UNDER THIS
              AGREEMENT AND/OR SUSPENDS OR DELETES YOUR ACCOUNT, THIS MEANS THAT
              YOUR ACCESS AND RIGHT TO USE THE SITE AND/OR THE GAME WILL BE
              REVOKED.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">20.</span>
              <span>
                Contributing User Generated Content To The Game, The Sites, Or
                The Satoshiverse NFTs
              </span>
            </h2>
            <p>
              Satoshiverse does not claim ownership of your User Generated
              Content (“UGC”). You are and remain the owner of your UGC.
              However, when you as a user create, upload, send, receive, post,
              publish or store your UGC, such as text, photos, audio, visual
              works, video or other materials and information, on, through or in
              the Game, the Sites, or the Satoshiverse NFTs or any forum,
              message board, or social media page controlled by Satoshiverse
              related thereto, you represent that (a) you either are the sole
              and exclusive owner of all UGC that you make available on or
              through the Game or Sites; (b) you have all rights, licenses,
              consents and releases that are necessary to grant to Satoshiverse
              the rights in and to such UGC, as contemplated under this
              Agreement, including without limitation, that you have a
              royalty-free, perpetual, irrevocable, worldwide, non-exclusive
              right (including any moral rights) and license to use, license,
              reproduce, modify, adapt, publish, translate, create derivative
              works from, distribute, derive revenue or other remuneration from,
              and communicate to the public, perform and display your UGC (in
              whole or in part) worldwide and/or to incorporate it in other
              works in any form, media or technology now known or later
              developed, for the full term of any worldwide intellectual
              property right that may exist in your UGC; (c) neither the UGC nor
              your posting, uploading, publication, submission or transmittal of
              the UGC or Satoshiverse’s use of the UGC (or any portion thereof)
              will infringe, misappropriate or violate a third party’s patent,
              copyright, trademark, trade secret, moral rights or other
              proprietary or intellectual property rights, or rights of
              publicity or privacy, or result in the violation of any applicable
              law or regulation.
            </p>
            <p>
              Satoshiverse may, without prior notice to you and in its sole
              judgment, remove UGC that may infringe the intellectual property
              or other rights of a third party. If you infringe on
              Satoshiverse’s or a third party’s intellectual property or other
              rights, Satoshiverse may terminate your Account without notice to
              you. If your Account(s) is/are terminated under this paragraph,
              you are not entitled to a refund for any fees you have paid, and
              you will lose access to any Non-NFT Entitlements associated with
              your Account.{" "}
            </p>
            <p>
              Satoshiverse reserves the right (but has no obligation except as
              required by law) to remove, block, edit, move or disable UGC for
              any reason, including when Satoshiverse determines that UGC
              violates these terms. The decision to remove UGC or other content
              at any time is in Satoshiverse’s sole and final discretion. To the
              maximum extent permitted by applicable law, Satoshiverse does not
              assume any responsibility or liability for UGC, or for removal of
              UGC, or any failure to or delay in removing UGC or other content.
            </p>
            <p>
              Satoshiverse may not pre-screen UGC and does not endorse or
              approve any UGC that you and other users may contribute to the
              Game, Site, or Satoshiverse NFTs. You are solely responsible for
              your use of the Game, Sites, and Satoshiverse NFTs and for any UGC
              you provide, including compliance with applicable laws, rules, and
              regulations.
            </p>
            <p>
              Satoshiverse will respond to notices of alleged copyright
              infringement under the United States Digital Millennium Copyright
              Act (“DMCA”). If you or your authorized representative believe
              that your intellectual property rights have been infringed, please
              notify our designated agent below immediately (the “DMCA Agent”):
            </p>
            <div className="AgentInfo">
              <h2 className="-underline">Designated Agent for DMCA Notices</h2>
              <span className="-block">
                Attn. Satoshiverse LLC - Daniella Restrepo Orozco
              </span>
              <span className="-block">Harvard Business Services Inc.</span>
              <span className="-block">
                16192 Coastal Highway Lewes, Delaware 19958, Sussex County
              </span>
              <span className="-block mt-1">
                Email: Satoshiverse@apollonft.com
              </span>
            </div>
            <p>
              The DMCA (see 17 U.S.C. § 512(c)(3)) sets forth specific
              requirements for proper notification of claimed infringement.
              Valid notification must be a written communication that includes
              all of the following:
            </p>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">i.</span>
                  </h3>
                  <span>
                    A physical or electronic signature of a person authorized to
                    act on behalf of the owner of an exclusive right that is
                    allegedly infringed.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">ii.</span>
                  </h3>
                  <span>
                    Identification of the copyrighted work claimed to have been
                    infringed, or, if multiple copyrighted works on the Game,
                    Sites, or Satoshiverse NFTs are covered by a single
                    notification, a representative list of such works.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">iii.</span>
                  </h3>
                  <span>
                    Identification of the material that is claimed to be
                    infringing or to be the subject of infringing activity and
                    that is to be removed or access to which is to be disabled,
                    and information reasonably sufficient to permit Satoshiverse
                    to locate the material.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">iv.</span>
                  </h3>
                  <span>
                    Information reasonably sufficient to permit Satoshiverse to
                    contact you or your authorized representative, such as an
                    address, telephone number, and, if available, an electronic
                    mail address at which you or your authorized representative
                    may be contacted.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">v.</span>
                  </h3>
                  <span>
                    A statement that you have a good faith belief that use of
                    the material in the manner complained of is not authorized
                    by the copyright owner, its agent, or the law.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">vi.</span>
                  </h3>
                  <span>
                    A statement that the information in the notification is
                    accurate, and under penalty of perjury, that the complaining
                    party is authorized to act on behalf of the owner of an
                    exclusive right that is allegedly infringed.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              It is Satoshiverse’s policy that, upon receiving a valid DMCA
              notice, we will remove or disable access to the allegedly
              infringing material. Please note that there are substantial
              penalties for false claims of copyright infringement (see 17
              U.S.C. § 512(f)).
            </p>
            <p>
              If you believe a notice of alleged copyright infringement under
              the DMCA has been wrongly filed against you, you may submit a
              counter-notification to our DMCA Agent. The DMCA (see 17 U.S.C. §
              512(g)(3)) sets forth specific requirements for a proper
              counter-notification. A valid counter-notification must be a
              written communication that includes each of the following:
            </p>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">i.</span>
                  </h3>
                  <span>Your physical or electronic signature.</span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">ii.</span>
                  </h3>
                  <span>
                    Identification of the material that has been removed or to
                    which access has been disabled and the location at which the
                    material appeared before it was removed or access to it was
                    disabled.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">iii.</span>
                  </h3>
                  <span>
                    A statement under penalty of perjury that you have a good
                    faith belief that the material was removed or disabled as a
                    result of mistake or misidentification of the material to be
                    removed or disabled.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">iv.</span>
                  </h3>
                  <span>
                    Your name, address, and telephone number, and a statement
                    that you consent to the jurisdiction of Federal District
                    Court for the judicial district in which the address is
                    located, or if your address is outside of the United States,
                    for any judicial district in which the Satoshiverse may be
                    found, and that you will accept service of process from the
                    person who provided notification under subsection (c)(1)(C)
                    or an agent of such person.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              Upon receipt of a valid counter-notification, we will forward it
              to the original complainant who submitted the DMCA notice alleging
              copyright infringement. The original complainant will then have
              ten days to notify us that it has filed a lawsuit relating to the
              allegedly infringing material. Otherwise we will restore the
              removed material or cease disabling access to it.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">21.</span>
              <span>UGC License Grant to Satoshiverse and Its Licensors</span>
            </h2>
            <p>
              By submitting, posting or displaying UGC on or through the Game,
              the Sites, the Satoshiverse NFTs, or any forum or social media
              page controlled by Satoshiverse related thereto, you grant
              Satoshiverse a worldwide, non-exclusive, royalty-free license
              (with the right to sublicense) to use, copy, reproduce, process,
              adapt, modify, publish, transmit, display and distribute such UGC
              in any and all media or distribution methods (now known or later
              developed). This license authorizes us to make your UGC available
              to the rest of the world and to let others do the same. You agree
              that this license includes the right for Satoshiverse to provide,
              promote, and improve the Game and the Sites and to make UGC
              submitted to or through the Game and the Sites available to other
              companies, organizations or individuals for the distribution,
              promotion or publication of the Game or the Sites on other media
              and services. Such additional uses by Satoshiverse, or other
              companies, organizations or individuals, may be made with no
              compensation paid to you with respect to the UGC that you submit,
              post, transmit or otherwise make available through the Game and
              the Sites.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">22.</span>
              <span>DISCLAIMERS</span>
            </h2>
            <p>
              EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY
              SATOSHIVERSE, THE GAME AND CONTENT CONTAINED THEREIN, THE SITE AND
              CONTENT CONTAINED THEREIN, THE NON-NFT ENTITLEMENTS, AND THE
              SATOSHIVERSE NFTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
              BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS
              OR IMPLIED.
            </p>
            <p>
              WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SATOSHIVERSE,
              ITS AFFILIATES, DIRECTORS, OFFICERS AND EMPLOYEES, AND LICENSORS
              DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE
              OF THE GAME, SITE, NON-NFT ENTITLEMENTS, OR SATOSHIVERSE NFTS WILL
              MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE GAME,
              SITE, NON-NFT ENTITLEMENTS, OR SATOSHIVERSE NFTS WILL BE
              UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA
              PROVIDED THROUGH THE GAME OR THE SITE WILL BE ACCURATE, (IV) THE
              GAME, SITE, NON-NFT ENTITLEMENTS, OR SATOSHIVERSE NFTS OR ANY
              CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE
              GAME OR THE SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS,
              OR (V) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE GAME OR
              THE SITE WILL BE SECURE.
            </p>
            <p>
              SATOSHIVERSE DISCLAIMS ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS
              OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OR
              CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE AND NON-INFRINGEMENT AS TO THE SERVICE, PRODUCT OR CONTENT
              CONTAINED THEREIN. SATOSHIVERSE DOES NOT REPRESENT OR WARRANT THAT
              CONTENT ON THE GAME, SITE, OR SATOSHIVERSE NFTS IS ACCURATE,
              COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WE WILL NOT BE LIABLE
              FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN
              RELIANCE ON MATERIAL OR INFORMATION, CONTAINED IN THE GAME OR THE
              SITE.{" "}
            </p>
            <p>
              WHILE SATOSHIVERSE ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE
              GAME AND THE SITE SAFE, SATOSHIVERSE CANNOT AND DOES NOT REPRESENT
              OR WARRANT THAT THE GAME OR THE SITE ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA
              THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS
              OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND
              WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT
              IS DUE TO OUR GROSS NEGLIGENCE.
            </p>
            <p>
              WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE
              NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE
              OF NFTS, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR
              CLAIMS ARISING FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
              INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (B)
              SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D)
              UNAUTHORIZED ACCESS TO APPLICATIONS; (E) ANY UNAUTHORIZED THIRD
              PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES,
              PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE GAME,
              SITE, NON-NFT ENTITLEMENTS OR SATOSHIVERSE NFTS.
            </p>
            <p>
              NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF
              THE OWNERSHIP RECORD MAINTAINED ON THE ETHEREUM OR OTHER
              BLOCKCHAIN NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY
              UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE
              ETHEREUM OR OTHER BLOCKCHAIN NETWORK. WE DO NOT GUARANTEE THAT
              SATOSHIVERSE CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY
              NFTS.
            </p>
            <p>
              SATOSHIVERSE IS NOT RESPONSIBLE FOR SUSTAINED CASUALTIES DUE TO
              VULNERABILITY OR ANY KIND OF FAILURE, ABNORMAL BEHAVIOR OF
              SOFTWARE (E.G., WALLET, SMART CONTRACT), BLOCKCHAINS OR ANY OTHER
              FEATURES OF THE NFTS. SATOSHIVERSE IS NOT RESPONSIBLE FOR
              CASUALTIES DUE TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR
              NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING
              NFTS INCLUDING FORKS, TECHNICAL NODE ISSUES OR ANY OTHER ISSUES
              HAVING FUND LOSSES AS A RESULT.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OF THE ABOVE MAY
              NOT APPLY TO YOU.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">23.</span>
              <span>Assumption of Risk</span>
            </h2>
            <p>
              You acknowledge and agree that your use of the Game, Sites,
              Non-NFT Entitlements, and Satoshiverse NFTs is at your own risk.
              You acknowledge and agree that Satoshiverse has no control over,
              and no duty to take any action regarding: which users gain access
              to or use the Game, Sites, Non-NFT Entitlements, and Satoshiverse
              NFTs; what effects the Game, Sites, Non-NFT Entitlements, or the
              Satoshiverse NFTs may have on you; how you may interpret or use
              the Game, Sites, Non-NFT Entitlements, and Satoshiverse NFTs; or
              what actions you may take or fail to take as a result of having
              been exposed to the Game, Sites, Non-NFT Entitlements, and
              Satoshiverse NFTs. You release Satoshiverse from all liability for
              your inability to access the Game, Sites, Non-NFT Entitlements,
              and Satoshiverse NFTs or any content therein.
            </p>
            <p>You further accept and acknowledge:</p>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">23.1</span>
                    <span className="-label">Not An Investment:</span>
                  </h3>
                  <span>
                    Satoshiverse NFTs are not meant to be an investment. We make
                    no claims or guarantees that your NFT will be worth
                    anything. You understand that they have no inherent monetary
                    value, and they should be treated as nothing more than
                    assets with utility within the Satoshiverse ecosystem, art,
                    and/or collectibles.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">23.2</span>
                    <span className="-label">Applicable Taxes:</span>
                  </h3>
                  <span>
                    You are solely responsible for determining what, if any,
                    taxes apply to your NFT transactions. Neither Satoshiverse
                    nor any of its Affiliates is responsible for determining the
                    taxes that apply to NFT transactions.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">23.3</span>
                    <span className="-label">Digital Currencies:</span>
                  </h3>
                  <span>
                    There are risks associated with using a digital or Internet
                    based currency, including but not limited to, the risk of
                    hardware, software and Internet connections, the risk of
                    malicious software introduction, and the risk that third
                    parties may obtain unauthorized access to information stored
                    within your wallet. You accept and acknowledge that
                    Satoshiverse will not be responsible for any communication
                    failures, disruptions, errors, distortions or delays you may
                    experience when using the NFTs, however caused.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">23.4</span>
                    <span className="-label">
                      Risk Of Lack Of Continued Blockchain Development:{" "}
                    </span>
                  </h3>
                  <span>
                    A lack of use or public interest in the creation and
                    development of distributed ecosystems could negatively
                    impact the development of those ecosystems and related
                    applications, and could therefore also negatively impact the
                    potential utility or value of NFTs.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">23.5</span>
                    <span className="-label">Regulatory Risks:</span>
                  </h3>
                  <span>
                    The regulatory regime governing blockchain technologies,
                    cryptocurrencies, and tokens is uncertain, and new
                    regulations or policies may materially adversely affect the
                    development of the Satoshiverse NFTs and the utility of NFTs
                    generally.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">23.6</span>
                    <span className="-label">
                      Risk Of Using Third Party Platforms:
                    </span>
                  </h3>
                  <span>
                    The Satoshiverse NFTs will rely on third-party platforms,
                    including, but not limited to, third party blockchain wallet
                    providers (e.g. MetaMask) to perform the transactions for
                    the sale of NFTs and to confirm your ownership thereof. If
                    we are unable to maintain a good relationship with such
                    platform providers; if the terms and conditions or pricing
                    of such platform providers change; if we violate or cannot
                    comply with the terms and conditions of such platforms; or
                    if any of such platforms loses market share or falls out of
                    favor or is unavailable for a prolonged period of time,
                    access to and use of the Satoshiverse NFTs will suffer.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">23.7</span>
                    <span className="-label">
                      Risk Of Purchasing User Generated Content:
                    </span>
                  </h3>
                  <span>
                    There are risks associated with purchasing user generated
                    content, including but not limited to, the risk of
                    purchasing counterfeit assets, mislabeled assets, assets
                    that are vulnerable to metadata decay, assets on smart
                    contracts with bugs, and assets that may become non
                    transferrable. Satoshiverse reserves the right to hide
                    collections, contracts, and assets affected by any of these
                    issues or by other issues. Assets you purchase may become
                    inaccessible on the Satoshiverse website. Under no
                    circumstances shall the inability to view your assets on the
                    Satoshiverse website serve as grounds for a claim against
                    Satoshiverse.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">24.</span>
              <span>Limitation of Liability</span>
            </h2>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
              SATOSHIVERSE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
              PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
              SPECIAL OR PUNITIVE DAMAGES ARISING FROM THIS AGREEMENT, THE GAME,
              SITE, OR SATOSHIVERSE NFTS, OR THIRD PARTY SITES AND PRODUCTS, OR
              FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS
              OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
              OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
              NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE
              AND EVEN IF SATOSHIVERSE HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES. ACCESS TO, AND USE OF, THE GAME, SITE, OR
              SATOSHIVERSE NFTS OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR
              OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR
              ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF
              DATA RESULTING THEREFROM.
            </p>
            <p>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
              EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF SATOSHIVERSE
              ARISING OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS
              TO AND USE OF SATOSHIVERSE PRODUCTS, CONTENT, NFTS, OR ANY
              PRODUCTS OR SERVICES PURCHASED RELATED TO THE GAME, SITE, OR
              SATOSHIVERSE NFTS EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT
              RECEIVED BY SATOSHIVERSE FROM THE SALE OF THE SATOSHIVERSE NFTS
              THAT ARE THE SUBJECT OF THE CLAIM.
            </p>
            <p>
              NOTHING IN THIS AGREEMENT SHALL EXCLUDE OR LIMIT LIABILITY OF
              EITHER PARTY FOR FRAUD, DEATH OR BODILY INJURY CAUSED BY
              NEGLIGENCE, VIOLATION OF LAWS, OR ANY OTHER ACTIVITY THAT CANNOT
              BE LIMITED OR EXCLUDED BY LEGITIMATE MEANS.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS ALSO LIMIT
              DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY FROM
              CONSUMER PRODUCTS, SO THIS LIMITATION MAY NOT APPLY TO PERSONAL
              INJURY CLAIMS.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">25.</span>
              <span>Dispute Resolution; Arbitration; Class Action Waiver</span>
            </h2>
            <h2>
              <span>
                PLEASE READ THE FOLLOWING ARBITRATION AGREEMENT IN THIS SECTION
                (“ARBITRATION AGREEMENT”) CAREFULLY. IT REQUIRES YOU TO
                ARBITRATE DISPUTES WITH SATOSHIVERSE AND LIMITS THE MANNER IN
                WHICH YOU CAN SEEK RELIEF FROM US.
              </span>
            </h2>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.1</span>
                    <span className="-label">Initial Dispute Resolution.</span>
                  </h3>
                  <span>
                    Satoshiverse's Customer Support department is available at
                    satoshiverse@apollonft.com to address any concerns you may
                    have regarding the Game, the Sites, and/or the Satoshiverse
                    NFTs. Most concerns are quickly resolved in this manner to
                    our customers’ satisfaction. In an effort to accelerate
                    resolution and reduce the cost of any dispute between us,
                    you and Satoshiverse agree to first attempt to negotiate any
                    dispute informally for at least thirty (30) days before
                    either party initiates any arbitration or court proceeding
                    (the “Initial Dispute Resolution Period”). That period
                    begins upon receipt of written notice from the party raising
                    the dispute. If Satoshiverse has a Dispute with you, it will
                    send notice of that Dispute to your billing address and/or
                    email address you have provided to us. If you have a Dispute
                    with Satoshiverse, you must notify us in writing at the
                    following email address: Satoshiverse@apollonft.com, using
                    the subject line “Initial Dispute Resolution Notice.” Your
                    notice of dispute must be individual to you and must include
                    your name, the Account Name and/or email address associated
                    with your player account, and your residential address. The
                    notice of dispute also must describe the dispute, explain
                    the facts of the dispute as you understand them, and tell
                    Satoshiverse what you want us to do to resolve the problem.
                    The parties shall use their best efforts to settle any
                    dispute directly through consultation and good faith
                    negotiations, and you agree that a notice of dispute
                    containing all of the information required above, followed
                    by at least 30 days of good faith negotiation, are
                    preconditions to either party initiating a lawsuit or
                    arbitration. A notice of dispute will not be valid, will not
                    start the Initial Dispute Resolution Period, and will not
                    allow you or Satoshiverse later to initiate a lawsuit or
                    arbitration, unless it contains all of the information
                    required by this paragraph. If either of us commences an
                    arbitration without having previously provided a valid and
                    compliant notice of dispute, you and Satoshiverse agree that
                    the applicable arbitration provider (or the arbitrator, if
                    one has been appointed) must suspend the arbitration until
                    the party that initiated it complies with the Initial
                    Dispute Resolution Period. You and Satoshiverse authorize
                    the arbitration provider or the arbitrator to decide
                    summarily whether the party that commenced an arbitration
                    complied with the Initial Dispute Resolution Period
                    requirement, relying solely on this Agreement and the notice
                    of dispute (if any) that you or Satoshiverse provided before
                    commencing arbitration.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.2</span>
                    <span className="-label">
                      Applicability of Arbitration Agreement.
                    </span>
                  </h3>
                  <span>
                    If a Dispute cannot be resolved through negotiations during
                    the Initial Dispute Resolution Period, you agree that any
                    dispute or claim relating in any way to your access,
                    purchase or use of the Game, the Sites, Non-NFT
                    Entitlements, or the Satoshiverse NFTs, sold or distributed
                    by the Satoshiverse, or to any aspect of your relationship
                    with Satoshiverse will be resolved by binding arbitration,
                    rather than in court, except that (1) you may assert claims
                    in small claims court if your claims qualify; and (2) you or
                    Satoshiverse may seek equitable relief in court for
                    infringement or other misuse of intellectual property rights
                    (such as trademarks, trade dress, domain names, trade
                    secrets, copyrights, and patents).
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.3</span>
                    <span className="-label">Arbitration Rules and Forum.</span>
                  </h3>
                  <span>
                    The United States Federal Arbitration Act governs the
                    interpretation and enforcement of this Arbitration
                    Agreement. To begin an arbitration proceeding, you must send
                    a letter requesting arbitration and describing your claim to
                    us. The arbitration will be conducted by JAMS, an
                    established alternative dispute resolution provider.
                    Disputes involving claims and counterclaims under $250,000,
                    not inclusive of attorneys’ fees and interest, shall be
                    subject to JAMS’s most current version of the Streamlined
                    Arbitration Rules and procedures available at{" "}
                    <a
                      className="-link"
                      href="http://www.jamsadr.com/rules-streamlined-arbitration/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.jamsadr.com/rules-streamlined-arbitration/
                    </a>
                    ; all other claims shall be subject to JAMS’s most current
                    version of the Comprehensive Arbitration Rules and
                    Procedures, available at{" "}
                    <a
                      className="-link"
                      href="http://www.jamsadr.com/rules-comprehensive-arbitration/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.jamsadr.com/rules-comprehensive-arbitration/
                    </a>
                    . JAMS’s rules are also available at jamsadr.com or by
                    calling JAMS at 800-352-5267. If JAMS is not available to
                    arbitrate, the parties will select an alternative arbitral
                    forum. Any judgment on the award rendered by the arbitrator
                    may be entered in any court of competent jurisdiction.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.4</span>
                    <span className="-label">Authority of Arbitrator.</span>
                  </h3>
                  <span>
                    The arbitrator shall have exclusive authority to (a)
                    determine the scope and enforceability of this Arbitration
                    Agreement and (b) resolve any dispute related to the
                    interpretation, applicability, enforceability or formation
                    of this Arbitration Agreement including, but not limited to
                    any claim that all or any part of this Arbitration Agreement
                    is void or voidable. The arbitrator will decide the rights
                    and liabilities, if any, of you and Satoshiverse. The
                    arbitration proceeding will not be consolidated with any
                    other matters or joined with any other cases or parties. The
                    arbitrator shall have the authority to grant motions
                    dispositive of all or part of any claim. The arbitrator
                    shall have the authority to award monetary damages and to
                    grant any non-monetary remedy or relief available to an
                    individual under applicable law, the arbitral forum’s rules,
                    and the Agreement (including the Arbitration Agreement). The
                    arbitrator shall issue a written award and statement of
                    decision describing the essential findings and conclusions
                    on which the award is based, including the calculation of
                    any damages awarded. The arbitrator has the same authority
                    to award relief on an individual basis that a judge in a
                    court of law would have. The award of the arbitrator is
                    final and binding upon you and us.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.5</span>
                    <span className="-label">Waiver of Jury Trial.</span>
                  </h3>
                  <span>
                    YOU AND SATOSHIVERSE HEREBY WAIVE ANY CONSTITUTIONAL AND
                    STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT
                    OF A JUDGE OR A JURY. You and Satoshiverse are instead
                    electing that all claims and disputes shall be resolved by
                    arbitration under this Arbitration Agreement, except as
                    specified in Section 25.1 and 25.2 above. An arbitrator can
                    award on an individual basis the same damages and relief as
                    a court and must follow this Agreement as a court would.
                    However, there is no judge or jury in arbitration, and court
                    review of an arbitration award is subject to very limited
                    review.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.6</span>
                    <span className="-label">
                      Waiver of Class or Other Non-Individualized Relief.{" "}
                    </span>
                  </h3>
                  <span>
                    ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
                    AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT
                    ON A COLLECTIVE CLASS BASIS, ONLY INDIVIDUAL RELIEF IS
                    AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER
                    CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER
                    CUSTOMER OR USER. If a decision is issued stating that
                    applicable law precludes enforcement of any part of this
                    subsection’s limitations as to a given claim for relief,
                    then that claim must be severed from the arbitration and
                    brought in the state or federal courts located in the State
                    of Delaware. All other claims shall be arbitrated.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.7</span>
                    <span className="-label">30-Day Right to Opt Out.</span>
                  </h3>
                  <span>
                    You have the right to opt out of the provisions of this
                    Arbitration Agreement by sending written notice of your
                    decision to opt out to{" "}
                    <span className="-highlighted">
                      satoshiverse@apollonft.com
                    </span>
                    , within 30 days after first becoming subject to this
                    Arbitration Agreement. Your notice must include your name
                    and address, the email address you used to set up your
                    account (if you have one), and an unequivocal statement that
                    you want to opt out of this Arbitration Agreement. If you
                    opt out of this Arbitration Agreement, all other parts of
                    this Agreement will continue to apply to you. Opting out of
                    this Arbitration Agreement has no effect on any other
                    arbitration agreements that you may currently have, or may
                    enter in the future, with us.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.8</span>
                    <span className="-label">Severability.</span>
                  </h3>
                  <span>
                    If any part or parts of this Arbitration Agreement are found
                    under the law to be invalid or unenforceable, then such
                    specific part or parts shall be of no force and effect and
                    shall be severed and the remainder of the Arbitration
                    Agreement shall continue in full force and effect.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.9</span>
                    <span className="-label">Survival of Agreement.</span>
                  </h3>
                  <span>
                    This Arbitration Agreement will survive the termination of
                    your relationship with Satoshiverse.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">25.10</span>
                    <span className="-label">Modification.</span>
                  </h3>
                  <span>
                    Notwithstanding any provision in this Agreement to the
                    contrary, we agree that if Satoshiverse makes any future
                    material change to this Arbitration Agreement, you may
                    reject that change within thirty (30) days of such change
                    becoming effective by emailing to{" "}
                    <span className="-highlighted">
                      satoshiverse@apollonft.com
                    </span>
                    , as set forth in Section 25.7.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">26.</span>
              <span>Use of Data</span>
            </h2>
            <p>
              FOR FULL DETAILS ABOUT HOW SATOSHIVERSE COLLECTS, STORES AND USES
              PERSONAL AND OTHER INFORMATION, PLEASE SEE THE PRIVACY POLICY
              AVAILABLE AT{" "}
              <a
                className="-link"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.satoshiverse.io/privacy
              </a>
              .
            </p>
            <p>
              YOU ARE RESPONSIBLE FOR REVIEWING, AND AGREE TO REVIEW,
              SATOSHIVERSE'S PRIVACY POLICY.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">27.</span>
              <span>Consent to Monitor</span>
            </h2>
            <p>
              Communications made using the Game or the Sites and any forums
              and/or message boards controlled by Satoshiverse related thereto
              should not be considered private. Satoshiverse may monitor and/or
              record your communications (including without limitation chat text
              or voice communications) when you are using the Sites or the Game
              and any forums and/or message boards controlled by Satoshiverse
              related thereto, and you hereby provide your irrevocable, express
              consent to such monitoring and recording. You acknowledge and
              agree that you have no expectation of privacy concerning the
              transmission of any UGC or communications, including without
              limitation chat text or voice communications. Because voice chat
              and other communications may be viewed and/or heard by other
              users, users should avoid revealing any personally identifiable
              information. Additionally, Satoshiverse may, with or without
              notice to you, disclose your Internet Protocol (IP) address(es),
              personal information, chat logs, and other information about you
              and your activities consistent with our Privacy Policy.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">28.</span>
              <span>Changes To This Agreement</span>
            </h2>
            <h2>
              <span>
                FOR RESIDENTS OUTSIDE THE EUROPEAN UNION AND THE UNITED KINGDOM:
              </span>
            </h2>
            <p>
              Except for Section 25 (Dispute Resolution; Arbitration; Class
              Action Waiver), Satoshiverse reserves the right, at its sole and
              absolute discretion, to change, modify, add to, supplement or
              delete any of the terms and conditions of this Agreement at any
              time, effective with or without prior notice, and by any means,
              including without limitation by posting notification on the Game
              or the Sites, by email, through a patch process, pop-up screen or
              other form of notice. Your continued use of the Game, Sites, or
              Satoshiverse NFTs following any revision to this Agreement
              constitutes your acceptance of any such changes. Additionally, you
              may be asked to affirmatively accept updates to this Agreement
              from time to time. Note that if you do not affirmatively indicate
              your acceptance when requested, you may not be able to continue to
              use the Game or the Sites.
            </p>
            <h2>
              <span>
                FOR RESIDENTS IN THE EUROPEAN UNION AND THE UNITED KINGDOM:
              </span>
            </h2>
            <p>
              FROM TIME TO TIME, SATOSHIVERSE MAY MODIFY, ADD TO, SUPPLEMENT OR
              DELETE THE TERMS OF THIS AGREEMENT, FOR INSTANCE IF THERE IS A
              CHANGE TO ITS PRODUCTS AND SERVICES, TO IMPROVE PLAYERS’ SAFETY OR
              IN CASE OF CHANGE IN APPLICABLE LAWS. IF SATOSHIVERSE
              SUBSTANTIALLY CHANGES THE TERMS OF THIS AGREEMENT, SATOSHIVERSE
              WILL NOTIFY YOU IN ADVANCE BEFORE THE NEW TERMS OF THIS AGREEMENT
              COME INTO EFFECT AND YOU WILL BE ASKED TO ACCEPT THOSE CHANGES TO
              CONTINUE TO USE SATOSHIVERSE PRODUCTS AND SERVICES. YOU WILL HAVE
              THE OPTION TO REFUSE THE AMENDED AGREEMENT, BUT IF YOU DO YOU WILL
              NO LONGER BE ABLE TO USE SATOSHIVERSE PRODUCTS AND SERVICES. THE
              LATEST VERSION OF THE AGREEMENT IS AVAILABLE ON OUR WEBSITE{" "}
              <a
                className="-link"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.satoshiverse.io/terms
              </a>
              , SO WE ADVISE YOU TO CHECK WHETHER THE TERMS OF THE AGREEMENT
              HAVE BEEN UPDATED EACH TIME YOU USE SATOSHIVERSE PRODUCTS AND
              SERVICES. CHANGES TO THE TERMS OF THIS AGREEMENT WON’T AFFECT YOUR
              RIGHTS, WILL NOT SUBSTANTIALLY CHANGE THE CONTRACTUAL BALANCE
              BETWEEN YOU AND SATOSHIVERSE AND WILL NOT HAVE RETROACTIVE
              EFFECTS.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">29.</span>
              <span>Support</span>
            </h2>
            <p>
              If you have a complaint regarding the Game, the Sites, or the
              Satoshiverse NFTs or desire further information regarding the
              Game, the Sites, or the Satoshiverse NFTs, please open a support
              ticket in our Discord at{" "}
              <a
                className="-link"
                href="https://discord.gg/satoshiverse"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://discord.gg/satoshiverse
              </a>
              .
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">30.</span>
              <span>Governing Law and Venue</span>
            </h2>
            <p>
              This Agreement and the rights of the parties hereunder shall be
              governed, construed and enforced in accordance with the laws of
              the State of Delaware, without regard to its conflict of law
              principles. Subject to Section 25, any suit or action arising out
              of or in connection with this Agreement, or any breach thereof,
              shall be brought and maintained in the federal or state courts in
              Delaware. The parties hereby irrevocably submit to the
              jurisdiction of such courts for the purpose of such suit or action
              and hereby expressly and irrevocably waive, to the fullest extent
              permitted by law, any objection it may now or hereafter have to
              the venue of any such suit or action in any such court and any
              claim that any such suit or action has been brought in an
              inconvenient forum.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">31.</span>
              <span>Severability</span>
            </h2>
            <p>
              If any part of this Agreement is held invalid or unenforceable,
              that portion shall be interpreted in a manner consistent with
              applicable law to reflect, as nearly as possible, the original
              intentions of Satoshiverse, and the remaining portions shall
              remain in full force and effect.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">32.</span>
              <span>
                Updates to the The Game, The Sites, The Non-NFT Entitlements,
                And/Or the Satoshiverse NFTs
              </span>
            </h2>
            <p>
              SATOSHIVERSE MAY FIND IT NECESSARY TO UPDATE OR RESET CERTAIN
              PARAMETERS OF THE SITE, THE GAME, THE NON-NFT ENTITLEMENTS OR THE
              SATOSHIVERSE NFTS. SATOSHIVERSE RESERVES THE RIGHT TO MAKE THESE
              UPDATES WITHOUT NOTICE AND IS NOT LIABLE TO YOU FOR THESE CHANGES.
            </p>
          </li>
          <li className="EUAItem">
            <h2>
              <span className="-num">33.</span>
              <span>Miscellaneous</span>
            </h2>
            <ul className="EUASubList">
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">33.1</span>
                    <span className="-label">Entire Agreement.</span>
                  </h3>
                  <span>
                    This Agreement along with the Satoshiverse Privacy Policy
                    constitute the entire agreement between you and Satoshiverse
                    relating to your access to and use of the Game, the Sites,
                    and the Satoshiverse NFTs.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">33.2</span>
                    <span className="-label">Waiver.</span>
                  </h3>
                  <span>
                    This Agreement, and any rights and licenses granted
                    hereunder, may not be transferred or assigned by you without
                    the prior written consent of Satoshiverse and Satoshiverse’s
                    failure to assert any right or provision under this
                    Agreement shall not constitute a waiver of such right or
                    provision.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">33.3</span>
                    <span className="-label">Third Party Beneficiaries. </span>
                  </h3>
                  <span>
                    Except as otherwise provided herein, this Agreement is
                    intended solely for the benefit of the parties and is not
                    intended to confer third party beneficiary rights upon any
                    other person or entity.
                  </span>
                </p>
              </li>
              <li className="EUASubItem">
                <p className="-justify">
                  <h3>
                    <span className="-num">33.4</span>
                    <span className="-label">Remedies.</span>
                  </h3>
                  <span>
                    You understand and agree that this Agreement, the Privacy
                    Policy and all terms incorporated into this Agreement,
                    including Satoshiverse’s enforcement of those policies, are
                    not intended to confer, and do not confer, any rights or
                    remedies upon any person.
                  </span>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
