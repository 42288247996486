import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part12.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore_Part12.png`

const Part12 = (props) => {
  return (
    <BasicLoreModal
      image={image}
    >
      <div className="LoreBlock">
        <p>
          The pirates took their pillaging to new heights under Captain Apemo's command! They primarily targeted ships that trafficked humans, a practice that Apemo despised, and which also frequently carried large sums of money acquired through the heinous trade. Apemo would set free any humans he found aboard after conquering the ship, keeping the riches for himself and his crew. Humans would occasionally choose to join Apemo's cause, feeling grateful to Apemo for releasing them. As a result, Apemo's crew was made up of brave warriors from all over the world. Apemo didn't care who they were or where they came from as long as they were loyal.
        </p>
        <p>
          While Captain Apemo considered all of his crewmates to be family, five key members of the crew became Apemo's closest companions.        </p>
        <p>
          Captain Zhao, the ship's former captain and Apemo's closest confidante, was the first. While his body had aged and he could no longer move as quickly as he used to, he was still one of the best fighters around. His many battles had made him extremely wise, and whenever Apemo needed advice, he would go to Zhao.        </p>
        <p>
          The second was Cara. Cara joined Apemo after he rescued her from a ship off the coast of Belize. While there were some women pirates on Apemo’s ship, this was the first time Apemo had seen a human woman as beautiful as Cara. Her dark skin contrasted perfectly with her light green eyes. And while she had a small slender figure, she was not to be underestimated on the battlefield. She was extremely agile and quick with her sword. Before you knew what was coming, the fight was already over.          <br></br>
        </p>
        <p>
          The third was Mav, a dashingly handsome fellow who always beat to his own drum. Mav was formerly the captain of an American sailing vessel on a mission to end human trafficking. However, during one unfortunate raid, Mav lost his entire crew and was taken hostage by the traffickers. Mav thought it was all over for him, but one fateful day, his destiny changed. Captain Apemo and his crew took the ship by force and offered Mav a new chance on life. Grateful to Apemo for rescuing him and knowing their interests were aligned, Mav vowed his loyalty to Apemo and his crew.
        </p>
        <p>
          The fourth was a mammoth of a man named Simón. While Simón looked like he was built for the battlefield, it was his intelligence that set him apart. In fact, Simon was a gentle giant and wouldn’t harm a fly unless he had to. One day, a trafficking crew hired him to monitor their radars and communications systems at sea tricking him into joining them by telling him they were on a fishing expedition. When he found out their true intentions, it was already too late. So he devised a plan to undermine their mission. He sent out signals in Morse Code over the radar hoping one of the military vessels would intercept them. But it was not a military vessel that intercepted the signal. Shortly after receiving a signal back saying “On our way,” Simón peered into the horizon and saw a ship with a large black flag with what appeared to be an ape’s skull and crosswords on it headed right for them. It was Captain Apemo and his crew! They laid quick waste to the crew of traffickers and rewarded Simón for tipping them off. But Simón did not want the reward. He had finally found a purpose worth fighting for and decided to join Apemo in his battles.         </p>
        <p>
          The fifth hero to stand by Apemo’s side was a Scandinavian woman named Freyja. Built like a bear, Freyja struggled her whole life to fit in. At some point, she stopped caring about what other people thought and embraced both her strength and her femininity. She was the fashionista of the group while also being the strongest. Unlike the others, she was not rescued by Apemo in his conquests. She chose the pirate life! One night at a secret pirate bar on an island in the Caribbean, Apemo was up to his usual ways of hustling other pirates out of their money in arm wrestling competitions when suddenly, Freyja sat down across from him. Never having been beaten before, Apemo assumed he would make quick work of Freyja. Apemo never underestimated Freyja again. After giving her his money, he knew he had to have her onboard. After several drinks Freyja agreed and over time, she became one of Apemo’s closest companions.
        </p>
      </div>
    </BasicLoreModal>
  );
};

export default Part12;
