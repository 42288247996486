import { useSelector, useDispatch, connect } from "react-redux";
import { useEffect } from "react";

import { connectWallet, disconnectWallet } from "actions/blockActions";

import { NETWORK_ID } from "actions/blockActions";

import "./styles.css";

import StickyNav from "components/StickyNav/StickyNav";
import { setupApemo } from "actions/whitelistActions";

import Countdown2 from "./Countdown/Countdown2";
import Phase0 from "./phases/Phase0/Phase0";
import Phase1 from "./phases/Phase1/Phase1";
import Phase2 from "./phases/Phase2/Phase2";
import Phase3 from "./phases/Phase3/Phase3";
import Phase4 from "./phases/Phase4/Phase4";
import Phase5 from "./phases/Phase5/Phase5";

import ReactLoading from "react-loading";
import { setPhaseNumber } from "actions/whitelistActions";

import Footer from "components/Footer/Footer";

import { log } from "actions/errorActions";

const ApemoArmyTest = (props) => {
  const isFetchingData = useSelector((state) => state.block.isFetchingData);
  const isMinting = useSelector((state) => state.whitelist.isMinting);

  const address = useSelector((state) => state.block.address);
  const chainId = useSelector((state) => state.block.chainId);

  const successMsg = useSelector((state) => state.block.successMsg);
  const errorMsg = useSelector((state) => state.error.msg);
  const statusMsg = useSelector((state) => state.block.statusMsg);

  const isWalletConnected = useSelector(
    (state) => state.block.isWalletConnected
  );

  const leftPurchaseCount = useSelector((state) =>
    state.whitelist.leftPurchaseCount !== undefined
      ? state.whitelist.leftPurchaseCount
      : 5000
  );

  const phaseNumber = useSelector((state) => state.whitelist.phaseNumber);

  const tokenCounts = useSelector(
    (state) => state.whitelist.whitelistTokenCounts
  );

  log("tokenCounts: ");
  log(tokenCounts);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setupApemo());
  }, []);

  useEffect(() => {
    log("Is Wallet Connected: ", isWalletConnected);
  }, [isWalletConnected]);

  const getContentByPhase = () => {
    switch (phaseNumber) {
      case 0:
        return <Phase0 checkNetwork={checkNetwork} />;
      case 1:
        return <Phase1 checkNetwork={checkNetwork} />;
      case 2:
        return <Phase2 checkNetwork={checkNetwork} />;
      case 3:
        return <Phase3 checkNetwork={checkNetwork} />;
      case 4:
        return <Phase4 checkNetwork={checkNetwork} />;
      case 5:
        return <Phase5 />;
      default:
        return <></>;
    }
  };

  const getDescriptionByPhase = () => {
    switch (phaseNumber) {
      case 1:
        return (
          <p className="phase-desc">
            From 9 AM PST to 10 AM PST on August 22nd, only the following lists
            can mint: Apelist, Crewmen's List, Trevor Jones Bitcoin Angel
            Texture List, and Hackatao Q+K Texture List.
          </p>
        );
      case 2:
        return <></>;
      case 3:
        return (
          <p className="phase-desc">
            For 24 hours, the Apemo Army mint will be open to the public at the
            public mint price. Minting will be on a first come first served
            basis while supplies last. Maximum 10 mints per transaction.
          </p>
        );
      case 4:
        return (
          <p className="phase-desc">
            All collectors who have eligible free claim pieces as of the
            snapshot time can mint their free claim allotment.
          </p>
        );
      default:
        return <></>;
    }
  };

  const onConnect = (e) => {
    dispatch(connectWallet());
  };

  const onDisconnect = () => {
    dispatch(disconnectWallet());
  };

  const checkNetwork = () => chainId === NETWORK_ID;

  const ellipsify = (str) => {
    if (str.length > 10) {
      return str.substring(0, 10) + "...";
    } else {
      return str;
    }
  };

  // log("leftPurchaseCount: ", leftPurchaseCount);

  return (
    <div className="JS-Scroll">
      <div className="ApemoArmyTestSection">
        <StickyNav
          onLoreClick={props.onLoreClick}
          onScrollClick={props.onScrollClick}
          changeRoute={props.changeRoute}
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          useSmooth={true}
        />
        <div className="MintSection">
          {
            <div className="imgContainer">
              <img
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/Logo_apemo_army.png`}
                className="apemoLogo"
              />
            </div>
          }
          <Countdown2 />
          {/* <h1>Phase {phaseNumber}</h1> */}
          <div id="anchor" />
          {getDescriptionByPhase()}

          {!isWalletConnected ? (
            <button className="apemo-btn" onClick={onConnect}>
              Connect Wallet
            </button>
          ) : (
            <button className="apemo-btn" onClick={onDisconnect}>
              Disconnect
            </button>
          )}

          {errorMsg !== "" ? (
            <span
              className="error"
              style={{ marginTop: "1rem", marginBottom: "2rem", color: "red" }}
            >
              {errorMsg}
            </span>
          ) : null}

          {successMsg !== "" ? (
            <span
              className="error"
              style={{
                marginTop: "1rem",
                marginBottom: "2rem",
                color: "green",
              }}
            >
              {successMsg}
            </span>
          ) : null}

          {statusMsg !== "" ? (
            <span
              className="error"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                color: "white",
              }}
            >
              {statusMsg}
            </span>
          ) : null}

          {isFetchingData || isMinting ? (
            <ReactLoading className="apemo-loading-spin" type="spin" />
          ) : null}

          {address && address !== "" ? (
            <span className="address">ADDRESS: {ellipsify(address)}</span>
          ) : null}

          {getContentByPhase()}

          {leftPurchaseCount <= 1000 ? (
            <p className="phase-desc" style={{ marginTop: "1rem" }}>
              {leftPurchaseCount} apemo army avatars left for sale
            </p>
          ) : null}

          <a
            className="apemo-link"
            style={{ margin: "2rem 0rem", textAlign: "center" }}
            href="/apemo-army"
            target="_blank"
          >
            Learn more
          </a>
        </div>
      </div>

      <div style={{ height: "70px" }} />
      <Footer />
      <div style={{ height: "70px" }} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // mintCrewmen: (quantity, whitelistId) =>
    //   dispatch(mintCrewmen(quantity, whitelistId)),
    setupApemo: () => dispatch(setupApemo()),
    setPhaseNumber: (phase) => dispatch(setPhaseNumber(phase)),
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(ApemoArmyTest);
