import React, { Component } from "react";
import "./Formulary.css";

import axios from "axios";
import ReactLoading from "react-loading"

import { environment } from "../../../config";

class Formulary extends Component {
  state = {
    agreed: false,
    twitter_address: "",
    eth_address: "",
    email: "",

    errorMsg: "",
    isLoading: false,
    errorColor: "",

    showEthAddressInfo: false,
    isFocusedEthAddress: false,
  };

  onMouseEthAddressEnter = () => {
    if (!this.state.isFocusedEthAddress && !this.state.showEthAddressInfo) {
      this.setState({ showEthAddressInfo: true });
    }
  };

  onMouseEthAddressLeave = () => {
    if (this.state.showEthAddressInfo) {
      this.setState({ showEthAddressInfo: false });
    }
  };

  onFocusEthAddress = () => {
    this.setState({ isFocusedEthAddress: true });
  };
  onBlurEthAddress = () => {
    this.setState({ isFocusedEthAddress: false });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (this.state.errorMsg) this.setState({ errorMsg: "", errorColor: "" });

    this.setState({
      [name]: value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { agreed } = this.state;
    let email = this.props.email;
    let token = this.props.token;

    try {
      console.log(`User with e-mail ${email} agreed to receive: ${!agreed}`);

      this.setState({ isLoading: true });

      const response = await axios({
        method: "post",
        url: "https://54qu7akuwd.execute-api.us-east-2.amazonaws.com/Fase1/save-email-preferences",
        data: {
          email,
          agreed,
          token
        },
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.statusCode === 200) {
        this.reset();
        this.setState({
          errorMsg: "Your preferences have been saved.",
          errorColor: "green",
          isLoading: false,
        });
      } else {
        this.reset();
        this.setState({
          errorMsg: JSON.parse(response.data.body).msg,
          errorColor: "red",
          isLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        errorMsg: "An error occurred while trying to submit. Please try again.",
        isLoading: false,
      });
    }
  };

  reset = () => {
    this.setState({
      agreed: false,
      twitter_address: "",
      eth_address: "",
      email: "",

      isLoading: false,
      errorColor: "",
      errorMsg: "",
    });
  };

  validateForm = () => {
    return true;
  };

  render() {
    return (
      <div className="Formulary">
        <div className="ColumnSection">
          <div className="ColumnBlock">
            <div className="--BlockFlex">
              <p className="pt-1"
                style={
                  this.props.width >= 950
                    ? { display: "none" }
                    : { marginBottom: "1.5rem" }
                }
              >
                To update your mailing preferences ({this.props.email}),  please check or uncheck the options below:
              </p>
              <div className="--BlockLeft-1">
                <p className="pt-1" style={this.props.width < 950 ? { display: "none" } : {}}>
                  To update your mailing preferences ({this.props.email}), please check or uncheck the options below
                </p>
                {
                  <React.Fragment>
                    <form className="FormBTC" onSubmit={this.onSubmit}>
                      <div className="FormFlex">
                      </div>
                      <div className="AgreementBox">
                        <label className="InputCheckboxContainer">
                          <input
                            name="agreed"
                            type="checkbox"
                            checked={this.state.agreed}
                            onChange={this.handleInputChange}
                            className="InputCheckbox"
                          />
                          <span className="checkmark"></span>
                        </label>
                        <p className="pt-2">
                          Unsubscribe from all e-mails regarding https://www.satoshiverse.io/, The Satoshiverse, and ApolloNFT (uncheck if you wish to remain subscribed to our e-mails).
                        </p>
                      </div>
                      {this.state.errorMsg ? (
                        <span
                          className="ErrorMessage"
                          style={{
                            color: this.state.errorColor
                              ? this.state.errorColor
                              : "white",
                          }}
                        >
                          {this.state.errorMsg}
                        </span>
                      ) : null}
                      <div className="--Center-mobile">
                        {this.state.isLoading ? (
                          <ReactLoading className="--LoadingSpin" type="spin" />
                        ) : null}
                        <input
                          className="SubmitButton"
                          type="submit"
                          value="Submit"
                          disabled={this.state.isLoading || !this.validateForm()}
                          style={
                            this.state.isLoading || !this.validateForm()
                              ? { pointerEvents: "none" }
                              : {}
                          }
                        ></input>
                      </div>


                    </form>
                  </React.Fragment>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Formulary;
