import React from "react";
import "./FooterFill.css";

const FooterFill = (props) => {
  return (
    <div
      className="FooterFill"
      style={props.customColor ? { background: props.customColor } : {}}
    ></div>
  );
};

export default FooterFill;
