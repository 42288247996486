import React, { useState } from "react";
import "./TeamMember2.css";

import TeamDetails from "components/Team/TeamDetails/TeamDetails";

const TeamMember2 = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isDetailsHovered, setIsDetailsHovered] = useState(false);

  const onMouseEnter = (e) => {
    setShowDetails(true);
  };

  const onMouseLeave = (e) => {
    if (!isDetailsHovered) setShowDetails(false);
  };

  return (
    <li
      className={`TeamMember2 ${props.isCofounder ? "-isCofounder" : ""}`}
      style={props.width > 480 ? { position: "relative" } : {}}
    >
      <img
        className="member-img"
        src={props.imgUrl}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      ></img>
      <span className="name">{props.name}</span>
      <span className="title">{props.title}</span>
      {props.isCofounder && (showDetails || isDetailsHovered) ? (
        <TeamDetails
          setIsDetailsHovered={setIsDetailsHovered}
          width={props.width}
          name={props.name}
          complete_name={props.complete_name}
          bio={props.bio}
          twitterUrl={props.twitterUrl}
          linkedUrl={props.linkedUrl}
        />
      ) : null}
    </li>
  );
};

export default TeamMember2;
