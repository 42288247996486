import { Buy } from './Buy'
import Father from '../Father/';

export default ({
  onLoreClick,
  onScrollClick,
  changeRoute,
  toggleBurger,
  closeBurger,
  isBurgerOpen,
  width,
}) => {
  return (
    <Father
      onLoreClick={onLoreClick}
      onScrollClick={onScrollClick}
      changeRoute={changeRoute}
      toggleBurger={toggleBurger}
      closeBurger={closeBurger}
      isBurgerOpen={isBurgerOpen}
      width={width}
      Component={Buy}
    />
  )
};
