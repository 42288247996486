import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { Div, Button, Text } from '../styled';

export const Form = ({
  getUnclaimedTokens = () => { },
  address,
}) => {
  const [email, setEmail] = useState(null);
  const [numberTokens, setNumber] = useState(null);
  const [message, setMessage] = useState(null);

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }
  const handleNumber = (e) => {
    e.preventDefault();
    console.log('handleNumber: ', e);
    setNumber(e.target.value);
  }
  const handleSubmit = () => {
    axios.post('https://54qu7akuwd.execute-api.us-east-2.amazonaws.com/Fase1/saveunclaimed', {
      email,
      numberTokens,
      metamaskAccountAddress: address,
    }).then(res => {
      if (res.data.statusCode === 400) {
        setMessage('Error, please check your wallet, email or number of claims')
      }
      if (res.data.statusCode === 200) {
        setMessage('Success!')
      }
    })
  }
  const maxNumber = getUnclaimedTokens();
  return (
    <>
      <Div isFlex direction="column" width="100%">
        <Div width="80%" isFlex direction="column" mb={60}>
          <Label htmlFor="email">
            Email
          </Label>
          <Input
            type="email"
            name="email"
            onChange={handleEmail}
          />
        </Div>
        <Div width="80%" isFlex direction="column" mb={60}>
          <Label htmlFor="numberClaims">
            Number of claims
          </Label>
          <Input
            type="number"
            name="numberClaims"
            min="1"
            max={maxNumber}
            onChange={handleNumber}
          />
        </Div>
      </Div>
      <Div isCenter>
        <Button
          type='submit'
          onClick={() => handleSubmit()}
          mb={50}
          isCenter
        >
          send
        </Button>
      </Div>
      {message && (
        <Text mb={40} isCenter color={message === 'Success!' ? 'green' : 'red'} size={26}>
          {message}
        </Text>
      )}
      </>
  )
}

const Label = styled.label`
  width: 100%;
  font-family: neuropolNova;
  color: white;
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
`

const Input = styled.input`
  font-family: neuropolNova;
  width: ${isMobile ? '100%' : '60%'};
  height: 35px;
  color: white;
  border: solid 1px white;
  background: #2E3742;
  border-radius: 15px;
  font-size: 20px;
  padding: 0 10px;

  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`
