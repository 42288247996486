import React from 'react'
import "./QRNYC.css"

import NYCIntro from './NYCIntro/NYCIntro'
import NYCForm from './NYCForm/NYCForm'
import NYCInfo from './NYCInfo/NYCInfo'
import NYCSocial from './NYCSocial/NYCSocial'

const QRNYC = (props) => {
  return (
    <div className="JS-Scroll">
      <div className="QRNYCSection">
        <NYCIntro />
        {/* <NYCForm /> */}
        <NYCInfo />
        <NYCSocial />
      </div>
    </div>
  )
}

export default QRNYC
