import React from "react";
import "./SatoshiSocial.css";

const SatoshiSocial = (props) => {
  return (
    <div className="SatoshiSocial">
      <a href="https://twitter.com/Satoshiverse_io" target="_blank">
        <img
          className="SatoshiSocial-Item"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/twitter_button.png`}
        />
      </a>
      <a href="https://www.instagram.com/satoshiverse.io/" target="_blank">
        <img
          className="SatoshiSocial-Item"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/instagram_button.png`}
        />
      </a>
      <a href="https://discord.gg/satoshiverse" target="_blank">
        <img
          className="SatoshiSocial-Item"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/discord_button.png`}
        />
      </a>
      <a
        href="https://www.youtube.com/channel/UCWfae5GI1zC832yB0bsKORw/videos"
        target="_blank"
      >
        <img
          className="SatoshiSocial-Item"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/youtube_button.png`}
        />
      </a>
    </div>
  );
};

export default SatoshiSocial;
