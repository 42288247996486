import styled,{ createGlobalStyle, css } from "styled-components";

export const GlobalStyles = createGlobalStyle`

`

export const spaces = css`
    position: ${(props) => (props.position ? props.position : 'relative')};
    left: ${(props) => (props.left && props.left)};
    right: ${(props) => (props.right && props.right)};
    bottom: ${(props) => (props.bottom && props.bottom)};
    top: ${(props) => (props.top && props.top)};

    margin-top: ${(props) => props.mt && props.mt}px;
    margin-bottom: ${(props) => props.mb && props.mb}px;
    margin-right: ${(props) => props.mr && props.mr}px;
    margin-left: ${(props) => props.ml && props.ml}px;
    margin: ${(props) => props.margin && props.margin};
   
    padding-top: ${(props) => props.pt && props.pt}px;
    padding-bottom: ${(props) => props.pb && props.pb}px;
    padding-right: ${(props) => props.pr && props.pr}px;
    padding-left: ${(props) => props.pl && props.pl}px;
    padding: ${(props) => props.padding && props.padding};
    width: ${(props) => props.fullWidth && '100%'};
    height: ${(props) => props.fullHeight && '100%'};

    z-index:${(props) => props.z && '10'};

    &::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar {
    background: #0a0a0a;        /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
    background-color: black;    /* color of the scroll thumb */
    border-radius: 0px;       /* roundness of the scroll thumb */
  }
    ${(props) => props.fluid && css`
        min-height: 100%;
        min-width: 100%;
    `}

`;
export const FlexCenter = css`
    ${spaces}
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: center;
`;

export const Flex = css`
    ${spaces}
    display: flex;
    align-items: ${(props) => props.align || 'center'};
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: ${(props) => props.justify || 'center'};
`;
export const div = css`
    ${spaces}
    display: ${(props) => props.display || 'block'};
    width: ${(props) => props.width && props.width};
    height: ${(props) => props.height && props.height};
    min-height: ${(props) => props.minHeight && props.minHeight};
    background-color: ${(props) => props.backgroundColor && props.backgroundColor};
    border-radius: ${(props) => props.radius && props.radius};
    border: ${(props) => props.border && props.border};
    position: ${(props) => props.position && props.position};
    padding: ${(props) => props.padding && props.padding};
    a {
      text-decoration: none;
    }
    ${(props) => props.overflow && css`
        overflow:auto;
    `}
    ${(props) => props.isCenter && css`
        ${FlexCenter}
    `}
    
    ${(props) => props.isOverflow && css`
        overflow: hidden;
    `}
    
    ${(props) => props.isFlex && css`
        ${Flex}
    `}
    ${(props) => props.centerMargin && css`
      margin-left:auto;
      margin-right: auto;
    `}
    ${(props) => props.hover && css`
      opacity:auto;
      &:hover {
            opacity:1;
        }    `}
    ${(props) => props.isContainer && css`
         .title {
    font-family: neuropolNova;
    z-index: 10;
    font-size: 40px;
    color: rgb(255, 194, 0);
    position: absolute;
    bottom:20px;
    transition: all 1s ease-out;
    opacity: 0;
    left: 0;
  }
  &:hover {
  opacity: 1;
  .title{
    opacity: 1;
    left: ${((props) => props.leftBox ? props.leftBox : '35%')};
    -webkit-text-stroke: 1px black;
  }
    `}
`;

export const Div = styled.div`
    ${div}
`;