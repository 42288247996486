import React from "react";
import "./ApemoArmyPart.css";

const ApemoArmyPart = () => {
  return (
    <div className="PartApemoArmy Avatar-apemo_army">
      <video autoPlay muted loop className="video-reel">
        <source
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Video/Reel_apemo_army_web.mp4`}
          type="video/mp4"
        />
      </video>
      <div className="PartApemoArmyContent">
        <h1>
          The Apemo <br /> Army
        </h1>
        <p className="-p1">
          The Apemo Army gives collectors and gamers alike a new way to join the
          Satoshiverse and access holder benefits for the Satoshiverse game. The
          Apemo Army is an army of half-human-half-animal heroes led by Captain
          Apemo who join Satoshi The Creator in his quest to defeat the
          Defenders Of Fiat. Owning an Apemo Army avatar will give you access to
          holder benefits within the Satoshiverse including early Alpha Phase 2
          access to the Legions 2029 game.
        </p>
        {/* <img
          className="sold-out-img"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/soul_out_2.png`}
        /> */}
        <div className="-btns">
          <a href="https://opensea.io/collection/apemo-army" target="_blank">
            <img
              className="opensea-btn"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Opensea_button.png`}
            />
          </a>
          <a
            href="https://www.niftygateway.com/marketplace/collectible/0xbe3eedda303d28e1a763106e27605247ab8e302a"
            target="_blank"
          >
            <img
              className="nifty-btn"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/nifty_button_1.png`}
            />
          </a>
          <a
            href="https://raritysniper.com/satoshiverse-the-apemo-army"
            target="_blank"
          >
            <img
              className="rs-btn"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Rs_button.png`}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ApemoArmyPart;
