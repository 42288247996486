import {
  SETUP_CONTRACT,
  RESET_STATE,
  SHOW_INSTALL_METAMASK,
  HIDE_INSTALL_METAMASK,
  SHOW_CHANGE_NETWORK,
  HIDE_CHANGE_NETWORK,
  SHOW_CONNECT_METAMASK,
  HIDE_CONNECT_METAMASK,
  WALLET_LOADING,
  WALLET_CONNECT_SUCCESS,
  WALLET_CONNECT_FAIL,
  FETCH_TOKEN_DATA,
  IS_FETCHING_DATA,
  SET_IS_WALLET_IN_WHITELIST,
  CLEAR_STATUS_MSG,
  SET_STATUS_MSG,
  CLEAR_SUCCESS_MSG,
  SET_SUCCESS_MSG,
  SET_TRANSACTION_DATA,
  CLEAR_TRANSACTION_DATA,
  RESET_CONTRACT_DATA,
} from "../actions/types";

const initialState = {
  refProvider: null,
  provider: null,
  contract: null,
  address: null,
  chainId: null,

  satoshiverseContract: null,
  legionnaireContract: null,
  apemoContract: null,
  wethContract: null,
  isFetchingData: false,
  isWalletInWhitelist: false,

  // ---
  showInstallMetamask: false,
  showChangeNetwork: false,
  showConnectMetamask: false,

  isWalletLoading: false,
  isWalletConnected: false,

  tokensBalance: {},
  tokensCount: {},
  transactionReceipt: {},
  transactionData: {},

  statusMsg: "",
  successMsg: "",
};

export default function foo(state = initialState, action) {
  switch (action.type) {
    case SET_IS_WALLET_IN_WHITELIST:
      return {
        ...state,
        isWalletInWhitelist: true,
      };
    case SET_STATUS_MSG:
      return {
        ...state,
        statusMsg: action.payload.statusMsg,
      };
    case CLEAR_STATUS_MSG:
      return {
        ...state,
        statusMsg: "",
      };
    case SET_SUCCESS_MSG:
      return {
        ...state,
        successMsg: action.payload.successMsg,
      };
    case CLEAR_SUCCESS_MSG:
      return {
        ...state,
        successMsg: "",
      };
    case SETUP_CONTRACT:
      return {
        ...state,
        ...action.payload,
        showConnectMetamask: false,
      };
    case SHOW_INSTALL_METAMASK:
      return {
        ...state,
        showInstallMetamask: true,
      };
    case HIDE_INSTALL_METAMASK:
      return {
        ...state,
        showInstallMetamask: false,
      };
    case SHOW_CONNECT_METAMASK:
      return {
        ...state,
        showConnectMetamask: true,
      };
    case HIDE_CONNECT_METAMASK:
      return {
        ...state,
        showConnectMetamask: false,
      };
    case SHOW_CHANGE_NETWORK:
      return {
        ...state,
        showChangeNetwork: true,
        isWalletLoading: false,
        showConnectMetamask: false,
      };
    case HIDE_CHANGE_NETWORK:
      return {
        ...state,
        showChangeNetwork: false,
        isWalletLoading: false,
        showConnectMetamask: false,
      };
    case WALLET_LOADING:
      return {
        ...state,
        isWalletLoading: true,
      };
    case WALLET_CONNECT_SUCCESS:
      return {
        ...state,
        isWalletLoading: false,
        isWalletConnected: true,
      };
    case WALLET_CONNECT_FAIL:
      return {
        ...state,
        isWalletLoading: false,
        isWalletConnected: false,
      };
    case RESET_STATE:
      return {
        ...state,
        refProvider: null,
        provider: null,
        contract: null,
        address: null,
        network: null,

        satoshiverseContract: null,
        legionnaireContract: null,
        apemoContract: null,
        wethContract: null,

        isWalletInWhitelist: false,
        isFetchingData: false,

        showInstallMetamask: false,
        showConnectMetamask: false,
        isWalletLoading: false,
        isWalletConnected: false,

        tokensBalance: {},
        tokensCount: {},
        transactionReceipt: {},
        transactionData: {},
      };
    case RESET_CONTRACT_DATA:
      return {
        ...state,
        contract: null,

        satoshiverseContract: null,
        legionnaireContract: null,
        apemoContract: null,
        wethContract: null,

        isWalletInWhitelist: false,
        isFetchingData: false,

        tokensBalance: {},
        tokensCount: {},
        transactionReceipt: {},
        transactionData: {},
      };
    case FETCH_TOKEN_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case IS_FETCHING_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_TRANSACTION_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_TRANSACTION_DATA:
      return {
        ...state,
        transactionReceipt: {},
        transactionData: {},
      };
    default:
      return state;
  }
}
