import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part9.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore part 9.png`;

const Part9 = (props) => {
  return (
    <BasicLoreModal image={image} isOpen>
      <div className="LoreBlock">
        <p>
          Deep in the mountains of Bogota, a bright golden light shines from
          what was thought to be an abandoned cave. Within the cave, a complex
          apparatus with many wires quickly overflows with golden electricity,
          all flowing into what appears to be a man in a metallic suit lying on
          a table. But it is not a man. At least not anymore. Lying on the table
          is something new. Something different from any technology the world
          has seen. And as the electricity begins flowing into it, its eyes
          spring open and a golden Bitcoin symbol on its chest illuminates.
          Suddenly, the being awakens quickly sitting upright. Just as quickly
          as it rises up, it grabs a large case that is next to the table, the
          jets on its arms and legs ignite, and it flies out of the cave into
          the sky.
        </p>
        <p>
          Hours pass in Nina’s small apartment in the Outskirts of Washington
          D.C. with seemingly nothing but a screen with a Bitcoin symbol to show
          from the mysterious flash drive. But just as Nina begins to lose hope,
          she hears a loud sound in the other room. With her young brother and
          their rescued husky Athos at her side, she knows someone else has
          entered their small apartment. As she steps in front of her brother to
          protect him, through the door walks a being like nothing she has ever
          seen before. It appears to be straight out of the comic books she
          would read as a child. It is certainly not human. But it also does not
          seem to be purely machine. While the body is surrounded by a suit of
          golden armor, the face, also metallic, moves with human-like features.
          Shining from its chest is that once familiar symbol - Bitcoin. “Could
          it be?” she wonders. “Satoshi?” she utters aloud.
        </p>
        <p>
          Much to her surprise, the being responds. “Yes. My dear. I am Satoshi.
          Or at least I was. You must be Nina. And that, who is that?” The boy
          utters, “Hi. I’m Eric.” “Where is your father? Your mother?” Satoshi
          asks. Nina lowers her head as a tear runs down her face. “Oh no. This
          is much worse than I could have imagined. I am so so sorry my dear.
          Now that I am back I will make sure no harm comes to you or your
          brother.” “But what about all the others?” Nina asks. “We are not the
          only ones that have lost our families. Someone needs to do something.”
        </p>
        <p>
          “The time will come to fight. But for now we must take away their
          greatest weapon ... Providence,” Satoshi proclaims. Satoshi then opens
          a large case that he has beside him. Inside are several white masks
          with the same markings as Satoshi’s mask. “Take these,” he says. “Give
          them to people you trust. The technology inside them will make those
          who wear them invisible to Providence. Only then will we have a chance
          at defeating the D.O.F. when the time comes. But be careful not to be
          detected. I will return soon with more.” And just like that, Satoshi
          vanishes, leaving Nina and her brother in their apartment with a crate
          full of masks and something more … hope!
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part9" style={{ ...props.customStyle }}>
    //   <div className="LorePart-9 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part9Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>The Awakening</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part 9_ The Awakening.jpg"></img>
    //     <p>
    //       Deep in the mountains of Bogota, a bright golden light shines from
    //       what was thought to be an abandoned cave. Within the cave, a complex
    //       apparatus with many wires quickly overflows with golden electricity,
    //       all flowing into what appears to be a man in a metallic suit lying on
    //       a table. But it is not a man. At least not anymore. Lying on the table
    //       is something new. Something different from any technology the world
    //       has seen. And as the electricity begins flowing into it, its eyes
    //       spring open and a golden Bitcoin symbol on its chest illuminates.
    //       Suddenly, the being awakens quickly sitting upright. Just as quickly
    //       as it rises up, it grabs a large case that is next to the table, the
    //       jets on its arms and legs ignite, and it flies out of the cave into
    //       the sky.
    //     </p>
    //     <p>
    //       Hours pass in Nina’s small apartment in the Outskirts of Washington
    //       D.C. with seemingly nothing but a screen with a Bitcoin symbol to show
    //       from the mysterious flash drive. But just as Nina begins to lose hope,
    //       she hears a loud sound in the other room. With her young brother and
    //       their rescued husky Athos at her side, she knows someone else has
    //       entered their small apartment. As she steps in front of her brother to
    //       protect him, through the door walks a being like nothing she has ever
    //       seen before. It appears to be straight out of the comic books she
    //       would read as a child. It is certainly not human. But it also does not
    //       seem to be purely machine. While the body is surrounded by a suit of
    //       golden armor, the face, also metallic, moves with human-like features.
    //       Shining from its chest is that once familiar symbol - Bitcoin. “Could
    //       it be?” she wonders. “Satoshi?” she utters aloud.
    //     </p>
    //     <p>
    //       Much to her surprise, the being responds. “Yes. My dear. I am Satoshi.
    //       Or at least I was. You must be Nina. And that, who is that?” The boy
    //       utters, “Hi. I’m Eric.” “Where is your father? Your mother?” Satoshi
    //       asks. Nina lowers her head as a tear runs down her face. “Oh no. This
    //       is much worse than I could have imagined. I am so so sorry my dear.
    //       Now that I am back I will make sure no harm comes to you or your
    //       brother.” “But what about all the others?” Nina asks. “We are not the
    //       only ones that have lost our families. Someone needs to do something.”
    //     </p>
    //     <p>
    //       “The time will come to fight. But for now we must take away their
    //       greatest weapon ... Providence,” Satoshi proclaims. Satoshi then opens
    //       a large case that he has beside him. Inside are several white masks
    //       with the same markings as Satoshi’s mask. “Take these,” he says. “Give
    //       them to people you trust. The technology inside them will make those
    //       who wear them invisible to Providence. Only then will we have a chance
    //       at defeating the D.O.F. when the time comes. But be careful not to be
    //       detected. I will return soon with more.” And just like that, Satoshi
    //       vanishes, leaving Nina and her brother in their apartment with a crate
    //       full of masks and something more … hope!
    //     </p>
    //   </div>
    // </div>
  );
};

export default Part9;
