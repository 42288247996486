import React from 'react'
import "./NYCIntro.css"

const NYCIntro = (props) => {
  return (
    <div className="NYCIntro">
      <div className="IntroContent">
        <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLACC/SVG/headline.svg`} />
        <div className="IntroFlex">
          <p>
          The signup period for the free LACC 2022 NFT has ended. If you already signed up correctly, your NFT will be airdropped to your Ethereum wallet on the Polygon blockchain by December 20, 2022.
          </p>
          <video
            autoPlay
            playsInline
            muted
            loop
            className="card-img">
            <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLACC/VIDEO/BlackCard-LACC-2022-Alpha_H.265.mp4` } type='video/mp4;codecs=hvc1'></source>
            <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLACC/VIDEO/black-card.webm`} type="video/webm"></source>            
          </video>
        </div>
      </div>
      <br></br><br></br><br></br><br></br><br></br><br></br>

      <img
        className="free-img"
        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesNYC/SVG/Free_img.svg`}
      />
    </div>
  )
}

export default NYCIntro
