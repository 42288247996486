import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part2.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 2.png`;

const Part2 = (props) => {
  return (
    <BasicLoreModal image={image}>
      <div className="LoreBlock">
        <p>
          Unfortunately, the technological renaissance is short lived. Late in
          the year 2019, an event that will change the world begins - The Great
          Pandemic. A disease like none we have seen before. Beginning in Asia,
          the Great Pandemic quickly spreads across the world. Death and
          economic hardship set in. Political divides are drawn. The worst faces
          of man are shown. Weeks of pandemic become months. Months, years. With
          each sign of hope, the pandemic mutates, causing more death and
          economic decay. Desperation begins to set in. People grow frustrated.
          Protests. Looting. All of which only cause the pandemic to worsen.
          With each vaccine a mutation. With each mutation more death. The
          poorest countries are hit the hardest. Over 25% of the population
          loses their lives.
        </p>
        <p>
          With a global solution desperately needed, in December 2022, United
          States <strong>President Lance Morgan</strong>, a tall slender old man
          with perfectly combed straight grey hair, calls for a secret meeting
          of the United Nations to discuss a drastic proposal to attempt to kill
          off the Great Pandemic once and for all. He calls it “The Lockdown.” A
          globally enforced mandate that all citizens shall remain in their
          homes until a United Nations panel can confirm that the Great Pandemic
          is gone forever.
        </p>
        <p>
          At first the idea causes outrage amongst the leaders. They question
          <strong>President Morgan</strong>’s motives. Surely there is a better
          way. But <strong>President Morgan</strong>
          has demonstrated himself to be a reasoned and able leader throughout
          the pandemic and has earned the respect of his peers. Slowly as he
          speaks, the leaders begin to accept the reality that this drastic plan
          is their last hope in stopping the Great Pandemic. But a key issue
          remains. How can such a drastic plan be enforced? Seven billion people
          around the world will never agree to voluntarily remain in their homes
          indefinitely. The governments of the world, crippled by the last three
          years of pandemic, are in no position to enforce such a drastic plan.
        </p>
        <p>
          <strong>President Morgan</strong> then proposes a solution which, in
          hindsight, topples the first domino in a long chain eventually leading
          to the chaos of present day: Worldwide surveillance using the most
          advanced in satellite, air, and ground technology. A system which will
          be enforced by artificial intelligence enabled machines that work in
          unison with the satellites to detect breaches of the lockdown and
          detain any who choose to defy it.
        </p>
        <p>
          But how, the leaders ask. No such technology exists and the leaders
          certainly do not have the time or resources to create it in such a
          limited time. That is when they are introduced to Frank Fazor. Founder
          of Alpha Corporation. A large, heavy-set man with a look that
          immediately inspires thoughts of the mob bosses of old. Publicly,
          Frank Fazor is a model citizen. Son of an oil tycoon, he spent the
          early part of his career using his father’s money to build a world
          banking empire called <strong>Alpha Corporation</strong>. A
          philanthropist who built housing complexes for poor families in the
          Middle East. But privately … a different man altogether.
        </p>
        <p>
          Frank Fazor takes the podium and explains that for the last five years
          he and his large team of researchers have been developing a technology
          that will allow for the level of surveillance and ground enforcement
          necessary to carry out <strong>President Morgan</strong>’s bold plan.
          He calls it Project Providence.
        </p>
        <p>
          Frank Fazor explains that Project Providence will use satellites,
          already manufactured by <strong>Alpha Corporation</strong> and sitting
          in warehouses awaiting deployment, to create a web of cameras that can
          canvas the Earth with high resolution surveillance technology. Those
          cameras are equipped with a patented technology created by{" "}
          <strong>Alpha Corporation</strong> that can scan the bodies and faces
          of any person at any angle and pull public data records to immediately
          identify them. The satellites will then be linked to drones that can
          quickly respond to breaches of the Lockdown and tranquilize citizens
          without harming them. The citizens will then be collected by Ground
          Enforcement Units, or “G.E.U.s”, that will transport them to monitored
          holding facilities until the United Nations Counsel determines it is
          safe for the population to emerge. “Let’s end the Great Pandemic once
          and for all!” Frank Fazor proclaims as he finishes his speech to the
          silent room.
        </p>
        <p>
          Slowly silence again turns to skepticism and outrage. “How could we?”
          The leaders ask. To subject the world to that level of surveillance
          would be an infringement of all the liberties their people hold dear.
          But with <strong>President Morgan</strong>’s endorsement, one by one
          the leaders come to believe that there is no better solution.
          Enforcement of a worldwide lockdown to finally end the pandemic will
          be impossible without such surveillance. And by a narrow margin,
          Project Providence is voted into existence.
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part2" style={{ ...props.customStyle }}>
    //   <div className="LorePart-2 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part2Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>The Beginning Of The End</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/SatoshiLoreScene02.jpg"></img>
    //     <p>
    //       Unfortunately, the technological renaissance is short lived. Late in
    //       the year 2019, an event that will change the world begins - The Great
    //       Pandemic. A disease like none we have seen before. Beginning in Asia,
    //       the Great Pandemic quickly spreads across the world. Death and
    //       economic hardship set in. Political divides are drawn. The worst faces
    //       of man are shown. Weeks of pandemic become months. Months, years. With
    //       each sign of hope, the pandemic mutates, causing more death and
    //       economic decay. Desperation begins to set in. People grow frustrated.
    //       Protests. Looting. All of which only cause the pandemic to worsen.
    //       With each vaccine a mutation. With each mutation more death. The
    //       poorest countries are hit the hardest. Over 25% of the population
    //       loses their lives.
    //     </p>
    //     <p>
    //       With a global solution desperately needed, in December 2022, United
    //       States <strong>President Morgan</strong>, a tall slender old man with
    //       perfectly combed straight grey hair, calls for a secret meeting of the
    //       United Nations to discuss a drastic proposal to attempt to kill off
    //       the Great Pandemic once and for all. He calls it “The Lockdown.” A
    //       globally enforced mandate that all citizens shall remain in their
    //       homes until a United Nations panel can confirm that the Great Pandemic
    //       is gone forever.
    //     </p>
    //     <p>
    //       At first the idea causes outrage amongst the leaders. They question
    //       <strong>President Morgan</strong>’s motives. Surely there is a better way.
    //       But <strong>President Morgan</strong>
    //       has demonstrated himself to be a reasoned and able leader throughout
    //       the pandemic and has earned the respect of his peers. Slowly as he
    //       speaks, the leaders begin to accept the reality that this drastic plan
    //       is their last hope in stopping the Great Pandemic. But a key issue
    //       remains. How can such a drastic plan be enforced? Seven billion people
    //       around the world will never agree to voluntarily remain in their homes
    //       indefinitely. The governments of the world, crippled by the last three
    //       years of pandemic, are in no position to enforce such a drastic plan.
    //     </p>
    //     <p>
    //       <strong>President Morgan</strong> then proposes a solution which, in
    //       hindsight, topples the first domino in a long chain eventually leading
    //       to the chaos of present day: Worldwide surveillance using the most
    //       advanced in satellite, air, and ground technology. A system which will
    //       be enforced by artificial intelligence enabled machines that work in
    //       unison with the satellites to detect breaches of the lockdown and
    //       detain any who choose to defy it.
    //     </p>
    //     <p>
    //       But how, the leaders ask. No such technology exists and the leaders
    //       certainly do not have the time or resources to create it in such a
    //       limited time. That is when they are introduced to{" "}
    //       Frank Fazor. Founder of Alpha Corporation. A large, heavy-set man with
    //       a look that immediately inspires thoughts of the mob bosses of old.
    //       Publicly, Frank Fazor is a model citizen. Son of an oil
    //       tycoon, he spent the early part of his career using his father’s money
    //       to build a world banking empire called <strong>Alpha Corporation</strong>. A
    //       philanthropist who built housing complexes for poor families in the
    //       Middle East. But privately … a different man altogether.
    //     </p>
    //     <p>
    //       Frank Fazor takes the podium and explains that for the last
    //       five years he and his large team of researchers have been developing a
    //       technology that will allow for the level of surveillance and ground
    //       enforcement necessary to carry out <strong>President Morgan</strong>’s bold
    //       plan. He calls it Project Providence.
    //     </p>
    //     <p>
    //       Frank Fazor explains that Project Providence will use
    //       satellites, already manufactured by <strong>Alpha Corporation</strong> and
    //       sitting in warehouses awaiting deployment, to create a web of cameras
    //       that can canvas the Earth with high resolution surveillance
    //       technology. Those cameras are equipped with a patented technology
    //       created by <strong>Alpha Corporation</strong> that can scan the bodies and faces
    //       of any person at any angle and pull public data records to immediately
    //       identify them. The satellites will then be linked to drones that can
    //       quickly respond to breaches of the Lockdown and tranquilize citizens
    //       without harming them. The citizens will then be collected by Ground
    //       Enforcement Units, or “G.E.U.s”, that will transport them to monitored
    //       holding facilities until the United Nations Counsel determines it is
    //       safe for the population to emerge. “Let’s end the Great Pandemic once
    //       and for all!” Frank Fazor proclaims as he finishes his
    //       speech to the silent room.
    //     </p>
    //     <p>
    //       Slowly silence again turns to skepticism and outrage. “How could we?”
    //       The leaders ask. To subject the world to that level of surveillance
    //       would be an infringement of all the liberties their people hold dear.
    //       But with <strong>President Morgan</strong>’s endorsement, one by one the
    //       leaders come to believe that there is no better solution. Enforcement
    //       of a worldwide lockdown to finally end the pandemic will be impossible
    //       without such surveillance. And by a narrow margin, Project Providence
    //       is voted into existence.
    //     </p>
    //     {/* <p className="--center">[To be continued]</p> */}
    //   </div>
    // </div>
  );
};

export default Part2;
