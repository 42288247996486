import React from "react";
import "./LoreSection.css";

import NavBar from "components/NavBar/NavBar";
import { Lore } from "./Lore";
import Footer from "../Footer/Footer";

const LoreSection = (props) => {
  return (
    <div className="JS-Scroll">
      <div className="LoreSection">
        <NavBar
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          location={props.location}
        />

        <div style={{ marginTop: "9rem" }}>
          <Lore customStyle={{}} />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LoreSection;
