import React, { useState, useMemo } from 'react';
import { convert } from '../../utils/convertBody';
import dataBody from '../../utils/NewProbability.json'
import { BodyTable } from './BodyTable';
import { TexturesTable } from './TexturesTable';
import { ColorTable } from './ColorTable';
import DiagonalSection from '../DiagonalSection/DiagonalSection';

import StickyNav from '../StickyNav/StickyNav';
import Footer from '../Footer/Footer';
import FooterFill from '../FooterFill/FooterFill';

import "./style.css";
import "./tables.css";

export default (props) => {
  const data = convert();
  return (
    <div
      className="JS-Scroll"
      style={{
        /*overflowY: 'auto',
        height: '100vh',*/
      }}>
      <div className="RaritySection" style={{
        width: '100%',
        margin: 'auto',
      }}>
        <StickyNav
          onLoreClick={props.onLoreClick}
          onScrollClick={props.onScrollClick}
          changeRoute={props.changeRoute}
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          useSmooth={true}
        />
        <div style={{
          marginTop: "7.5rem",
          padding: '0 45px',
        }}>
          <h1 className="mb-2">
            Rarity Guide
          </h1>
          <p className="mb-2">
          The purpose of this Rarity Guide is to give you an overview of the rate at which each trait occurs across the Satoshi’s Legions - The Legionnaires collection. For more detailed analytics of the rarities of your Legionnaires check out our rarity rankings on
          <a href='https://raritysniper.com/satoshis-legions-the-legionnaires' target="_blank"> Rarity Sniper</a> and <a target="_blank" href='https://rarity.tools/legionnaires'>Rarity.Tools.</a></p>
        </div>
        <div style={{
        }}>
          <DiagonalSection
            i={0}
            direction={"left"}
            usePattern={false}
            topGradient={"#171818"}
            bottomGradient={"#171818"}
            Component={
              <BodyTable
                data={dataBody.Body}
                titleTable="Body Type"
              />}
            customStyle={{
              marginTop: "0",
              paddingTop: "0"
            }}
          />
          <DiagonalSection
            i={1}
            direction={"left"}
            usePattern={false}
            topGradient={"#171818"}
            bottomGradient={"#171818"}
            Component={<BodyTable
              data={dataBody.Mask}
              titleTable="Mask Type"
            />}
            customStyle={{ marginTop: "0" }}
          />
          <DiagonalSection
            i={2}
            direction={"right"}
            usePattern={false}
            topGradient={"#171818"}
            bottomGradient={"#171818"}
            Component={<BodyTable
              data={dataBody.Weapon}
              titleTable="Weapon Type"
            />}
            customStyle={{ marginTop: "0" }}
          />
          <DiagonalSection
            i={0}
            direction={"right"}
            usePattern={false}
            topGradient={"#171818"}
            bottomGradient={"#171818"}
            Component={<BodyTable
              data={dataBody.Headgear}
              titleTable="Headgear Type"
            />}
            customStyle={{ marginTop: "0" }}
          />
          <ColorTable
            data={data.TextureColors.colorsBody}
            titleTable="Primary colors"
            percentage={data.TextureColors.colorsBody[0].probability}
          />
          <ColorTable
            data={data.TextureColors.maskColors}
            titleTable="Mask colors"
            percentage={data.TextureColors.maskColors[0].probability}
          />
          <ColorTable
            data={data.TextureColors.eyeColors}
            titleTable="Eye colors"
            percentage={data.TextureColors.eyeColors[0].probability}
            type="eye"
          />
          <TexturesTable
            data={data.TextureColors.colorPrimary}
            titleTable="Textures"
          />

          <DiagonalSection
            i={3}
            direction={"right"}
            usePattern={false}
            topGradient={"#170336"}
            bottomGradient={"#170336"}
            Component={<Footer />}
            customStyle={{ paddingBottom: "2rem", minHeight: "auto" }}
          />
          <FooterFill />
        </div>
      </div>
    </div >
  );
};
