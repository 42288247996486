import React from "react";
import "./PlayAndEarn.css";

const PlayAndEarn = (props) => {
  return (
    <div className="PlayAndEarn">
      <div
        className="PlayAndEarnBG"
        style={
          props.width > 1650
            ? {
                backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/BG_Play_and_Earn_web.png')`,
              }
            : {}
        }
      >
        <div
          className="PlayAndEarnTitle"
          style={{
            backgroundImage:
              props.width >= 1050
                ? `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/Tittle_L_web.png')`
                : `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG_phone/tittle_L_phone.png')`,
          }}
        >
          <h1 className="-Title">Open Player Economy</h1>
        </div>

        {props.width <= 1050 ? (
          <div
            className="PlayAndEarnImgContainer-Phone"
            style={{
              backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/BG_Play_and_Earn_web.png')`,
            }}
          >
            <img
              className="PlayAndEarnImg-Phone"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/IMG_legionaries_web.png`}
            ></img>
          </div>
        ) : null}
        <div
          className="PlayAndEarnContent"
          style={{
            backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/Line_PE_web.png')`,
          }}
        >
          <div className="PlayAndEarnFlex">
            <div className="PlayAndEarnText">
              <p>
                For decades, RPG staples like Diablo, World of Warcraft and
                Runescape have built incredible gameplay experiences with highly
                intricate player economies. However, all of these player
                economies are closed ecosystems where players own little more
                than a license to display their items while playing the game.
                Legions 2029 uses blockchain technology to give players a new
                level of ownership over their items, featuring a player economy
                where users can freely transfer their in-game items and progress
                on third-party marketplaces.
              </p>
            </div>
            {props.width > 1050 ? (
              <img
                className="PlayAndEarnImg"
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/IMG_legionaries_web.png`}
              ></img>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayAndEarn;
