export const SETUP_APEMO = "SETUP_APEMO";
export const IS_MINTING = "IS_MINTING";
export const CHECK_WHITELIST_STATUS = "CHECK_WHITELIST_STATUS";
export const CLEAR_WHITELIST_STATUS = "CLEAR_WHITELIST_STATUS";
export const CHECK_WHITELIST_TOKEN_COUNTS = "CHECK_WHITELIST_TOKEN_COUNTS";
export const SET_PHASE_NUMBER = "SET_PHASE_NUMBER";

export const SHOW_INSTALL_METAMASK = "SHOW_INSTALL_METAMASK";
export const HIDE_INSTALL_METAMASK = "HIDE_INSTALL_METAMASK";

export const SHOW_CONNECT_METAMASK = "SHOW_CONNECT_METAMASK";
export const HIDE_CONNECT_METAMASK = "HIDE_CONNECT_METAMASK";

export const SHOW_CHANGE_NETWORK = "SHOW_CHANGE_NETWORK";
export const HIDE_CHANGE_NETWORK = "HIDE_CHANGE_NETWORK";

export const WALLET_LOADING = "WALLET_LOADING";
export const WALLET_CONNECT_SUCCESS = "WALLET_CONNECT_SUCCESS";
export const WALLET_CONNECT_FAIL = "WALLET_LOADING_FAIL";

export const RESET_STATE = "RESET_STATE";
export const SETUP_CONTRACT = "SETUP_CONTRACT";

export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const FETCH_TOKEN_DATA = "FETCH_TOKEN_DATA";
export const IS_FETCHING_DATA = "IS_FETCHING_DATA";

export const SET_CLAIM = "SET_CLAIM";
export const SET_PURCHASE = "SET_PURCHASE";
export const SET_PURCHASE_WHITELIST_ONLY = "SET_PURCHASE_WHITELIST_ONLY";

export const SET_STATUS_MSG = "SET_STATUS_MSG";
export const CLEAR_STATUS_MSG = "CLEAR_STATUS_MSG";
export const SET_SUCCESS_MSG = "SET_SUCCESS_MSG";
export const CLEAR_SUCCESS_MSG = "CLEAR_SUCCESS_MSG";

export const SET_IS_WALLET_IN_WHITELIST = "SET_IS_WALLET_IN_WHITELIST";
export const SET_TRANSACTION_DATA = "SET_TRANSACTION_DATA";
export const CLEAR_TRANSACTION_DATA = "CLEAR_TRANSACTION_DATA";

export const RESET_CONTRACT_DATA = "RESET_CONTRACT_DATA";
