import React from 'react'

export const Texture = ({
  array
}) => {
  const ind = array[0].indexOf("-")
  const title = array[0].substring(0, ind)
  return (
    <>
      <div style={{
        margin: '40px 30px',
      }}>
        <h2 className="">
          {title || array[0]}
        </h2>
        {
          array.length > 0 &&
            array.length === 1 ? (
            array.map((info, i) => (
              <img
                key={i}
                style={{
                  width: '100%',
                  height: '80px',
                  objectFit: "contain"
                }}
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/${info.replace(" ", "+")}.png` || `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/question-mark.png`}
                alt={info}
              />
            ))
          ) : (
            <div className="grid">
              {
                array.length > 1 && array.map((info, i) => (
                  <img
                    key={i}
                    style={{
                      width: '100%',
                      height: '80px',
                      objectFit: "contain"
                    }}
                    className="zoom"
                    src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/${info.replace(" ", "+")}.png` || `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesTable/question-mark.png`}
                    alt={info}
                  />
                ))
              }
            </div>
          )
        }
      </div>
    </>
  )
}
