import React from "react";
import "./The100Part.css";
import The100PartItem from "./The100PartItem/The100PartItem";

const The100Part = () => {
  return (
    <div className="PartThe100">
      <h1>The 100</h1>
      <div className="PartThe100Content">
        <p className="-p1">
          Through our 100 Program, we have partnered and collaborated with many of
          the pioneers of the blockchain gaming and art space, ranging from gaming
          guilds to artists and thought leaders. As the Satoshiverse continues to
          grow we plan to continue to work with these innovators to build the future
          of interactive gaming and entertainment.
        </p>
        <br />
        <p className="-p1 -highlight">
          Some members of the 100 include:
        </p>
      </div>
      <ul className="PartThe100List">
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/33.png`}
          label={"33"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/decentraland.png`}
          label={"decentraland"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/888.png`}
          label={"888"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/alex_solis.png`}
          label={"Alex Solis"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/bosslogic.png`}
          label={"Bosslogic"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/crypto_pop.png`}
          label={"Crypto pop"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/hackatao.png`}
          label={"hackatao"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/lady_pheonix.png`}
          label={"lady pheonix"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/makersplace.png`}
          label={"makersplace"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/manta.png`}
          label={"manta"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/nifty_gateway.png`}
          label={"nifty gateway"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/ready_player_d_a_d.png`}
          label={"ready player d.a.o."}
        />

        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/trevor_jones.png`}
          label={"trevor jones"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/val_kilmer.png`}
          label={"val kilmer"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/violet_jones.png`}
          label={"violet jones"}
        />
        <The100PartItem
          imgUrl={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/ygg.png`}
          label={"ygg"}
        />
      </ul>
    </div>
  );
};

export default The100Part;
