import React from 'react';

import "./PrizeBlock1.css";

const PrizeBlock1 = (props) => {
  return (
    <div className="ColumnSection">
      <div className="ColumnBlock">
        <h1>Satoshi’s Legions</h1>
        {/*<div className="column-block-full-video">
                    <video
                        autoPlay
                        playsInline
                        muted
                        loop
                        className=""
                    > 
                        <source src={mintAnnouncement} type='video/mp4' />
                    </video>          
    </div>*/}
        <p className="--paragraph">
          Satoshi’s Legions are next generation avatar NFTs for the
          Satoshiverse! In the Satoshiverse lore, after the Defenders Of Fiat
          take control of cities around the world, Satoshi The Creator returns
          as a half man, half machine superhero to assemble an army to take back
          control and restore the people’s basic freedoms. To do that, Satoshi
          contacts 100 leaders of the blockchain community from around the
          world, “The 100”, and asks each of them to secretly assemble “Legions”
          to lead rebellions against the Defenders Of Fiat. Each Legion is
          composed of “Legionnaires”, all of different ages, races, genders
          and backgrounds, who are unified in the fight to take back what was
          taken from them. The hallmark feature of each Legionnaire is the high
          tech mask that was given to each of them by Satoshi and allows the
          Legionnaires to maintain anonymity by combatting the advanced
          surveillance technology the Defenders Of Fiat have implemented to
          oppress the people. The masks also allow the Legionnaires to
          communicate with Satoshi and each other undetected and quickly learn
          valuable fighting skills they will need in the battle against their
          oppressors.
        </p>
        <p className="--paragraph">
          By becoming a Legionnaire, you too can join the fight! Your
          Legionnaire will be more than just another avatar in your collection.
          It will be your ticket to, and identity within, the Satoshiverse! We
          believe that the future of avatar NFTs starts with amazing art and
          community inclusion and ends with utility. We have created Satoshi’s
          Legions to exemplify those concepts and take avatar NFTs to a whole
          new level.
        </p>
        <p className="--paragraph" style={{ marginBottom: "3rem", paddingTop: "1rem" }}>
          <span className="--link-btn"
            onClick={(e) => {
              e.preventDefault();
              props.changeRoute("Launch", "/launch");
            }}
          >Purchase</span>
        </p>
        <h2 className="--header">Satoshi’s Legions - DNA</h2>
        <div className="--flex mb-4 mt-2">
          <div className="--left-1">
            <h3 className="--subheader">5555 Unique Legionnaires</h3>
          </div>
          <div className="--left-1 order-swap-1 align-center">
            <p>
              Each of the 5555 Legionnaires in the Satoshiverse is meant to be
              a truly one of a kind creation. Meticulously crafted using the
              latest in 3D and generative art techniques, the Legionnaires are
              ready for the metaverses of the future. From their unique masks to
              their custom body types and pattern variations, each Legionnaire
              is unique from the next. And because they are fully rigged and
              natively created in 3D, they will be ready for the Satoshiverse
              and other gaming worlds that will emerge.
              <br />
              <br />
              Each Legionnaire has traits that have been randomly generated
              using a custom 3D randomization script we developed. The traits,
              which vary in rarity, include: (1) Body Type; (2) Primary Body
              Color/Pattern; (3) Secondary Body Color; (4) Body Accent Color;
              (5) Headgear (occurs on ~ 25% of Legionnaires); (6) Mask; (7) Mask
              Color 1; (8) Mask Color 2; (9) Mask Color 3; (10) Eye Color; and
              (11) Weapon. Each Legionnaire is also assigned to 1 of 100 Legions
              representing cities around the world. Randomness is ensured on the
              smart contract using Chainlink VRF.
            </p>
            <br></br>
            <p>
              Check out the rarity rankings <a href="https://raritysniper.com/satoshis-legions-the-legionnaires" target="_blank" rel="noreferrer">here</a>
            </p>
          </div>
          <div className="--right-1-c pd-h-2 order-swap-2">
            <video autoPlay playsInline muted loop className="-border">
              <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/Trailer2_trimmed.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="--flex -reverse mb-4">
          <div className="--right-2 order-swap-1">
            <h3 className="--subheader">The Masks</h3>
            <p>
              One of the key features of the Legionnaires is anonymity. In the
              Satoshiverse, age, race, gender and background are set aside and
              the people express their uniqueness through their artistic choices
              while they stand united in the fight against the Defenders Of
              Fiat. This begins with their masks which vary in style, color, and
              eye color, with tens of thousands of potential combinations. Your
              mask is the beginning of your identity within the Satoshiverse and
              beyond.
            </p>
          </div>
          <div className="--left-2 pd-h-2 order-swap-2">
            <video autoPlay playsInline muted loop className="-border">
              <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/Masks_trimmed.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="--flex mb-3 align-center" style={{}}>
          <div className="--left-1 order-swap-1" style={{}}>
            <h3 className="--subheader">The Legionnaire Body Types</h3>
            <p>
              Another key feature of the Legionnaires are their body types. Each
              body type will vary in rarity and each will have a backstory
              within the Satoshiverse lore. There are 12 different body types
              and tens of thousands of possible combinations of colors and
              patterns for each. In addition to their unique bodies, a smaller
              population of Legionnaires will be generated with custom headgear
              that they will take into battle. There are endless possibilities
              for how your Legionnaires will turn out!
            </p>
          </div>
          <div className="--right-1 pd-h-2 order-swap-2">
            <video
              autoPlay
              playsInline
              muted
              loop
              className="-border"
              style={{ height: '100%' }}
            >
              <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/CryptoPriest_compatible.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="--flex -reverse mb-4 align-center">
          <div className="--right-2 order-swap-1">
            <div className="">
              <h3 className="--subheader">The Weapons</h3>
            </div>
            <p>
              Weapons! And lots of them! In order to make sure your Legionnaires
              are ready for battle against the Defenders Of Fiat, each is
              equipped with one of over 30 unique weapons.
              As Legionnaires progress through the game, they will have the
              ability to gain new weapons which will be even more powerful.
            </p>
          </div>
          <div
            className="--left-2 pd-h-2 order-swap-2"
            style={{ marginTop: '-2rem' }}
          >
            <video autoPlay playsInline muted loop className="-border">
              <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/ninja_compatible.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="--flex align-center mb-3" style={{}}>
          <div className="--left-1">
          </div>
          <div
            className="--left-1 order-swap-1"
            style={{ marginBottom: '0rem' }}
          >
            <h3 className="--subheader">The Legions</h3>
            <p>
              One feature that we believe makes each Legionnaire particularly
              special is that each will have a unique 3D animated background
              representing that Legionnaire’s Legion. The Legion will correspond
              to 1 of 100 cities all around the world where the Legionnaires are
              asked to battle against the Defenders Of Fiat to take back their
              freedom. The backgrounds are filled with easter eggs and details
              including flags, iconic monuments of the cities, and other
              cultural references. We hope that this will make the Legionnaires
              truly stand out as a project for everyone around the world.
            </p>
          </div>
          <div className="--right-1 pd-h-2 order-swap-2">
            <video
              autoPlay
              playsInline
              muted
              loop
              className="-border"
              style={{ height: '100%' }}
            >
              <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/hammer_compatible.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="--flex align-center -reverse mb-3">
          <div className="--right-2">
          </div>
          <div className="--right-2 order-swap-1">
            <div className="">
              <h3 className="--subheader">The Music</h3>
            </div>
            <p>
              We didn’t think it was enough to just have great visual art. In
              our opinion, no great avatar project would be complete without
              unique programmatically generated music for each avatar. So we
              generated over 5,555 unique soundtracks each slightly different
              from the next for your Legionnaires.
            </p>
          </div>
          <div
            className="--left-2 pd-h-2 order-swap-2"
            style={{ marginTop: '-2rem' }}
          >
            <video controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar" autoPlay muted loop className="-border">
              <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/legionnaireWSound.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>
        <div>
          <h1>Satoshi’s Legions - Utility</h1>
          <p className="--paragraph">
            When we started creating Satoshi’s Legions, we knew we didn’t just
            want to do something really special from an artistic perspective,
            but we wanted to make sure that each Legionnaire will provide its
            collector with utility for years to come within the world we are
            creating together. This means not only ensuring that the
            Legionnaires will be useful in the Satoshiverse, but also within the
            rapidly growing blockchain metaverse more generally.
            <br />
            <br />
            When you collect your first Legionnaire, you are doing more than
            just collecting a cool art NFT that you can use as your profile
            picture (although we encourage you to do so). You are beginning your
            journey into the Satoshiverse! Our goal with the Satoshiverse is
            nothing less than to build the next great superhero universe, on the
            blockchain and for the blockchain, and we have assembled a team of
            over 15 artists, programmers, marketing specialists and lawyers
            (yup, you need those too) in order to do it. By becoming a
            Legionnaire you will have access to and be part of the development
            of that universe which will feature:
          </p>
          <ul className="List">
            <li className="List-item mt-1">
              <span>
                A 3D Play-To-Earn Game - The Satoshiverse.
              </span>
              <ul className="List">
                <li className="List-item mt-1">
                  <span>
                    Legionnaires will have early access to the Satoshiverse P2E game and will be able to use their Legionnaire within the game.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="List">
            <li className="List-item mt-1">
              <span>
                Comic collectibles created in collaboration with some of the top comic artists in the world as well as you, the community.              </span>
              <ul className="List">
                <li className="List-item mt-1">
                  <span>
                    Legionnaires will receive special airdrops of these comic collectibles.                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="List">
            <li className="List-item mt-1">
              <span>
                Partnerships and integrations with existing blockchain metaverses.
              </span>
              <ul className="List">
                <li className="List-item mt-1">
                  <span>
                    In the future, Legionnaires will be able to obtain a fully rigged 3D version of their Legionnaire to be used in other metaverses as they continue to emerge.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="List">
            <li className="List-item mt-1">
              <span>
                Exclusive contests and giveaways.

              </span>
            </li>
          </ul>
          <ul className="List">
            <li className="List-item mt-1">
              <span>
                Much more to be announced...
              </span>
            </li>
          </ul>
        </div>
        {/*<div className="--flex -reverse">
          <div className="--right-2">
            <h3 className="--subheader">Community Inclusion</h3>
          </div>
          <div className="--right-2 order-swap-1">
            <p>
              In the Satoshiverse, people of all ages, races, genders, and
              backgrounds unite to fight against the oppressive Defenders Of
              Fiat. While anonymity is a key feature of all of Satoshi’s
              Legionnaires, the Legionnaires hail from all parts of the Earth
              and are of all ages, races, genders, and backgrounds. And although
              they are completely covered from head to toe to conceal their
              identities, they use their clothing, masks, and signature
              animations to express themselves. Our biggest goal is to develop
              an incredible community of people who truly care about the
              advancement of blockchain technology, the NFT space, and amazing
              artistic expression within it.
            </p>
          </div>
          <div className="--left-2 pd-h-2 order-swap-2">
            <video autoPlay playsInline muted loop className="-border">
              <source src={Hammer} type="video/mp4" />
            </video>
          </div>
        </div>*/}

        {/* <div className="column-block-images">
                    <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/Legionnaire00028.png" />
                    <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/Legionnaire00025.png" />
                    <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/Legionnaire00026.png" />
                </div> */}

        {/*<div className="column-block-videos">
          <video autoPlay playsInline muted loop className="">
            <source src={cyborg} type="video/mp4" />
          </video>
          <video autoPlay playsInline muted loop className="">
            <source src={ninja} type="video/mp4" />
          </video>
            </div>*/}

        {/*<ul className="List">
          <li className="List-item mt-2">
            <span>
              <strong>The Art:</strong> We believe having great art is just the
              first step. Satoshi’s Legions will push the artistic limits of
              what is possible with programmatically generated 3D avatars on the
              blockchain. This begins with meticulously crafting each unique 3D
              trait of the Legionnaires from their masks, to their outfits, to
              their weapons to create avatars that will truly be unique from one
              another. Then, to ensure that each Legionnaire NFT is a complete
              work of art, we designed a system to programmatically generate
              custom animated 3D backgrounds using elements we created to embody
              the signature traits and cities of each Legion. Finally,
              Legionnaires are given one of several signature animations to make
              them further stand out. The end result is a one-of-a-kind 3D art
              NFT that will also function as your avatar across the Satoshiverse
              and within the metaverse more broadly.
            </span>
          </li>
          <li className="List-item mt-2">
            <span>
              <strong>Utility:</strong> We believe that the future of avatars in
              the NFT space will be the utility they provide within the rapidly
              growing blockchain metaverse. When you collect your first
              Satoshi’s Legionnaire, you are doing more than just collecting a
              cool art NFT that you can use as your profile picture (although we
              encourage you to do so). You are beginning your journey into the
              Satoshiverse! Our goal with the Satoshiverse is nothing less than
              to build the next great superhero universe, on the blockchain, for
              the blockchain, and we have assembled a team of over 15 artists,
              programmers, marketing specialists and lawyers (yup you need those
              too) in order to do it. By becoming a Legionnaire you will have
              access to and be part of the development of that universe which
              will feature:
            </span>
            <ul className="SubList">
              <li className="SubList-item mt-1">
                <span>
                  <strong>A 3D Play-To-Earn Game - The Satoshiverse.</strong>
                </span>
                <ul className="InnerList">
                  <li className="InnerList-item mt-1">
                    Legionnaires will have early access to the Satoshiverse P2E
                    game and will be able to use their Legionnaire within the
                    game.
                  </li>
                  <li className="InnerList-item mt-1">
                    Owning multiple Legionnaires will grant power ups for your
                    Legionnaire to give you an advantage while playing and
                    earning.
                  </li>
                </ul>
              </li>
              <li className="SubList-item mt-1">
                <span>
                  <strong>
                    Comic collectibles created in collaboration with some of the
                    top comic artists in the world as well as you, the
                    community.
                  </strong>
                </span>
                <ul className="InnerList">
                  <li className="InnerList-item mt-1">
                    Legionnaires will receive special airdrops of these comic
                    collectibles.
                  </li>
                </ul>
              </li>
              <li className="SubList-item mt-1">
                <span>
                  <strong>
                    An NFT comic series telling the story of Satoshi The Creator
                    and the Satoshiverse.
                  </strong>
                </span>
                <ul className="InnerList">
                  <li className="InnerList-item mt-1">
                    Legionnaires will be able to access and read the Genesis
                    Edition of the comic series for free
                  </li>
                </ul>
              </li>
              <li className="SubList-item mt-1">
                <span>
                  <strong>
                    Exclusive contests and giveaways with prizes worth thousands
                    of dollars.
                  </strong>
                </span>
                <ul className="InnerList">
                  <li className="InnerList-item mt-1">
                    Many of these contests will be exclusively reserved for
                    Legionnaires.
                  </li>
                </ul>
              </li>
              <li className="SubList-item mt-1">
                <span>
                  <strong>
                    Partnerships and integrations with existing blockchain
                    metaverses.
                  </strong>
                </span>
                <ul className="InnerList">
                  <li className="InnerList-item mt-1">
                    In the future, Legionnaires will be able to request a fully
                    rigged 3D version of their Legionnaire to be used in other
                    metaverses as they continue to emerge
                  </li>
                </ul>
              </li>
              <li className="SubList-item mt-1">
                <span>
                  <strong>And much much more…</strong>
                </span>
              </li>
            </ul>
          </li>
          <li className="List-item mt-2">
            <span>
              <strong>Community Inclusion:</strong> In the Satoshiverse, people
              of all ages, races, genders, and backgrounds unite to fight
              against the oppressive Defenders Of Fiat. While anonymity is a key
              feature of all of Satoshi’s Legionnaires, the Legionnaires hail
              from all parts of the Earth and are of all ages, races, genders,
              and backgrounds. And although they are completely covered from
              head to toe to mask their identities, they use their clothing,
              masks, and signature animations to express themselves. Our biggest
              goal is to develop an incredible community of people who truly
              care about the advancement of blockchain technology, the NFT
              space, and amazing artistic expression within it. As a member of
              the Satoshiverse community, not only will you get access to all
              the great features we are hard at work building, but you will also
              get to influence how Satoshi and his followers’ story progresses.
            </span>
          </li>
              </ul>*/}
        {/*<div className="column-block-images">
          <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/Mask07.png" />
          <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/Mask06.png" />
          <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/Mask09.png" />
            </div>*/}
      </div>
    </div >
  );
};

export default PrizeBlock1;
