import React from "react";
import "./TheHideoutIntroPart.css";

import { Link } from "react-router-dom";
import TheHideoutBanner from "components/HomeSection2/TheHideoutPart/TheHideoutBanner/TheHideoutBanner";

const TheHideoutIntroPart = () => {
  return (
    <div className="PartTheHideoutIntro">
      <TheHideoutBanner />
      <div className="PartTheHideoutIntroContent">
        <img
          className="HideoutTitle"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/The Hideout Logo.png`}
        />
        <p className="-p2">
          The Hideout is a weekly panel-style X Spaces featuring discussions on
          Web3, gaming, NFTs, the blockchain, and the metaverse.
        </p>
        <iframe
          style={{
            marginTop: "4rem",
            marginBottom: "3rem",
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/videoseries?si=jHWUsQcmC4PHnMbc&amp;list=PLjZhEBj_yXw9e80HnhrpmGBzV1R_Hjjg6"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default TheHideoutIntroPart;
