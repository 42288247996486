const cities = [
  "Afghanistan - Kabul",
  "Angola - Luanda",
  // "Argentina - Buenos Aires",
  // "Australia - Melbourne",
  "Australia - Sydney",
  "Austria - Vienna",
  "Bangladesh - Dhaka",
  "Belgium - Brussels",
  // "Brazil - Sao Paulo",
  // "Brazil - Rio de Janeiro",
  "Cameroon - Yaounde",
  //"Canada - Toronto",
  "Canada - Montreal",
  "Cayman Islands - George Town",
  "Chile - Santiago",
  "China - Shanghai",
  "China - Beijing",
  "China - Chongqing",
  "China - Tianjin",
  // "China - Guangzhou",
  // "Colombia - Bogota",
  // "Cuba - Havanna",
  "Czech Republic - Pague",
  "Dominican Republic - Santo Domingo",
  "DR Congo - Kinshasa",
  "Egypt - Cairo",
  "El Salvador - Salvador",
  "Emirates - Dubai",
  // "Ethiopia - Addis Ababa",
  // "France - Paris",
  "Germany - Berlin",
  "Ghana - Kumasi",
  "Greece - Athens",
  "Hong Kong - Hong Kong",
  "Hungary - Budapest",
  "India - Delhi",
  "India - Mumbai",
  "India - Kolkata",
  "India - Bangalore",
  "Indonesia - Jakarta",
  "Indonesia - Bekasi",
  "Iran - Tehran",
  "Iraq - Baghdad",
  "Israel - Tel Aviv",
  "Italy - Rome",
  // "Italy - Milan",
  "Ivory Coast - Abidjan",
  "Japan - Tokyo",
  // "Japan - Osaka",
  // "Japan - Nagoya",
  "Kenya - Nairobi",
  "Kuwait - Kuwait City",
  "Madagascar - Antananarivo",
  // "Malaysia - Kuala Lumpur",
  // "Mexico - Mexico City",
  // "Mexico - Guadalajara",
  "Morocco - Casablanca",
  "Myanmar - Yangon",
  //"Netherlands - Amsterdam",
  "Nigeria - Lagos",
  "Nigeria - Kano",
  "Pakistan - Karachi",
  "Pakistan - Lahore",
  "Paraguay - Asuncion",
  // "Peru - Lima",
  //"Philippines - Manila",
  // "Poland - Warsaw",
  "Portugal - Lisbon",
  "Russia - Moscow",
  // "Russia - Saint Petersburg",
  "Saudi Arabia - Riyadh",
  "Saudi Arabia - Jiddah",
  //"Scotland - Edinburgh",
  "Senegal - Dakar",
  "Singapore - Singapore",
  "South Africa - Johannesburg",
  // "South Africa - Cape Town",
  "South Korea - Seoul",
  // "South Korea - Busan",
  "Spain - Madrid",
  "Spain - Barcelona",
  "Sudan - Khartoum",
  "Sweden - Stockolm",
  // "Switzerland - Geneva",
  "Taiwan - Xinbei",
  "Tanzania - Dar es Salaam",
  "Thailand - Bangkok",
  "Turkey - Istanbul",
  "Turkey - Ankara",
  "Uganda - Kampala",
  // "Ukraine - Kyiv",
  // "United Kingdom - London",
  //"United States - New York City",
  // "United States - Los Angeles",
  //"United States - San Francisco",
  // "United States - Washington DC",
  //"United States - Miami",
  "Venezuela - Caracas",
  // "Vietnam - Ho Chi Minh City",
  "Vietnam - Hanoi",
];

export default cities;
