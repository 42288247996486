import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

import "./CityDropdown.css";

class CityDropdown extends Component {
  state = {
    isOpen: false,
    wasOpenedAtLeastOnce: false,
  };

  handleClickOutside = (e) => {
    this.setState({ isOpen: false });

    if (this.state.wasOpenedAtLeastOnce) {
      if (this.props.cityExists(this.props.city)) {
        this.props.setCityErrorMsg("");
      } else {
        this.props.setCityErrorMsg("Select a valid country/city.");
      }
    }
  };

  closeDropdown = () => {
    this.setState({ isOpen: false });
    this.props.setCityErrorMsg("");
  };

  toggleDropdown = () => {
    if (!this.state.wasOpenedAtLeastOnce)
      this.setState({ wasOpenedAtLeastOnce: true });

    this.setState({ isOpen: !this.state.isOpen });
    this.props.setCityErrorMsg("");
  };

  render() {
    return (
      <div className="-Dropdown">
        <div className="-InputContainer">
          <input
            className="-Text"
            type="text"
            name="city"
            style={{
              marginBottom: "0rem",
              color: this.props.cityErrorMsg ? "red" : "black",
            }}
            value={this.props.city}
            onChange={async (e) => {
              await this.props.onChange(e);

              if (!this.state.isOpen) this.setState({ isOpen: true });
            }}
            onClick={this.toggleDropdown}
          ></input>
          <img
            className="dropdown-arrow-icon"
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/arrow-icon.png`}
            style={{
              transform: this.state.isOpen
                ? "translateY(-50%) rotateZ(180deg)"
                : "translateY(-50%) rotateZ(0deg)",
            }}
          ></img>
        </div>
        <ul
          className="-Dropdown-List"
          style={{ display: this.state.isOpen ? "block" : "none" }}
        >
          {this.props.cities.length > 0 ? (
            this.props.cities.map((city, i) => (
              <li
                key={i}
                onClick={(e) => {
                  this.props.onClickCity(city);
                  this.closeDropdown();
                }}
                style={
                  this.props.city === city
                    ? { background: "#170336", color: "white" }
                    : {}
                }
              >
                {city}
              </li>
            ))
          ) : (
            <li>No cities found</li>
          )}
        </ul>
        {this.props.cityErrorMsg ? (
          <span className="form-error-msg">{this.props.cityErrorMsg}</span>
        ) : null}
      </div>
    );
  }
}

export default onClickOutside(CityDropdown);
