import React from 'react';
import { getSameNames } from '../../utils/convertBody';
import { Texture } from './Texture';

export const TexturesTable = ({ data, titleTable }) => {
  const array = getSameNames(data);
  console.log(array)
  return (
    <>
      <h2 className="mb-3">
        {titleTable}
      </h2>
      <p className="mb-2" style={{
        fontSize: '20px',
        textAlign: 'center',
      }}>
        Each Texture has the same probability of occurring (.9%) except Gold which is rarer, but the actual distribution may vary slightly.
      </p>
      <div className="Textures">
        {
          array.length > 0 && array.map((info, i) => (
            <Texture array={info} key={i}/>
          )
          )
        }
      </div>
    </>
  )
}
