import React, { Component } from "react";
import styled from 'styled-components';
import "./StickyNav.css";

import { Link } from "react-router-dom";

import LoreDropdown from "./LoreDropdown/LoreDropdown";
import LoreNavItem from "./LoreNavItem/LoreNavItem";
import { isMobile } from 'react-device-detect';

const logo = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/logoNavBar.png`;

class StickyNav extends Component {
  
  state = {
    isDropdownOpen: false,
    isLoreDropdownOpen: false,
  };

  componentDidUpdate() {
    if (this.props.isBurgerOpen !== this.state.isDropdownOpen) {
      this.setState({
        isDropdownOpen: this.props.isBurgerOpen,
      });
    }
  }

  onClick = (e, i) => {
    e.preventDefault();
    this.props.onScrollClick(i, this.props.useSmooth);
  };

  toggleLoreDropdown = (e) => {
    this.setState({
      isLoreDropdownOpen: !this.state.isLoreDropdownOpen,
    });
  };

  openLoreDropdown = (e) => {
    this.setState({
      isLoreDropdownOpen: true,
    });
  };

  closeLoreDropdown = (e) => {
    this.setState({
      isLoreDropdownOpen: false,
    });
  };

  render() {
    return (
      <div className="StickyNav">
        <div className="--inner">
          <div className="--relative">
            <div className="burger" onClick={this.props.toggleBurger}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            {
              !isMobile && (
                <Logo onClick={(e) => {
                  e.preventDefault();
                  this.props.changeRoute("Home", "/");
                }}
                  src={logo} alt="logoNavbar"
                />
              )
            }
            <ul
              className="--navlist"
              style={
                this.props.width <= 1050
                  ? this.state.isDropdownOpen
                    ? { display: "flex" }
                    : { display: "none" }
                  : { display: "flex" }
              }
            >
              {
                isMobile && (
                  <li
                    className="--navitem"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.changeRoute("Home", "/");
                    }}
                  >
                    <span>Home</span>
                  </li>
                )
              }
              <li
                className="--navitem"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeRoute("Team", "/team");
                }}
              >
                <span>The Team</span>
              </li>
              <li
                className="--navitem"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeRoute("Legions", "/game");
                }}
              >
                <span>Game</span>
              </li>
              <li
                className="--navitem"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeRoute("Apemo", "/apemo-army");
                }}
              >
                <span>Apemo Army</span>
              </li>
              <li
                className="--navitem"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeRoute("Launch", "/launch");
                }}
              >
                <span>Purchase</span>
              </li>
              <li
                className="--navitem"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeRoute("Rarity", "/rarity");
                }}
              >
                <span>Rarity</span>
              </li>
              <li
                className="--navitem"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeRoute("Lore", "/lore");
                }}
              >
                <span>Lore</span>
              </li>
              {/* <LoreNavItem
                isLoreDropdownOpen={this.state.isLoreDropdownOpen}
                toggleLoreDropdown={this.toggleLoreDropdown}
                openLoreDropdown={this.openLoreDropdown}
                closeLoreDropdown={this.closeLoreDropdown}
                onLoreClick={this.props.onLoreClick}
                width={this.props.width}
                closeBurger={this.props.closeBurger}
                changeRoute={this.props.changeRoute}
              /> */}
            </ul>
            <div
              className="fadescreen"
              onClick={this.props.toggleBurger}
              style={
                this.props.width <= 1050
                  ? this.state.isDropdownOpen
                    ? { display: "block" }
                    : { display: "none" }
                  : { display: "none" }
              }
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default StickyNav;

const Logo = styled.img`
  z-index:10;
  position: relative;
  height: 100%;
  margin-left: 40px;
  cursor: pointer;
  padding: 5px 0;
`;