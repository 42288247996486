import React from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { isMobile } from "react-device-detect";

gsap.registerPlugin(ScrollTrigger);

export const Legions = () => {
  return (
    <Main className="JS-Scroll">
      <VideoContainer>
        <Video autoPlay playsInline muted loop>
          <source
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/lore.mp4`}
            type="video/mp4"
          />
        </Video>
      </VideoContainer>
      <Container>
        <ImageTitle
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/LogoVertical.png`}
          alt="Logo"
        />
        <ScheduleAlpha>Alpha Phase 4 Now Live</ScheduleAlpha>
        <TextContainer>
          <p>
            The year is 2030. The Defenders of Fiat remain firmly in control of
            the world’s major cities, leaving those who do not follow them to
            fend for themselves in the Outskirts. For years the people have
            suffered this new reality. But a new flower of hope has begun to
            blossom. Whispers in hideouts and alleyways tell the tale of
            Satoshi’s plot to help the people take back their freedom. His
            followers quietly ready for a rebellion, awaiting Satoshi’s
            direction. But as the whispers spread, the DOF become increasingly
            on edge, attempting to quash any sign of rebellion through violent
            force. The people are outnumbered and outgunned by the DOF’s
            advanced weapons and surveillance technology. But a new
            technological breakthrough from Satoshi may give the people the
            chance they need to level the playing field. Soon, heroes from
            around the world will battle against the DOF to save the Outskirts
            and bring the people one step closer to the liberty they so
            desperately seek.
          </p>
        </TextContainer>
        <VideoGameplay>
          {/* <img className='topLine' src={}/>
            <img className='bopLine' src={}/> */}
          <video autoPlay playsInline muted loop>
            <source
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/gameplay.mp4`}
              type="video/mp4"
            />
          </video>
          <GameplayTitleContainer>
            <GameplayTitle>Gameplay</GameplayTitle>
          </GameplayTitleContainer>
        </VideoGameplay>
        <TextContainer>
          <p className="gameplay">
            “Satoshi’s Legions: The Battle For The Outskirts” will be a fast
            paced third person Roguelike RPG where players will battle enemies
            with their Avatars, earning experience, in-game boosts, NFT weapons,
            NFT materials, and other NFT items along the way. Players will need
            to hone their skills, mastering different fighting styles as they
            level up their Avatars to progress further and further into the
            game. The power of Unreal Engine 5 will unlock a new level of
            graphics and combat that will give players a chance to experience
            Web 3.0 gaming like never before.{" "}
          </p>
        </TextContainer>
        <PlayAndEarnContainerImage>
          <img
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLegions/legionarios.png`}
          />
          <TitlePlayEarning>PLAY AND EARN</TitlePlayEarning>
        </PlayAndEarnContainerImage>
        <TextContainer>
          <p className="playAndEarn">
            We believe that creating a successful play-and-earn ecosystem begins
            with creating a great game. In fact, for decades, hits like Diablo,
            World of Warcraft and Runescape have exemplified both great gameplay
            and play-and-earn mechanics, albeit in a centralized way. Satoshi’s
            Legions will take the best of these play-and-earn ecosystems and use
            Web 3.0 technology to unlock their potential by decentralizing key
            aspects. Imagine a game where the hours of hard work you put into
            training your Avatar are tracked in the Avatar’s NFT metadata,
            freely transferable on the blockchain. Imagine true ownership of
            your in-game items through NFTs. Imagine a player economy that makes
            holders the key to unlocking the most important features of the
            game, giving holders the lion’s share of the value as the game
            grows. We believe these will be hallmarks of sustainable
            play-and-earn games and intend for Satoshi’s Legions to be an
            exemplar of all that can be accomplished through the incorporation
            of Web 3.0 into a great game.
          </p>
        </TextContainer>
        <TitleActiveEarning>ACTIVE EARNING</TitleActiveEarning>
        <ActiveEarningText>
          There will be two primary ways that a player can <b>actively</b> earn
          by playing Satoshi’s Legions: (1) Avatar progression and (2) finding
          NFT items.
        </ActiveEarningText>
        <ActiveEarningContainer>
          <ActiveSection>
            <ActiveContainer>
              <TitleActive>AVATAR PROGRESSION</TitleActive>
              <video autoPlay playsInline muted loop>
                <source
                  src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/ReelSatoshisLegions.mp4`}
                  type="video/mp4"
                />
              </video>{" "}
              <DescriptionActive>
                One of the main drawbacks of traditional RPGs is that the many
                hours of work that players put into developing their characters
                are non-transferable. Satoshi’s Legions will change this. When
                you play Satoshi’s Legions, the experience points (Data Points)
                you earn by defeating enemies will be tracked in your Avatar’s
                metadata. The more you play, the stronger your Avatar will
                become and you will be able to transfer this progress, through
                your Avatar, on the blockchain.
              </DescriptionActive>
            </ActiveContainer>
            <ActiveContainer>
              <TitleActive className="nft">NFT Items</TitleActive>
              <video autoPlay playsInline muted loop>
                <source
                  src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/NFT_Items.mp4`}
                  type="video/mp4"
                />
              </video>
              <DescriptionActive>
                What would a play-and-earn RPG be without NFT items? In
                Satoshi’s Legions, the best items that you find, fabricate, and
                upgrade will be NFTs. From legendary weapons to computing parts,
                these items will make your avatar more powerful, increasing your
                chances of defeating the DOF and finding even rarer loot. And
                because they are NFTs, the choice of what you do with these rare
                items you own will be yours. Will you use them to defeat the
                DOF? Or will you sell them to another hero seeking to do the
                same?{" "}
              </DescriptionActive>
            </ActiveContainer>
          </ActiveSection>
          <ActiveSection>
            {/* <ActiveImage src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLegions/weapons.png`} /> */}
          </ActiveSection>
          <TitleActiveEarning>PASSIVE EARNING</TitleActiveEarning>
          <ActiveContainerCrystals>
            <ActiveTextContainer>
              <TitleActiveCrystals>Satoshi’s Crystals</TitleActiveCrystals>
              <DescriptionActiveCrystals>
                Satoshi’s Legions will be designed to make holders a key player
                in the game economy. Avatar holders will be able to use their
                Legionnaire and Apemo Army avatars to passively earn “Satoshi’s
                Crystals”. Satoshi’s Crystals will be ERC-1155 NFTs that will be
                key resources within the game, unlocking all sorts of utility,
                including the ability to fabricate the rarest and most powerful
                items in the game. Holding a Satoshiverse Avatar will be the
                easiest way to accumulate these crystals.
                <br></br>
                <br></br>
                The rate at which you earn Satoshi’s Crystals will depend on
                which avatar NFTs you hold, with the following multipliers:
              </DescriptionActiveCrystals>
            </ActiveTextContainer>
            <video autoPlay playsInline muted loop>
              <source
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/Crystal_Holo.mp4`}
                type="video/mp4"
              />
            </video>
          </ActiveContainerCrystals>
          {/* <ActiveImage src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLegions/dark_gem.png`} /> */}
        </ActiveEarningContainer>
        {isMobile ? (
          <>
            <Box>
              <h4>“Special” Avatar Collections Including Apemo Army</h4>
              <Number>
                1X
                <p>Multiplier</p>
              </Number>
            </Box>
            <Box>
              <h4>
                Legionnaires: Rebels, Outlaws, Bandits, Renegades, & Drifters
              </h4>
              <Number>
                2X
                <p>Multiplier</p>
              </Number>
            </Box>
            <Box>
              <h4>Legionnaires: Assassins, Hackers, Commanders, & Knights</h4>
              <Number>
                3X
                <p>Multiplier</p>
              </Number>
            </Box>
            <Box>
              <h4>Legionnaires: Cyborgs, Ninjas, & Prophets</h4>
              <Number>
                4X
                <p>Multiplier</p>
              </Number>
            </Box>
            <Box>
              <h4>Gold Legionnaires</h4>
              <Number>
                10X
                <p>Multiplier</p>
              </Number>
            </Box>
          </>
        ) : (
          <>
            <BotContainer>
              <Box>
                <h4>“Special” Avatar Collections Including Apemo Army</h4>
                <Number>
                  1X
                  <p>Multiplier</p>
                </Number>
              </Box>
              <Box>
                <h4>
                  Legionnaires: Rebels, Outlaws, Bandits, Renegades, & Drifters
                </h4>
                <Number>
                  2X
                  <p>Multiplier</p>
                </Number>
              </Box>
              <Box>
                <h4>Legionnaires: Assassins, Hackers, Commanders, & Knights</h4>
                <Number>
                  3X
                  <p>Multiplier</p>
                </Number>
              </Box>
            </BotContainer>
            <BotContainer>
              <Box>
                <h4>Legionnaires: Cyborgs, Ninjas, & Prophets</h4>
                <Number>
                  4X
                  <p>Multiplier</p>
                </Number>
              </Box>
              <Box>
                <h4>Gold Legionnaires</h4>
                <Number>
                  10X
                  <p>Multiplier</p>
                </Number>
              </Box>
            </BotContainer>
          </>
        )}
        <BotText>
          More details on Satoshi’s Crystals and other benefits for holders will
          be announced at a later date.
        </BotText>
      </Container>
    </Main>
  );
};

const ImageTitle = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const ActiveTextContainer = styled.div`
  height: fit-content;
  width: 55%;
  z-index: 1;
  background-repeat: no-repeat;
  padding: 0px 30px;
  border-left: 2px solid #b9f601;
  border-bottom: 2px solid #b9f601;
  border-right: 2px solid #b9f601;
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    background: none;
  }
`;
const VideoGameplay = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  width: 100%;
  padding: 5% 0;
  border-radius: 5px;

  video {
    width: 100%;
    object-fit: cover;
    border-radius: 75px;
    box-shadow: 0 0 0.5rem #252525, 0 0 0.6rem #252525, 0 0 0.1rem #b9f601,
      0 0 0.1rem #b9f601, 0 0 1rem #b9f601, inset 0 0 0rem #b9f601;
    position: relative;
    z-index: 0;
    @media (max-width: 768px) {
      object-fit: contain;
    }
  }
  @media (max-width: 768px) {
    height: auto;
    background-image: none;
    padding: 0;
  }
  .topLine {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;
const PlayAndEarnContainerImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const GameplayTitleContainer = styled.div`
  position: absolute;
  margin-bottom: 5%;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 768px) {
    position: relative;
  }
`;

const GameplayTitle = styled.div`
  @media (max-width: 1366px) {
    &&& {
      font-size: 60px;
    }
  }
  @media (max-width: 768px) {
    font-size: 50px;
    margin-top: 30px;
  }
  font-size: 100px;
  padding: 0 20px;
  text-transform: uppercase;
  font-family: "revolution";
  color: #b9f601;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  text-shadow: 1px 6px rgba(185, 246, 1, 0.4), 0 0 7px #252525, 0 0 10px #252525,
    0 0 5px #252525, 0 0 5px #b9f601, 0 0 5px #b9f601, 0 0 5px #b9f601,
    0 0 5px #b9f601, 0 0 11px #b9f601;
`;

const TextContainer = styled.div`
  width: 100%;
  p {
    font-size: 26px;
    font-family: dinNext;
    color: white;
    letter-spacing: 2.4px;
    text-align: justify;
    font-weight: 100;
    word-spacing: 4px;
    line-height: 32px;
  }
  .playAndEarn {
    margin-top: 5%;
    text-align: center;
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  .gameplay {
    text-align: center;
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    p {
      font-size: 16px;
      letter-spacing: 2px;
      word-spacing: 3px;
      line-height: 26px;
    }
  }
`;
const VideoContainer = styled.div`
  position: relative;
`;

const Video = styled.video`
  width: 100%;
  position: relative;
`;

const BotText = styled.div`
  margin: 50px 0;
  text-align: center;
  color: #b9f601;
  font-size: 20px;
  text-transform: uppercase;
  font-family: dinNext;
  font-weight: bold;
  padding: 0 30%;
  @media (max-width: 768px) {
    padding: 0 5%;
  }
`;

const ScheduleAlpha = styled.div`
  width: 100%;
  color: #b9f601;
  font-family: dinNext;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 2px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px;
  }
`;
const ActiveContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  .nft {
    text-align: center;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      text-align: center;
    }
    video {
      margin-top: 20px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    margin: 0;
  }
`;

const ActiveContainerCrystals = styled.div`
  align-items: center;
  min-height: 500px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  video {
    height: 100%;
    width: 60%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    right: 0;
    @media (max-width: 768px) {
      width: 100%;
      position: relative;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const TitleActive = styled.div`
  height: 150px;
  letter-spacing: 2.5px;
  font-family: dinNext;
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  color: #252525;
  font-weight: bold;
  -webkit-text-stroke: 2px #b9f601;
  @media (max-width: 768px) {
    font-size: 38px;
    text-align: center;
    height: auto;
  }
  @media (max-width: 1366px) {
    height: 200px;
  }
`;

const TitleActiveCrystals = styled.div`
  letter-spacing: 2.5px;
  font-family: dinNext;
  text-align: left;
  font-size: 50px;
  text-transform: uppercase;
  color: #252525;
  font-weight: bold;
  -webkit-text-stroke: 2px #b9f601;
  @media (max-width: 768px) {
    font-size: 38px;
    text-align: center;
  }
`;

const DescriptionActive = styled.div`
  margin-top: 20px;
  text-align: justify;
  color: white;
  font-family: dinNext;
  text-align: justify;
  font-size: 24px;
  letter-spacing: 2px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const DescriptionActiveCrystals = styled.div`
  margin-top: 18px;
  text-align: justify;
  color: white;
  font-family: dinNext;
  text-align: justify;
  font-size: 20px;
  letter-spacing: 2px;
  padding-left: 20px;
  @media (max-width: 768px) {
    padding-left: 0;
    font-size: 16px;
  }
`;
const Number = styled.div`
  height: 100%;
  font-size: 80px;
  font-family: dinNext;
  text-align: center;
  border-top: #b9f601 4px solid;
  color: #b9f601;
  width: 100%;
  font-weight: bold;
  text-shadow: -2px 3px #00cc00, -3px 5px #000;
  p {
    font-size: 30px;
    color: #b9f601;
    text-align: center;
    text-transform: uppercase;
    font-weight: 200;
    text-shadow: none;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 50px;
    p {
      font-size: 20px;
    }
  }
`;

const Main = styled.div`
  margin-top: 60px;
  &&& {
    background: none;
    background-color: #202020;
  }
`;
const TitlePlayEarning = styled.h4`
  @media (max-width: 1366px) {
    &&& {
      font-size: 100px;
    }
  }
  font-family: "revolution";
  font-size: 140px;
  color: #b9f601;
  text-align: center;
  text-shadow: 1px 6px rgba(185, 246, 1, 0.4), 0 0 7px #252525, 0 0 10px #252525,
    0 0 5px #252525, 0 0 5px #b9f601, 0 0 5px #b9f601, 0 0 5px #b9f601,
    0 0 5px #b9f601, 0 0 11px #b9f601;
  @media (max-width: 768px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
  z-index: 1;
  margin-top: -140px;
  @media (max-width: 768px) {
    margin-top: -100px;
  }
`;
const TitleActiveEarning = styled.h4`
  font-family: "blackOps";
  font-size: 60px;
  margin-bottom: 5%;
  color: #b9f601;
  text-align: center;
  margin-top: 60px;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`;
const Container = styled.div`
  padding: 0 10%;
  position: relative;
  background-image: url(${process.env
    .REACT_APP_AWS_MEDIA_LINK}/imagesApemo/background_square_1.png);
  background-repeat: repeat;
`;
const SatoshisTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6%;
  flex-direction: column;
  margin-bottom: 5px;
`;

const SatoshiTitleTop = styled.h1`
  width: 90%;
  &&& {
    font-size: 11vw;
  }
  color: white;
  font-family: "revolution";
  margin-top: 30px;
  text-align: justify;
  @media (max-width: 1366px) {
    &&& {
      font-size: 120px;
    }
  }
  @media (max-width: 768px) {
    &&& {
      font-size: 40px;
    }
  }
`;
const SatoshiTitleBot = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const SatoshiTitleBotLegions = styled.h1`
  &&& {
    font-size: 8vw;
  }
  font-family: "revolution";
  color: #b9f601;
  text-shadow: 1px 6px rgba(185, 246, 1, 0.4), 0 0 7px #252525, 0 0 10px #252525,
    0 0 5px #252525, 0 0 5px #b9f601, 0 0 5px #b9f601, 0 0 5px #b9f601,
    0 0 5px #b9f601, 0 0 11px #b9f601;
  @media (max-width: 768px) {
    &&& {
      font-size: 40px;
    }
  }
  @media (max-width: 1366px) {
    &&& {
      font-size: 80px;
    }
  }
`;
const SatoshiTitleBotBattle = styled.h1`
  &&& {
    font-size: 2.7vw;
  }
  color: #252525;
  font-family: dinNext;
  font-weight: bold;
  text-shadow: 0 0 7px #252525, 0 0 10px #252525, 0 0 5px #252525,
    0 0 5px #b9f601, 0 0 5px #b9f601, 0 0 5px #b9f601, 0 0 5px #b9f601,
    0 0 11px #b9f601;
  -webkit-text-stroke: 2px #b9f601;
  letter-spacing: 2px;
  @media (max-width: 768px) {
    &&& {
      font-size: 20px;
    }
  }
  @media (max-width: 1366px) {
    &&& {
      font-size: 27px;
    }
  }
`;
const ActiveEarningContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 150px;
  @media (max-width: 768px) {
    margin-bottom: 60px;
    margin-top: 10px;
    .Avatar {
      flex-direction: column-reverse;
    }
    .Crystals {
      flex-direction: row;
    }
  }
`;

const ActiveEarningText = styled.p`
  padding: 0 15%;
  text-align: center;
  font-family: "dinNext";
  font-size: 24px;
  color: white;
  letter-spacing: 1.8px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ActiveImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: 30px;
  box-shadow: 0 0 0.5rem #252525, 0 0 0.6rem #252525, 0 0 0.1rem #b9f601,
    0 0 0.1rem #b9f601, 0 0 1rem #b9f601, inset 0 0 0rem #b9f601;
`;
const ActiveSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 60px;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 30px;
    box-shadow: 0 0 0.5rem #252525, 0 0 0.6rem #252525, 0 0 0.1rem #b9f601,
      0 0 0.1rem #b9f601, 0 0 1rem #b9f601, inset 0 0 0rem #b9f601;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const BotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const Box = styled.div`
  width: 30%;
  height: 350px;
  background-color: #252525;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 10px 20px;
  box-shadow: 0 0 0.5rem #252525, 0 0 0.6rem #252525, 0 0 0.1rem #b9f601,
    0 0 0.1rem #b9f601, 0 0 1.5rem #b9f601, inset 0 0 0rem #b9f601;
  h4 {
    font-family: dinNext;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 320px;
    padding: 10px;
    margin: 10px;
    h4 {
      font-size: 16px;
    }
  }
`;
