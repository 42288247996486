export const types = {
  accent_color: [
    "Dark Red",
    "Red",
    "Light Red",
    "Dark Blue",
    "Blue",
    "Light Blue",
    "Teal",
    "Dark Green",
    "Green",
    "Brown",
    "Dark Brown",
    "Light Brown",
    "Orange",
    "Black",
    "Grey",
    "White",
    "Purple",
  ],
  body_type: [
    "Bandit",
    "Hacker",
    "Commander",
    "Rebel",
    "Drifter",
    "Outlaw",
    "Assassin",
    "Cyborg",
    "Ninja",
    "Renegade",
    "Prophet",
    "Knight",
  ],
  eye_color: [
    "Red",
    "Blue",
    "Green",
    "White",
    "Purple",
    "Dark Blue",
    "Orange",
    "Turquoise",
    "Yellow",
    "Pink",
  ],
  legion: [
    // "Brazil - Sao Paulo",
    "Bangladesh    - Dhaka",
    "Egypt - Cairo",
    // "Japan - Osaka",
    " Pakistan    - Karachi",
    "China - Chongqing",
    "Turkey -    Istanbul",
    "India - Kolkata",
    "Democratic Republic of the Congo -    Kinshasa",
    " Nigeria - Lagos",
    "China - Tianjin",
    // "China - Guangzhou",
    "Pakistan - Lahore",
    "India - Bangalore",
    "Indonesia - Jakarta",
    // "Peru - Lima",
    // "Japan - Nagoya",
    " Iran - Tehran",
    // "Vietnam - Ho Chi Minh City",
    "Angola - Luanda",
    // " Malaysia    - Kuala Lumpur",
    "Saudi Arabia - Riyadh",
    "Iraq - Baghdad",
    "Tanzania -    Dar es Salaam",
    "Chile - Santiago",
    "Sudan - Khartoum",
    "South Africa - Johannesburg",
    // "Russia    Saint - Petersburg",
    "Myanmar - Yangon",
    "Ivory Coast - Abidjan",
    // "Mexico - Guadalajara",
    "Turkey - Ankara",
    "Kenya - Nairobi",
    "Vietnam - Hanoi",
    // "South Africa - Cape Town",
    "Saudi Arabia    - Jiddah",
    "Taiwan - Xinbei",
    "Afghanistan - Kabul",
    "Cameroon - Yaounde",
    "Nigeria - Kano",
    "Morocco - Casablanca",
    "Madagascar - Antananarivo",
    "Indonesia - Bekasi",
    "Ghana - Kumasi",
    "Uganda - Kampala",
    // "South Korea - Busan",
    "Paraguay - Asuncion",
    "Dominican Republic    - Santo Domingo",
    "Senegal - Dakar",
    "Kuwait - Kuwait City",
    // "Ukraine - Kyiv",
    // "Cuba - Havanna",
    "Venezuela - Caracas",
  ],
  primary_color: [
    "Pure Black",
    "Dark Red",
    "Red",
    "Light Red",
    "Dark Blue",
    "Blue",
    "Light Blue",
    "Teal",
    "Dark Green",
    "Green",
    "Brown",
    "Dark Brown",
    "Light Brown",
    "Orange",
    "Black",
    "Grey",
    "White",
    "Purple",
    "Camo - Blue",
    "Camo - Green",
    "Camo - Red",
    "Camo - Pink",
    "Camo - Light Blue",
    "Camo - Grey",
    "Circuit - Blue",
    "Circuit - White",
    "Circuit - Green",
    "Circuit - Purple",
    "Circuit - Red",
    "Disorder Stripes - Blue",
    "Disorder Stripes - Green",
    "Disorder Stripes - Grey",
    "Disorder Stripes - Red",
    "Disorder Stripes - Salmon",
    "Disorder Stripes - Purple",
    "Doodle - Aqua",
    "Doodle - Brown",
    "Doodle - Green",
    "Doodle - Grey",
    "Doodle - Orange",
    "Doodle - White",
    "Intricate Stripes - Beige",
    "Intricate Stripes - Black",
    "Intricate Stripes - Blue",
    "Intricate Stripes - Green",
    "Intricate Stripes - Red",
    "Intricate Stripes - White",
    "Maze - Blue",
    "Maze - Green",
    "Maze - Grey",
    "Maze - Orange",
    "Maze - Pink",
    "Maze - White",
    "Los Muertos - Blue",
    "Los Muertos - Brown",
    "Los Muertos - Green",
    "Los Muertos - Pink",
    "Los Muertos - Purple",
    "Los Muertos - Red",
    "Stained Glass - Aqua",
    "Stained Glass - Green",
    "Stained Glass - Orange",
    "Stained Glass - Red",
    "Stained Glass - Salmon",
    "Topo - Blue",
    "Topo - Brown",
    "Topo - Green",
    "Topo - Grey",
    "Topo - Red",
    "Topo - White",
  ],
  secondary_color: [
    "Dark Red",
    "Red",
    "Light Red",
    "Dark Blue",
    "Blue",
    "Light Blue",
    "Teal",
    "Dark Green",
    "Green",
    "Brown",
    "Dark Brown",
    "Light Brown",
    "Orange",
    "Black",
    "Grey",
    "White",
    "Purple",
  ],
  weapon: [
    "Partisan",
    "Rune Axe",
    "Tactical Hammer",
    "Saw Blade",
    "Flanged Mace",
    "Polearm",
    "Viking Axe",
    "Firefighter's Axe",
    "Battle Axe",
    "Danish Axe",
    "Tomahawk",
    "War Hammer",
    "Tactical swords",
    "Double Bats",
    "Golf Club",
    "Katana",
    "Bat",
    "Industrial Hammer",
    "Medieval Hammer",
    "Sickle",
    "Crowbar",
    "Machete",
    "Morning Star Mace",
    "Shovel",
    "Trident",
    "Sarissa",
    "Yari",
    "Rusty Sword",
    "Longsword",
    "Dadao",
    "Persian Sword",
  ],
  headgear: [
    "Crown",
    "Viking Helmet",
    "Steampunk Hat",
    "Cowboy Hat",
    "Samurai Helmet",
    "Top Hat",
    "Pilot Helmet",
    "Army Helmet",
    "Ballcap",
    "Dragon Skull",
    "Spartan Helmet",
    "Winged Helmet",
  ],
};
