import React, { useState } from "react";
import "./Gameplay.css";

const Gameplay = (props) => {
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="Gameplay">
      <div
        className="GameplayBG"
        style={{
          backgroundImage:
            props.width >= 1050
              ? `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/BG_gameplay_web.png')`
              : `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG_phone/BG_gameplay_phone.png')`,
        }}
      >
        <div className="GameplayContent">
          <div
            className="GameplayTitle"
            style={{
              backgroundImage:
                props.width >= 1050
                  ? `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/Tittle_R_web.png')`
                  : `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG_phone/tittle_R_phone.png')`,
            }}
          >
            <h1 className="-Title">Gameplay</h1>
          </div>
          <div className="-Relative">
            <video autoPlay muted={isMuted} loop className="GameplayVideo">
              <source
                src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Video/Legions_2029_-_Announcement_Release_Trailer_50MB.mp4`}
                type="video/mp4"
              />
            </video>
            <button className="SoundIconBtn" onClick={toggleMute}>
              {!isMuted ? (
                <img
                  className="SoundIconImg"
                  src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/sound-icon.png`}
                />
              ) : (
                <img
                  className="SoundIconImg"
                  src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/sound-icon-muted.png`}
                />
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        className="GameplayPBG"
        style={{
          backgroundImage:
            props.width >= 950
              ? `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/Line_gameplay_web.png')`
              : `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG_phone/Line_gameplay_phone.png')`,
        }}
      >
        <p>
          “Legions 2029” is a first-of-its-kind PC game that mixes the
          progression elements of iconic RPGs like Diablo and World of Warcraft
          with the dynamic combat experience of hack-and-slash games like God of
          War. It offers a unique Roguelike gameplay loop where players venture
          deeper into the Outskirts, battling formidable foes, and earning
          valuable resources. Every run is uniquely challenging with randomly
          arranged maps, and players can compete for rewards and prestige in the
          Speedrun mode after completing the Campaign Mode. Additionally,
          Legions 2029 features a dynamic player economy, allowing gamers to
          have full control and flexibility over their in-game progress and
          items.
        </p>
      </div>
    </div>
  );
};

export default Gameplay;
