import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part6.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 6.png`;

const Part6 = (props) => {
  return (
    <BasicLoreModal image={image}>
      <div className="LoreBlock">
        <p>
          Unknown to the people, the Defenders Of Fiat have existed for years.
          Led by Frank Fazor, they are a secret society of titans of industry
          from around the world with one core purpose: keeping the balance of
          wealth and power. A balance that tips heavily in their favor.
        </p>
        <p>
          Several years prior to the Great Pandemic, the Defenders Of Fiat
          became concerned as they saw Bitcoin and other cryptocurrencies
          gaining traction. They feared that this new technology could cause a
          paradigm shift in the traditional financial institutions that they
          benefited from for decades. In the beginning, they responded how they
          always did: threw money at the technology to attempt to control it.
          Indeed, the Defenders Of Fiat were some of the earliest investors in
          Bitcoin. They believed that if they could own it, they could control
          it. But this only fueled more rapid adoption of the currencies and
          technology. The D.O.F. knew they needed to do something more drastic
          to stay atop the food chain. So they sold their cryptocurrency for
          considerable profits in 2018 causing the markets to temporarily crash
          in the process. They reinvested all their earnings in Alpha
          Corporation and began to build the surveillance and military
          technology they would need to monitor their adversaries and eliminate
          them where needed.
        </p>
        <p>
          But Frank Fazor dreamed bigger. Like many in the D.O.F., he was
          thirsty for more power. He wanted to use his new technology to know
          everything as it happened so he would always be ahead of his
          competitors. But he also knew that the world leaders would not
          willingly let him launch his satellites into space without a reason.
          So while keeping a squeaky clean public image, he concocted a plan for
          ultimate control. He would manufacture a virus that could only be
          cured through the use of his technology. The D.O.F. thought the plan
          was drastic. But fueled in part by greed and in part by fear of Frank
          Fazor, one by one they agreed. Well at least most did. Those that did
          not vanished mysteriously before they could go public. And after a
          year, the Great Pandemic was born.
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part6" style={{ ...props.customStyle }}>
    //   <div className="LorePart-6 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part6Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>The Defenders of Fiat</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/SatoshiLore06.png"></img>
    //     <p>
    //       Unknown to the people, the Defenders Of Fiat have existed for years.
    //       Led by Frank Fazor, they are a secret society of titans of industry from
    //       around the world with one core purpose: keeping the balance of wealth
    //       and power. A balance that tips heavily in their favor.
    //     </p>
    //     <p>
    //       Several years prior to the Great Pandemic, the Defenders Of Fiat
    //       became concerned as they saw Bitcoin and other cryptocurrencies
    //       gaining traction. They feared that this new technology could cause
    //       a paradigm shift in the traditional financial institutions that
    //       they benefited from for decades. In the beginning, they responded
    //       how they always did: threw money at the technology to attempt to
    //       control it. Indeed, the Defenders Of Fiat were some of the earliest
    //       investors in Bitcoin. They believed that if they could own it, they
    //       could control it. But this only fueled more rapid adoption of the
    //       currencies and technology. The D.O.F. knew they needed to do
    //       something more drastic to stay atop the food chain. So they sold
    //       their cryptocurrency for considerable profits in 2018 causing the
    //       markets to temporarily crash in the process. They reinvested all
    //       their earnings in Alpha Corporation and began to build the surveillance and
    //       military technology they would need to monitor their adversaries
    //       and eliminate them where needed.
    //     </p>
    //     <p>
    //       But Frank Fazor dreamed bigger. Like many in the D.O.F., he was thirsty for
    //       more power. He wanted to use his new technology to know everything as
    //       it happened so he would always be ahead of his competitors. But he also
    //       knew that the world leaders would not willingly let him launch his
    //       satellites into space without a reason. So while keeping a squeaky
    //       clean public image, he concocted a plan for ultimate control. He would
    //       manufacture a virus that could only be cured through the use of his
    //       technology. The D.O.F. thought the plan was drastic. But fueled in part
    //       by greed and in part by fear of Frank Fazor, one by one they agreed. Well at
    //       least most did. Those that did not vanished mysteriously before they
    //       could go public. And after a year, the Great Pandemic was born.
    //     </p>
    //   </div>
    // </div>
  );
};

export default Part6;
