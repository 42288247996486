import React, { Component } from 'react';
import "../Instructions/Instructions.css"
import "./InstructionsMobile.css";

import onClickOutside from "react-onclickoutside";

class InstructionsMobile extends Component {
    handleClickOutside = (e) => {
        this.props.setShowInstructions(false)
      };

    render() {
        return (
            <div className="Instructions">
            
            <div className="--content">
                <div className="close-btn" onClick={(e) => this.props.setShowInstructions(false)}> 
                    <div className="--x">
                        <div className="stick-1"></div>
                        <div className="stick-2"></div>
                    </div>
                </div>
                <h2>Connecting with metamask</h2>
       

            <div className="--instructions">
                <div className="--steps">
                <h3 style={{textAlign: "left", marginBottom: "0.5rem"}}>Method 1:</h3>
                    <p style={{marginBottom: "0.5rem"}}>
                    To connect your metamask wallet through the Metamask app do the following:
                    </p>    
                    <p style={{paddingLeft: "1rem", textAlign: "left"}}>
                        <span><strong>1.</strong> Make sure to have installed the Metamask app.</span>
                        <br /><span><strong>2.</strong> Open the Metamask App and import your wallet if you haven't.</span>
                        <br /><span><strong>3.</strong> Press the menu icon and select the Explorer tab.</span>
                        <br /><span><strong>4.</strong> Select the lens icon in the tab menu.</span>
                        <br /><span><strong>5.</strong> Write down https://www.satoshiverse.io/ and press Enter.</span>
                        <br /><span><strong>6.</strong> Navigate to Launch section through the navbar.</span>
                        <br /><span><strong>7.</strong> Press the Connect to Metamask Wallet button.</span>
                    </p>

                    <h3 style={{textAlign: "left", marginBottom: "0.5rem"}}>Method 2:</h3>
                    <p style={{marginBottom: "0.5rem"}}>
                    To connect your metamask wallet through the website with your mobile browser do the following:
                    </p>    
                    <p style={{paddingLeft: "1rem", textAlign: "left"}}>
                        <span><strong>1.</strong> Make sure to have installed the Metamask App and have the application closed.</span>
                        <br /><span><strong>2.</strong> Press the Connect to Metamask Wallet button in the Satoshiverse website.</span>
                        <br /><span><strong>3.</strong> A WalletConnect popup will appear, then press the connect button and select open with Metamask.</span>
                        <br /><span><strong>4.</strong> Login or authenticate inside metamask, a popup inside Metamask will appear.</span>
                        <br /><span><strong>5.</strong> Press the connect button and then go back to the website.</span>
                    </p>
                   
                </div>
            </div>
          
                </div>
            </div>
        );
    }
}

export default onClickOutside(InstructionsMobile);