import React, { useState } from 'react'
import { Parts } from "./Parts";
import { Comic } from "./Comic";

export const Lore = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [number, setNumber] = useState(1);
  const next = () => {
    if (number === 13) {
      setNumber(1)
    } else {
      setNumber(number + 1);
    }
  }
  const preview = () => {
    if (number === 1) {
      setNumber(12);
    } else {
      setNumber(number - 1);
    }
  }
  const open = (number) => {
    setNumber(number);
    setIsOpen(true);
  }
  return (
    <>
      <Comic open={open} />
      <Parts
        number={number}
        preview={preview}
        next={next}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>

  )
}
