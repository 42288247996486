import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part4.css";

const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 4.png`;

const Part4 = (props) => {
  return (
    <BasicLoreModal image={image}>
      <div className="LoreBlock">
        <p>
          The people of the world are shocked. Some are hopeful. Many are
          scared. More are outraged. But the decree has been made and is
          supported by their leaders, and all will soon learn what their fate
          will be if they resist. While many pack up their bags and begin their
          journeys to their designated cities, some decide to protest. One
          protest in particular sends shockwaves around the world. Just outside
          of Washington DC a group of thousands of youth march on the capital
          where Alpha Corporation has set up its headquarters. Tens of thousands
          chanting “We will not comply! We will not comply!”
        </p>
        <p>
          The question emerges of how Alpha Corporation will handle this early
          uprising. And that is the first time President Morgan realizes he has
          made a grave mistake. In agreeing to share his technology with the
          world, Frank Fazor demanded that he be given ultimate implementation
          authority over the Lockdown. He convinced the leaders that without one
          centralized source of authority, the kind of coordination necessary to
          implement a worldwide lockdown would not be possible. The leaders
          begrudgingly agreed on the important condition that Frank Fazor could
          not use his power to harm anyone. This protest presents the first test
          of Frank Fazor’s power. Will he respond with diplomacy… or force?
        </p>
        <p>
          “Deploy the drones” he shouts! “No!” shouts President Morgan. But it
          is to no avail. The technicians work for Frank Fazor, and the order
          has been given. “Don’t worry.” Frank Fazor says. “I have no intention
          of harming these poor children. But the people must learn they must
          not resist.”
        </p>
        <p>
          A swarm of drones flies out of the central command of Alpha
          Corporation towards the protesters. As soon as they arrive the drones
          simultaneously fire a giant blue beam of light at the protesters that
          can be seen for miles. Instantly all the protesters fall to the
          ground. President Morgan watches in horror. “Relax. They are just
          tranquilized” Frank Fazor says. Shortly after, a swarm of G.E.U.s is
          deployed to the scene and they make quick work of collecting the
          protesters and taking them to the holding facilities that have been
          set up for breachers of the Lockdown.
        </p>
        <p>
          Around the world, news of the protest spreads and people quickly see
          the power Alpha Corporation possesses and what will happen if they
          resist. Those who originally thought about resisting quickly pack
          their belongings and join the others in their journeys to their
          designated cities.
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part4" style={{ ...props.customStyle }}>
    //   <div className="LorePart-4 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part4Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>A Futile Rebellion</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Drones.png"></img>
    //     <p>
    //       The people of the world are shocked. Some are hopeful. Many are
    //       scared. More are outraged. But the decree has been made and is
    //       supported by their leaders, and all will soon learn what their fate
    //       will be if they resist. While many pack up their bags and begin their
    //       journeys to their designated cities, some decide to protest. One
    //       protest in particular sends shockwaves around the world. Just outside
    //       of Washington DC a group of thousands of youth march on the capital
    //       where Alpha Corporation has set up its headquarters. Tens of thousands chanting
    //       “We will not comply! We will not comply!”
    //     </p>
    //     <p>
    //       The question emerges of how Alpha Corporation will handle this early uprising.
    //       And that is the first time President Morgan realizes he has made a grave
    //       mistake. In agreeing to share his technology with the world, Frank Fazor
    //       demanded that he be given ultimate implementation authority over the
    //       Lockdown. He convinced the leaders that without one centralized source
    //       of authority, the kind of coordination necessary to implement a
    //       worldwide lockdown would not be possible. The leaders begrudgingly
    //       agreed on the important condition that Frank Fazor could not use his power
    //       to harm anyone. This protest presents the first test of Frank Fazor’s power.
    //       Will he respond with diplomacy… or force?
    //     </p>
    //     <p>
    //       “Deploy the drones” he shouts! “No!” shouts President Morgan. But it is to
    //       no avail. The technicians work for Frank Fazor, and the order has been
    //       given. “Don’t worry.” Frank Fazor says. “I have no intention of harming
    //       these poor children. But the people must learn they must not resist.”
    //     </p>
    //     <p>
    //       A swarm of drones flies out of the central command of Alpha Corporation towards
    //       the protesters. As soon as they arrive the drones simultaneously fire
    //       a giant blue beam of light at the protesters that can be seen for
    //       miles. Instantly all the protesters fall to the ground. President Morgan
    //       watches in horror. “Relax. They are just tranquilized” Frank Fazor says.
    //       Shortly after, a swarm of G.E.U.s is deployed to the scene and they
    //       make quick work of collecting the protesters and taking them to the
    //       holding facilities that have been set up for breachers of the
    //       Lockdown.
    //     </p>
    //     <p>
    //       Around the world, news of the protest spreads and people quickly see
    //       the power Alpha Corporation possesses and what will happen if they resist. Those
    //       who originally thought about resisting quickly pack their belongings
    //       and join the others in their journeys to their designated cities.
    //     </p>
    //   </div>
    // </div>
  );
};

export default Part4;
