import React from "react";
import "./AvatarsPart.css";

import { Link } from "react-router-dom";

const AvatarsPart = () => {
  return (
    <div className="PartAvatars">
      <div className="AvatarsBannerContainer">
        {/* <div className="Avatars-Banner-Social"></div> */}
        <img
          className="Avatars-Banner-Framework-izq"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Banner_avatars_framework_izq.png`}
        />
        <img
          className="Avatars-Banner-Img"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/banner_avatars.png`}
        />
        <img
          className="Avatars-Banner-Framework-der"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Banner_avatars_framework_der.png`}
        />
        {/* <div className="Avatars-Banner-Social">
          <img
            className="Avatars-Banner-Social-Item"
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/twitter_button.png`}
          />
          <img
            className="Avatars-Banner-Social-Item"
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/instagram_button.png`}
          />
          <img
            className="Avatars-Banner-Social-Item"
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/discord_button.png`}
          />
          <img
            className="Avatars-Banner-Social-Item"
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/youtube_button.png`}
          />
        </div> */}
      </div>
      <div className="PartAvatarsContent">
        <h1>Avatars</h1>
        <p className="-p2">
          There are currently two collections of avatars in the Satoshiverse
          that give holders exclusive access to benefits within the Satoshiverse
          ecosystem as well as early Alpha access to the Legions 2029 game! :
          The Legionnaires <span className="-highlight">(5,555 avatars) </span>
          and The Apemo Army <span className="-highlight">(3,333 avatars)</span>
          .
        </p>
        <br />
        <Link to="/game" className="btn-link" style={{ marginTop: "2rem" }}>
          Learn more
        </Link>
      </div>
    </div>
  );
};

export default AvatarsPart;
