import React, { useState, useEffect } from "react";
import "./AvatarsCarousel.css";

const AvatarsCarousel = (props) => {
  const { isRight, collectionId } = props;
  const [index, setIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1 > 6 ? 1 : prevIndex + 1));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getCollection = () => {
    switch (collectionId) {
      case "legionnaires":
        return {
          label: "Legionnaires",
          quantity: "5.5K ITEMS",
        };
      case "apemo_army":
        return {
          label: "Apemo Army",
          quantity: "3.3K ITEMS",
        };
      default:
        return {
          label: "",
          quantity: "",
        };
    }
  };

  const { label, quantity } = getCollection();

  const onClick = () => {
    props.onAvatarClick(collectionId);
  };

  return (
    <div className="AvatarsCarousel">
      <img
        className="avatar-img-carousel"
        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/carousel/${collectionId}-${index}.png`}
        style={isRight ? { right: "2.15%" } : { left: "2.15%" }}
      />
      <div
        className="collection-label"
        style={
          props.width < 450
            ? isRight
              ? { right: "3%" }
              : { left: "3.25%" }
            : isRight
            ? { right: "6%" }
            : { left: "6.25%" }
        }
      >
        <h2 className="-collectionName" onClick={onClick}>
          {label}
        </h2>
        <p className="-p2 -collectionQuantity">{quantity}</p>
      </div>

      <ul
        style={
          props.width < 1000
            ? isRight
              ? { right: "-3.5%" }
              : { left: "-3.5%" }
            : isRight
            ? { right: "0%" }
            : { left: "0%" }
        }
      >
        <li>
          <div
            className={index === 1 ? "marked-yellow-dot" : "yellow-dot"}
            onClick={() => setIndex(1)}
          >
            <div></div>
          </div>
        </li>
        <li>
          <div
            className={index === 2 ? "marked-yellow-dot" : "yellow-dot"}
            onClick={() => setIndex(2)}
          >
            <div></div>
          </div>
        </li>
        <li>
          <div
            className={index === 3 ? "marked-yellow-dot" : "yellow-dot"}
            onClick={() => setIndex(3)}
          >
            <div></div>
          </div>
        </li>
        <li>
          <div
            className={index === 4 ? "marked-yellow-dot" : "yellow-dot"}
            onClick={() => setIndex(4)}
          >
            <div></div>
          </div>
        </li>
        <li>
          <div
            className={index === 5 ? "marked-yellow-dot" : "yellow-dot"}
            onClick={() => setIndex(5)}
          >
            <div></div>
          </div>
        </li>
        <li>
          <div
            className={index === 6 ? "marked-yellow-dot" : "yellow-dot"}
            onClick={() => setIndex(6)}
          >
            <div></div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AvatarsCarousel;
