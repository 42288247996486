import React from "react";
import "./GuestsPart.css";
import GuestsPartItem from "./GuestsPartItem/GuestsPartItem";

const guests = [
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/bga.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/BGA Name.png`,
    label: "Blockchain Game Alliance",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/Big Time.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/Big Time Name.png`,
    label: "Big Time",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/Decentraland.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/Decentraland Name.png`,
    label: "Decentraland",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/Illuvium.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/Illuvium Name.png`,
    label: "Illuvium",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/OnCyber.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/OnCyber Name.png`,
    label: "OnCyber",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/Pixels.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/Pixels Name.png`,
    label: "Pixels",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/Polygon.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/Polygon Studios Name.png`,
    label: "Polygon Studios",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/RTFKT.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/RTFKT Name.png`,
    label: "RTFKT",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/Trevor Jones.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/Trevor Jones Name.png`,
    label: "Trevor Jones",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/VeVe.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/VeVe Name.png`,
    label: "VeVe",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/WAGMI.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/WAGMI Name.png`,
    label: "WAGMI",
  },
  {
    imgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Circles/WAX.png`,
    labelImgUrl: `${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/Guests/Names/WAX Name.png`,
    label: "WAX",
  },
];

const GuestsPart = () => {
  return (
    <div className="PartGuests">
      <div className="PartGuestsContent">
        <p>Some of our past guests include:</p>
      </div>
      <ul className="PartGuestsList">
        {guests.map((guest) => (
          <GuestsPartItem
            imgUrl={guest.imgUrl}
            labelImgUrl={guest.labelImgUrl}
            label={guest.label}
          />
        ))}
      </ul>

      <div style={{ textAlign: "center", marginTop: "4rem" }}>
        <a
          className="TwitterTheHideout-Link"
          href="https://twitter.com/TheHideout_Show"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="TwitterTheHideout">
            <img
              className="twitter-icon"
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Img/twitter-x-icon.png`}
            />
            <span>The Hideout</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default GuestsPart;
