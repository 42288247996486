import React, { Component } from "react";
import PropTypes from "prop-types";

import './Father.css';

import { connect } from "react-redux";
import {
  connectWallet,
  disconnectWallet,
  checkNetwork,
  claim,
  buy,
  claimUnclaimedToken,
  setStatusMsg,
} from "../../../actions/blockActions";

import DiagonalSection from "../../DiagonalSection/DiagonalSection";
import StickyNav from "../../StickyNav/StickyNav";
import Footer from "../../Footer/Footer";
import FooterFill from "../../FooterFill/FooterFill";


import Instructions from "../Instructions/Instructions";
import InstructionsMobile from "../InstructionsMobile/InstructionsMobile";
import { isMobile } from 'react-device-detect';

class Father extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClaimEnabled: true,
      isPurchaseWhitelistOnlyEnabled: false,
      isPurchaseEnabled: true,

      showInstructions: false,
      showInstructionsMobile: false,

      isBuyingLoading: false,
      isClaimingLoading: false,

      daysSince: 0,
      phaseNumber: 5,

      statusMsg: this.props.statusMsg,

      quantity: 0,

      msg: this.props.msg,
      tokensCount: this.props.tokensCount,

      address: this.props.address,

      isClaim: false,
    };
  }

  setIsClaimEnabled = (isEnabled) => { this.setState({ isClaimEnabled: isEnabled }) }
  setIsPurchaseWhitelistOnlyEnabled = (isEnabled) => { this.setState({ isPurchaseWhitelistOnlyEnabled: isEnabled }) }
  setIsPurchaseEnabled = (isEnabled) => { this.setState({ isPurchaseEnabled: isEnabled }) }
  setDaysSince = (daysSince) => this.setState({ daysSince })
  setPhaseNumber = (phaseNumber) => this.setState({ phaseNumber });
  setShowInstructions = (state) => this.setState({ showInstructions: state })
  setShowInstructionsMobile = (state) => this.setState({ showInstructionsMobile: state })
  setIsClaim = (state) => this.setState({ isClaim: state })


  static propTypes = {
    connectWallet: PropTypes.func.isRequired,
    disconnectWallet: PropTypes.func.isRequired,
    isWalletConnected: PropTypes.bool.isRequired,

    address: PropTypes.string,
    chainId: PropTypes.number,
    tokensBalance: PropTypes.object.isRequired,
    tokensCount: PropTypes.object.isRequired,
    transactionData: PropTypes.object.isRequired,
    transactionReceipt: PropTypes.object.isRequired,
    isFetchingData: PropTypes.bool.isRequired,
    isWalletInWhitelist: PropTypes.bool.isRequired,

    msg: PropTypes.string.isRequired,
    statusMsg: PropTypes.string.isRequired
  };

  componentDidMount() {

  };

  setIsBuyingLoading = (isLoading) => { this.setState({ isBuyingLoading: isLoading }) }
  setIsClaimingLoading = (isLoading) => { this.setState({ isClaimingLoading: isLoading }) }

  setAddQuantity = () => {
    this.setState({ quantity: parseInt(this.state.quantity) + 1 })
  }
  setSubstractQuantity = () => {
    if (this.state.quantity <= 0) {
      this.setState({ quantity: 0 })
    } else {
      this.setState({ quantity: parseInt(this.state.quantity) - 1 })
    }
  }

  connectWallet = async (methodKey) => {
    // console.log("methodKey: ", methodKey)
    await this.props.connectWallet(methodKey);
  };

  disconnectWallet = async () => {
    await this.props.disconnectWallet();
  }

  onClaim = async (e) => {

    if (!this.validateClaimByPhase()) {
      console.log("Trying to claim!");
      this.props.dispatch(setStatusMsg("Fill out a valid quantity to claim!"))
      return;
    }

    if (this.state.isClaimEnabled) {
      console.log("Claiming...");
      this.setIsClaimingLoading(true);
      await this.props.claim(this.state.quantity);
      this.setIsClaimingLoading(false);
      this.setState({ quantity: 0 })
    } else {
      console.log("Claim is not enabled!");
    }
  };

  onBuy = async (e) => {
    if (!this.validateBuy()) {
      this.props.dispatch(setStatusMsg("Fill out a valid quantity to buy!"))
      return;
    }

    if (this.state.isPurchaseEnabled) {
      this.setIsBuyingLoading(true);
      await this.props.buy(this.state.quantity);
      this.setIsBuyingLoading(false);

      this.setState({ quantity: 0 })
    } else if (this.state.isPurchaseWhitelistOnlyEnabled && this.props.isWalletInWhitelist) {
      console.log("Buying in whitelist mode...");
      this.setIsBuyingLoading(true);
      await this.props.buy(this.state.quantity);
      this.setIsBuyingLoading(false);

      this.setState({ quantity: 0 })
    } else {
      console.log("Buying is not enabled!");
    }
  };

  validateInputClaim = () => {
    if (!this.state.isClaimingLoading) {
      if (checkNetwork()
        && this.validateClaimByPhase()
        && !this.props.isFetchingData && !this.isEmpty(this.props.tokensCount)
        && this.props.isWalletConnected
        && this.state.isClaimEnabled
        && !this.state.isBuyingLoading) return false;
    }
    return true;
  }

  ellipsify = (str) => {
    if (str.length > 10) {
      return str.substring(0, 10) + "...";
    } else {
      return str;
    }
  };

  renderByPhase = () => {
    let genesisCount, platinumCount, goldCount, silverCount, totalCount;
    if (this.isEmpty(this.props.tokensCount)) {
      genesisCount = "-";
      platinumCount = "-";
      goldCount = "-";
      silverCount = "-";

      totalCount = 0;
    } else {
      switch (this.state.phaseNumber) {
        case 1:
          genesisCount = this.props.tokensCount["genesis"];
          platinumCount = this.props.tokensCount["platinum"];
          goldCount = "Not yet.";
          silverCount = "Not yet.";

          totalCount = genesisCount + platinumCount;
          break;
        case 2:
          genesisCount = this.props.tokensCount["genesis"];
          platinumCount = this.props.tokensCount["platinum"];
          goldCount = this.props.tokensCount["gold"];
          silverCount = "Not yet.";

          totalCount = genesisCount + platinumCount + goldCount;
          break;
        case 3:
          genesisCount = this.props.tokensCount["genesis"];
          platinumCount = this.props.tokensCount["platinum"];
          goldCount = this.props.tokensCount["gold"];
          silverCount = this.props.tokensCount["silver"];

          totalCount = genesisCount + platinumCount + goldCount + silverCount;
          break;
        case 4:
        case 5:
          genesisCount = this.props.tokensCount["genesis"];
          platinumCount = this.props.tokensCount["platinum"];
          goldCount = this.props.tokensCount["gold"];
          silverCount = this.props.tokensCount["silver"];

          totalCount = genesisCount + platinumCount + goldCount + silverCount;
          break;
        default:
          genesisCount = "Not yet.";
          platinumCount = "Not yet.";
          goldCount = "Not yet.";
          silverCount = "Not yet.";
          totalCount = 0;
      }
    }

    return !this.props.isFetchingData && this.props.address && checkNetwork() ? totalCount : "-"
  }

  validateClaimByPhase = () => {
    if (this.isEmpty(this.props.tokensCount))
      return false;

    //console.log("phaseNumber: ", this.state.phaseNumber)
    let quantityLimit;
    switch (this.state.phaseNumber + 1) {
      // case 1:
      //   quantityLimit = this.props.tokensCount["genesis"];
      //   break;
      case 2:
        quantityLimit = this.props.tokensCount["genesis"]
          + this.props.tokensCount["platinum"];
        break;
      case 3:
        quantityLimit = this.props.tokensCount["genesis"]
          + this.props.tokensCount["platinum"]
          + this.props.tokensCount["gold"];
        break;
      case 4:
        quantityLimit = this.props.tokensCount["genesis"]
          + this.props.tokensCount["platinum"]
          + this.props.tokensCount["gold"]
          + this.props.tokensCount["silver"];
        break;
      case 5:
      case 6:
        quantityLimit = this.props.tokensCount["genesis"]
          + this.props.tokensCount["platinum"]
          + this.props.tokensCount["gold"]
          + this.props.tokensCount["silver"];
        break;
      default:
        return false;
    }
    console.log(this.state.quantity);
    if (this.state.quantity !== "" && parseInt(this.state.quantity) <= quantityLimit && parseInt(this.state.quantity) > 0) {
      return true;
    } else {
      return false;
    }
  }

  validateBuy = () => {
    if (this.isEmpty(this.props.tokensCount))
      return false;

    if (this.state.isPurchaseEnabled) {
      if (this.state.quantity !== "" && parseInt(this.state.quantity) > 0) {

        // IT'S DAY 5
        if (this.state.daysSince + 1 === 5) {
          let quantityLimit = 10;
          if (parseInt(this.state.quantity) <= quantityLimit) {
            return true;
          } else {
            return false;
          }
        }

        return true;
      } else {
        return false;
      }
    } else if (this.state.isPurchaseWhitelistOnlyEnabled && this.props.isWalletInWhitelist) {
      let quantityLimit = this.props.tokensCount["purchasedSoFar"] !== undefined ? 2 - this.props.tokensCount["purchasedSoFar"] : 0;
      if (this.state.quantity !== "" && parseInt(this.state.quantity) <= quantityLimit && parseInt(this.state.quantity) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  renderWhitelistStatus = () => {
    if (this.state.isPurchaseWhitelistOnlyEnabled && this.props.isWalletInWhitelist && this.props.isWalletConnected) {
      return <div className="metamask-msg" style={{ padding: "0.75rem 0rem" }}>
        <p style={{ lineHeight: "2rem" }}>
          You are on the early access whitelist.
          <br />

          {/*console.log("--> ", this.props.tokensCount["purchasedSoFar"])*/}
          {this.props.tokensCount["purchasedSoFar"] !== undefined ?
            <span style={{ display: "block", textTransform: "none", width: "100%", textAlign: "center" }}>Today, you can buy up to <strong style={{ textDecoration: "underline" }}>{this.props.tokensCount["purchasedSoFar"] !== undefined ? 2 - this.props.tokensCount["purchasedSoFar"] : 0} tokens</strong></span> : null
          }
        </p>
      </div>
    } else if (this.state.isPurchaseEnabled && this.state.daysSince + 1 === 5 && this.props.isWalletConnected) {
      return <div className="metamask-msg" style={{ padding: "0.75rem 0rem" }}>
        <p style={{ lineHeight: "2rem" }}>
          <span style={{ display: "block", textTransform: "none" }}>Today, you can only buy up to  <strong style={{ textDecoration: "underline" }}>10 tokens</strong> per transaction.</span>
        </p>
      </div>
    } else if (this.state.isPurchaseEnabled && this.state.daysSince + 1 > 5 && this.props.isWalletConnected) {
      return <div className="metamask-msg" style={{ padding: "0.75rem 0rem" }}>
        <p style={{ lineHeight: "2rem" }}>
          <span style={{ display: "block", textTransform: "none" }}>The public sale for <strong style={{ textDecoration: "underline" }}>ALL</strong> collectors has started!</span>
          <span style={{ display: "block", textTransform: "none", textAlign: "center" }}><strong style={{}}>.1 ETH</strong> PER LEGIONNAIRE</span>
        </p>
      </div>
    } else if (this.state.isPurchaseEnabled && this.state.daysSince + 1 > 5) {
      return <div className="metamask-msg" style={{ padding: "0.75rem 0rem" }}>
        <p style={{ lineHeight: "2rem" }}>
          <span style={{ display: "block", textTransform: "none" }}>The public sale for <strong style={{ textDecoration: "underline" }}>ALL</strong> collectors has started!</span>
          <span style={{ display: "block", textTransform: "none", textAlign: "center" }}><strong style={{}}>.1 ETH</strong> PER LEGIONNAIRE</span>
        </p>
      </div>
    }
    else {
      return null;
    }
  }

  onChange = (e) => {
    console.log(e.target.name, e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    }, () => { })
  }

  isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  validateBuyButton = () => {
    if (!this.state.isBuyingLoading) {
      if (checkNetwork()
        && this.validateBuy()
        && !this.props.isFetchingData && !this.isEmpty(this.props.tokensCount)
        && this.props.isWalletConnected
        && (this.state.isPurchaseEnabled || (this.state.isPurchaseWhitelistOnlyEnabled && this.props.isWalletInWhitelist))
        && !this.state.isClaimingLoading) return false;
    } return true

  }

  onClaimUnclaimed = async () => {
    this.setIsClaimingLoading(true);
    await this.props.claimUnclaimedToken();
    this.setIsClaimingLoading(false);
  }

  getUnclaimedTokens = () => {
    return this.props.tokensCount["unclaimedTokens"] ?
     this.props.tokensCount["genesis"] + this.props.tokensCount["platinum"]
     +this.props.tokensCount["gold"]+this.props.tokensCount["silver"]:
     "-"
  }

  validateUnclaimed = () => {
    if (!this.state.isClaimingLoading) {
      if (checkNetwork()
        && this.props.tokensCount["unclaimedTokens"] 
        && this.props.tokensCount["unclaimedTokens"].length > 0
        && !this.props.isFetchingData && !this.isEmpty(this.props.tokensCount)
        && this.props.isWalletConnected
        && this.state.isClaimEnabled
        && !this.state.isBuyingLoading) return false;
    }
    return true;
  }

  getPurchaseLeftCount = () => {
    return this.props.tokensCount["purchaseLeftCount"] !== undefined ? this.props.tokensCount["purchaseLeftCount"] : null
  }

  send = {
    ...this.state,
    addQuantity: this.setAddQuantity,
    substractQuantity: this.setSubstractQuantity,
    ellipsify: this.ellipsify,
    validateBuy: this.validateBuy,
    onBuy: this.onBuy,
    getPurchaseLeftCount: this.getPurchaseLeftCount,
    onChange: this.onChange,
    onClaim: this.onClaim,
    onClaimUnclaimed: this.onClaimUnclaimed,
    validateUnclaimed: this.validateUnclaimed,
    getUnclaimedTokens: this.getUnclaimedTokens,
    isEmpty: this.isEmpty,
    connectWallet: this.connectWallet,
    disconnectWallet: this.disconnectWallet,
    setIsBuyingLoading: this.setIsBuyingLoading,
    setIsClaimEnabled: this.setIsClaimEnabled,
    setIsPurchaseWhitelistOnlyEnabled: this.setIsPurchaseWhitelistOnlyEnabled,
    setIsPurchaseEnabled: this.setIsPurchaseEnabled,
    setShowInstructions: this.setShowInstructions,
    setShowInstructionsMobile: this.setShowInstructionsMobile,
    setDaysSince: this.setDaysSince,
    setPhaseNumber: this.setPhaseNumber,
    setIsClaimingLoading: this.setIsClaimingLoading,
    renderByPhase: this.renderByPhase,
    validateClaim: this.validateInputClaim,
    validateButtonBuy: this.validateBuyButton,
    setIsClaim: this.setIsClaim,
  }
  render() {
    return (
      <div className="JS-Scroll">
        <StickyNav
          onLoreClick={this.props.onLoreClick}
          onScrollClick={this.props.onScrollClick}
          changeRoute={this.props.changeRoute}
          width={this.props.width}
          toggleBurger={this.props.toggleBurger}
          closeBurger={this.props.closeBurger}
          isBurgerOpen={this.props.isBurgerOpen}
          useSmooth={true}
        />
        {
          this.state.showInstructions && <Instructions isClaim={this.state.isClaim} setShowInstructions={this.setShowInstructions} />
        }

        {
          isMobile && this.state.showInstructionsMobile && <InstructionsMobile setShowInstructions={this.setShowInstructionsMobile} />
        }
        <this.props.Component {...this.state} {...this.props} {...this.send} />
        <DiagonalSection
          i={1}
          direction={"right"}
          usePattern={false}
          Component={<Footer />}
          customStyle={{ paddingBottom: "2rem", minHeight: "auto" }}
        />
        <FooterFill />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showConnectMetamask: state.block.showConnectMetamask,

  msg: state.error.msg,
  address: state.block.address,
  chainId: state.block.chainId,

  tokensBalance: state.block.tokensBalance,
  tokensCount: state.block.tokensCount,
  transactionData: state.block.transactionData,
  transactionReceipt: state.block.transactionReceipt,
  isFetchingData: state.block.isFetchingData,
  isWalletInWhitelist: state.block.isWalletInWhitelist,

  statusMsg: state.block.statusMsg,

  isWalletLoading: state.block.isWalletLoading,
  isWalletConnected: state.block.isWalletConnected,
});

const mapDispatchToProps = dispatch => {
  return {
    connectWallet: (methodKey) => dispatch(connectWallet(methodKey)),
    //fetchTokenBalance: () => dispatch(fetchTokenBalance()),
    claim: (quantity) => dispatch(claim(quantity)),
    buy: (quantity) => dispatch(buy(quantity)),
    claimUnclaimedToken: () => dispatch(claimUnclaimedToken()),
    disconnectWallet: () => dispatch(disconnectWallet()),
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Father);
