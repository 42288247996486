import { ethers } from "ethers";
import axios from "axios";

import {
  HIDE_INSTALL_METAMASK,
  WALLET_LOADING,
  WALLET_CONNECT_FAIL,
  WALLET_CONNECT_SUCCESS,
  RESET_STATE,
  SHOW_CHANGE_NETWORK,
  HIDE_CHANGE_NETWORK,
  SETUP_CONTRACT,
  FETCH_TOKEN_DATA,
  IS_FETCHING_DATA,
  SET_IS_WALLET_IN_WHITELIST,
  CLEAR_STATUS_MSG,
  SET_STATUS_MSG,
  CLEAR_SUCCESS_MSG,
  SET_SUCCESS_MSG,
  SET_TRANSACTION_DATA,
  CLEAR_TRANSACTION_DATA,
  RESET_CONTRACT_DATA,
  CLEAR_WHITELIST_STATUS,
} from "./types";

import { setError, clearError } from "./errorActions";
import {
  checkWhitelistStatus,
  getPhaseNumber,
  checkTokenCounts,
} from "./whitelistActions";

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import TokenArtifact from "../contracts/TestContract/TestContract.json";
import contractAddress from "../contracts/TestContract/contract-address.json";

import SatoshiVerseArtifact from "../contracts/SatohiVerse/SatoshiVerse.json";
import LegionnaireArtifact from "../contracts/Legionnaire/Legionnaire.json";
import ApemoArtifact from "../contracts/Apemo/Apemo.json";

import { NFTTokens } from "./NFTTokens";

import GenesisSnapshot from "./snapshots/genesis_snapshot.json";
import PlatinumSnapshot from "./snapshots/platinum_snapshot.json";
import GoldSnapshot from "./snapshots/gold_snapshot.json";
import SilverSnapshot from "./snapshots/silver_snapshot.json";

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import store from "../store";
import { environment } from "../config";

import { log } from "./errorActions";

// const providerOptions = {
//   /* See Provider Options Section */
//   walletconnect: {
//     package: WalletConnectProvider, // required
//     options: {
//       infuraId: "1ea1a2aba620431d9cd75ee162c9681b" // required
//     }
//   }
// };

const web3Modal = new Web3Modal({
  network: "mainnet", // Optional. If using WalletConnect on xDai, change network to "xdai" and add RPC info below for xDai chain.
  cacheProvider: true, // optional
  theme: "light", // optional. Change to "dark" for a dark theme.
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        //bridge: "https://polygon.bridge.walletconnect.org",
        infuraId: "1ea1a2aba620431d9cd75ee162c9681b",
        rpc: {
          1: `https://eth-mainnet.alchemyapi.io/v2/CCbOYngbjTF-8JVpXyD7daLUOdq1BcUy`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
          // 4: `https://eth-rinkeby.alchemyapi.io/v2/q04ExuufEWWNmEm0qD8yPHoQSIu1Jv5u`
          // 42: `https://kovan.infura.io/v3/${INFURA_ID}`,
          // 100: "https://dai.poa.network", // xDai
        },
      },
    },
    //disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
  },
});

// const Web3 = require("web3");

// const NETWORK_ID = 1;
// const NETWORK_NAME = "Mainnet";

export const NETWORK_ID = 1;
const NETWORK_NAME = "Mainnet";

const SATOSHIVERSE_CONTRACT_ADDRESS = environment.SATOSHIVERSE_CONTRACT_ADDRESS;
const LEGIONNAIRE_CONTRACT_ADDRESS = environment.LEGIONNAIRE_CONTRACT_ADDRESS;
const APEMO_CONTRACT_ADDRESS = environment.APEMO_CONTRACT_ADDRESS;

export const connectWallet = (methodKey) => async (dispatch, getState) => {
  await web3Modal.clearCachedProvider();

  if (getState().error.msg !== "") dispatch(clearError());

  /*if (window.ethereum === undefined) {
    dispatch({
      type: SHOW_INSTALL_METAMASK,
    });
  } else */ if (!getState().block.address) {
    if (getState().block.showInstallMetamask) {
      dispatch({
        type: HIDE_INSTALL_METAMASK,
      });
    }

    dispatch({ type: WALLET_LOADING });

    try {
      let provider;
      try {
        provider = await web3Modal.connect();
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }

      // Save address in state
      await dispatch({
        type: SETUP_CONTRACT,
        payload: {
          refProvider: provider,
        },
      });

      // Get a Web3 instance for the wallet
      const web3 = new Web3(provider);

      // Get connected chain id from Ethereum node
      // const chainId = await web3.eth.getChainId();

      //  // Save address in state
      //  await dispatch({
      //     type: SETUP_CONTRACT,
      //     payload: {
      //       refProvider: provider
      //     },
      // });

      // Get list of accounts of the connected wallet
      const accounts = await web3.eth.getAccounts();

      // Subscribe to accounts change
      provider.on("accountsChanged", async (accounts) => {
        // `accountsChanged` event can be triggered with an undefined newAddress.
        // This happens when the user removes the Dapp from the "Connected
        // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
        // To avoid errors, we reset the dapp state
        if (accounts[0] === undefined) {
          return dispatch({
            type: RESET_STATE,
          });
        }

        if (!checkNetwork()) {
          dispatch(
            setError(
              `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
              null,
              null
            )
          );
        }

        await dispatch({ type: RESET_CONTRACT_DATA });
        dispatch(initialize(accounts[0]));
      });

      // Subscribe to chainId change
      provider.on("chainChanged", async (chainId) => {
        // We reset the dapp state if the network is changed
        // await dispatch({
        //   type: RESET_STATE,
        // });

        await dispatch({ type: RESET_CONTRACT_DATA });

        /* RINKEBY NETWORK */
        if (parseInt(chainId.replace("0x", "")) === NETWORK_ID) {
          dispatch(clearError());
        } else {
          dispatch(
            setError(
              `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
              null,
              null
            )
          );
        }

        const { address } = getState().block;

        if (address) {
          dispatch(initialize(address));
        } else {
          dispatch(connectWallet(address));
        }
      });

      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log("info: ", info);
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        console.log(error);

        return dispatch({
          type: RESET_STATE,
        });
      });

      // if (!checkNetwork()) {
      //   dispatch(
      //     setError(
      //       `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
      //       null,
      //       null
      //     )
      //   );
      // }

      return dispatch(initialize(accounts[0]));

      // const accounts = await window.ethereum.request({
      //   method: "eth_requestAccounts",
      // });
      // const address = accounts[0];

      // // Handle network change
      // window.ethereum.on("chainChanged", (networkId) => {
      //   // We reset the dapp state if the network is changed
      //   dispatch({
      //     type: RESET_STATE,
      //   });

      //   /* RINKEBY NETWORK */
      //   if (parseInt(networkId.replace("0x", "")) === NETWORK_ID) {
      //     dispatch(clearError());
      //   } else {
      //     dispatch(
      //       setError(
      //         `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
      //         null,
      //         null
      //       )
      //     );
      //   }

      //   if (getState().address) {
      //     dispatch(initialize(getState().address));
      //   } else {
      //     dispatch(connectWallet(getState().address));
      //   }
      // });

      // // Handle account switching
      // window.ethereum.on("accountsChanged", ([newAddress]) => {
      //   // `accountsChanged` event can be triggered with an undefined newAddress.
      //   // This happens when the user removes the Dapp from the "Connected
      //   // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
      //   // To avoid errors, we reset the dapp state
      //   if (newAddress === undefined) {
      //     return dispatch({
      //       type: RESET_STATE,
      //     });
      //   }

      //   /*dispatch({
      //     type: RESET_STATE,
      //   });*/

      //   if (!checkNetwork()) {
      //     dispatch(
      //       setError(
      //         `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
      //         null,
      //         null
      //       )
      //     );
      //   }

      //   dispatch(initialize(newAddress));
      // });

      // if (!checkNetwork()) {
      //   dispatch(
      //     setError(
      //       `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
      //       null,
      //       null
      //     )
      //   );
      // }

      // return dispatch(initialize(address));
    } catch (error) {
      console.log(error);

      dispatch(setError("ERROR: Could not connect to wallet", null, null));
      dispatch({
        type: WALLET_CONNECT_FAIL,
      });
    }
  }
};

const initialize = (address) => async (dispatch, getState) => {
  const { refProvider } = getState().block;
  log(`Initializing with address ${address}...`);

  // We first initialize ethers by creating a provider using window.ethereum
  const ethersProvider = new ethers.providers.Web3Provider(refProvider);
  // Get a Web3 instance for the wallet
  const web3 = new Web3(refProvider);

  const chainId = await web3.eth.getChainId();
  // console.log("chainId: ", chainId)
  //const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

  dispatch({
    type: WALLET_CONNECT_SUCCESS,
  });

  // let validAddress = await ethers.utils.getAddress(address);

  // Save address in state
  await dispatch({
    type: SETUP_CONTRACT,
    payload: {
      provider: ethersProvider,
      address: address.toLowerCase(),
      chainId: chainId,
    },
  });

  await dispatch({
    type: IS_FETCHING_DATA,
    payload: { isFetchingData: true },
  });
  await dispatch(checkWhitelistStatus("apeList"));
  await dispatch(checkWhitelistStatus("crewmenList"));
  await dispatch(checkWhitelistStatus("trevorList"));
  await dispatch(checkWhitelistStatus("hackataoList"));
  await dispatch(checkWhitelistStatus("publicAllowList"));
  await dispatch(checkWhitelistStatus("freeClaimList"));
  await dispatch({
    type: IS_FETCHING_DATA,
    payload: { isFetchingData: false },
  });

  if (checkNetwork()) {
    // When, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.
    const contract = new ethers.Contract(
      contractAddress.Token,
      TokenArtifact.abi,
      ethersProvider.getSigner(0)
    );

    const satoshiverseContract = new ethers.Contract(
      SATOSHIVERSE_CONTRACT_ADDRESS,
      SatoshiVerseArtifact.abi,
      ethersProvider.getSigner(0)
    );

    const legionnaireContract = new ethers.Contract(
      LEGIONNAIRE_CONTRACT_ADDRESS,
      LegionnaireArtifact.abi,
      ethersProvider.getSigner(0)
    );

    const apemoContract = new ethers.Contract(
      APEMO_CONTRACT_ADDRESS,
      ApemoArtifact,
      ethersProvider.getSigner(0)
    );

    // const web3 = new Web3(Web3.givenProvider);
    // const wethContract = new web3.eth.Contract(LegionnaireArtifact.abi, LEGIONNAIRE_CONTRACT_ADDRESS);

    // Get connected chain id from Ethereum node
    const wethContract = new web3.eth.Contract(
      LegionnaireArtifact.abi,
      LEGIONNAIRE_CONTRACT_ADDRESS
    );

    await dispatch({
      type: SETUP_CONTRACT,
      payload: {
        contract,
        satoshiverseContract,
        legionnaireContract,
        apemoContract,
        wethContract,
      },
    });

    await dispatch(checkTokenCounts());
  } else {
    dispatch(
      setError(
        `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
        null,
        null
      )
    );
  }
};

export const disconnectWallet = () => async (dispatch, getState) => {
  const { refProvider } = getState().block;
  log("Killing the wallet connection", refProvider);

  // TODO: Which providers have close method?
  if (refProvider && refProvider.close) {
    await refProvider.close();

    // If the cached provider is not cleared,
    // WalletConnect will default to the existing session
    // and does not allow to re-scan the QR code with a new wallet.
    // Depending on your use case you may want or want not his behavir.
    await web3Modal.clearCachedProvider();
  }

  dispatch({
    type: CLEAR_WHITELIST_STATUS,
  });

  return dispatch({
    type: RESET_STATE,
  });
};

export const checkNetwork = () => {
  const { chainId } = store.getState().block;
  log(
    `chainId ${chainId} === NETWORK_ID ${NETWORK_ID} ->`,
    chainId === NETWORK_ID
  );

  if (chainId === NETWORK_ID) {
    return true;
  }

  return false;

  /*
  if(window.ethereum) {
    if (window.ethereum.networkVersion === NETWORK_ID.toString()) {
      return true;
    }
  }

  return false;*/
};

export const fetchWhitelistStatus = () => async (dispatch, getState) => {
  const { address } = getState().block;
  if (address) {
    const wallet = {
      metamaskAccoundAddress: address,
    };

    try {
      const res = await axios.post(
        "https://hbiieiidn3.execute-api.us-east-2.amazonaws.com/dev/getLegionnaireWhitelist",
        JSON.stringify(wallet)
      );
      if (res.data.statusCode === 200) {
        dispatch({ type: SET_IS_WALLET_IN_WHITELIST });
        console.log("Successfully setted whitelist status!");
      } else {
        console.log("Not in whitelist ", res.data);
      }
    } catch (e) {
      console.log("ERROR: Could not fetch whitelist status!");
    }
  }
};

export const fetchPurchaseCount = () => async (dispatch, getState) => {
  const { provider, address, wethContract, tokensCount } = getState().block;

  if (address && provider) {
    try {
      const events = await wethContract.getPastEvents("Transfer", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });

      let maxPurchaseTokenId = -1;

      let from, to, tokenId;
      events.forEach((e) => {
        ({ from, to, tokenId } = e.returnValues);
        tokenId = parseInt(tokenId);
        // console.log(tokenId)

        if (from === "0x0000000000000000000000000000000000000000") {
          if (tokenId > 3300) {
            if (maxPurchaseTokenId < tokenId) {
              maxPurchaseTokenId = tokenId;
            }
          }
        }
      });

      console.log(tokensCount);
      dispatch({
        type: FETCH_TOKEN_DATA,
        payload: {
          tokensCount: {
            ...tokensCount,
            purchaseLeftCount:
              maxPurchaseTokenId > 0 ? 5555 - maxPurchaseTokenId : 6700,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    dispatch(setError("Connect to metamask!", null, null));
  }
};

export const fetchTokenCounts = () => async (dispatch, getState) => {
  const { provider, address, satoshiverseContract, tokensCount } =
    getState().block;

  console.log("Fetching token counts...");
  if (address && provider) {
    try {
      const signer = provider.getSigner(0);
      const genesis_count = await satoshiverseContract
        .connect(signer)
        .tokensCount(address, "genesis");
      const platinum_count = await satoshiverseContract
        .connect(signer)
        .tokensCount(address, "platinum");
      const gold_count = await satoshiverseContract
        .connect(signer)
        .tokensCount(address, "gold");
      const silver_count = await satoshiverseContract
        .connect(signer)
        .tokensCount(address, "silver");

      const purchasedSoFar = await satoshiverseContract
        .connect(signer)
        .purchasedSoFar(address);

      // console.log(purchasedSoFar)
      // console.log(`Genesis_count ${address}: `, parseInt(genesis_count));
      // console.log(`Platinum_count ${address}: `, parseInt(platinum_count));
      // console.log(`Gold_count ${address}: `, parseInt(gold_count));
      // console.log(`Silver_count ${address}: `, parseInt(silver_count));
      // console.log("purchasedSoFar: ", parseInt(purchasedSoFar));

      dispatch({
        type: FETCH_TOKEN_DATA,
        payload: {
          tokensCount: {
            ...tokensCount,
            genesis: parseInt(genesis_count),
            platinum: parseInt(platinum_count),
            // gold: GoldSnapshot[address] ? GoldSnapshot[address] : 0,
            // silver: SilverSnapshot[address] ? SilverSnapshot[address] : 0,

            gold: parseInt(gold_count),
            silver: parseInt(silver_count),
            purchasedSoFar: parseInt(purchasedSoFar),
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    dispatch(setError("Connect to metamask!", null, null));
  }
};

const fetchUnclaimedCount = () => async (dispatch, getState) => {
  const { address, legionnaireContract, tokensCount } = getState().block;
  if (address) {
    try {
      const approvals = {};

      const approvalEventFilter = await legionnaireContract.filters.Approval();
      const events = await legionnaireContract.queryFilter(
        approvalEventFilter,
        0,
        "latest"
      );
      console.log("# events: ", events.length);

      let owner, approved, tokenId;
      events.forEach((event, i) => {
        ({ owner, approved, tokenId } = event.args);
        approvals[tokenId] = approved;
      });

      Object.keys(approvals).forEach((tokenId) => {
        if (
          approvals[tokenId] === "0x0000000000000000000000000000000000000000"
        ) {
          delete approvals[tokenId];
        }
      });

      let unclaimedTokens = [];
      Object.keys(approvals).forEach((tokenId) => {
        if (approvals[tokenId].toLowerCase() === address.toLowerCase()) {
          unclaimedTokens.push(parseInt(tokenId));
        }
      });

      //console.log("approvals: ", approvals);
      console.log("unclaimedTokens: ", unclaimedTokens);

      dispatch({
        type: FETCH_TOKEN_DATA,
        payload: {
          tokensCount: {
            ...tokensCount,
            unclaimedTokens: unclaimedTokens,
          },
        },
      });

      // if(unclaimedTokens.length !== 1) {
      //   dispatch(setError("You are not authorized to claim any legionnaires yet!"));
      // }
    } catch (e) {
      console.log(e);
    }
  } else {
    dispatch(setError("Connect to metamask!", null, null));
  }
};

const fetchTokenBalances = () => async (dispatch, getState) => {
  const { address } = getState().block;
  if (address) {
    if (!checkNetwork()) {
      return dispatch(
        setError(
          `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
          null,
          null
        )
      );
    }

    await dispatch({
      type: FETCH_TOKEN_DATA,
      payload: {
        tokensBalance: {
          genesis: GenesisSnapshot[address] ? GenesisSnapshot[address] : 0,
          platinum: PlatinumSnapshot[address] ? PlatinumSnapshot[address] : 0,
          gold: GoldSnapshot[address] ? GoldSnapshot[address] : 0,
          silver: SilverSnapshot[address] ? SilverSnapshot[address] : 0,
        },
      },
    });
  } else {
    dispatch(setError("Connect to metamask!", null, null));
  }
};

const fetchTokenBalance = (token) => async (dispatch, getState) => {
  const { contractAddress, abi, tokenType, minter, tokenId } = NFTTokens[token];
  const { provider, address, tokensBalance } = getState().block;

  if (address && provider) {
    if (!checkNetwork()) {
      return dispatch(
        setError(
          `ERROR: Wrong network. Connect to ${NETWORK_NAME} network.`,
          null,
          null
        )
      );
    }

    const owners = {};
    const zeroAddress = "0x0000000000000000000000000000000000000000";

    const contract = new ethers.Contract(contractAddress, abi, provider);

    if (tokenType === "ERC721") {
      const eventFilter = await contract.filters.Transfer();
      const events = await contract.queryFilter(eventFilter);

      let from, to, tokenId;
      events.forEach((event, i) => {
        ({ from, to, tokenId } = event.args);
        tokenId = tokenId.toNumber();

        if (!owners[from]) owners[from] = 0;
        owners[from] -= 1;

        if (!owners[to]) owners[to] = 0;
        owners[to] += 1;
      });
    } else if (tokenType === "ERC1155") {
      const eventFilter = await contract.filters.TransferSingle();
      const events = await contract.queryFilter(eventFilter);

      let operator, from, to, id, value;
      events.forEach((event, i) => {
        ({
          _operator: operator,
          _from: from,
          _to: to,
          _id: id,
          _value: value,
        } = event.args);

        id = id.toNumber();
        value = value.toNumber();

        if (id !== tokenId) return;

        if (!owners[from]) owners[from] = 0;
        owners[from] -= value;

        if (!owners[to]) owners[to] = 0;
        owners[to] += value;
      });
    }

    // Blacklist minter
    if (owners[minter]) delete owners[minter];

    // Blacklist zero address
    if (owners[zeroAddress]) delete owners[zeroAddress];

    await dispatch({
      type: FETCH_TOKEN_DATA,
      payload: {
        tokensBalance: {
          ...tokensBalance,
          [token]: owners[address] ? owners[address] : 0,
        },
      },
    });
  } else {
    dispatch(setError("Connect to metamask!", null, null));
  }
};

export const showChangeNetwork = () => () => {
  return {
    type: SHOW_CHANGE_NETWORK,
  };
};

export const hideChangeNetwork = () => () => {
  return {
    type: HIDE_CHANGE_NETWORK,
  };
};

export const setStatusMsg = (msg) => {
  return {
    type: SET_STATUS_MSG,
    payload: { statusMsg: msg },
  };
};

export const setSuccessMsg = (msg) => {
  return {
    type: SET_SUCCESS_MSG,
    payload: { successMsg: msg },
  };
};

export const clearStatusMsg = () => {
  return {
    type: CLEAR_STATUS_MSG,
  };
};

export const clearSuccessMsg = () => {
  return {
    type: CLEAR_SUCCESS_MSG,
  };
};

const waitXSeconds = (s) =>
  new Promise((resolve, reject) => setTimeout(() => resolve(), s * 1000));

export const claim = (quantity) => async (dispatch, getState) => {
  await dispatch({ type: CLEAR_TRANSACTION_DATA });
  console.log("Claiming...");

  const { provider, address, satoshiverseContract } = getState().block;

  if (address && provider) {
    if (quantity === "") return dispatch(setStatusMsg("Fill out quantity."));

    let count = parseInt(quantity);
    if (count <= 0)
      return dispatch(setStatusMsg("Quantity has to be greater than zero."));

    try {
      dispatch(setStatusMsg("Please, wait a moment..."));
      //await waitXSeconds(10);

      const tx = await satoshiverseContract.claim(count);
      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionData: tx,
        },
      });

      const receipt = await tx.wait();
      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionReceipt: receipt,
        },
      });

      console.log("receipt: ", receipt);

      dispatch(
        setStatusMsg(
          `Successfully claimed ${count} legionnaire${count > 1 ? "s" : ""}!`
        )
      );

      await dispatch({
        type: IS_FETCHING_DATA,
        payload: { isFetchingData: true },
      });
      await dispatch(fetchTokenCounts());
      await dispatch({
        type: IS_FETCHING_DATA,
        payload: { isFetchingData: false },
      });
    } catch (error) {
      console.log(error);

      let errorStatusMsg = "";
      let strError = error.message;
      if (strError.includes("execution reverted: ")) {
        strError = strError.split("execution reverted: ");
        if (strError.length >= 2) {
          strError = strError[1].split('"');
          if (strError.length >= 1) {
            errorStatusMsg = strError[0];
          }
        }
      }

      if (strError.includes("MetaMask Tx Signature: ")) {
        strError = strError.split("MetaMask Tx Signature: ");
        if (strError.length >= 2) {
          errorStatusMsg = strError[1];
        }
      }

      if (error.message.includes("insufficient funds")) {
        errorStatusMsg = "Insufficient funds";
      }

      if (errorStatusMsg !== "") {
        dispatch(setStatusMsg("ERROR: " + errorStatusMsg));
      } else {
        dispatch(setStatusMsg("ERROR: Unknown error."));
      }
    }
  } else {
    dispatch(setStatusMsg("Connect to metamask!"));
  }
};

export const buy = (quantity) => async (dispatch, getState) => {
  await dispatch({ type: CLEAR_TRANSACTION_DATA });
  console.log("Buying...");

  const { provider, address, satoshiverseContract } = getState().block;
  if (address && provider) {
    if (quantity === "") return dispatch(setStatusMsg("Fill out quantity."));

    let count = parseInt(quantity);
    if (count <= 0)
      return dispatch(setStatusMsg("Quantity has to be greater than zero."));

    try {
      dispatch(setStatusMsg("Please, wait a moment..."));
      //await waitXSeconds(10);

      const tx = await satoshiverseContract.purchase(count, {
        value: ethers.utils.parseEther(`${0.1 * count}`),
      });
      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionData: tx,
        },
      });
      const receipt = await tx.wait();
      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionReceipt: receipt,
        },
      });

      console.log(receipt);
      dispatch(
        setStatusMsg(
          `Successfully bought ${count} legionnaire${count > 1 ? "s" : ""}!`
        )
      );

      await dispatch({
        type: IS_FETCHING_DATA,
        payload: { isFetchingData: true },
      });
      await dispatch(fetchTokenCounts());
      await dispatch(fetchPurchaseCount());
      await dispatch({
        type: IS_FETCHING_DATA,
        payload: { isFetchingData: false },
      });
    } catch (error) {
      console.log(error);

      let errorStatusMsg = "";
      let strError = error.message;
      if (strError.includes("execution reverted: ")) {
        strError = strError.split("execution reverted: ");
        if (strError.length >= 2) {
          strError = strError[1].split('"');
          if (strError.length >= 1) {
            errorStatusMsg = strError[0];
          }
        }
      }

      if (strError.includes("MetaMask Tx Signature: ")) {
        strError = strError.split("MetaMask Tx Signature: ");
        if (strError.length >= 2) {
          errorStatusMsg = strError[1];
        }
      }

      if (error.message.includes("insufficient funds")) {
        errorStatusMsg = "Insufficient funds";
      }

      if (errorStatusMsg !== "") {
        dispatch(setStatusMsg("ERROR: " + errorStatusMsg));
      } else {
        dispatch(setStatusMsg("ERROR: Unknown error."));
      }
    }
  } else {
    dispatch(setStatusMsg("Connect to metamask!"));
  }
};

export const claimUnclaimedToken = () => async (dispatch, getState) => {
  await dispatch({ type: CLEAR_TRANSACTION_DATA });
  console.log("Claiming unclaimed token...");

  const { provider, address, legionnaireContract, tokensCount } =
    getState().block;

  if (address && provider) {
    if (
      !tokensCount["unclaimedTokens"] ||
      tokensCount["unclaimedTokens"].length <= 0
    ) {
      return dispatch(setStatusMsg("There are not unclaimed tokens to claim."));
    }

    try {
      dispatch(setStatusMsg("Please, wait a moment..."));
      console.log(
        "Claiming unclaimed token #",
        tokensCount["unclaimedTokens"][0]
      );

      const tx = await legionnaireContract.transferFrom(
        "0xa5a56dbb9bd7304b0fbf20368d88ea017760eb06",
        address,
        tokensCount["unclaimedTokens"][0]
      );
      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionData: tx,
        },
      });

      const receipt = await tx.wait();
      await dispatch({
        type: SET_TRANSACTION_DATA,
        payload: {
          transactionReceipt: receipt,
        },
      });

      console.log("receipt: ", receipt);

      dispatch(setStatusMsg(`Successfully claimed 1 legionnaire!`));

      await dispatch({
        type: IS_FETCHING_DATA,
        payload: { isFetchingData: true },
      });
      await dispatch(fetchUnclaimedCount());
      await dispatch({
        type: IS_FETCHING_DATA,
        payload: { isFetchingData: false },
      });
    } catch (error) {
      console.log(error);

      let errorStatusMsg = "";
      let strError = error.message;
      if (strError.includes("execution reverted: ")) {
        strError = strError.split("execution reverted: ");
        if (strError.length >= 2) {
          strError = strError[1].split('"');
          if (strError.length >= 1) {
            errorStatusMsg = strError[0];
          }
        }
      }

      if (strError.includes("MetaMask Tx Signature: ")) {
        strError = strError.split("MetaMask Tx Signature: ");
        if (strError.length >= 2) {
          errorStatusMsg = strError[1];
        }
      }

      if (error.message.includes("insufficient funds")) {
        errorStatusMsg = "Insufficient funds";
      }

      if (errorStatusMsg !== "") {
        dispatch(setStatusMsg("ERROR: " + errorStatusMsg));
      } else {
        dispatch(setStatusMsg("ERROR: Unknown error."));
      }
    }
  } else {
    dispatch(setStatusMsg("Connect to metamask!"));
  }
};

/*export const setClaim = (isEnabled) => async (dispatch, getState) => {
  return dispatch({
    type: SET_CLAIM,
    payload: {
      isClaimEnabled: isEnabled
    }
  })
}

export const setPurchase = (isEnabled) => async (dispatch, getState) => {
  return dispatch({
    type: SET_PURCHASE,
    payload: {
      isPurchaseEnabled: isEnabled
    }
  })
}

export const setPurchaseWhitelistOnly = (isEnabled) => async (dispatch, getState) => {
  return dispatch({
    type: SET_PURCHASE_WHITELIST_ONLY,
    payload: {
      isPurchaseWhitelistOnlyEnabled: isEnabled
    }
  })
}*/

export const mint = () => async (dispatch, getState) => {
  dispatch(setError("Minting...", null, null));

  /*const address = getState().block.address;
  if (!address) {
    dispatch(setError("Connect to metamask", null, null));
    return dispatch({ type: SHOW_CONNECT_METAMASK });
  }*/

  try {
    const tx = await getState().block.contract.sayHello();
    const receipt = await tx.wait();
    for (const event of receipt.events) {
      if (event.event !== "Hello") {
        continue;
      }
      console.log("Event found: ", event);
    }
    return dispatch(setError("Minting successful!", null, null));
  } catch (error) {
    console.log(error);
    return dispatch(setError("Minting error!", null, null));
  }
};
