import React from "react";
import "./GuestsPartItem.css";

const GuestsPartItem = (props) => {
  return (
    <li className="PartGuestsItem">
      <img className="GuestsIcon" src={props.imgUrl} />
      <img className="GuestsLabelImg" src={props.labelImgUrl} />
      {/* <span className="-highlight-h2">{props.label}</span> */}
    </li>
  );
};

export default GuestsPartItem;
