import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part10.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part10OpenComic.png`

const Part10 = (props) => {
  return (
    <BasicLoreModal
      image={image}
    >
      <div className="LoreBlock">
        <p>
          Satoshi knows he must devise a plan to lead the greatest global
          rebellion the world has seen. And to do so, he will need help. While
          the Defenders Of Fiat have established their headquarters in
          Washington D.C., only a coordinated attack on their control centers
          all around the world will be enough to defeat them.
        </p>
        <p>
          Satoshi begins by identifying 100 trusted thought leaders from all
          around the world who have demonstrated their devotion to blockchain
          technology during his nearly twenty-year absence. He calls them “The
          100.” They consist of artists, developers, builders, community
          members, and others who have been responsible for keeping the dream of
          blockchain and decentralization alive despite the many great
          challenges. Each of the 100 will be tasked with leading Satoshi’s
          “Legions'' in the 100 cities where the D.O.F. have established
          strongholds and assembling a group of 100 Legionnaires to join them in
          battle.
        </p>
        <p>
          One by one, Satoshi secretly visits these leaders. Each is bestowed
          with a golden mask and 100 small metal bars to give to their most
          trusted allies. Satoshi instructs the leaders that when the time
          comes, Satoshi will deliver a high tech mask to each Legionnaire who
          possesses a bar. The masks will also ​​allow the Legionnaires to
          maintain anonymity by combatting the advanced surveillance technology
          the Defenders Of Fiat have implemented to oppress them. The masks also
          allow the Legionnaires to communicate with Satoshi and each other
          undetected and quickly learn valuable fighting skills they will need
          in the battle against their oppressors.
        </p>
        <p>
          Satoshi then vanishes again to finish creating the masks. As he works,
          the 100 begin to assemble their Legionnaires. In dimly lit allies and
          secret meetings across the globe, the 100 begin to distribute the 100
          metal bars to their most trusted allies. Slowly, a sense of hope
          starts to spread amongst the people as they patiently wait to answer
          Satoshi’s call.
        </p>
        <p>
          After several weeks, the day finally arrives. Satoshi flies around the
          world secretly delivering masks to the 10,000 Legionnaires who will
          join him in the rebellion. With each mask is a note. “We are all one.
          But each of us is unique. This mask is your canvas. Show them who you
          are!”
        </p>
        <p>
          A new fire has been lit in the hearts of the people. Their sense of
          purpose and self worth has been restored, and for the first time in
          years, a light at the end of the tunnel has shown itself. The people
          begin to decorate their masks, each one unique, as they patiently wait
          for the signal from Satoshi to take to the streets and begin the
          fight. They know the odds are against them and the battle will not be
          easy, but when that time comes, they will stand ready!
        </p>
        <p className="--center">[To be continued]</p>
      </div>

    </BasicLoreModal>
    // <div className="Part10" style={{ ...props.customStyle }}>
    //   <div className="LorePart-10 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part10Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>The 100</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part 10_ The 100.jpg"></img>
    //     <p>
    //       Satoshi knows he must devise a plan to lead the greatest global
    //       rebellion the world has seen. And to do so, he will need help. While
    //       the Defenders Of Fiat have established their headquarters in
    //       Washington D.C., only a coordinated attack on their control centers
    //       all around the world will be enough to defeat them.
    //     </p>
    //     <p>
    //       Satoshi begins by identifying 100 trusted thought leaders from all
    //       around the world who have demonstrated their devotion to blockchain
    //       technology during his nearly twenty-year absence. He calls them “The
    //       100.” They consist of artists, developers, builders, community
    //       members, and others who have been responsible for keeping the dream of
    //       blockchain and decentralization alive despite the many great
    //       challenges. Each of the 100 will be tasked with leading Satoshi’s
    //       “Legions'' in the 100 cities where the D.O.F. have established
    //       strongholds and assembling a group of 100 Legionnaires to join them in
    //       battle.
    //     </p>
    //     <p>
    //       One by one, Satoshi secretly visits these leaders. Each is bestowed
    //       with a golden mask and 100 small metal bars to give to their most
    //       trusted allies. Satoshi instructs the leaders that when the time
    //       comes, Satoshi will deliver a high tech mask to each Legionnaire who
    //       possesses a bar. The masks will also ​​allow the Legionnaires to
    //       maintain anonymity by combatting the advanced surveillance technology
    //       the Defenders Of Fiat have implemented to oppress them. The masks also
    //       allow the Legionnaires to communicate with Satoshi and each other
    //       undetected and quickly learn valuable fighting skills they will need
    //       in the battle against their oppressors.
    //     </p>
    //     <p>
    //       Satoshi then vanishes again to finish creating the masks. As he works,
    //       the 100 begin to assemble their Legionnaires. In dimly lit allies and
    //       secret meetings across the globe, the 100 begin to distribute the 100
    //       metal bars to their most trusted allies. Slowly, a sense of hope
    //       starts to spread amongst the people as they patiently wait to answer
    //       Satoshi’s call.
    //     </p>
    //     <p>
    //       After several weeks, the day finally arrives. Satoshi flies around the
    //       world secretly delivering masks to the 10,000 Legionnaires who will
    //       join him in the rebellion. With each mask is a note. “We are all one.
    //       But each of us is unique. This mask is your canvas. Show them who you
    //       are!”
    //     </p>
    //     <p>
    //       A new fire has been lit in the hearts of the people. Their sense of
    //       purpose and self worth has been restored, and for the first time in
    //       years, a light at the end of the tunnel has shown itself. The people
    //       begin to decorate their masks, each one unique, as they patiently wait
    //       for the signal from Satoshi to take to the streets and begin the
    //       fight. They know the odds are against them and the battle will not be
    //       easy, but when that time comes, they will stand ready!
    //     </p>
    //     <p className="--center">[To be continued]</p>
    //   </div>
    // </div>
  );
};

export default Part10;
