import React, { Component } from "react";
import "./PreferenceDropdown.css";

import onClickOutside from "react-onclickoutside";

const preferences = ["Yes.", "No."];

class PreferenceDropdown extends Component {
  state = {
    isOpen: false,
  };

  handleClickOutside = (e) => {
    this.setState({ isOpen: false });
  };

  toggleDropdown = (e) => {
    if (e) e.preventDefault();

    this.setState({ isOpen: !this.state.isOpen });
    this.props.setPreferenceErrorMsg("");
  };

  render() {
    return (
      <div className="-Dropdown">
        <div className="-InputContainer">
          <button
            className="-Text"
            name="preference"
            style={{
              marginBottom: "0rem",
              color: this.props.preferenceErrorMsg ? "red" : "black",
            }}
            onClick={this.toggleDropdown}
          >
            {this.props.preference}
          </button>
          <img
            className="dropdown-arrow-icon"
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/arrow-icon.png`}
            style={{
              transform: this.state.isOpen
                ? "translateY(-50%) rotateZ(180deg)"
                : "translateY(-50%) rotateZ(0deg)",
            }}
          ></img>
        </div>
        <ul
          className="-Dropdown-List"
          style={{ display: this.state.isOpen ? "block" : "none" }}
        >
          {preferences.map((preference, i) => (
            <li
              key={i}
              onClick={(e) => {
                this.props.onClickPreference(preference);
                this.toggleDropdown();
              }}
              style={
                this.props.preference === preference
                  ? { background: "#170336", color: "white" }
                  : {}
              }
            >
              {preference}
            </li>
          ))}
        </ul>
        {this.props.preferenceErrorMsg ? (
          <span className="form-error-msg">
            {this.props.preferenceErrorMsg}
          </span>
        ) : null}
      </div>
    );
  }
}

export default onClickOutside(PreferenceDropdown);
