import React from "react";
import "./SatoshiLegionsPart.css";

import { Link } from "react-router-dom";

const SatoshiLegionsPart = () => {
  return (
    <div className="PartSatoshiLegions">
      <div className="PartSatoshiLegionsContent">
        <p className="-p1">
          While there are many ways to become part of the Satoshiverse,
          collecting special Satoshiverse Avatar digital collectibles will give
          you exclusive benefits throughout the Satoshiverse ecosystem including
          within the Legions 2029 play-and-earn game!
        </p>
        <br />
        <Link
          to="/avatars"
          className="btn-link"
          style={{ marginBottom: "4rem", marginTop: "2rem" }}
        >
          Learn more
        </Link>
      </div>
      <h1>Game</h1>
      <div className="SatoshiLegionsBannerContainer">
        <img
          className="SatoshiLegions-Framework-top"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/Frame_1.png`}
        />
        <video autoPlay muted loop className="video-satoshiverse-reel">
          <source
            src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Video/Gameplay.mp4`}
            type="video/mp4"
          />
        </video>
        <img
          className="SatoshiLegions-Framework-bottom"
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webSatoshi/Home/frame_2.png`}
        />
      </div>
      <div className="PartSatoshiLegionsContent" style={{ marginTop: "3rem" }}>
        <p className="-p1">
          “Legions 2029” is a first-of-its-kind free-to-play PC game that mixes
          the progression elements of iconic RPGs like Diablo and World of
          Warcraft with the dynamic combat experience of third person
          hack-and-slash games like God of War. The game is a high fidelity
          experience developed on Unreal Engine 5. It offers a unique Roguelike
          gameplay loop where players venture deep into “the Outskirts,”
          battling formidable enemies, and earning valuable loot. Every run is
          uniquely challenging with randomly arranged maps, and players can
          compete for rewards and prestige in the Timed Challenge Mode after
          completing the Campaign Mode. Additionally, Legions 2029 features a
          dynamic open player economy, allowing gamers to have full control and
          flexibility over their in-game progress and items.
        </p>
        <a
          href="https://legions2029.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-link"
          style={{ marginBottom: "2rem", marginTop: "2rem" }}
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default SatoshiLegionsPart;
