import React from "react";

import { Legions } from "components/HomeSection/components/NewLegions/Legions";
import NavBar from "components/NavBar/NavBar";
import SatoshiSocial from "components/SatoshiSocial/SatoshiSocial";

import "./PrizeSection.css";

import Footer from "../Footer/Footer";
import NewLegions from "components/HomeSection/components/NewLegions/NewLegions";

const PrizeSection = (props) => {
  return (
    <div className="JS-Scroll">
      <NavBar
        width={props.width}
        toggleBurger={props.toggleBurger}
        closeBurger={props.closeBurger}
        isBurgerOpen={props.isBurgerOpen}
        location={props.location}
      />
      <SatoshiSocial />
      {/* <Legions changeRoute={props.changeRoute} /> */}
      <NewLegions width={props.width} />
      <Footer background="#010005" />
    </div>
  );
};

export default PrizeSection;
