import React from "react";
import "./StoreNav.css";

import { Link, useHistory } from "react-router-dom";

function StoreNav() {
  const history = useHistory();
  const { pathname } = history.location;

  return (
    <nav className="StoreNav">
      <ul className="StoreNavList">
        <li className="StoreNavItem">
          <Link
            className={`${
              pathname === "/marketplace/legionnaires" ? "-marked" : ""
            }`}
            to="/marketplace/legionnaires"
          >
            Legionnaires
          </Link>
        </li>
        <li className="StoreNavItem">
          <Link
            className={`${
              pathname === "/marketplace/apemo-army" ? "-marked" : ""
            }`}
            to="/marketplace/apemo-army"
          >
            Apemo Army
          </Link>
        </li>
      </ul>
      <div className="StoreNavSeparator"></div>
    </nav>
  );
}

export default StoreNav;
