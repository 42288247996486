import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part13.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore_Part13.png`

const Part13 = (props) => {
  return (
    <BasicLoreModal
      image={image}
    >
      <div className="LoreBlock">
        <p>
          It had been several years since the terrible day when Apemo became separated from his brother and lost his parents to the pack of jaguars. As soon as Apemo learned their language, he convinced the pirates to go back to the island and look for his brother. For months they searched, but found no signs. However, several years later as Captain Apemo became a name well known on the high seas, rumors began to spread of another pirate ape. One that resembled Apemo but had been mutated in horrible ways. The rumors went that another group of pirates had found Apemo’s brother and were carrying out experiments on a secret island when one of the experiments went terribly wrong.
        </p>
        <p>
          Immediately, it became Captain Apemo’s mission to verify the truth of these rumors. On one hand, Apemo had a renewed sense of hope of being reunited with his brother once again. On the other, Apemo was terrified of what he might find. Nevertheless, Apemo rallied his crewmen and set sail for the island to find his brother and exact revenge on the pirates for any harm they caused.
        </p>
        <p>
          The plan was simple, Zhao would lead Mav, Cara, Simon, and Freyja in a scouting party to investigate the encampment. Then Apemo would follow with the rest of the crew for the raid. But the heroes were not prepared for what they were about to find. As the heroes navigated through the jungle they saw what appeared to be a warehouse hidden in the foliage. But this was not like the island structures they were familiar with. The building looked like it belonged in a city, not an island. Stamped on the side of the building was a symbol that the heroes were unfamiliar, with but would soon be known around the world - the soon to be infamous iconography of Project Providence and the Defenders of Fiat!
        </p>
        <p>
          Just as soon as the heroes saw the building, they were detected by its inhabitants. A cloud of drones flew out of the building and moved quickly towards them. Before they could react, it was too late. Blue beams shot out of the drones, incapacitating the heroes. Seeing the blue beams from the mast of the ship, Apemo knew something was wrong and charged toward them, telling his loyal crewmen to stay back with the ship. But by the time he arrived, it was too late. He saw a group of heavily armored men dragging his companions into the building as the swarm of drones stood guard. 
        </p>
        <p>
          Captain Apemo did not know what to do. He could not approach them alone after seeing five of his best fighters go down so easily. But he also could not just stand by and do nothing. He returned to his ship and told the others what had happened. His crewmen were shocked and angered and ready to charge into battle to save their brothers and sisters. But Apemo could not stand the thought of losing more of his crew after seeing the strength his enemy possessed.
        </p>
        <p>
        For days, Apemo was in a state of total despair. He paced around his ship desperately trying to think of a plan that would allow him to retrieve his companions. All hope seemed to be lost, until...
        </p>
      </div>
    </BasicLoreModal>
  );
};

export default Part13;
