import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import LoreDropdown from "../LoreDropdown/LoreDropdown";

class LoreNavItem extends Component {
  handleClickOutside = (e) => {
    this.props.closeLoreDropdown();
  };

  render() {
    return (
      <li
        className="--navitem"
        style={{ position: "relative" }}
        
      >
        <span onClick={(e) => {
          e.preventDefault();
          this.props.changeRoute("Lore", "/lore");
        }}>Lore</span>
        <img className="lore-dropdown-icon" src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/arrow-icon.png`} 
          onClick={(e) => {
            this.props.toggleLoreDropdown();
          }}
        />
        <LoreDropdown
          isDropdownOpen={this.props.isLoreDropdownOpen}
          toggleDropdown={this.props.toggleLoreDropdown}
          closeDropdown={this.props.closeLoreDropdown}
          onLoreClick={this.props.onLoreClick}
          width={this.props.width}
          closeBurger={this.props.closeBurger}
        />
      </li>
    );
  }
}

export default onClickOutside(LoreNavItem);
