import React, { useRef } from 'react';
import styled from 'styled-components';
import { Div } from 'globalStyles';
import { IoMdArrowDropright } from 'react-icons/io'
import { claimList } from './TableObjects';
import { ReactComponent as CartButton } from "./buton_buy.svg"
import { Title, Text, Background } from './styles';

export const FreeClaim = ({ isMobile, heightMain }) => {
  const ref = useRef(null);

  const scrollToTop = () => {
    ref.current.scrollIntoView();
    // console.log('Scroll')
  }
  return (
    <>
      <Title isMobile={isMobile} title="Free Claim list" mt={20} mb={30} />
      <Background >
      <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
        <Text size="20" letterSpacing="3px" align="left" margin="0 15%" isCenter>
          Holders of the pieces below will be able to claim differing amounts of free Apemo Army avatars.
        </Text>
      </Div>
        <MainDiv isFlex align="flex-end" justify={isMobile && 'flex-start'}>
          {
            isMobile && (
              <IoMdArrowDropright className="arrowTable" size={48} color="#4CBBF6" />
            )
          }
          <Div isFlex direction="column">
            <ImagBar1 src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/data_017.png`} />
            <ImagBar2 src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/0AA.png`} />
            <MapImage src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/map.png`} />
          </Div>
          <Table ref={ref}>
            <tr>
              <TableHeader width="0px" background="transparent"></TableHeader>
              <TableHeader isMobile={isMobile} width="41%">piece</TableHeader>
              <TableHeader isMobile={isMobile} width="30%">free claim entitlement</TableHeader>
              <TableHeader isMobile={isMobile} width="15%">buy</TableHeader>
            </tr>
            {claimList.map((item, i) => (
              <tr key={i}>
                <ImageTable image={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/${item.image}.png`} />
                <TableD isMobile={isMobile}>
                  {item.piece}
                  <ImageBarra src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/barra_piece.png`} />
                </TableD>
                <TableDClaim isMobile={isMobile}>
                  {item.entitlement}
                </TableDClaim>
                <ButtonBuy>
                  <a href={item.buyLink} target="_blank" rel='noreferrer'>
                    <ButtonBuyImg />
                  </a>
                </ButtonBuy>
              </tr>
            )
            )}
          </Table>
          <Button type='button' onClick={() => scrollToTop()}>
            <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/boton_up.png`} alt="upButton" />
          </Button>
        </MainDiv>
      </Background>
    </>
  )
}
const MainDiv = styled(Div)`
  /* height: 800px; */
  overflow: auto;
  position: relative;
  .arrowTable {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10%;
    margin-top: 5%;
    z-index: 2;
    animation: MoveLeftRight 1.2s linear infinite;
    @keyframes MoveLeftRight {
      0%, 100% {
        transform: translateX(-40px);
      }
      50% {
        transform: translateX(0);
      }
      }
  }
`

const ImageTable = ({ image }) => {
  return (
    <ImageContainer>
      <Image src={image} />
      <CrossContainer>
        +
      </CrossContainer>
    </ImageContainer>
  )
}

const ButtonBuyImg = styled(CartButton)`
  width: 80%;
  height: 80%;
  .cls-3{
    &:hover{
      fill: #C28917
    }
  }
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`



const MapImage = styled.img`
  margin-left: -42px;
  margin-top: 30px;
  height: 600px;
`

const ImagBar1 = styled.img`
  width: 40px;
`
const ImagBar2 = styled.img`
  width: 50px;
  margin-top: 20px;
`

const Table = styled.table`
  
  width: 100%;
  height: auto;
  border-spacing:15px;
  border-collapse: separate;
  margin: 3% 0%;
`

const TableHeader = styled.th`
  width: ${(props) => (props.width && props.width)};
  font-size: ${(props) => (props.isMobile ? '16px' : '25px')};
  font-family: dinNext;
  background-color: ${(props) => (props.background ? props.background : '#4CBBF6')};
  border: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-radius: 7px;
  padding: 5px 10px;
  margin: 0 5px;
  color: black;
  font-weight: 500;
`;

const TableD = styled.td`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  height: 145px;
  width: ${(props) => (props.width && props.width)};
  font-size: ${(props) => (props.isMobile ? '16px' : '25px')};
  font-family: dinNext;
  border-radius: 5px;
  border: 1px solid #4CBBF6;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 3px;
  color: #4CBBF6;
  font-weight: bold;
`;
const TableDClaim = styled.td`
  padding: 20px;
  height: 145px;
  width: ${(props) => (props.width && props.width)};
  font-size: ${(props) => (props.isMobile ? '20px' : '40px')};
  font-family: dinNext;
  border-radius: 5px;
  border: 1px solid #4CBBF6;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  color: #4CBBF6;
  font-weight: bold;
`;

const ImageBarra = styled.img`
  width: 40%;
  object-fit: contain;
`;

const ImageContainer = styled.td`
  position: relative;
  min-width: 100px;
  min-height: 100px;
  width: 145px;
  height: 145px;
  border: 1px solid #4CBBF6;
  border-radius: 5px;
`;

const ButtonBuy = styled.td`
  position: relative;
  width: 145px;
  height: 145px;
  border: 1px solid #4CBBF6;
  border-radius: 5px;
  object-position: center center;
`

const CrossContainer = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  top:-13px;
  left: -13px;
  font-size: 40px;
  color: #4CBBF6;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 5px;
  border-radius: 5px;
`