import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part1.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/SatoshiLoreScene01.jpg`

const Part1 = (props) => {
  return (
    <BasicLoreModal
      image={image}
    >
      <div className="LoreBlock">
        <p>
          The year is 2007. For years corporate greed has fueled aggressive
          lending practices, inflating an ever growing housing bubble set to
          explode on the world economy. The people are happy to snatch up cheap
          loans they cannot afford and invest their money into a housing market
          whose foundation is quickly withering away. The American dream. Houses
          on credit. Meals on credit. Life on credit. A world too good to be
          true.
        </p>
        <p>
          And then abruptly it ends. The bubble bursts. The impacts are felt
          around the world. From Times Square to Tokyo, stock markets crash,
          homes are lost, lives are torn apart. The greedy become the desperate.
          The uninformed become the exploited. But at the top, corruption
          inspires a solution to save the culprits. A bailout! A seemingly
          simple solution with drastic consequences that will be borne by
          generations to come. Print more money. Save those who were once
          considered “too big to fail.” Recession. Depression. Regression. The
          consequences of our greed. They call it “the Great Recession.”
        </p>
        <p>
          As the economy continues to crumble, all hope is not lost for the
          people. In an undisclosed location hidden in the shadows, a mysterious
          figure known only by his pseudonym Satoshi Nakamoto works on a
          solution: a digital currency secured by a virtual ledger living on the
          most powerful decentralized network of computers in the world. An idea
          that will eventually change the world as we know it. Blockchain
          technology and its flagship currency, Bitcoin!
        </p>
        <p>
          As the world reels from the impact of the Great Recession, Satoshi
          completes his creation and in January 2009, the Bitcoin Network goes
          live. For the next two years, news of the new network quickly spreads
          across the web, with the power of the network growing steadily each
          day. Adoption begins. From basements to warehouses all around the
          world, people of all ages and races begin to anonymously contribute
          their computing power to the Bitcoin Network. Then, mysteriously, one
          day Satoshi vanishes, leaving the people to carry his vision forward.
          Emboldened by the gift Satoshi has given them and with the powerful
          idea of financial freedom burned into their minds and hearts, the
          people press on. New innovations in Blockchain technology emerge,
          accelerating the pace of adoption.
        </p>
        <p>
          Unfortunately, like any great idea, the flaws of man lead people to
          misuse Bitcoin. The anonymity of the network provides a cloak for
          criminals and other nefarious beings to use Bitcoin to transact on the
          dark web with impunity. Public opinion sours and governments, with the
          financial institutions pulling their strings, seize the opportunity to
          publicly denounce the new currency.
        </p>
        <p>
          But Satoshi’s followers are not deterred. They continue to innovate
          like never before. Developers from around the world take Satoshi’s
          idea and build upon it. New blockchains are created. The seed for a
          technological renaissance begins to sprout and the internet as we know
          it changes. The technology causes a new age of digital art, music, and
          gaming to begin! Satoshi’s vision begins to take shape. The world
          starts to see blockchain’s potential. Even governments begin to accept
          the new technology. A bright future is within reach. But the world
          could have never predicted what would happen next...
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part1" style={{ ...props.customStyle }}>
    //   <div className="LorePart-1 LoreFill"></div>
    //   {/* <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part1Banner.png"></img> */}
    //   <div className="LoreBlock">
    //     <p style={{ marginTop: "1.5rem" }}>
    //       <i>
    //         * This is a work of fiction. Any similarity to actual persons,
    //         living or dead, is purely coincidental. Some parts of the story are
    //         loosely based on real events, but in many cases, incidents,
    //         characters and timelines have been changed or are entirely
    //         fictitious for dramatic purposes.
    //       </i>
    //     </p>
    //     <p>
    //       <i>
    //         * The names of <strong>President Morgan</strong>{" "}
    //         and <strong>Alpha Corporation</strong> have not been decided yet and will be
    //         voted by the community.
    //       </i>
    //     </p>
    //     <h1>The Birth Of Blockchain</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/SatoshiLoreScene01.jpg"></img>
    //     <p>
    //       The year is 2007. For years corporate greed has fueled aggressive
    //       lending practices, inflating an ever growing housing bubble set to
    //       explode on the world economy. The people are happy to snatch up cheap
    //       loans they cannot afford and invest their money into a housing market
    //       whose foundation is quickly withering away. The American dream. Houses
    //       on credit. Meals on credit. Life on credit. A world too good to be
    //       true.
    //     </p>
    //     <p>
    //       And then abruptly it ends. The bubble bursts. The impacts are felt
    //       around the world. From Times Square to Tokyo, stock markets crash,
    //       homes are lost, lives are torn apart. The greedy become the desperate.
    //       The uninformed become the exploited. But at the top, corruption
    //       inspires a solution to save the culprits. A bailout! A seemingly
    //       simple solution with drastic consequences that will be borne by
    //       generations to come. Print more money. Save those who were once
    //       considered “too big to fail.” Recession. Depression. Regression. The
    //       consequences of our greed. They call it “the Great Recession.”
    //     </p>
    //     <p>
    //       As the economy continues to crumble, all hope is not lost for the
    //       people. In an undisclosed location hidden in the shadows, a mysterious
    //       figure known only by his pseudonym Satoshi Nakamoto works on a
    //       solution: a digital currency secured by a virtual ledger living on the
    //       most powerful decentralized network of computers in the world. An idea
    //       that will eventually change the world as we know it. Blockchain
    //       technology and its flagship currency, Bitcoin!
    //     </p>
    //     <p>
    //       As the world reels from the impact of the Great Recession, Satoshi
    //       completes his creation and in January 2009, the Bitcoin Network goes
    //       live. For the next two years, news of the new network quickly spreads
    //       across the web, with the power of the network growing steadily each
    //       day. Adoption begins. From basements to warehouses all around the
    //       world, people of all ages and races begin to anonymously contribute
    //       their computing power to the Bitcoin Network. Then, mysteriously, one
    //       day Satoshi vanishes, leaving the people to carry his vision forward.
    //       Emboldened by the gift Satoshi has given them and with the powerful
    //       idea of financial freedom burned into their minds and hearts, the
    //       people press on. New innovations in Blockchain technology emerge,
    //       accelerating the pace of adoption.
    //     </p>
    //     <p>
    //       Unfortunately, like any great idea, the flaws of man lead people to
    //       misuse Bitcoin. The anonymity of the network provides a cloak for
    //       criminals and other nefarious beings to use Bitcoin to transact on the
    //       dark web with impunity. Public opinion sours and governments, with the
    //       financial institutions pulling their strings, seize the opportunity to
    //       publicly denounce the new currency.
    //     </p>
    //     <p>
    //       But Satoshi’s followers are not deterred. They continue to innovate
    //       like never before. Developers from around the world take Satoshi’s
    //       idea and build upon it. New blockchains are created. The seed for a
    //       technological renaissance begins to sprout and the internet as we know
    //       it changes. The technology causes a new age of digital art, music, and
    //       gaming to begin! Satoshi’s vision begins to take shape. The world
    //       starts to see blockchain’s potential. Even governments begin to accept
    //       the new technology. A bright future is within reach. But the world
    //       could have never predicted what would happen next...
    //     </p>
    //     {/* <p className="--center">[To be continued]</p> */}
    //   </div>
    // </div>
  );
};

export default Part1;
