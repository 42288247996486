import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part5.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 5.png`;

const Part5 = (props) => {
  return (
    <BasicLoreModal image={image}>
      <div className="LoreBlock">
        <p>
          The lockdown goes largely as planned, at least publicly. Once the
          people arrive they get settled in their apartment units and temporary
          housing within a 20 mile radius of their designated cities. Some units
          are much nicer than others, but, aside from being confined to their
          homes, the living standards are decent. The units were built by or
          renovated by robots that Alpha Corporation designed for quick
          construction. The people receive their meals three times a day at
          their windows as they were promised and are otherwise required to do
          very little. Occasionally someone tries to leave their home. But
          Project Providence is extremely effective and those people are
          tranquilized and detained within minutes. Alpha Corporation monitors
          everything that happens outside. But as long as people don’t break the
          rules and leave their homes, they are free to pass the time as they
          please, waiting for the day that the United Nations Counsel will
          announce that the Great Pandemic is over and they can go back to their
          lives. Or so they think.
        </p>
        <p>
          In reality, it is Alpha Corporation that is conducting the tests to
          determine whether the pandemic is still present. Each month, Alpha
          Corporation R conducts tests of the air samples and month after month
          it reports that trace amounts of the virus were detected and the
          Lockdown will need to continue another month. Occasionally, a
          scientist expresses privately that the results do not make sense. The
          pandemic should not have survived more than 3 months. But before those
          scientists can make their claims publicly, a drone appears at their
          window and “tranquilizes” them and their families. But unlike the
          lockdown breachers, no G.E.U. comes to collect them. They simply …
          disappear.{" "}
        </p>
        <p>
          As three months become six and six months nine, the people grow weary.
          Little do they know that in warehouses all over the world, Alpha
          Corporation’s robots are secretly building an army of drones and
          G.E.U.s for a day that is soon to come….
        </p>
        <p>
          That day comes on December 7, 2023. A day that begins like any other.
          Breakfast delivered at 9 AM. Reruns on the television. A normal
          morning by all accounts. But what is about to occur is anything but
          normal. This day will be burned into everyone’s minds as the day
          everything changed. The Takeover.
        </p>
        <p>
          It is almost 12 PM and the people on the outskirts of Washington DC
          are getting ready for their lunch. But as they approach their windows,
          they notice something very different. G.E.U.s line the streets and
          thousands of drones buzz in the air. But these are not the normal food
          delivery drones. These are the drones used to tranquilize breachers.
          The people stand confused. Suddenly, all televisions and radios switch
          to a broadcast. In Washington D.C., Frank Fazor appears to address the
          people for the first time since the Lockdown began. In other cities,
          other faces emerge behind the screen. Familiar faces. Titans of
          industry in each country. All with the same message. They begin:
        </p>
        <p>
          “We are proud to announce that the Great Pandemic has ended!” Cheers
          fill the air all around the world as the people rejoice thinking that
          they will finally be able to return to their homes after so long. But
          what follows quickly silences those cheers. “Now we begin a new era!
          An era we will call ‘The Rebuilding.’ We will return to the traditions
          of the past to make our cities great again! And WE will be your
          leaders in that effort!” At that moment the cameras switch to footage
          of each of the respective former leaders of their countries being
          tranquilized and taken away. The screens then turn to a green and
          black flag with a symbol of a pyramid with an eye in the center.
        </p>
        <p>
          Who are the Defenders Of Fiat? Why have they taken their leaders
          hostage? What does this all mean? The people are left with many
          questions. But one thing is clear. This will not be the cheerful
          return to their homes that people hoped for.
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part5" style={{ ...props.customStyle }}>
    //   <div className="LorePart-5 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part5Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>The Lockdown and The Takeover</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/SatoshiLore05.png"></img>
    //     <p>
    //       The lockdown goes largely as planned, at least publicly. Once the
    //       people arrive they get settled in their apartment units and temporary
    //       housing within a 20 mile radius of their designated cities. Some units
    //       are much nicer than others, but, aside from being confined to their
    //       homes, the living standards are decent. The units were built by or
    //       renovated by robots that Alpha Corporation designed for quick construction. The
    //       people receive their meals three times a day at their windows as they
    //       were promised and are otherwise required to do very little.
    //       Occasionally someone tries to leave their home. But Project Providence
    //       is extremely effective and those people are tranquilized and detained
    //       within minutes. Alpha Corporation monitors everything that happens outside. But
    //       as long as people don’t break the rules and leave their homes, they
    //       are free to pass the time as they please, waiting for the day that the
    //       United Nations Counsel will announce that the Great Pandemic is over
    //       and they can go back to their lives. Or so they think.
    //     </p>
    //     <p>
    //       In reality, it is Alpha Corporation that is conducting the tests to determine
    //       whether the pandemic is still present. Each month, Alpha Corporation R conducts
    //       tests of the air samples and month after month it reports that trace
    //       amounts of the virus were detected and the Lockdown will need to
    //       continue another month. Occasionally, a scientist expresses privately
    //       that the results do not make sense. The pandemic should not have
    //       survived more than 3 months. But before those scientists can make
    //       their claims publicly, a drone appears at their window and
    //       “tranquilizes” them and their families. But unlike the lockdown
    //       breachers, no G.E.U. comes to collect them. They simply … disappear.{" "}
    //     </p>
    //     <p>
    //       As three months become six and six months nine, the people grow weary.
    //       Little do they know that in warehouses all over the world, Alpha Corporation’s
    //       robots are secretly building an army of drones and G.E.U.s for a day
    //       that is soon to come….
    //     </p>
    //     <p>
    //       That day comes on December 7, 2023. A day that begins like any other.
    //       Breakfast delivered at 9 AM. Reruns on the television. A normal
    //       morning by all accounts. But what is about to occur is anything but
    //       normal. This day will be burned into everyone’s minds as the day
    //       everything changed. The Takeover.
    //     </p>
    //     <p>
    //       It is almost 12 PM and the people on the outskirts of Washington DC
    //       are getting ready for their lunch. But as they approach their windows,
    //       they notice something very different. G.E.U.s line the streets and
    //       thousands of drones buzz in the air. But these are not the normal food
    //       delivery drones. These are the drones used to tranquilize breachers.
    //       The people stand confused. Suddenly, all televisions and radios switch
    //       to a broadcast. In Washington D.C., Frank Fazor appears to address the
    //       people for the first time since the Lockdown began. In other cities,
    //       other faces emerge behind the screen. Familiar faces. Titans of
    //       industry in each country. All with the same message. They begin:
    //     </p>
    //     <p>
    //       “We are proud to announce that the Great Pandemic has ended!” Cheers
    //       fill the air all around the world as the people rejoice thinking that
    //       they will finally be able to return to their homes after so long. But
    //       what follows quickly silences those cheers. “Now we begin a new era!
    //       An era we will call ‘The Rebuilding.’ We will return to the traditions
    //       of the past to make our cities great again! And WE will be your
    //       leaders in that effort!” At that moment the cameras switch to footage
    //       of each of the respective former leaders of their countries being
    //       tranquilized and taken away. The screens then turn to a green and
    //       black flag with a symbol of a pyramid with an eye in the center.
    //     </p>
    //     <p>
    //       Who are the Defenders Of Fiat? Why have they taken their leaders hostage?
    //       What does this all mean? The people are left with many questions. But one
    //       thing is clear. This will not be the cheerful return to their homes that
    //       people hoped for.
    //     </p>
    //   </div>
    // </div>
  );
};

export default Part5;
