const drawPanel = (ctx, i, width, height, topGradient, bottomGradient, direction) => {
    ctx.clearRect(0, 0, width, height);
    var t = ctx.createLinearGradient(0, 0, 0, height);
    t.addColorStop(0, topGradient ? topGradient:"#e962ac");
    t.addColorStop(1, bottomGradient ? bottomGradient:"#eb3096");
    ctx.fillStyle = t;
    ctx.beginPath();
    drawShape(ctx, i, width, height, direction);
    ctx.fill();
  }

const drawShape = (ctx, i, width, height, direction) => {
    if(isEven(i)) {
      drawSingleDirection(ctx, direction/*getDirection(i)*/, width, height);
    } else {
      drawCone(ctx, direction /*getDirection(i - 1)*/, width, height);
    }
  }

  const drawSingleDirection = (ctx, direction, width, height) => {
    if("left" === direction) { 
      ctx.moveTo(0, 0);
      ctx.lineTo(width, 65);
      ctx.lineTo(width, height);
      ctx.lineTo(0, height - 65);
    } else {
      ctx.moveTo(0, 65);
      ctx.lineTo(width, 0);
      ctx.lineTo(width, height - 65);
      ctx.lineTo(0, height);
    }
  }

  const drawCone = (ctx, direction, width, height) => {
    if("left" === direction) {
      ctx.moveTo(0, 0);
      ctx.lineTo(width, 65);
      ctx.lineTo(width, height - 65);
      ctx.lineTo(0, height);
    } else {
      ctx.moveTo(0, 65);
      ctx.lineTo(width, 0);
      ctx.lineTo(width, height);
      ctx.lineTo(0, height - 65);
    }
  }

  const drawPattern = (ctx, i, imagePath, width, height, patternScaleMobile, patternScaleDesktop, patternOpacity, direction, patternOffsetX, patternOffsetY) => {
    const image = new Image();
    
    image.onload = () => {
      ctx.globalAlpha = patternOpacity;
      
      var patternImage = document.createElement('canvas');
      if((height / width) > 0.5 /**Aspect Ratio */ && width < 1350 /*(width / image.width) < 0.45*/) {
        if(width < 330) {
          patternImage.width = image.width * 1.25;
          patternImage.height = image.height * 1.25;
        } else {
          patternImage.width = image.width * patternScaleMobile;
          patternImage.height = image.height * patternScaleMobile;
        }
      } else {
        patternImage.width = width * patternScaleDesktop;
        patternImage.height = height * patternScaleDesktop;
      }
      
      patternImage.getContext('2d').drawImage(image, 0, 0, image.width, image.height, ((width-patternImage.width) / 2) + patternOffsetX, ((height-patternImage.height)/2) + patternOffsetY,  patternImage.width,  patternImage.height);

      const pattern = ctx.createPattern(patternImage, "repeat");
      ctx.fillStyle = pattern;

      ctx.beginPath();
      drawShape(ctx, i, width, height, direction);
      ctx.fill()
    }

    image.src = imagePath;
  }

  const getDirection = (i) => {
    return i % 4 === 0 ? "right" : "left"
  }

  const isEven = (i) => {
    return i % 2 === 0;
  }

  export { drawPanel, drawPattern };