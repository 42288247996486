import React from 'react'
import "./NYCSocial.css"

const NYCSocial = (props) => {
  return (
    <div className='NYCSocial'>
        <div className='NYCSocialContent'>
            <ul className='NYCSocialFlex'>
                <li className='NYCSocialItem'>
                    <a className='NYCSocialLink'
                        href="https://twitter.com/Satoshiverse_io"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesNYC/SVG/twitter_button.svg`} />
                    </a>
                </li>
                <li className='NYCSocialItem'>
                    <a className='NYCSocialLink'
                        href="https://opensea.io/collection/legionnaires"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesNYC/SVG/opensea_button_1.svg`} />
                    </a>
                </li>
                <li className='NYCSocialItem'>
                    <a className='NYCSocialLink'
                        href="https://discord.gg/satoshiverse"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesNYC/SVG/discord_button.svg`} />
                    </a>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default NYCSocial
