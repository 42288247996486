import styled, { css } from "styled-components";
import { spaces, Div } from "globalStyles";

export const Main = styled.div`
 &&&{
  background: none;
  background-color: #202020;
 }
    &::-webkit-scrollbar {
    width: 6px;
    /* width of the entire scrollbar */
}
&::-webkit-scrollbar-track {
    background: rgb(8, 8, 8);
    /* color of the tracking area */
}
&::-webkit-scrollbar-thumb {
    background-color: black;
    /* color of the scroll thumb */
    border-radius: 0px;
    /* roundness of the scroll thumb */
}
`
export const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

const TitleContainer = styled.div`
  ${spaces}
  display: flex;
  justify-content: center;
 `

const TitleText = styled.h1`
&&& {
  font-family: blackOps, sans-serif;
  width: auto;
  text-transform: uppercase;
  background-color: rgba(51, 51, 51, 0.5);
  padding: 10px 15%;
  text-align: center;
  font-size: ${(props) => (props.isMobile ? '40px' : '60px')};
}
`
export const Title = ({ title, isMobile, ...props }) => {
  return (
    <TitleContainer {...props}>
      <TitleText isMobile={isMobile}>{title}</TitleText>
    </TitleContainer>
  )
}

export const FlexCenter = css`
    ${spaces}
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: center;
`;

export const Text = styled.div`
  ${spaces}
  font-family: dinNext;
  width: ${(props) => (props.width ? props.width : '100%')};
  color: ${(props) => props.color && props.color};
  background-color: ${(props) => props.background && props.background};
  text-transform: ${(props) => (props.isUpper ? 'uppercase' : 'unset')};
  text-decoration: ${(props) => props.decoration && props.decoration};
  text-transform: ${(props) => props.transform && props.transform};
  font-size: ${(props) => props.size && props.size}px;
  font-weight: ${(props) => props.weight && props.weight};
  text-align: ${(props) => props.align && props.align};
  font-style: ${(props) => props.fontStyle && props.fontStyle};
  letter-spacing: ${(props) => props.letterSpacing && props.letterSpacing};
  z-index: ${(props) => props.index && props.index};
  ${(props) => props.isCenter && css`
        ${FlexCenter}
        text-align: center;
    `}
  ${(props) => props.isInline && css`
      display: inline-block;
      width: auto;
  `};
`

export const LabelTest = styled.label`
  .arrow {
    width: 30px;
    height: 30px;
    color: red;
  }
`;

export const Background = styled(Div)`
margin-top: 64px;
  background-image: url(${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/background_square_1.png);
  .satoshiFixed {
  width: 10%;
  top: 0;
  left: 0;
  margin-left: 20px;
  position: fixed;
  z-index: 2;
  .cls-1 {
    fill: black;
  }
}
  .satoshiSticky {
  width: 10%;
  top: 0;
  left: 0;
  margin-left: 20px;
  position: fixed;
  z-index: 2;
  .cls-1 {
    fill: white;
  }
}
`
