import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import { types } from "./types";
import { Div, Text } from "./styled";

export const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(
        "https://54qu7akuwd.execute-api.us-east-2.amazonaws.com/Fase1/savehundredform",
        {
          discordUsername: data.discordUsername,
          accent_color: data.accent_color,
          body_type: data.body_type,
          eye_color: data.eye_color,
          legion: data.legion,
          legion_second: data.legion_second,
          legion_third: data.legion_third,
          primary_color: data.primary_color,
          secondary_color: data.secondary_color,
          weapon: data.weapon,
          headgear: data.headgear,
        }
      )
      .then(() => {
        setIsLoading(false);
        setMessage("Success!");
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
        setMessage("Something was wrong, try again");
      });
  };
  return (
    <Div isCenter mb={20} mt={155}>
      <FormContent onSubmit={handleSubmit(onSubmit)}>
        <Text size={30} isCenter mb={20}>
          The 100 - Community Members
        </Text>
        <Label for="discordUsername">Discord Username</Label>
        <Div mb={15}>
          <Input
            type="text"
            {...register("discordUsername", { required: true })}
          />
        </Div>
        <Label for="body_type">Body Type</Label>
        <Div mb={15}>
          <Select {...register("body_type", { required: true })}>
            {types.body_type.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="primary_color">Primary Color</Label>
        <Div mb={15}>
          <Select {...register("primary_color", { required: true })}>
            {types.primary_color.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="secondary_color">Secondary Color</Label>
        <Div mb={15}>
          <Select {...register("secondary_color", { required: true })}>
            {types.secondary_color.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="accent_color">Accent Color</Label>
        <Div mb={15}>
          <Select {...register("accent_color", { required: true })}>
            {types.accent_color.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="eye_color">Eye Color</Label>
        <Div mb={15}>
          <Select {...register("eye_color", { required: true })}>
            {types.eye_color.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="weapon">Weapon</Label>
        <Div mb={15}>
          <Select {...register("weapon", { required: true })}>
            {types.weapon.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="legion">Legion</Label>
        <Div mb={15} mb={20}>
          <Select
            {...register("legion", {
              required: true,
              validate: (value) =>
                value !==
                  (getValues("legion_second") && getValues("legion_third")) ||
                "The legions must be differents",
            })}
          >
            {types.legion.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="legion2">Legion second choice</Label>
        <Div mb={15} mb={20}>
          <Select
            {...register("legion_second", {
              required: true,
              validate: (value) =>
                value !== (getValues("legion") && getValues("legion_third")) ||
                "The legions must be differents",
            })}
          >
            {types.legion.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        <Label for="legion_third">Legion third choice</Label>
        <Div mb={15} mb={20}>
          <Select
            {...register("legion_third", {
              required: true,
              validate: (value) =>
                value !== getValues("legion") ||
                "The legions must be differents",
            })}
          >
            {types.legion.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </Select>
        </Div>
        {/* errors will return when field validation fails  */}
        {(errors.legion || errors.legion_second || errors.legion_third) && (
          <Text isBody color="red" isCenter>
            The legions must be differents
          </Text>
        )}
        <Text isBody isCenter size={20} color={isError ? "red" : "green"}>
          {message}
        </Text>
        <Div isFlex>
          <Text isBody size={16} mb={10} align width="auto">
            {"By hitting Submit, you accept our "}
            <Link to="/privacy">{" Privacy Policy"}</Link>
          </Text>
        </Div>
        <Div isCenter>
          {isLoading ? (
            <ReactLoading type="spin" />
          ) : (
            <Div width="50%">
              <Input type="submit" />
            </Div>
          )}
        </Div>
      </FormContent>
    </Div>
  );
};
const FormContent = styled.form`
  width: ${isMobile ? "90%" : "70%"};
`;

const Input = styled.input`
  font-family: JetBrains Mono Regular;
  width: 100%;
  height: 35px;
  color: white;
  border: solid 1px white;
  background: #2e3742;
  border-radius: 15px;
  font-size: 16px;
  padding: 0 10px;
  margin-top: 5px;
`;
const Select = styled.select`
  font-family: JetBrains Mono Regular;
  width: 100%;
  height: 35px;
  color: white;
  border: solid 1px white;
  background: #2e3742;
  border-radius: 15px;
  font-size: 16px;
  margin-top: 5px;
  padding: 0 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`;
const Label = styled.label`
  width: 100%;
  font-family: Orbitron;
  color: #ffe53d;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  letter-spacing: 1px;
`;
