import React, { Component } from "react";
import "./NavDropdown.css";

import { Link } from "react-router-dom";
import onClickOutside from "react-onclickoutside";

class NavDropdown extends Component {
  handleClickOutside = (e) => {};

  render() {
    return (
      <div
        className="NavDropdown"
        style={{ display: this.props.isOpen ? "block" : "none" }}
      >
        {this.props.routes.map((route, i) => (
          <div key={i} className="NavDropdownItem">
            {route.local ? (
              <Link to={route.to}>{route.name}</Link>
            ) : (
              <a href={route.to} target="_blank" rel="noopener noreferrer">
                {route.name}
              </a>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default onClickOutside(NavDropdown);
