import React, { Component, createRef } from 'react';
import { drawPanel, drawPattern } from "../../utils/diagonal";

import "./DiagonalSection.css";

class DiagonalSection extends Component {
    constructor(props) {
        super(props);
        this.parentRef = createRef();
        this.canvasRef = createRef();
        this.contentHeight = null;
        this.needsResize = true;
    }

    componentDidMount() {
        this.container = this.parentRef.current;
        this.canvas = this.canvasRef.current

        this.canvas.width = this.container.clientWidth;
        this.canvas.height = this.container.clientHeight;

        window.addEventListener("resize", () => this.needsResize=true);

        this.ctx = this.canvas.getContext("2d");
        this.paintBackground();

        this.resizeCheck = setInterval(() => {
            if(this.needsResize || this.contentHeight !== this.container.clientHeight){
                this.onResize();
                this.needsResize = false;
            }
        }, 1000)
    }

    componentWillUnmount() {
        if(this.resizeCheck)
            clearInterval(this.resizeCheck);
    }

    onResize = () => {
        this.paintBackground();
    }

    paintBackground = () => {
        this.contentHeight = this.container.clientHeight;

        this.canvas.width = this.container.clientWidth;
        this.canvas.height = this.container.clientHeight;

        this.ctx.clearRect(0, 0, this.canvas.width*2, this.canvas.height*2);

        drawPanel(
            this.ctx, 
            this.props.i+2, 
            this.canvas.width, 
            this.canvas.height, 
            this.props.topGradient, 
            this.props.bottomGradient,
            this.props.direction
        );

        if(this.props.usePattern)
            drawPattern(
                this.ctx,
                this.props.i+2, 
                this.props.patternUrl,
                this.canvas.width, 
                this.canvas.height, 
                this.props.patternScaleMobile,
                this.props.patternScaleDesktop,
                this.props.patternOpacity,
                this.props.direction,
                this.props.patternOffsetX,
                this.props.patternOffsetY,
            )
    }

    render() {
        return (
            <div className={`DiagonalSection Section-${this.props.i}`} style={this.props.customStyle}>
                <div ref={this.parentRef} className="--background">
                    <canvas ref={this.canvasRef}></canvas>
                </div>
                {this.props.Component}
            </div>
        );
    }
}

export default DiagonalSection;