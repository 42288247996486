import React, { Component } from "react";
import "./LoreDropdown.css";

//import onClickOutside from "react-onclickoutside";

class LoreDropdown extends Component {
  onClick = (e, i) => {
    e.preventDefault();
    this.props.onLoreClick(i, this.props.useSmooth);
    this.props.closeDropdown();
    this.props.closeBurger();
  };

  /*handleClickOutside = (e) => {
    this.props.closeDropdown();
  };*/

  render() {
    return (
      <ul
        className="LoreDropdown"
        style={
          this.props.isDropdownOpen
            ? this.props.width <= 1050
              ? {
                  width: "100%",
                  marginTop: "35px",
                  transform: "translateX(0%)",
                  flexDirection: "row",
                }
              : {}
            : {
                display: "none",
              }
        }
      >
        <li onClick={(e) => this.onClick(e, 1)}>
          <span>Part 1</span>
        </li>
        <li onClick={(e) => this.onClick(e, 2)}>
          <span>Part 2</span>
        </li>
        <li onClick={(e) => this.onClick(e, 3)}>
          <span>Part 3</span>
        </li>
        <li onClick={(e) => this.onClick(e, 4)}>
          <span>Part 4</span>
        </li>
        <li onClick={(e) => this.onClick(e, 5)}>
          <span>Part 5</span>
        </li>
        <li onClick={(e) => this.onClick(e, 6)}>
          <span>Part 6</span>
        </li>
        <li onClick={(e) => this.onClick(e, 7)}>
          <span>Part 7</span>
        </li>
        <li onClick={(e) => this.onClick(e, 8)}>
          <span>Part 8</span>
        </li>
        <li onClick={(e) => this.onClick(e, 9)}>
          <span>Part 9</span>
        </li>
        <li onClick={(e) => this.onClick(e, 10)}>
          <span>Part 10</span>
        </li>
      </ul>
    );
  }
}

export default LoreDropdown;
