import React, { useState, useEffect, useRef } from "react";
import "./TeamDetails.css";

const TeamDetails = (props) => {
  const [isRight, setIsRight] = useState(true);
  const refContainer = useRef(null);

  useEffect(() => {
    let rect = refContainer.current.getBoundingClientRect();

    if (rect.x + 350 >= props.width) {
      setIsRight(false);
    } else if (!isRight) {
      setIsRight(true);
    }
  }, []);

  useEffect(() => {
    let rect = refContainer.current.getBoundingClientRect();

    if (rect.x + 350 >= props.width) {
      setIsRight(false);
    } else if (!isRight) {
      setIsRight(true);
    }
  }, [props.width]);

  const onMouseEnter = (e) => {
    props.setIsDetailsHovered(true);
  };

  const onMouseLeave = (e) => {
    props.setIsDetailsHovered(false);
  };

  return (
    <div
      className="TeamDetails"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={refContainer}
      style={{
        transform:
          props.width > 480
            ? props.width > 800
              ? isRight
                ? "translateX(50%)"
                : "translateX(-85%)"
              : isRight
              ? "translateX(10%)"
              : "translateX(-70%)"
            : "translate(-50%, -50%)",

        left: props.width <= 480 ? "50%" : "0%",
        top: props.width <= 480 ? "50%" : "0%",
      }}
    >
      <div className="TeamDetailsContent">
        <span className="name" style={!isRight ? { textAlign: "right" } : {}}>
          {props.complete_name}
        </span>

        {props.linkedUrl || props.twitterUrl ? (
          <div
            className="social-links"
            style={!isRight ? { justifyContent: "flex-start" } : {}}
          >
            {props.twitterUrl ? (
              <a
                className="team-twitter-link"
                href={props.twitterUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/twitter-logo.png`}
                />
              </a>
            ) : null}

            {props.linkedUrl ? (
              <a
                className="team-linkedin-link"
                href={props.linkedUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/linkedin-logo.png`}
                />
              </a>
            ) : null}
          </div>
        ) : null}

        <p>{props.bio}</p>
      </div>
    </div>
  );
};

export default TeamDetails;
