import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import { Div } from "./style";

export const Comic = ({ open }) => {
  const part1 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part1Comicx2.png`;
  const part2 = /*`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 2.png`;*/ `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/svgproube.svg`;
  const part3 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 3.png`; //`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part3Comicx2.png`;
  const part4 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 4.png`; //`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part4Comicx2.png`;
  const part5 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 5.png`; //`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part5Comicx2.png`;
  const part6 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 6.png`; //`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part6Comic.png`;
  const part7 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore part 7.png`; //`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part7Comic.png`;
  const part8 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 8.png`; //`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/SatoshiLore08.png`;
  const part9 = /*`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore part 9.png`;*/ `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part9Comic.png`;
  const part10 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Part10Comic.png`;
  const part11 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore_Part_11a.png`;
  const part12 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore_Part12.png`;
  const part13 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Lore_Part13.png`;
  const satoshi = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/SatoshiPart9.png`;
  const asset1 = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/Asset4.png`;
  if (isMobile) {
    return (
      <Div mb={isMobile && "200px"}>
        <TitleSection>Section 1</TitleSection>
        <ContainerPage>
          <Div height="30%" position="relative">
            <LeftTopContainer onClick={() => open(1)}>
              <Img src={part1} alt="part1" />
              <Title>Part 1</Title>
            </LeftTopContainer>
            <RightTopContainer onClick={() => open(2)}>
              <Img src={part2} alt="part2" />
              <Title>Part 2</Title>
            </RightTopContainer>
          </Div>
          <Div height="25%" backgroundColor="black">
            <Img
              border="2px solid white"
              src={part3}
              alt="part3"
              onClick={() => open(3)}
            />
            <Title>Part 3</Title>
          </Div>
          <Div height="45%" position="relative">
            <Img
              border="2px solid white"
              src={part5}
              alt="part5"
              onClick={() => open(5)}
            />
            <Title left="60%">Part 5</Title>
            <BotBoxContainer>
              <Img
                border="2px solid white"
                src={part4}
                alt="part5"
                onClick={() => open(4)}
              />
              <Title>Part 4</Title>
            </BotBoxContainer>
          </Div>
        </ContainerPage>
        <ContainerPage>
          <Div height="30.67%" position="relative">
            <LeftTopContainer onClick={() => open(6)}>
              <Img border="2px solid white" src={part6} alt="part6" />
              <Title>Part 6</Title>
            </LeftTopContainer>
            <RightTopContainer onClick={() => open(7)}>
              <Img border="2px solid white" src={part7} alt="part7" />
              <Title>Part 7</Title>
            </RightTopContainer>
          </Div>
          <Div
            height="35.33%"
            backgroundColor="black"
            border="3px solid white"
            onClick={() => open(8)}
          >
            <Img border="2px solid white" src={part8} alt="part8" />
            <Title>Part 8</Title>
          </Div>
          <Div height="34%" position="relative">
            <LeftTopContainer onClick={() => open(9)}>
              <Satoshi src={satoshi} alt="part9" />
              <Img border="2px solid white" src={part9} alt="part9" />
              <Title>Part 9</Title>
            </LeftTopContainer>
            <RightTopContainer onClick={() => open(10)}>
              <Img src={part10} alt="part10" />
              <Title>Part 10</Title>
            </RightTopContainer>
          </Div>
        </ContainerPage>
        <TitleSection>Section 2</TitleSection>
        <ContainerPage>
          <Div
            height="30%"
            backgroundColor="black"
            onClick={() => open(11)}
            isContainer
            leftBox="42%"
            border="3px solid white"
          >
            <Img src={part11} alt="part1" />
            <Title>Part 11</Title>
          </Div>
          <Div
            height="30%"
            backgroundColor="black"
            onClick={() => open(12)}
            isContainer
            leftBox="42%"
            border="3px solid white"
          >
            <Img src={part12} alt="part2" />
            <Title>Part 12</Title>
          </Div>
          <Div
            height="40%"
            backgroundColor="black"
            onClick={() => open(13)}
            isContainer
            leftBox="42%"
            border="3px solid white"
          >
            <Img src={part13} alt="part13" />
            <Title>Part 13</Title>
          </Div>
        </ContainerPage>
        <ContainerPage>
          <Div height="75%" backgroundColor="black" isContainer leftBox="42%">
            <Img src={asset1} alt="part13" />
          </Div>
        </ContainerPage>
      </Div>
    );
  }
  return (
    <Div>
      <TitleSection>Section 1</TitleSection>
      <Div isFlex>
        <ContainerPage>
          <Div height="30%" position="relative">
            <LeftTopContainer onClick={() => open(1)}>
              <Img src={part1} alt="part1" />
              <div className="title" id="title1">
                Part 1
              </div>
            </LeftTopContainer>
            <RightTopContainer onClick={() => open(2)}>
              <Img src={part2} alt="part2" />
              <div className="title" id="title2">
                Part 2
              </div>
            </RightTopContainer>
          </Div>
          <Div height="25%" backgroundColor="black" isContainer leftBox="42%">
            <Img
              src={part3}
              alt="part3"
              onClick={() => open(3)}
              borderWidth="4px 4px 4px 4px"
              borderColor="rgba(255, 255, 255, 0.55)"
              borderStyle="solid"
            />
            <div className="title" id="title2">
              Part 3
            </div>
          </Div>
          <Div height="45%" position="relative">
            <Div isContainer height="100%" leftBox="43%">
              <Img
                src={part5}
                alt="part5"
                onClick={() => open(5)}
                borderWidth="4px 4px 4px 4px"
                borderColor="rgba(255, 255, 255, 0.55)"
                borderStyle="solid"
              />
              <div className="title" id="title2">
                Part 5
              </div>
            </Div>
            <BotBoxContainer>
              <Img
                border="4px solid white"
                src={part4}
                alt="part5"
                onClick={() => open(4)}
                borderWidth="4px 4px 4px 4px"
                borderColor="rgba(255, 255, 255, 0.55)"
                borderStyle="solid"
              />
              <div className="title" id="title2">
                Part 4
              </div>
            </BotBoxContainer>
          </Div>
        </ContainerPage>
        <ContainerPage>
          <Div height="30.67%" position="relative">
            <LeftTopContainer width="60%" onClick={() => open(6)}>
              <Img
                src={part6}
                alt="part6"
                borderWidth="4px 4px 4px 4px"
                borderColor="rgba(255, 255, 255, 0.55)"
                borderStyle="solid"
              />
              <div className="title" id="title2">
                Part 6
              </div>
            </LeftTopContainer>
            <RightTopContainer width="40%" onClick={() => open(7)}>
              <Img
                border="4px solid white"
                src={part7}
                alt="part7"
                borderWidth="4px 4px 4px 4px"
                borderColor="rgba(255, 255, 255, 0.55)"
                borderStyle="solid"
              />
              <div className="title" id="title2">
                Part 7
              </div>
            </RightTopContainer>
          </Div>
          <Div
            height="35.33%"
            backgroundColor="black"
            onClick={() => open(8)}
            isContainer
            leftBox="42%"
          >
            <Img
              src={part8}
              alt="part8"
              borderWidth="4px 4px 4px 4px"
              borderColor="rgba(255, 255, 255, 0.55)"
              borderStyle="solid"
            />
            <div className="title" id="title2">
              Part 8
            </div>
          </Div>
          <Div height="34%" position="relative">
            <LeftTopContainer onClick={() => open(9)}>
              <Satoshi src={satoshi} alt="part9" />
              <Img src={part9} alt="part9" />
              <div className="title" id="title2">
                Part 9
              </div>
            </LeftTopContainer>
            <RightTopContainer onClick={() => open(10)} leftBox="48%">
              <Img src={part10} alt="part10" />
              <div className="title" id="title2">
                Part 10
              </div>
            </RightTopContainer>
          </Div>
        </ContainerPage>
      </Div>
      <TitleSection>Section 2</TitleSection>
      <Div isFlex>
        <ContainerPage>
          <Div
            height="30%"
            backgroundColor="black"
            onClick={() => open(11)}
            isContainer
            leftBox="42%"
            border="3px solid white"
          >
            <Img src={part11} alt="part1" />
            <div className="title" id="title2">
              Part 11
            </div>
          </Div>
          <Div
            height="30%"
            backgroundColor="black"
            onClick={() => open(12)}
            isContainer
            leftBox="42%"
            border="3px solid white"
          >
            <Img src={part12} alt="part2" />
            <div className="title" id="title2">
              Part 12
            </div>
          </Div>
          <Div
            height="40%"
            backgroundColor="black"
            onClick={() => open(13)}
            isContainer
            leftBox="42%"
            border="3px solid white"
          >
            <Img src={part13} alt="part13" />
            <div className="title" id="title2">
              Part 13
            </div>
          </Div>
        </ContainerPage>
        <ContainerPage>
          <Div height="75%" backgroundColor="black" isContainer leftBox="42%">
            <Img src={asset1} alt="part13" />
          </Div>
        </ContainerPage>
      </Div>
    </Div>
  );
};

const ContainerPage = styled.div`
  width: ${isMobile ? "300px" : "700px"};
  border: 4px solid white;
  height: ${isMobile ? "389px" : "906.2px"};
  margin-top: 35px;
  margin: ${isMobile && "85px auto 0 auto"};
  background: black;
`;

const TitleSection = styled.div`
  font-family: neuropolNova;
  font-size: ${isMobile ? "50px" : "70px"};
  margin-top: 95px;
  color: rgb(255, 194, 0);
  text-align: center;
`;

const ContainerPageTest = styled.div`
  width: ${isMobile ? "300px" : "700px"};
  border: 4px solid white;

  margin-top: 35px;
  margin: ${isMobile && "85px auto 0 auto"};
  background: black;
`;
const LeftTopContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: 100%;
  background-clip: content-box;
  left: 0;
  position: absolute;
  opacity: ${isMobile ? 1 : 0.5};
  .title {
    font-family: neuropolNova;
    z-index: 10;
    font-size: 40px;
    color: rgb(255, 194, 0);
    position: absolute;
    bottom: 20px;
    transition: all 1s ease-out;
    opacity: 0;
    left: 0;
    -webkit-text-stroke: 1px black;
  }
  &:hover {
    opacity: 1;
    .title {
      opacity: 1;
      left: ${(props) => (props.leftBox ? props.leftBox : "35%")};
    }
  }
`;
const RightTopContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: 100%;
  overflow: hidden;
  background-clip: content-box;
  right: 0;
  position: absolute;
  opacity: ${isMobile ? 1 : 0.5};
  .title {
    font-family: neuropolNova;
    z-index: 10;
    font-size: 40px;
    color: rgb(255, 194, 0);
    position: absolute;
    bottom: 20px;
    transition: all 1s ease-out;
    opacity: 0;
    left: 0;
    -webkit-text-stroke: 1px black;
  }
  &:hover {
    opacity: 1;
    .title {
      opacity: 1;
      left: ${(props) => (props.leftBox ? props.leftBox : "35%")};
    }
  }
`;

const BotBoxContainer = styled.div`
width: 45%;
height: 80%;
position: absolute;
left: 0;
top: 0;
bottom: 0;
margin: auto 0 auto 3%;
background: black;
.title {
    font-family: neuropolNova;
    z-index: 10;
    font-size: 40px;
    color: rgb(255, 194, 0);
    position: absolute;
    bottom:20px;
    transition: all 1s ease-out;
    opacity: 0;
    left: 0;
    -webkit-text-stroke: 1px black;
  }
  &:hover {
  opacity: 1;
  .title {
    opacity: 1;
    left: ${(props) => (props.leftBox ? props.leftBox : "35%")};
  }

`;

const Img = styled.img`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 100%;
  z-index: 2;
  opacity: ${isMobile ? 1 : 0.5};
  border-width: ${(props) => props.borderWidth};
  border-color: ${(props) => props.borderColor};
  border-style: ${(props) => props.borderStyle};
  &:hover {
    opacity: 1;
    border-color: white;
  }
`;

const Satoshi = styled.img`
  height: ${isMobile && "200px"};
  position: absolute;
  bottom: 0;
  z-index: 2;
`;

const Title = styled.div`
  font-family: neuropolNova;
  z-index: 10;
  font-size: 21px;
  color: rgb(255, 194, 0);
  position: absolute;
  bottom: 20px;
  -webkit-text-stroke: 0.2px black;
  left: ${(props) => (props.left ? props.left : "35%")};
`;
