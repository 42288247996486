import React from 'react'

export const ColorTable = ({ data, titleTable, percentage, type }) => {
  return (
    <>
      <div style={{
        margin: '50px 30px',
      }}>
        <h2 className="mb-3">
          {titleTable}
        </h2>
        <p className="mb-2" style={{
          fontSize: '20px',
          textAlign: 'center',
        }}>
          {
            type === 'eye' ? (
              `Each Eye Color has the same probability of occurring (${parseFloat(percentage * 100).toFixed(2)}%), but the actual distribution may vary slightly.`
            ) : (
              `Each Primary Color has the same probability of occurring (${parseFloat(percentage * 100).toFixed(2)}%), but the actual distribution may vary slightly.`
            )
          }
        </p>
        <div className="Textures">
          {
            data.length > 0 && data.map((info, i) => (
              <div key={i}>
                <div>
                  <h3 style={{
                    textAlign: 'center',
                    fontSize: '18px'
                  }}>{info.name}</h3>
                  <div style={{
                    background: `${type ==='eye' ? `rgb${info?.color.color}`:`#${info?.color?.color}` || '#fffff'}`,
                    border: 'solid 1px #ffffff',
                    width: '100px',
                    height: '100px',
                    margin: '20px 60px',
                  }} />
                </div>
              </div>

            ))
          }
        </div>
      </div>
      {/* <h2 className="mb-2">
        {titleTable}
      </h2>
      <div style={{
        overflow: 'hidden',
        display: 'flex',
        flexWrap: "wrap",
        justifyContent: 'space-around',
        width: '100%'
      }}>
        <table>
          <thead style={{
            marginBottom: '15px',
          }}>
            <th>
              Name
            </th>
            <th>
              Color
            </th>
            <th>
              Probability
            </th>
          </thead>
          <tbody>
            {data.map((info, i) =>
              i < data.length / 2 && (
                <tr>
                  <td style={{
                    width: '150px'
                  }}>
                    {info.name !== "" ? info.name : "None"}
                  </td>
                  <td className="colorBox" style={{
                    background: `${`#${info?.color?.color}` || '#fffff'}`,
                    border: 'solid 1px #ffffff',
                  }} />
                  < td >
                    {parseInt(info.probability * 100)} %
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <table>
          <thead>
            <th>
              Name
            </th>
            <th>
              Color
            </th>
            <th>
              Probability
            </th>
          </thead>
          <tbody>
            {data.map((info, i) =>
              i >= data.length / 2 && (
                <tr>
                  <td style={{
                    width: '150px'
                  }}>
                    {info.name !== "" ? info.name : "None"}
                  </td>
                  <td className="colorBox" style={{
                    background: `${`#${info?.color?.color}` || '#fffff'}`,
                    border: 'solid 1px #ffffff',
                  }} />
                  <td>
                    {parseInt(info.probability * 100)} %
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div> */}
    </>
    // <div className="grid">
    //   {data?.map((info, i) => (
    //     <>
    //       <div className="grid-component">
    //         <p>
    //         {info.name}
    //         </p>
    //       </div>
    //       <div className="colorBox" style={{
    //         background: `${`#${info?.color.color}` || '#fffff'}`,
    //         width: '30%',
    //         height: '70%',
    //         border: 'solid 1px #ffffff',
    //       }}>
    //       </div>
    //       <div className="grid-component">
    //         {parseFloat(info.probability * 100).toFixed(3)} %
    //       </div>
    //     </>
    //   ))}
    // </div>
  )
}
