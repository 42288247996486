import React, { useRef } from 'react';
import styled from 'styled-components';
import { Div } from 'globalStyles';
import { IoMdArrowDropright } from 'react-icons/io'
import { Title, Text, Background } from './styles';
import { allowLists } from './TableObjects';

export const AllowLists = ({ isMobile, heightMain }) => {
  const ref = useRef(null);
  return (
    <>
      <Title isMobile={isMobile} title="Allow Lists" mt={20} />
      <Background >
      <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
        <Text size="20" letterSpacing="3px" align="left">
          To encourage a wide range of new collectors to join the Apemo Army, we will be offering our
          partner communities and engaged collectors within our own community discounted 
          spots that will give qualifying collectors priority access to mint up to two Apemo Army avatars at a
          discounted price. In order to control for pesky bots, we will also be implementing a public 
          that will require you to preregister for the public portion of our drop using premint.
        </Text>
      </Div>
      <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
        <Text size="20" letterSpacing="3px" align="left">
          Below are the different s you can qualify for. Join our Discord for more information on how
          to qualify for the Apelist and Crewmen’s list. And make sure to register for the Public !
        </Text>
      </Div>
      <MainDiv isCenter isFlex align="flex-end" justify={isMobile && 'flex-start'}>
        <Table ref={ref}>
          <tr>
            <TableHeader isMobile={isMobile} width="33%" background="transparent"></TableHeader>
            <TableHeader isMobile={isMobile} width="33%" background="transparent"></TableHeader>
            <TableHeader isMobile={isMobile} width="33%" background="transparent"></TableHeader>
          </tr>
          {allowLists.map((item, i) => (
            <tr key={i}>
              <TableDClaim isMobile={isMobile}>
                {item.name}
              </TableDClaim>
              <TableDClaim isMobile={isMobile}>
                {item.price}
              </TableDClaim>
              <TableDRegister isMobile={isMobile}>
                <a href={item.link} target="_blank" rel='noreferrer'>
                  PREMINT REGISTRATION
                </a>
              </TableDRegister>
            </tr>
          )
          )}
        </Table>
      </MainDiv>
      </Background>
    </>
  )
}

const Imagen = styled.img`
  margin-top: ${(props) => (!props.isMobile && '-120px')};
  width: 40%;
  object-fit: contain;
`

const MainDiv = styled(Div)`
  /* height: 800px; */
  overflow: auto;
  position: relative;
  .arrowTable {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10%;
    margin-top: 5%;
    z-index: 2;
    animation: MoveLeftRight 1.2s linear infinite;
    @keyframes MoveLeftRight {
      0%, 100% {
        transform: translateX(-40px);
      }
      50% {
        transform: translateX(0);
      }
      }
  }
`

const Table = styled.table`  
  width: 100%;
  height: auto;
  border-spacing:15px;
  border-collapse: separate;
  margin: 0 10%;
`

const TableD = styled.td`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  height: 145px;
  width: ${(props) => (props.width && props.width)};
  font-size: ${(props) => (props.isMobile ? '16px' : '25px')};
  font-family: dinNext;
  border-radius: 5px;
  border: 1px solid #4CBBF6;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 3px;
  color: #4CBBF6; 
  font-weight: bold;
`;

const TableDClaim = styled.td`
  padding: 20px;
  height: 145px;
  width: ${(props) => (props.width && props.width)};
  font-size: ${(props) => (props.isMobile ? '20px' : '40px')};
  font-family: dinNext;
  border-radius: 5px;
  border: 1px solid #4CBBF6;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  color: #4CBBF6;
  font-weight: bold;
`;

const TableDRegister = styled.td`
  padding: 20px;
  height: 145px;
  width: ${(props) => (props.width && props.width)};
  font-size: ${(props) => (props.isMobile ? '20px' : '40px')};
  font-family: dinNext;
  border-radius: 5px;
  border: 1px solid rgb(194,137,23);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  color: rgb(194,137,23);
  background-color: rgba(194,137,23, 0.2);
  font-weight: bold;
  a {
    &:hover {
      color: white;
    }
  }
`;

const TableHeader = styled.th`
  width: ${(props) => (props.width && props.width)};
  font-size: ${(props) => (props.isMobile ? '16px' : '25px')};
  font-family: dinNext;
  background-color: ${(props) => (props.background ? props.background : '#4CBBF6')};
  border: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-radius: 7px;
  padding: 5px 10px;
  margin: 0 5px;
  color: black;
  font-weight: 500;
`;