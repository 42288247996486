import React from 'react';
import styled from 'styled-components';
import { Div } from 'globalStyles';
import { Title, Text, Background } from './styles';

const Squares = () => (
  <>
    <Square />
    <Square />
    <Square />
  </>
)

export const LaunchSchedule = ({ isMobile, heightMain }) => {
  return (
    <>
      <Title isMobile={isMobile} title="Launch schedule" mt={20} />
      <Background >
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <BotContainerBlue>
            <BotText isUpper weight="bold" size={isMobile ? 12 : 24} isCenter>
              Phase 1 - apelist/crewmen's list exclusive mint period
            </BotText>
          </BotContainerBlue>
        </Div>
        <Div isFlex padding="0 10%" mt={30}>
          <Squares />
          <BotContainerBlue>
            <BotTextYellow size={isMobile ? 12 : 24} isCenter>
              August 22, 2022 - 9 AM PST - August 22, 2022, 10 AM PST
            </BotTextYellow>
          </BotContainerBlue>
          <Squares />

        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <Text size="20" letterSpacing="3px" isCenter>
            For 1 hour, collectors on the Apelist and Crewmen’s list will be able to mint their Apemo Army avatars prior to the public access period. Maximum 2 mints per list per wallet.
          </Text>
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <BotContainerBlue>
            <BotText isUpper weight="bold" size={isMobile ? 12 : 24} isCenter>
              Phase 2 - apelist/crewmen's list/public allowlist mint period
            </BotText>
          </BotContainerBlue>
        </Div>
        <Div isFlex padding="0 10%" mt={30}>
          <Squares />
          <BotContainerBlue>
            <BotTextYellow size={isMobile ? 12 : 24} isCenter>
              August 22, 2022 - 10 AM PST - August 23, 2022, 9 AM PST
            </BotTextYellow>
          </BotContainerBlue>
          <Squares />
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <Text size="20" letterSpacing="3px" isCenter>
            For 23 hours, collectors on the Apelist, Crewmen’s List and Public Allowlist will be able to mint 2 Apemo Army avatars per wallet on a
          </Text>
        </Div>
        <Div isFlex direction={isMobile && 'column'}>
          <Text weight="bold" size="20" letterSpacing="3px" isCenter>first come first served basis while supplies last.</Text>
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <BotContainerBlue>
            <BotText isUpper weight="bold" size={isMobile ? 12 : 24} isCenter>
              Phase 3 - public mint
            </BotText>
          </BotContainerBlue>
        </Div>
        <Div isFlex padding="0 10%" mt={30}>
          <Squares />
          <BotContainerBlue>
            <BotTextYellow size={isMobile ? 12 : 24} isCenter>
              August 23, 2022 - 9 AM PST - August 24, 2022, 9 AM PST
            </BotTextYellow>
          </BotContainerBlue>
          <Squares />
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <Text size="20" letterSpacing="3px" isCenter>
            For 24 hours, the Apemo Army mint will be open to the public at the public mint price. Minting will be on a first come first served basis while supplies last.
            Maximum 10 mints per transaction.
          </Text>
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <Text weight="bold" size="20" letterSpacing="3px" isCenter>The SNAPSHOT for the free claim will also be taken during this period.</Text>
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <BotContainerBlue>
            <BotText isUpper weight="bold" size={isMobile ? 12 : 24} isCenter>
              Phase 4 - free claim
            </BotText>
          </BotContainerBlue>
        </Div>
        <Div isFlex padding="0 10%" mt={30}>
          <Squares />
          <BotContainerBlue>
            <BotTextYellow size={isMobile ? 12 : 24} isCenter>
              August 24, 2022 - 9 AM PST - August 27, 2022, 9 AM PST
            </BotTextYellow>
          </BotContainerBlue>
          <Squares />
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <Text size="20" letterSpacing="3px" isCenter>
            All collectors who have eligible free claim pieces as of the snapshot time can mint their free claim allotment.
          </Text>
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <BotContainerBlue>
            <BotText isUpper weight="bold" size={isMobile ? 12 : 24} isCenter>
              Phase 5 - mint closes and reveal party
            </BotText>
          </BotContainerBlue>
        </Div>
        <Div isFlex padding="0 10%" mt={30}>
          <Squares />
          <BotContainerBlue>
            <BotTextYellow size={isMobile ? 12 : 24} isCenter>
              August 27, 2022 - 9 AM PST
            </BotTextYellow>
          </BotContainerBlue>
          <Squares />
        </Div>
        <Div isFlex direction={isMobile && 'column'} padding="0 10%" mt={30}>
          <Text size="20" letterSpacing="3px" isCenter>
            At this time collectors will no longer be able to mint and the collection size will be capped at the next amount of - 3,333; 4,444; 5,555; 6,666; 7,777; 8,888 or 10,000 which will guarantee no excess supply. We will be revealing the collection later that day and announcing details regarding a reveal party soon!
          </Text>
        </Div>
      </Background>
    </>
  )
}

const Imagen = styled.img`
  margin-top: ${(props) => (!props.isMobile && '-120px')};
  width: 40%;
  object-fit: contain;
`

const BotContainerBlue = styled.div`
  display: block; /* Hidden by default */
  width: 60%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
`

const BotContainerYellow = styled.div`
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
`

const BotText = styled(Text)`
  background-color: rgba(115, 250, 255, 0.2);
  color: #4CBBF6;
  letter-spacing: 5px;
  width: auto;
  padding: 6px;
`;
const BotTextYellow = styled(Text)`
background-color: rgba(51,51,51,0.5);
color: rgb(194,137,23);
letter-spacing: 5px;
width: auto;
padding: 6px;
`;

const Square = styled(Div)`
  width: 12px;
  height: 0px;
  padding-top: 12px;
  margin: 0 10px;
  background-color: rgb(194,137,23);
`;
