import React from "react";
import "./GameIntro.css";
import styled from "styled-components";

const GameIntro = () => {
  return (
    <div
      className="GameIntro"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/BG_Logo_web.png')`,
      }}
    >
      <div className="GameIntroContent">
        <img
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/webNewLegions/IMG/Rebrand/legions_logo_1.png`}
        />
        {/* <ScheduleAlpha>Alpha Phase 4 Now Live</ScheduleAlpha> */}
        <p>
          The year is 2029. For almost a decade, the world has been ruled by a
          clandestine group calling themselves The Defenders of Freedom, who
          used a global lockdown put into place during the Great Pandemic as a
          means of overthrowing world governments and taking absolute control
          through enhanced surveillance technologies and weaponry. When they
          took over, those who submitted to their rule were welcomed, leaving
          those who refused to struggle in the shadows of the Outskirts. But
          now, whispers of a rebellion led by the mysterious Satoshi The Creator
          have spread amongst the people giving the oppressed a glimmer of hope.
          From all corners of the world, heroes will rise to challenge the DOF
          and reclaim the Outskirts! Will you be the hero the world needs?
        </p>
      </div>
    </div>
  );
};

const ScheduleAlpha = styled.div`
  width: 100%;
  color: #37d5b9;
  font-family: Manifold, courier;
  text-transform: uppercase;
  font-size: 22pt;
  font-weight: regular;
  text-align: center;
  margin-bottom: 20px;
  font-spacing: 50pt;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px;
  }
`;

export default GameIntro;
