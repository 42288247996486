import React from "react";
import { BasicLoreModal } from "../Modal";
import "./Part8.css";
const image = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLore/lore part 8.png`;

const Part8 = (props) => {
  return (
    <BasicLoreModal image={image}>
      <div className="LoreBlock">
        <p>
          When the D.O.F. took over during the Lockdown, they confiscated all
          computers and other devices. They were eventually returned but were
          completely wiped clean, had tracking software installed, and no access
          to the internet or any other means of communicating with others. In
          doing so, the D.O.F. successfully put an end to the emergence of the
          Bitcoin Network. Or so they believed.
        </p>
        <p>
          On June 17th, 2029 something would happen that would ignite humanity’s
          fight to regain its freedom. In a small apartment in the Outskirts of
          Washington D.C., a young woman, age 18, named Nina and her kid brother
          Eric went about their days like most others in the Outskirts did.
          Afraid. Obedient. Praying that something would occur that would change
          their fates. Nina, only a teenager when the Lockdown began, was now a
          quick witted intelligent young woman with short blonde hair and an
          athletic physique. Her brother, a twelve year old boy, thin with brown
          hair, had grown up during the Lockdown and did not know much else.
          Their parents, vocal critics of the D.O.F., had unfortunately met the
          fate that most critics did early on - tranquilized and vanished, never
          to be seen again, leaving their children to fend for themselves.
        </p>
        <p>
          On that fateful morning, everything changed. As Nina was rearranging
          furniture in the small apartment, she noticed a loose floorboard under
          a rug. She quickly became intrigued and after turning on the radio so
          that the satellites could not hear what she was doing, she
          investigated what was underneath the floorboard. To her surprise, she
          found a package. Written on the front was an address in Bogota,
          Colombia and an instruction “Open Only In D.O.F. Emergency.” Of
          course, there was no choice but to open the package and see what was
          inside, and Nina did just that.
        </p>
        <p>
          Inside, she found an old flash drive and a letter. The letter read:
        </p>
        <p>
          “Dear Gerald, <br></br>
          <br></br>
          If you are reading this, my worst fears have been confirmed. The
          Defenders Of Fiat have taken over and the world is in grave danger.
          Months ago, I intercepted an email from one of the Defenders Of Fiat
          with surveillance software that I fear they will attempt to use to
          gain control of world power. If I know them as I think I do, they will
          use whatever means necessary to implement their new technology and
          stop what we have been building. I have set up a hideout deep in the
          mountains outside of Bogota to build technology to give us a chance in
          what I fear will be a great war.
        </p>
        <p>
          But I cannot do this alone. If in fact my fears have come true and the
          D.O.F. has taken control, plug this flash drive into any computer and
          it will disable the tracking software and reinstall the Bitcoin
          Network onto all the computers that are connected. I will then be able
          to harness their power to fuel what I am building. This will also tell
          me that the time has come to reemerge to the world. I only hope I will
          be ready in time. Until then, I must remain hidden.<br></br>
          <br></br>
          Your dearest friend, <br></br>
          Satoshi.”
        </p>
        <p>
          “It can’t be,” Nina thinks. “Satoshi? But he has been gone for almost
          20 years. What could my father possibly have to do with Satoshi?” But
          the instructions are clear and Nina knows what she has to do. She
          rushes to her computer and without a second of doubt plugs the flash
          drive in. The screen illuminates brightly in gold with a symbol that
          has been all but lost over the last five years - the golden B of
          Bitcoin.
        </p>
      </div>
    </BasicLoreModal>
    // <div className="Part8" style={{ ...props.customStyle }}>
    //   <div className="LorePart-8 LoreFill"></div>
    //   <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/Part8Banner.png"></img>
    //   <div className="LoreBlock">
    //     <h1>The Resurgence</h1>
    //     <img src={`${process.env.REACT_APP_AWS_MEDIA_LINK}`/imagesPublic/SatoshiLore08.png"></img>
    //     <p>
    //       When the D.O.F. took over during the Lockdown, they confiscated all
    //       computers and other devices. They were eventually returned but were
    //       completely wiped clean, had tracking software installed, and no access
    //       to the internet or any other means of communicating with others. In
    //       doing so, the D.O.F. successfully put an end to the emergence of the
    //       Bitcoin Network. Or so they believed.
    //     </p>
    //     <p>
    //       On June 17th, 2029 something would happen that would ignite humanity’s
    //       fight to regain its freedom. In a small apartment in the Outskirts of
    //       Washington D.C., a young woman, age 18, named Nina and her kid brother
    //       Eric went about their days like most others in the Outskirts did.
    //       Afraid. Obedient. Praying that something would occur that would change
    //       their fates. Nina, only a teenager when the Lockdown began, was now a
    //       quick witted intelligent young woman with short blonde hair and an
    //       athletic physique. Her brother, a twelve year old boy, thin with brown
    //       hair, had grown up during the Lockdown and did not know much else.
    //       Their parents, vocal critics of the D.O.F., had unfortunately met the
    //       fate that most critics did early on - tranquilized and vanished, never
    //       to be seen again, leaving their children to fend for themselves.
    //     </p>
    //     <p>
    //       On that fateful morning, everything changed. As Nina was rearranging
    //       furniture in the small apartment, she noticed a loose floorboard under
    //       a rug. She quickly became intrigued and after turning on the radio so
    //       that the satellites could not hear what she was doing, she
    //       investigated what was underneath the floorboard. To her surprise, she
    //       found a package. Written on the front was an address in Bogota,
    //       Colombia and an instruction “Open Only In D.O.F. Emergency.” Of
    //       course, there was no choice but to open the package and see what was
    //       inside, and Nina did just that.
    //     </p>
    //     <p>
    //       Inside, she found an old flash drive and a letter. The letter read:
    //     </p>
    //     <p>
    //       “Dear Gerald, <br></br>
    //       <br></br>
    //       If you are reading this, my worst fears have been confirmed. The
    //       Defenders Of Fiat have taken over and the world is in grave danger.
    //       Months ago, I intercepted an email from one of the Defenders Of Fiat
    //       with surveillance software that I fear they will attempt to use to
    //       gain control of world power. If I know them as I think I do, they will
    //       use whatever means necessary to implement their new technology and
    //       stop what we have been building. I have set up a hideout deep in the
    //       mountains outside of Bogota to build technology to give us a chance in
    //       what I fear will be a great war.
    //     </p>
    //     <p>
    //       But I cannot do this alone. If in fact my fears have come true and the
    //       D.O.F. has taken control, plug this flash drive into any computer and
    //       it will disable the tracking software and reinstall the Bitcoin
    //       Network onto all the computers that are connected. I will then be able
    //       to harness their power to fuel what I am building. This will also tell
    //       me that the time has come to reemerge to the world. I only hope I will
    //       be ready in time. Until then, I must remain hidden.<br></br>
    //       <br></br>
    //       Your dearest friend, <br></br>
    //       Satoshi.”
    //     </p>
    //     <p>
    //       “It can’t be,” Nina thinks. “Satoshi? But he has been gone for almost
    //       20 years. What could my father possibly have to do with Satoshi?” But
    //       the instructions are clear and Nina knows what she has to do. She
    //       rushes to her computer and without a second of doubt plugs the flash
    //       drive in. The screen illuminates brightly in gold with a symbol that
    //       has been all but lost over the last five years - the golden B of
    //       Bitcoin.
    //     </p>
    //   </div>
    // </div>
  );
};

export default Part8;
