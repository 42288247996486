import React from 'react';
import { isMobile } from 'react-device-detect';
import { Basic } from '../../Modal';
import { Div, Img } from '../style';

export const BasicLoreModal = ({
  children,
  image,
  name,
}) => {
  if (isMobile) {
    return (
      <Div fullWidth fullHeight overflow backgroundColor="#2D3842">
        {children}
      </Div>
    )
  }
  return (
    <>
      <Div isFlex height="100%" width="90%">
        <Div fullWidth fullHeight>
          <Img src={image} alt={name} />
        </Div>
        <Div pb={30} fullWidth fullHeight overflow backgroundColor="#2D3842">
          {children}
        </Div>
      </Div>
    </>
  )
}
