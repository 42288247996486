import {
  SETUP_APEMO,
  CHECK_WHITELIST_STATUS,
  CLEAR_WHITELIST_STATUS,
  IS_MINTING,
  CHECK_WHITELIST_TOKEN_COUNTS,
  SET_PHASE_NUMBER,
} from "../actions/types";

const initialState = {
  apemoQueryContract: null,
  phaseNumber: 0,
  leftPurchaseCount: 5000,

  proof: null,
  whitelistStatuses: {},
  whitelistTokenCounts: {},
  isMinting: false,
};

export default function foo(state = initialState, action) {
  switch (action.type) {
    case SETUP_APEMO:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PHASE_NUMBER:
      console.log("setting: ", action.payload);
      return {
        ...state,
        phaseNumber: action.payload,
      };
    case IS_MINTING:
      return {
        ...state,
        isMinting: action.payload,
      };

    case CHECK_WHITELIST_STATUS:
      let _whitelistStatuses = {
        ...state.whitelistStatuses,
      };

      _whitelistStatuses[action.payload.whitelistId] = {
        ...action.payload,
      };

      return {
        ...state,
        whitelistStatuses: _whitelistStatuses,
      };

    case CHECK_WHITELIST_TOKEN_COUNTS:
      let _whitelistTokenCounts = {
        ...state.whitelistTokenCounts,
      };

      _whitelistTokenCounts[action.payload.whitelistId] = action.payload.count;

      return {
        ...state,
        whitelistTokenCounts: _whitelistTokenCounts,
      };

    case CLEAR_WHITELIST_STATUS:
      return {
        ...state,
        proof: null,
        whitelistStatuses: {},
        whitelistTokenCounts: {},
        isMinting: false,
      };

    default:
      return state;
  }
}
