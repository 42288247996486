import data from './inputFileBody.json';
import dataColors from './colorsHex.json';

export function convert() {
  let fileFormate = {
    Body: [],
    TextureColors: {
      colorPrimary: [],
      colorsBody: [],
      maskColors: [],
      eyeColors:[],
    },
    Mask: [],
    Legion: [],
    Headgear: [],
    Weapon: [],
  }
  try {
    //Colors and textures
    for (let i = 0; i < data.attributes[0].possibleTraits[0].mainColor.length; i++) {
      const colors = data.attributes[0].possibleTraits[0].mainColor[i];
      const x = Object.entries(colors);
      const name = x[0][0];
      fileFormate.TextureColors.colorPrimary.push(name);
    }
    for (let i = 0; i < data.attributes[1].possibleTraits[0].color1.length; i++) {
      const colors = data.attributes[1].possibleTraits[0].color1[i];
      const x = Object.entries(colors);
      const color = dataColors.colorsMask.find(color => color.name === x[0][0])
      const obj = {
        name: x[0][0],
        probability: x[0][1],
        color: color || '#ffffff'
      }
      fileFormate.TextureColors.maskColors.push(obj);
    }
    for (let i = 0; i < data.attributes[0].possibleTraits[0].secondaryColor.length; i++) {
      const colors = data.attributes[0].possibleTraits[0].mainColor[i];
      const x = Object.entries(colors);
      const color = dataColors.colorsBody.find(color => color.name === x[0][0])
      const obj = {
        name: x[0][0],
        probability: x[0][1],
        color,
      }
      fileFormate.TextureColors.colorsBody.push(obj);
    }
    for (let i = 0; i < data.attributes[1].possibleTraits[0].eyeColor.length; i++) {
      const colors = data.attributes[1].possibleTraits[0].eyeColor[i];
      const x = Object.entries(colors);
      const color = dataColors.colorsEyes.find(color => color.name === x[0][0])
      const obj = {
        name: x[0][0],
        probability: x[0][1],
        color: color || '#ffffff'
      }
      fileFormate.TextureColors.eyeColors.push(obj);
    }
    //Rest Of body
    for (let i = 0; i < data.attributes.length; i++) {
      const nameTrait = data.attributes[i].trait_type;
      const traits = data.attributes[i].possibleTraits;
      if (nameTrait === 'Body Type') {
        for (let j = 0; j < traits.length; j++) {
          const x = Object.entries(traits[j]);
          const obj = {
            name: x[0][0],
            probability: x[0][1]
          }
          fileFormate.Body.push(obj)
        }
      } else if (nameTrait === 'Mask') {
        for (let j = 0; j < traits.length; j++) {
          const x = Object.entries(traits[j]);
          const obj = {
            name: x[0][0],
            probability: x[0][1]
          }
          fileFormate.Mask.push(obj)
        }
      } else if (nameTrait === 'Weapon') {
        for (let j = 0; j < traits.length; j++) {
          const x = Object.entries(traits[j]);
          const obj = {
            name: x[0][0],
            probability: x[0][1]
          }
          fileFormate.Weapon.push(obj)

        }
      } else if (nameTrait === 'Headgear') {
        for (let j = 0; j < traits.length; j++) {
          const x = Object.entries(traits[j]);
          const obj = {
            name: x[0][0],
            probability: x[0][1]
          }
          fileFormate.Headgear.push(obj);
        }
      } else {

      }
    }
    fileFormate.Body.sort((a, b) => (a.probability < b.probability) ? 1 : -1)
    fileFormate.Weapon.sort((a, b) => (a.probability < b.probability) ? 1 : -1)
    fileFormate.Mask.sort((a, b) => (a.probability < b.probability) ? 1 : -1)
    fileFormate.Headgear.sort((a, b) => (a.probability < b.probability) ? 1 : -1)
    fileFormate.TextureColors.colorPrimary.splice(0, 17);
    fileFormate.TextureColors.colorsBody.sort((a, b) => (a.name > b.name) ? 1 : -1)
    fileFormate.TextureColors.maskColors.sort((a, b) => (a.name > b.name) ? 1 : -1)
    
    console.log(fileFormate)
    return fileFormate;
  } catch (e) {
    console.log(e);
  }
}

export function getSameNames(data = []) {
  let array = []
  for (let i = 0; i < data.length; i++) {
    let estado = false;
    if(data[i].toLowerCase() === 'gold'){
      let newArray = []
      newArray.push(data[i])
      array.push(newArray);
      continue;
    }
    const ind = data[i].indexOf("-")
    const word = data[i].substring(0, ind);
    if (array.length === 0) {
      let newArray = []
      newArray.push(data[i])
      array.push(newArray);
    } else {
      for (let j = 0; j < array.length; j++) {
        if (array[j][0].includes(word)) {
          estado = true;
          array[j].push(data[i])
          break
        }
      }
      if(!estado) {
        const newArray = [data[i]]
        array.push(newArray);
      }
    }
  }
  return array;
}
