import React, { Component } from "react";
import "./Formulary.css";

import axios from "axios";
import ReactLoading from "react-loading"

import { environment } from "../../../config";

const BTC_FORM_ENABLED = environment.BTC_FORM_ENABLED;

class Formulary extends Component {
  state = {
    agreed: false,
    twitter_address: "",
    eth_address: "",
    email: "",

    errorMsg: "",
    isLoading: false,
    errorColor: "",

    showEthAddressInfo: false,
    isFocusedEthAddress: false,
  };

  onMouseEthAddressEnter = () => {
    if (!this.state.isFocusedEthAddress && !this.state.showEthAddressInfo) {
      this.setState({ showEthAddressInfo: true });
    }
  };

  onMouseEthAddressLeave = () => {
    if (this.state.showEthAddressInfo) {
      this.setState({ showEthAddressInfo: false });
    }
  };

  onFocusEthAddress = () => {
    this.setState({ isFocusedEthAddress: true });
  };
  onBlurEthAddress = () => {
    this.setState({ isFocusedEthAddress: false });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (this.state.errorMsg) this.setState({ errorMsg: "", errorColor: "" });

    this.setState({
      [name]: value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { agreed, twitter_address, eth_address, email } = this.state;

    if (agreed && twitter_address && eth_address && email) {
      try {
        console.log("Submitting...");

        this.setState({ isLoading: true });

        const response = await axios({
          method: "post",
          url: "https://54qu7akuwd.execute-api.us-east-2.amazonaws.com/Fase1/savebtcform",
          data: {
            twitter_address,
            eth_address,
            email,
          },
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.statusCode === 200) {
          this.reset();
          this.setState({
            errorMsg: "Submit success!",
            errorColor: "green",
            isLoading: false,
          });
        } else {
          this.reset();
          this.setState({
            errorMsg: JSON.parse(response.data.body).msg,
            errorColor: "red",
            isLoading: false,
          });
        }
      } catch (e) {
        console.log(e);
        this.setState({
          errorMsg: "Error trying to submit.",
          isLoading: false,
        });
      }
    } else {
      if (!twitter_address || !eth_address || !email) {
        this.setState({ errorMsg: "Fill all fields." });
      } else {
        this.setState({ errorMsg: "Check agreement checkbox." });
      }
    }
  };

  reset = () => {
    this.setState({
      agreed: false,
      twitter_address: "",
      eth_address: "",
      email: "",

      isLoading: false,
      errorColor: "",
      errorMsg: "",
    });
  };

  validateForm = () => {
    return (
      this.state.agreed &&
      this.state.eth_address &&
      this.state.email &&
      this.state.twitter_address
    );
  };

  render() {
    return (
      <div className="Formulary">
        <div className="ColumnSection">
          <div className="ColumnBlock">
            <div className="--BlockFlex">
              <h1
                style={
                  this.props.width >= 950
                    ? { display: "none" }
                    : { marginBottom: "1.5rem" }
                }
              >
                Free Satoshiverse BTC 2022 NFT
              </h1>
              <div className="--BlockLeft-1">
                <h1 style={this.props.width < 950 ? { display: "none" } : {}}>
                  Free Satoshiverse <br />
                  BTC 2022 NFT
                </h1>
                {
                    BTC_FORM_ENABLED ? <p className="pt-1">
                    To receive this free Satoshiverse NFT, <br /> please follow
                    the instructions below:
                  </p> : <p className="pt-1" style={{letterSpacing: "0.5px", lineHeight: "1.75rem"}}>
                    The signup period for the free BTC 2022 NFT has ended. If you already 
                    signed up correctly, your NFT will be airdropped to your Ethereum wallet 
                    on the Polygon blockchain by May 20, 2022. The easiest way to check if you 
                    have received your NFT is on {" "}
                    <a
                      href="https://opensea.io"
                      target="_blank"
                      rel="noreferrer">
                        Opensea.io
                    </a> . For more information on the 
                    Satoshiverse please visit  {" "}
                    <a
                      href="https://www.satoshiverse.io"
                      target="_blank"
                      rel="noreferrer">
                        www.satoshiverse.io
                    </a> {" "}
                    and keep track of our Twitter for more news and giveaways.
                  </p>
                  }
                {
                    BTC_FORM_ENABLED ?
                    <React.Fragment>
                <div className="FillSteps">
                  <h1 style={{ paddingRight: "1rem" }}>1</h1>
                  <div
                    className="StepLabel"
                    style={{
                      backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/Line.png')`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <span>Follow @Satoshiverse_io on Twitter</span>
                    <a
                      className="TwitterButton"
                      href="https://twitter.com/intent/user?screen_name=Satoshiverse_io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/Twitter_icon.png`}
                      />
                    </a>
                  </div>
                </div>
                <div className="FillSteps">
                  <h1 style={{ paddingRight: "1rem" }}>2</h1>
                  <div
                    className="StepLabel"
                    style={{
                      backgroundImage: `url('${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/Line.png')`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <span>Fill in the following form:</span>
                  </div>
                </div>
                <form className="FormBTC" onSubmit={this.onSubmit}>
                  <div className="FormFlex">
                    <div className="InputField">
                      <img
                        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/square.png`}
                      />
                      <div className="--InputRelative">
                        <div className="--Separator"></div>
                        <input
                          type="text"
                          placeholder="Twitter Handle"
                          name="twitter_address"
                          onChange={this.handleInputChange}
                          value={this.state.twitter_address}
                        ></input>
                      </div>
                    </div>
                    <div className="InputField">
                      <img
                        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/square.png`}
                      />
                      <div className="--InputRelative">
                        <div className="--Separator"></div>
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={this.handleInputChange}
                          value={this.state.email}
                        ></input>
                      </div>
                    </div>
                    <div className="InputField">
                      <img
                        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/square.png`}
                      />
                      <div className="--InputRelative">
                        {this.props.width >= 950 &&
                        this.state.showEthAddressInfo ? (
                          <div className="input-info">
                            Ethereum Address - This should be the address of an
                            Ethereum wallet that can receive NFTs.
                          </div>
                        ) : null}
                        <div className="--Separator"></div>
                        <input
                          type="text"
                          placeholder="Eth Address"
                          name="eth_address"
                          onChange={this.handleInputChange}
                          value={this.state.eth_address}
                          onFocus={this.onFocusEthAddress}
                          onBlur={this.onBlurEthAddress}
                          onMouseEnter={this.onMouseEthAddressEnter}
                          onMouseLeave={this.onMouseEthAddressLeave}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <p className="pt-3">
                    Your free NFT will be sent to you no later than May 15,
                    2022.
                  </p>
                  <div className="AgreementBox">
                    <label className="InputCheckboxContainer">
                      <input
                        name="agreed"
                        type="checkbox"
                        checked={this.state.agreed}
                        onChange={this.handleInputChange}
                        className="InputCheckbox"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <p className="pt-2">
                      By submitting this form, you agree to receive information
                      about The Satoshiverse. You can opt out at any time by
                      emailing the word “Unsubscribe” to
                      satoshiverse@apollonft.com or direct messaging the word
                      "Unsubscribe" to @satoshiverse_io on Twitter.
                    </p>
                  </div>
                  {this.state.errorMsg ? (
                    <span
                      className="ErrorMessage"
                      style={{
                        color: this.state.errorColor
                          ? this.state.errorColor
                          : "white",
                      }}
                    >
                      {this.state.errorMsg}
                    </span>
                  ) : null}
                  <div className="--Center-mobile">
                    {this.state.isLoading ? (
                      <ReactLoading className="--LoadingSpin" type="spin" />
                    ) : null}
                    <input
                      className="SubmitButton"
                      type="submit"
                      value="Submit"
                      disabled={this.state.isLoading || !this.validateForm()}
                      style={
                        this.state.isLoading || !this.validateForm()
                          ? { pointerEvents: "none" }
                          : {}
                      }
                    ></input>
                  </div>
                  
                  
                </form>
                </React.Fragment> : null}
                <div className="--FlexLinks">
                    <a
                      href="https://opensea.io/collection/legionnaires"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Legionnaires OS Collection</span>
                    </a>
                    <a
                      href="https://www.satoshiverse.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/web.png`}
                      ></img>
                      <span>Satoshiverse Website</span>
                    </a>
                  </div>
              </div>
              <div className="--BlockRight-1">
                <video autoPlay playsInline muted loop>
                  <source
                    src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesPublic/btc/freebtc_2k_compatible.webm`}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Formulary;
