import React from "react";
import "./OnboardingSection.css";
import StickyNav from "../StickyNav/StickyNav";
import DiagonalSection from "../DiagonalSection/DiagonalSection";
import Footer from "../Footer/Footer";
import FooterFill from "../FooterFill/FooterFill";

import TheHundred from "./TheHundred/TheHundred";
import NavBar from "components/NavBar/NavBar";

const OnboardingSection = (props) => {
  return (
    <div className="JS-Scroll BGSquare">
      <div className="OnboardingSection">
        <NavBar
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          location={props.location}
        />

        <div className="OnboardingBanner">
          <video autoPlay playsInline muted loop className="-Video">
            <source
              src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/Followers.mp4`}
              type="video/mp4"
            />
          </video>
        </div>

        <TheHundred />
        <Footer />

        {/* <DiagonalSection
          i={0}
          direction={"right"}
          usePattern={false}
          topGradient={"#1f2021"}
          bottomGradient={"#1f2021"}
          Component={<TheHundred />}
          customStyle={{ marginTop: "0" }}
        />
        <DiagonalSection
          i={1}
          direction={"right"}
          usePattern={false}
          topGradient={"#170336"}
          bottomGradient={"#170336"}
          Component={<Footer />}
          customStyle={{ paddingBottom: "2rem", minHeight: "auto" }}
        />
        <FooterFill /> */}
      </div>
    </div>
  );
};

export default OnboardingSection;
