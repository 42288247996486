import React from "react";

import "./Phase5.css";

const Phase5 = (props) => {
  return (
    <div className="PhaseContainer">
      <a
        className="apemo-link"
        style={{
          margin: "2rem 0rem",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <img
          src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesNYC/SVG/Open sea logo.svg`}
          style={{
            filter: "brightness(0) invert(1)",
            width: "70px",
            marginRight: "1rem",
          }}
        />
        Purchase on Opensea
      </a>
      <h1 className="Endtitle">The mint has ended</h1>
    </div>
  );
};

export default Phase5;
