import React from "react";
import StickyNav from "../StickyNav/StickyNav";

import DiagonalSection from "../DiagonalSection/DiagonalSection";

import Footer from '../Footer/Footer';
import FooterFill from "../FooterFill/FooterFill";

import PrizeBlock1 from "./PrizeBlocks/PrizeBlock1/PrizeBlock1";

import "./PrizeSection.css";

const OldPrizeSection = (props) => {
  return (
    <div className="JS-Scroll">
      <div className="PrizeSection">
        <div className="LandSection">
          <video autoPlay playsInline muted loop className="VideoBanner">
            <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/clips/Followers.mp4`} type="video/mp4" />
          </video>
        </div>
        <StickyNav
          onLoreClick={props.onLoreClick}
          onScrollClick={props.onScrollClick}
          changeRoute={props.changeRoute}
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          useSmooth={false}
        />

        <DiagonalSection
          i={0}
          direction={"right"}
          usePattern={false}
          Component={<PrizeBlock1 changeRoute={props.changeRoute} />}
          customStyle={{ marginTop: "0" }}
        />
        <DiagonalSection
          i={1}
          direction={"right"}
          usePattern={false}
          Component={<Footer />}
          customStyle={{ paddingBottom: "2rem", minHeight: "auto" }}
        />
        <FooterFill />
      </div>
    </div>
  );
};

export default OldPrizeSection;
