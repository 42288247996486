import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { purchase } from "actions/whitelistActions";

import "./Phase3.css";

const Phase3 = (props) => {
  const isWalletConnected = useSelector(
    (state) => state.block.isWalletConnected
  );

  const isMinting = useSelector((state) => state.whitelist.isMinting);
  const isFetchingData = useSelector((state) => state.block.isFetchingData);

  let minPurchaseCount = 1;
  let maxPurchaseCount = 10;

  const [purchaseCount, setPurchaseCount] = useState(1);

  const handleChangePurchaseCount = (event) => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setPurchaseCount(value);
  };

  useEffect(() => {
    if (!isMinting) setPurchaseCount(1);
  }, [isMinting]);

  const dispatch = useDispatch();

  const onPurchase = (count, whitelistId) => {
    dispatch(purchase(count, whitelistId));
  };
  const validateCount = (count, min, max) => {
    return count >= min && count <= max;
  };

  return (
    <div className="PhaseContainer">
      {isWalletConnected ? (
        <div className="PhaseContent">
          <div className="phase-field">
            <label>Quantity: </label>
            <input
              type="number"
              min={minPurchaseCount}
              max={maxPurchaseCount}
              value={purchaseCount}
              onChange={handleChangePurchaseCount}
              disabled={
                !isWalletConnected ||
                isFetchingData ||
                isMinting ||
                !props.checkNetwork()
              }
            />
          </div>
          <button
            className="phase-purchase-btn"
            onClick={() => onPurchase(purchaseCount)}
            disabled={
              !isWalletConnected ||
              isFetchingData ||
              isMinting ||
              !props.checkNetwork() ||
              maxPurchaseCount <= 0 ||
              !validateCount(purchaseCount, minPurchaseCount, maxPurchaseCount)
            }
          >
            Purchase - 0.1 ETH
          </button>
          <span className="btn-msg">Closes August 24th at 9 AM PST.</span>
          <p>
            The free claim period will run from August 24th at 9:00 AM PST to
            August 27th at 9:00 AM PST.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default Phase3;
