import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Basic } from '../Modal';
import { AiOutlineClose } from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Part1 from './Part1/Part1';
import Part2 from './Part2/Part2';
import Part3 from './Part3/Part3';
import Part4 from './Part4/Part4';
import Part5 from './Part5/Part5';
import Part6 from './Part6/Part6';
import Part7 from './Part7/Part7';
import Part8 from './Part8/Part8';
import Part9 from './Part9/Part9';
import Part10 from './Part10/Part10';
import Part11 from './Part11/Part11';
import Part12 from './Part12/Part12';
import Part13 from './Part13/Part13'
import { Div } from './style';

export const Parts = ({
  isOpen,
  number,
  next,
  preview,
  setIsOpen
}) => {

  const Views = () => {
    switch (number) {
      case 1: return <Part1 />;
      case 2: return <Part2 />;
      case 3: return <Part3 />;
      case 4: return <Part4 />;
      case 5: return <Part5 />;
      case 6: return <Part6 />;
      case 7: return <Part7 />;
      case 8: return <Part8 />;
      case 9: return <Part9 />;
      case 10: return <Part10 />;
      case 11: return <Part11 />;
      case 12: return <Part12 />;
      case 13: return <Part13 />;
      default: return null;
    }
  }
  return (
    <Basic
      zIndex={10}
      isBackground
      isOpen={isOpen}
      scroll
      width="90%"
      height="70vh"
    >
      <Title>Part {number}</Title>
      <Div isFlex justify="flex-end">
        <AiOutlineClose size={30} onClick={() => setIsOpen(false)} />
      </Div>
      <Div isFlex fullWidth fullHeight>
        <IoIosArrowBack size={30} onClick={() => preview()} />
        {Views()}
        <IoIosArrowForward size={30} onClick={() => next()} />
      </Div>
    </Basic>
  )
}

const Title = styled.div`
  color: #FFB300;
  text-align: center;
  font-size: ${isMobile ? '32px' : '42px'};
  font-family: neuropolNova;
  text-transform: uppercase;
`