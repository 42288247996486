import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Div } from 'globalStyles';
import { Title, Text, Background } from "./styles"
import { IoMdArrowDropdown } from 'react-icons/io'
import { ReactComponent as SatoshiHead } from './satoshi_web_apemo.svg'


export const Header = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Background ref={props.refHeader}>
        {/* <SatoshiHeadImg className={isSticky ? 'satoshiSticked' : 'satoshiFixed'} /> */}
        <ImgContainer>
          <ImgApemo src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/apemo army_logo_2png.png`} />
        </ImgContainer>
        <Div isFlex>
          <Link to={'apemo-army-mint'}>
            <MintButton>
              Go To Mint Page
            </MintButton>
          </Link>
        </Div>
        <Div
          margin={props.isMobile ? "0 20px" : "0 20%"}
        >
          <Text size={20}>
            Satoshi’s Legions are expanding! Satoshi The Creator has struck a deal with Captain Apemo to create a new army of soldiers to aid Satoshi in his battle against the Defenders Of Fiat!
            <br />
            <br />
            The Apemo Army will be a new high-fidelity 3D avatar collection that will reward Jose Delbo’s Captain Apemo collection holders as well as Legionnaires and give new collectors an opportunity to join the Satoshiverse prior to the release of the Alpha version of our much anticipated play and earn game!
          </Text>
          <BotContainer
            onClick={() => setOpen(!open)}
            isFlex
            open={open}
            mt={40}
          >
            <IoMdArrowDropdown className="arrow" size={32} color="#4CBBF6" />
            <BotContainerBlue>
              <BotText isUpper weight="bold" size={props.isMobile ? 16 : 32} isCenter>
                Learn all about this new collection below!
              </BotText>
            </BotContainerBlue>
            <IoMdArrowDropdown className="arrow" size={32} color="#4CBBF6" />
          </BotContainer>
        </Div>
      </Background>
      <Title isMobile={props.isMobile} title="The heroes" mt={50} mb={20} />
      <ImgContainerHeroes>
        <VideoHeroes autoPlay
          playsInline
          muted
          loop>
          <source src={`${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesApemo/theHeroesVideo.mp4`} />
        </VideoHeroes>
      </ImgContainerHeroes>
      <DivRelative>
        <div className="loading animated fadeIn">
          <Div isFlex>
            <Text
              background="#c28917"
              color="#202020"
              size={props.isMobile ? 16 : 20}
              width="auto"
              padding=" 0px 30px"
              weight="bold"
              index="2"
            >
            </Text>
          </Div>
          <div className="bg"></div>
        </div>
      </DivRelative>

    </>
  )
}

const MintButton = styled.button`
 margin: 1rem 0rem;

padding: 15px 70px;
font-size: 36px;
letter-spacing: 3px;
border-radius: 4px;

background: rgb(89 171 245);
font-family: dinNext;
font-weight: bold;
text-transform: uppercase;
color: #252525;

border: none;
outline: none;

cursor: pointer;
box-shadow: 0 0 1rem #252525,
  0 0 .8rem #252525,
  0 0 0.1rem #79B9F3,
  0 0 0.1rem #79B9F3,
  0 0 0.5rem #79B9F3,
  inset 0 0 0rem #79B9F3;
`

// const SatoshiHeadImg = styled(SatoshiHead)`


// `

// const VideoContainer = styled.div`
//   position: relative;
//   .arrowVideo {
//   animation: MoveUpDownVideo 1.5s linear infinite;
//   background-color: rgba(0,0,0,0.1);
//   }
//   @keyframes MoveUpDownVideo {
//   0%, 100% {
//     transform: translateY(-40px);
//   }
//   50% {
//     transform: translateY(0);
//   }
//   }
// `

// const Video = styled.video`
//   width: 100%;
//   position: relative;
// `;

// const ArrowsContainer = styled.button`
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   z-index: 1;
//   bottom: 0;
//   left: 50%;
//   background: none;
//   border: none;
//   cursor: pointer;
// `

const BotContainerBlue = styled.div`
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
`

const DivRelative = styled.div`
  position: relative;
  height: auto;

@keyframes MOVE-BG {
	from {
		transform: translateX(0);
	}
	to { 
		transform: translateX(46px);
	}
}


.loading {
	height: auto;
    text-align: center;
    color: #4c3f2e;	
    position: relative;
    overflow: hidden;
	padding: 20px;
}

.bg{
    position: absolute;
    left: -46px;
    right: 0;
    top: 0;
    bottom: 0;
	
  background: repeating-linear-gradient(
    135deg,
    #c28917,
    #c28917 20px,
    transparent 20px,
    transparent 40px
	);
    
    animation-name: MOVE-BG;
	animation-duration: .6s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
`

const ImgContainer = styled.div`
  width: 100%;
  object-position: center;
  object-fit: contain;
  display: flex;
  justify-content: center;
`

const ImgApemo = styled.img`
  width: 100%;
  /* height: 600px; */
  object-fit: contain;
`;

const ImgContainerHeroes = styled.div`
  position: relative;
  &:after {
    content:'';
  position:absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  }
`

const VideoHeroes = styled.video`
  width: 100%;
  object-fit: contain;
`

const BotText = styled(Text)`
  background-color: rgba(115, 250, 255, 0.2);
  color: #4CBBF6;
  letter-spacing: 5px;
  width: auto;
  padding: 6px;
`;

const BotContainer = styled(Div)`
  .arrow {
  animation: MoveUpDown 1.5s linear infinite;
  }
  @keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(-20px);
  }
}
`;