import React from "react";
import "./HomeSection2.css";

import NavBar from "components/NavBar/NavBar";
import SatoshiSocial from "components/SatoshiSocial/SatoshiSocial";
import IntroPart from "./IntroPart/IntroPart";
import AvatarsCarouselPart from "./AvatarsCarouselPart/AvatarsCarouselPart";
import SatoshiLegionsPart from "./SatoshiLegionsPart/SatoshiLegionsPart";
import The100Part from "./The100Part/The100Part";
import LorePart from "./LorePart/LorePart";
import RoadmapPart from "./RoadmapPart/RoadmapPart";

import Footer from "components/Footer/Footer";
import TheHideoutPart from "./TheHideoutPart/TheHideoutPart";

const HomeSection2 = (props) => {
  return (
    <div className="JS-Scroll BGSquare">
      <div className="HomeSection2">
        <NavBar
          width={props.width}
          toggleBurger={props.toggleBurger}
          closeBurger={props.closeBurger}
          isBurgerOpen={props.isBurgerOpen}
          location={props.location}
        />
        <SatoshiSocial />
        <IntroPart />
        <AvatarsCarouselPart
          width={props.width}
          onAvatarClick={props.onAvatarClick}
        />
        <SatoshiLegionsPart />
        <TheHideoutPart />
        <The100Part />
        <LorePart />
        <RoadmapPart />
        <Footer />
      </div>
    </div>
  );
};

export default HomeSection2;
