import { Claim2 } from './Claim2';
import Father from '../Father/';

export default ({
  onLoreClick,
  onScrollClick,
  changeRoute,
  toggleBurger,
  closeBurger,
  isBurgerOpen,
  width,
}) => (
  <Father
    onLoreClick={onLoreClick}
    onScrollClick={onScrollClick}
    changeRoute={changeRoute}
    toggleBurger={toggleBurger}
    closeBurger={closeBurger}
    isBurgerOpen={isBurgerOpen}
    width={width}
    Component={Claim2}
  />
);
