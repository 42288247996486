import React, { Component } from "react";
import "./App.css";

import { Route, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import { createBrowserHistory } from "history";

import routes from "./objects/routes";

import Footer from "components/Footer/Footer";

const replaceHashPath = () => {
  const history = createBrowserHistory();
  const hash = history.location.hash;
  if (hash) {
    const path = hash.replace(/^#!/, "");
    if (path) {
      history.replace(path);
    }
  }
};
replaceHashPath();

class App extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,

      section: "",
      elTarget: -1,
      partTarget: -1,
      tokenTarget: "",
      avatarTarget: "",

      isLocked: false,

      isBurgerOpen: false,
    };
  }

  componentDidMount = () => {
    if (this.props.location.pathname === "/") {
      this.setState({ section: "Home" });
    } else if (this.props.location.pathname === "/lore") {
      this.setState({ section: "Lore" });
    } else if (this.props.location.pathname === "/avatars") {
      this.setState({ section: "Avatars" });
    }

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    if (this.timeout) clearTimeout(this.timeout);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  setIsBlockSectionOpen = (isOpen) => {
    this.setState({
      isBlockSectionOpen: isOpen,
    });
  };

  changeRoute = async (section, path) => {
    this.closeBurger();
    await this.setState({ section });
    this.props.history.push(path);
  };

  onTokenClick = async (token, useSmooth = true) => {
    if (this.state.section !== "Legions") {
      await this.setState({ tokenTarget: token });
      this.changeRoute("Legions", "/game");
    } else {
      await this.setState({ tokenTarget: token });
      this.onScrollIntoToken(token, true);
    }
  };

  onLoreClick = async (i, useSmooth = true) => {
    if (this.state.section !== "Lore") {
      await this.setState({ partTarget: i });
      this.changeRoute("Lore", "/lore");
    } else {
      await this.setState({ partTarget: i });
      this.onScrollIntoPart(i, true);
    }
  };

  onScrollClick = async (i, useSmooth = true) => {
    if (this.state.section !== "Home") {
      await this.setState({ elTarget: i });
      this.changeRoute("Home", "/");
    } else {
      await this.setState({ elTarget: i });
      this.onScrollIntoView(i, true);
    }
  };

  onAvatarClick = async (avatar, useSmooth = true) => {
    if (this.state.section !== "Avatars") {
      await this.setState({ avatarTarget: avatar });
      this.changeRoute("Avatars", "/avatars");
    } else {
      // this.setState({ avatarTarget: avatar }, () => {
      //   this.onScrollIntoAvatar(avatar, true);
      // });
    }
  };

  onScrollIntoView = (i, useSmooth) => {
    let el = document.querySelector(`.Section-${i}`);
    if (el) {
      if (!useSmooth) {
        el.scrollIntoView({});
      } else {
        el.scrollIntoView({ behavior: "smooth" });
      }

      this.closeBurger();
      this.setState({ elTarget: -1 });
    } else {
      console.log("It does not exist");
    }
  };

  onScrollIntoPart = (i, useSmooth) => {
    let el = document.querySelector(`.LorePart-${i}`);
    if (el) {
      if (!useSmooth) {
        el.scrollIntoView({});
      } else {
        el.scrollIntoView({ behavior: "smooth" });
      }

      this.setState({ partTarget: -1 });
    } else {
      console.log("It does not exist");
    }
  };

  onScrollIntoToken = (token, useSmooth) => {
    let el = document.querySelector(`.Token-${token}`);
    if (el) {
      if (!useSmooth) {
        el.scrollIntoView({});
      } else {
        el.scrollIntoView({ behavior: "smooth" });
      }

      this.setState({ tokenTarget: "" });
    } else {
      console.log("It does not exist");
    }
  };

  onScrollIntoAvatar = (avatar, useSmooth) => {
    let el = document.querySelector(`.Avatar-${avatar}`);
    if (el) {
      if (!useSmooth) {
        el.scrollIntoView({});
      } else {
        el.scrollIntoView({ behavior: "smooth" });
      }

      this.setState({ avatarTarget: "" });
    } else {
      console.log("It does not exist");
    }
  };

  toggleBurger = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen,
    });
  };

  closeBurger = () => {
    this.setState({
      isBurgerOpen: false,
    });
  };

  render() {
    return (
      <div className="App">
        {routes.map(({ name, path, Component }) => (
          <Route key={path} exact path={path}>
            {({ match }) => {
              return (
                <CSSTransition
                  in={match != null}
                  timeout={
                    name !== "Home" && name !== "Lore" && name !== "Legions"
                      ? 500
                      : 500
                  }
                  classNames="-"
                  unmountOnExit
                  onEnter={() => {}}
                  onEntered={() => {
                    if (match != null) {
                      if (name === "Home") {
                        if (this.state.elTarget !== -1) {
                          setTimeout(() => {
                            this.onScrollIntoView(this.state.elTarget, true);
                          }, 500);
                        }
                      } else if (name === "Lore") {
                        if (this.state.partTarget !== -1) {
                          setTimeout(() => {
                            this.onScrollIntoPart(this.state.partTarget, true);
                          }, 500);
                        }
                      } else if (name === "Legions") {
                        if (this.state.tokenTarget !== "") {
                          setTimeout(() => {
                            this.onScrollIntoToken(
                              this.state.tokenTarget,
                              true
                            );
                          }, 500);
                        }
                      } else if (name === "Avatars") {
                        if (this.state.avatarTarget !== "") {
                          setTimeout(() => {
                            this.onScrollIntoAvatar(
                              this.state.avatarTarget,
                              true
                            );
                          }, 500);
                        }
                      }
                    }
                  }}
                >
                  <Component
                    width={this.state.width}
                    onLoreClick={this.onLoreClick}
                    onScrollClick={this.onScrollClick}
                    onAvatarClick={this.onAvatarClick}
                    toggleBurger={this.toggleBurger}
                    closeBurger={this.closeBurger}
                    isBurgerOpen={this.state.isBurgerOpen}
                    changeRoute={this.changeRoute}
                    onTokenClick={this.onTokenClick}
                    location={this.props.location}
                  />
                </CSSTransition>
              );
            }}
          </Route>
        ))}
      </div>
    );
  }
}

export default withRouter(App);
