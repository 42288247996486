// import ContentSection from "../components/ContentSection/ContentSection";
// import HomeSection from "../components/HomeSection/HomeSection";
import HomeSection2 from "components/HomeSection2/HomeSection2";
import TeamSection from "../components/TeamSection/TeamSection";
import PrizeSection from "../components/PrizeSection/PrizeSection";
import OldPrizeSection from "../components/PrizeSection/OldPrizeSection";
import OnboardingSection from "../components/OnboardingSection/OnboardingSection";
import LoreSection from "../components/LoreSection/LoreSection";
import Buy from "../components/LaunchSection/Buy";
import HundredForm from "../components/HundredForm";
import Claim from "../components/LaunchSection/Claim/index.jsx";
import ClaimForm from "../components/LaunchSection/Claim/index2.jsx";
import Tables from "../components/Tables";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";
import Apemo from "../components/Apemo";
import QRNYC from "components/QRNYC/QRNYC";

import FormularyBTC from "../components/FormularyBTC/FormularyBTC";
import MailingListPreferences from "../components/MailingList/MailingListPreferences";
import ApemoArmyTest from "components/ApemoArmyTest";
import AvatarsSection from "components/Avatars/AvatarsSection";

import StoreLegionnaires from "../components/Store/StoreLegionnaires";
import StoreApemoArmy from "../components/Store/StoreApemoArmy";
import TheHideoutSection from "components/TheHideout/TheHideoutSection";

const routes = [
  { path: "/", name: "Home", Component: HomeSection2, isDisabled: false },
  { path: "/team", name: "Team", Component: TeamSection, isDisabled: false },
  {
    path: "/the-hideout",
    name: "The Hideout",
    Component: TheHideoutSection,
    isDisabled: false,
  },
  {
    path: "/game",
    name: "Game",
    Component: PrizeSection,
    isDisabled: false,
  },
  {
    path: "/legions",
    name: "Legions",
    Component: OldPrizeSection,
    isDisabled: false,
  },
  {
    path: "/marketplace/legionnaires",
    name: "StoreLegionnaires",
    Component: StoreLegionnaires,
    isDisabled: false,
  },
  {
    path: "/marketplace/apemo-army",
    name: "StoreApemoArmy",
    Component: StoreApemoArmy,
    isDisabled: false,
  },
  {
    path: "/the-100",
    name: "Onboarding",
    Component: OnboardingSection,
    isDisabled: false,
  },
  {
    path: "/apemo-army",
    name: "Apemo",
    Component: Apemo,
    isDisabled: false,
  },
  {
    path: "/rarity",
    name: "Rarity",
    Component: Tables,
    isDisabled: false,
  },
  {
    path: "/terms",
    name: "Terms",
    Component: Terms,
    isDisabled: false,
  },
  {
    path: "/btc2022",
    name: "FormularyBTC",
    Component: FormularyBTC,
    isDisabled: false,
  },
  {
    path: "/unsubscribe",
    name: "MailingListPreferences",
    Component: MailingListPreferences,
    isDisabled: false,
  },
  {
    path: "/privacy",
    name: "Privacy",
    Component: Privacy,
    isDisabled: true,
  },
  {
    path: "/lore",
    name: "Lore",
    Component: LoreSection,
    isDisabled: false,
  },
  {
    path: "/launch",
    name: "Purchase",
    Component: Buy, //LaunchSection,
    isDisabled: false,
  },
  {
    path: "/late-claim",
    name: "Launch",
    Component: Claim,
    isDisabled: false,
  },
  {
    path: "/late-form",
    name: "Launch",
    Component: ClaimForm,
    isDisabled: false,
  },
  {
    path: "/community-100",
    name: "the-100",
    Component: HundredForm,
    isDisabled: false,
  },
  {
    path: "/lacc2022",
    name: "lacc",
    Component: QRNYC,
    isDisabled: false,
  },
  {
    path: "/apemo-army-mint",
    name: "Apemo army mint",
    Component: ApemoArmyTest,
    isDisabled: false,
  },

  {
    path: "/avatars",
    name: "Avatars",
    Component: AvatarsSection,
    isDisabled: false,
  },
];

export default routes;
