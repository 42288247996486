import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { claim } from "actions/whitelistActions";

import "./Phase4.css";

const Phase4 = (props) => {
  const isWalletConnected = useSelector(
    (state) => state.block.isWalletConnected
  );

  const isMinting = useSelector((state) => state.whitelist.isMinting);
  const isFetchingData = useSelector((state) => state.block.isFetchingData);

  // Free Claim allotment (max limit to claim)
  const freeClaimAllotment = useSelector((state) =>
    state.whitelist.whitelistStatuses["freeClaimList"] !== undefined
      ? state.whitelist.whitelistStatuses["freeClaimList"].allotment
      : 0
  );

  // Current claimed amount with the free claim list
  const freeClaimCount = useSelector((state) =>
    state.whitelist.whitelistTokenCounts["freeClaimList"] !== undefined
      ? state.whitelist.whitelistTokenCounts["freeClaimList"]
      : 0
  );

  let minClaimCount = 0,
    maxClaimCount = 0;

  minClaimCount = freeClaimAllotment - freeClaimCount > 0 ? 1 : 0;
  maxClaimCount =
    freeClaimAllotment - freeClaimCount >= 0
      ? freeClaimAllotment - freeClaimCount
      : 0;

  const [claimCount, setClaimCount] = useState(minClaimCount);

  const handleChangeClaimCount = (event) => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setClaimCount(value);
  };

  useEffect(() => {
    if (!isFetchingData) setClaimCount(minClaimCount);
  }, [isFetchingData]);

  useEffect(() => {
    if (!isMinting) setClaimCount(minClaimCount);
  }, [isMinting]);

  const dispatch = useDispatch();

  const onClaim = (count, whitelistId) => {
    dispatch(claim(count, whitelistId));
  };

  const validateCount = (count, min, max) => {
    return count >= min && count <= max;
  };

  return (
    <div className="PhaseContainer">
      {isWalletConnected ? (
        <div className="PhaseContent">
          <span className="YellowText" style={{ fontSize: "24px" }}>
            Free Claim Allotment Remaining: {maxClaimCount}
          </span>

          <div className="phase-field">
            <label>Quantity: </label>
            <input
              type="number"
              min={minClaimCount}
              max={maxClaimCount}
              value={claimCount}
              onChange={handleChangeClaimCount}
              disabled={
                !isWalletConnected ||
                isFetchingData ||
                isMinting ||
                !props.checkNetwork()
              }
            />
          </div>
          <button
            className="phase-purchase-btn"
            onClick={() => onClaim(claimCount, "freeClaimList")}
            disabled={
              !isWalletConnected ||
              isFetchingData ||
              isMinting ||
              !props.checkNetwork() ||
              maxClaimCount <= 0 ||
              !validateCount(claimCount, minClaimCount, maxClaimCount)
            }
          >
            Free Claim
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Phase4;
