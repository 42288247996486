import React from 'react'
import { isMobile } from 'react-device-detect';

import {
  Div,
  Text,
  Button,
  MainContainer,
  ImageContainer,
  Main,
} from '../styled';

const buyPhoto = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/buyPhoto.png`
const rearible = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/rarible.png`
const buy = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/buy.png`
const nifty = `${process.env.REACT_APP_AWS_MEDIA_LINK}/imagesLaunch/nifty.png`

export const Buy = () => {
  return (
    <MainContainer>
      <Div isFlex align="flex-start">
        <ImageContainer>
          <img src={buyPhoto} alt="buyPhoto" />
        </ImageContainer>
        <Div isFlex direction="column" width={!isMobile && "40%"}>
          <Text color="#FFC200" size={40} isCenter mb={100}>
            You can find us on:
          </Text>
          <Div>
            <a href="https://opensea.io/collection/legionnaires" target="_blank">
              <Button mb={60}>
                <img src={buy} alt="openSea" />
                <Text pl={25} size={25}>
                  Opensea
                </Text>
              </Button>
            </a>
            <a href="https://rarible.com/legionnaires" target="_blank">
              <Button mb={60}>
                <img src={rearible} alt="rearible" />
                <Text pl={25} size={25} pr={20}>
                  Rarible
                </Text>
              </Button>
            </a>
            <a href="https://niftygateway.com/marketplace/collectible/0x5041a99684d38e280e4b0b356185bf18c991f88b" target="_blank">
              <Button mb={60}>
                <img src={nifty} alt="nifty" />
                <Text pl={25} size={25} pr={20}>
                  NiftyGateway
                </Text>
              </Button>
            </a>
          </Div>
        </Div>
      </Div>
    </MainContainer>

  )
}
